<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }} {{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <!-- The keydown and keyup actions are placed here to resolve issues involving using the browsers autocomplete -->
  <input
    matInput
    [autocomplete]="autocomplete ? autocomplete : 'postal-code'"
    [formControl]="postalCodeControl"
    [required]="required"
    [mask]="mask"
    [attr.data-cy]="dataCy"
    [placeholder]="placeholder"
    (keydown)="removeMask()"
    [name]="id ? id : null"
    [id]="id ? id : null"
  />

  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
