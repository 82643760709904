import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TreeListOption } from '../../enums/account-list-light.enum';
import { FlatNode } from '../../interfaces/account-list-light.interface';

@Component({
  selector: 'ltlcc-account-actions',
  templateUrl: './account-actions.component.html',
  styleUrls: ['./account-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-AccountActions' },
})
export class AccountActionsComponent {
  @Input() node: FlatNode;
  @Input() enableDelete: boolean;
  @Output() deleteAccount = new EventEmitter<FlatNode>();

  TreeListOption = TreeListOption;

  reload(): void {
    this.node.data.rawData.reload();
  }

  emitDeleteAccount(event: Event, account: FlatNode): void {
    event.stopPropagation();
    this.deleteAccount.emit(account);
  }
}
