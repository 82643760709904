import { AccountType } from '@ltlc/api';

export enum TreeListOption {
  defaultXPOAccount = 'defaultXPOAccount',
  newAddress = 'newAdress',
  sameAsRequester = 'sameAsRequester',
  individual = 'individual',
  reload = 'reload',
  loader = '__loader',
}

export enum ListIdPrefix {
  Associated = 'associated_',
  Flat = 'flat_',
  Shipper = 'shipper_',
  AddressBook = 'addressBook_',
  User = 'user_',
}

export enum AccountAddressLocationType {
  address = 'A',
  userAddress = 'U',
  shipperAccount = 'S',
}

export enum AccountListFilter {
  PREFERRED_ACCOUNTS = 'PREFERRED_ACCOUNTS',
  ALL_ACCOUNTS = 'ALL_ACCOUNTS',
}

export type AccountListLocationType = AccountType | AccountAddressLocationType | string;

export enum AccountCreditStatusCode {
  C = 'C', // Credit
  N = 'N', // No credit
}

export enum AccountListAction {
  //TODO: Add commented (other ticket)
  // myXpoAccount = 'myXpoAccount',
  newAddress = 'newAddress',
  // sameAsRequestor = 'sameAsRequestor',
}
