import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, NgZone, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[ltlccRemoveCharacters]',
})
export class RemoveCharactersDirective implements OnInit {
  static readonly RemoveCharactersRegex = {
    cleanEmailInputRegex: /[\s,]/g, // Removes spaces and commas. TODO: Improve per requirements
  };

  @Input('ltlccRemoveCharacters') regex: RegExp;

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent): void {
    this.replace(event);
  }

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    @Optional() private parentControl?: NgControl
  ) {}

  ngOnInit(): void {
    this.replace();
  }

  private get inputEl(): HTMLInputElement {
    return this.el.nativeElement;
  }

  private replace(keyboardEvent?: KeyboardEvent): void {
    if (!this.regex) {
      return;
    }

    keyboardEvent?.stopImmediatePropagation();

    this.inputEl.value = this.inputEl.value.replace(this.regex, '');

    if (this.parentControl?.control) {
      if (this.parentControl.control.value !== this.inputEl.value) {
        // prevent change after checked error
        this.zone.onStable.pipe(take(1)).subscribe(() => {
          this.parentControl.control.setValue(this.inputEl.value);
        });
      }
    }

    this.cd.markForCheck();
  }
}
