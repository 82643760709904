import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { InquirerInfo } from '../api-shared/interfaces/api-shared.interface';
import { CreatePurBody, PickupRequestApiService } from '../pickup-requests';
import { CustomerProfileRequester } from './../pickup-requests/interfaces';

export const MockPickupRequestService: Provider = {
  provide: PickupRequestApiService,
  useValue: <PickupRequestApiService>{
    pickupRequestURI: '',
    getCustomerPickupRequestDetail: (pickupReferenceNumber: string) => of({}),
    createPickupRequest: (pickupRequest: CreatePurBody) => of(''),
    updatePickupRequest: (pickupRequest: CreatePurBody, confirmationNumber: string): Observable<string> => of(''),
    cancelPickupRequest: (confirmationNumber: string, cancelReason: string): Observable<string> => of(''),
    deletePickupItem: (confirmationNumber: string, itemIndex: number): Observable<string> => of(''),
    getShipperCustomerProfiles: (profileInstId: string): Observable<InquirerInfo[]> => of([]),
    getCustomerProfile: (profileInstId: string): Observable<CustomerProfileRequester> =>
      of(<CustomerProfileRequester>{}),
  },
};

export const MockPickupRequestApiService = MockPickupRequestService;
