import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  BaseWebGridDetailRequestSnapshot,
  BaseWebGridDetailSnapshotResponse,
  BaseWebGridItemResponse,
  FreightBillCopyPdfData,
  FreightBillCopyPdfRequest,
  LoadFormRequest,
  WebActionRequest,
  WebActionResponse,
  WebFormBody,
  WebFormSubmitFormRequest,
  WebFormSubmitFormResponse,
  WebGridDetailRequest,
  WebGridDetailResponse,
  WebGridRefreshRequest,
  WebGridRequest,
  WebGridSearchResponse,
  WebSubmissionApiService,
} from '../web-submission';

export const MockWebSubmissionApiService: Provider = {
  provide: WebSubmissionApiService,
  useValue: {
    webSubmissionUri: '',
    submitForm(request: WebFormSubmitFormRequest): Observable<WebFormSubmitFormResponse> {
      return of(<WebFormSubmitFormResponse>{});
    },
    getNewForm(oadFormRequest: LoadFormRequest): Observable<WebFormBody> {
      return of(<WebFormBody>{});
    },
    getWebAction<T = any>(webActionRequest: WebActionRequest): Observable<WebActionResponse<T>> {
      return of(<WebActionResponse<T>>{});
    },
    getGridSearch<T extends BaseWebGridItemResponse = BaseWebGridItemResponse>(
      request: WebGridRequest
    ): Observable<WebGridSearchResponse<T>> {
      return of(<WebGridSearchResponse<T>>{});
    },
    gridRefresh: (request: WebGridRefreshRequest): Observable<null> => of(null),
    getGridDetail: <
      K extends BaseWebGridDetailRequestSnapshot = BaseWebGridDetailRequestSnapshot,
      T extends BaseWebGridDetailSnapshotResponse = BaseWebGridDetailSnapshotResponse
    >(
      request: WebGridDetailRequest<K>
    ): Observable<WebGridDetailResponse<T>> => of(<WebGridDetailResponse<any>>{}),
    getfreightBillPdf: (_request: FreightBillCopyPdfRequest): Observable<FreightBillCopyPdfData> =>
      of(<FreightBillCopyPdfData>{}),
  },
};
