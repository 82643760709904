<mat-form-field appearance="outline">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select [required]="required" [formControl]="freightControl">
    <mat-option [value]="class.value" *ngFor="let class of classes">{{ class.label }}</mat-option>
  </mat-select>
  <!-- We don't want to show errors if there is a commodityClass route since we always want to show that route -->
  <mat-error *ngIf="!commodityClassRoute" ltlccFormErrorMessage></mat-error>
  <mat-hint *ngIf="commodityClassRoute">
    <a [href]="commodityClassRoute" target="_blank">{{ hint }}</a>
  </mat-hint>
</mat-form-field>
