export enum PaymentType {
  card = 1, // Card (Default),
  cardOnFile = 2,
  giftCard = 3,
  rewardCard = 4,
}
export enum ButtonTypeNumeric {
  buy = 1,
  donate = 2,
  next = 3,
  pay = 4, // Pay (Default)
  save = 5,
  buyNow = 6,
  payNow = 7,
}

export enum CardNumberPlaceholderType {
  blank = 1,
  cardNumbers = 2, //  4111111111111111 (Default)
  enterCardNumber = 3,
}

export enum ExpirationDatePlaceholder {
  blank = 1,
  date = 2, // MM/YY (Default)
}

export enum PostalCodePlaceholderType {
  blank = 1,
  numbers = 2, // 11111 (Default)
  enterPostalCode = 3,
}

export enum SecurityCodePlaceholderType {
  blank = 1,
  numbers = 2, // 123 (Default)
  cvc = 3,
  cvv = 4,
  cvcNumber = 5,
  cvvNumber = 6,
}

export enum ValidationMessageType {
  none = 1,
  feedback = 2, // Feedback (Default)
  tooltip = 3,
}

export enum CardIconDisplayType {
  dynamic = 1, // (Default)
  fixed = 2,
  hidden = 3,
}

export type CultureCode =
  | 'de-DE' // German
  | 'en-US' //English (default)
  | 'es-ES' // Spanish
  | 'fr-CA' // French Canadian
  | 'fr-FR' // French
  | 'it-IT'; // Italian

export interface QuoteRequest {
  // From HPC Freedompay Docs
  buttonType?: ButtonTypeNumeric;
  cardNumberPlaceholderType?: CardNumberPlaceholderType;
  expirationDatePlaceholder?: ExpirationDatePlaceholder;
  postalCodePlaceholderType?: PostalCodePlaceholderType;
  securityCodePlaceholderType?: SecurityCodePlaceholderType;
  validationMessageType?: ValidationMessageType;
  paymentType?: PaymentType;
  styles?: string;
  cardIconDisplayType?: CardIconDisplayType;
  cultureCode?: CultureCode | string; // https://github.xpo.com/OneXPO/ltl-freedompay-api/pull/24/files

  // XPO added
  cardToken?: string;
  referenceNumber: string;
  referenceType: string;
  customerCode: string;
  partnerCodes?: string[];
  isHPCInit?: boolean; // For onboarding
  emailAddress?: string;
  phoneNumber?: string;
  isVerifyOnboardingRequest?: boolean;
}
export interface CreditCardIframeResponse {
  iframeUrl: string;
  sessionKey: string;
  context?: string;
}
