import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URI } from '../tokens';
import { Unauth } from './enums';
import {
  AddressBook,
  AddressBookApiResponse,
  AddressBookData,
  AddressBooksApiResponse,
  CommoditiesApiResponse,
  CommoditiesDeleteApiResponse,
  CommodityApiResponse,
  CommodityItemApiResponse,
  CommodityItemRequest,
  CommodityItemResponse,
  NewRegistration,
  NewRegistrationResponse,
  NewRegistrationResponseData,
  PasswordResetApiRequest,
  PasswordResetApiResponse,
  PasswordResetApiResponseData,
  UpdateFavoriteShipmentResponseData,
} from './interfaces';
import { FlatAccountData, FlatAccountListResponse } from './interfaces/accounts.interface';

@Injectable({
  providedIn: 'root',
})
export class UserMaintenanceService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get userMaintenanceURI(): string {
    return `${this.apiUri}/usermaintenance/1.0/`;
  }

  getCommodities(): Observable<CommodityItemApiResponse[]> {
    const url = `${this.userMaintenanceURI}user-commodities`;
    return this.httpClient.get<CommoditiesApiResponse>(url).pipe(
      map((response) => {
        return response.data.userCommodities;
      })
    );
  }

  getCommodityById(commodityId: number): Observable<CommodityItemApiResponse> {
    const url = `${this.userMaintenanceURI}user-commodities/${commodityId}`;
    return this.httpClient.get<CommodityApiResponse>(url).pipe(
      map((response) => {
        return response.data.userCommodity;
      })
    );
  }

  saveCommodity(request: CommodityItemRequest): Observable<CommodityItemResponse> {
    const url = `${this.userMaintenanceURI}user-commodities`;
    return this.httpClient.post<CommodityItemResponse>(url, request);
  }

  updateCommodity(request: CommodityItemRequest, commodityId: number): Observable<CommoditiesDeleteApiResponse> {
    const url = `${this.userMaintenanceURI}user-commodities/${commodityId}`;
    return this.httpClient.put<CommoditiesDeleteApiResponse>(url, request);
  }

  deleteCommodity(commodityId: number | string): Observable<CommoditiesDeleteApiResponse> {
    const url = `${this.userMaintenanceURI}user-commodities/${commodityId}`;
    return this.httpClient.delete<CommoditiesDeleteApiResponse>(url);
  }

  getFlatAccountList(accountIds: string[], excludeParentsIndicator = false): Observable<FlatAccountData[]> {
    if (!accountIds || accountIds.length === 0) {
      return of([]);
    }
    const url = new URL(`${this.userMaintenanceURI}flattened-account-tree`);

    if (Array.isArray(accountIds)) {
      accountIds = [...new Set(accountIds)].sort();
      for (const accountId of accountIds) {
        url.searchParams.append('accountIds', accountId);
      }
    }
    url.searchParams.append('excludeParentsIndicator', excludeParentsIndicator.toString());
    url.searchParams.append('format', 'json');

    return this.httpClient.get<FlatAccountListResponse>(url.toString()).pipe(
      map((result) => {
        return result.data.accounts;
      })
    );
  }

  newRegistration(newRegistration: NewRegistration): Observable<NewRegistrationResponseData> {
    const url = `${this.userMaintenanceURI}user-accounts/new-registration`;
    return this.httpClient.post<NewRegistrationResponse>(url, newRegistration).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  passwordReset(resetPasswordData: PasswordResetApiRequest): Observable<PasswordResetApiResponseData> {
    const url = `${this.userMaintenanceURI}users/password-resets`;
    const options = {};
    // All membership API's are allowed anonymously
    options['headers'] = {
      [Unauth.allowAnonymous]: '',
    };

    return this.httpClient.post<PasswordResetApiResponse>(url, resetPasswordData).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  updateFavoriteShipment(shipmentInstanceId: number): Observable<UpdateFavoriteShipmentResponseData> {
    const url = `${this.userMaintenanceURI}favorite-shipments`;

    return this.httpClient.put<any>(url, { shipmentInstanceId }).pipe(map((v) => v.data));
  }

  getAddressBookEntries(): Observable<AddressBook[]> {
    const url = `${this.userMaintenanceURI}address-book-entries`;
    return this.httpClient.get<AddressBooksApiResponse>(url).pipe(
      map((response: AddressBooksApiResponse) => {
        return response.data.addressBookEntry;
      })
    );
  }

  deleteAddressBook(sequenceNbr: number): Observable<AddressBookApiResponse> {
    const url = `${this.userMaintenanceURI}address-book-entries/${sequenceNbr}`;
    return this.httpClient.delete<AddressBookApiResponse>(url);
  }

  addNewAddressBook(addressBook: AddressBookData): Observable<AddressBook> {
    const url = `${this.userMaintenanceURI}address-book-entry`;
    return this.httpClient.post<AddressBookApiResponse>(url, addressBook).pipe(
      map((response: AddressBookApiResponse) => {
        return response.data.addressBookEntry;
      })
    );
  }

  saveAddressBook(addressBook: AddressBookData, sequenceNbr: number): Observable<AddressBookApiResponse> {
    const url = `${this.userMaintenanceURI}address-book-entries/${sequenceNbr}`;
    return this.httpClient.put<AddressBookApiResponse>(url, addressBook).pipe(
      map((response: AddressBookApiResponse) => {
        return response;
      })
    );
  }
}
