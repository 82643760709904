import { InjectionToken } from '@angular/core';

export interface LtlConnectApplicationRoutesData {
  getCommodityClassCalculator?: () => string;
  getRateQuotes?: (accountId?: string | number) => string;
  getAddressBook?: () => string;
  getTariffLibrary?: () => string;
}

export const LTL_CONNECT_APPLICATION_ROUTES = new InjectionToken<LtlConnectApplicationRoutesData>(
  'LTL_CONNECT_APPLICATION_ROUTES'
);
