import { FlatTreeControl } from '@angular/cdk/tree';
import { TitleCasePipe } from '@angular/common';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import {
  AccountType,
  Address,
  AddressBook,
  CountryCode,
  FlatAccountData,
  InquirerInfo as ShipperCustomerProfileAddress,
  LocationTypePM,
  LocationUser,
  PreferredAccount,
  UserAccount,
} from '@ltlc/api';
import { ArraySortHelper } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import * as deepmerge from 'deepmerge';
import { CountryCodeHelper } from '../../../helpers/country-code.helper';
import { WebUser, WebUserProfile } from '../../../services/web-user.interface';
import { AccountForm } from '../../account-form/account-form.interface';
import { defaultDockingInterval } from '../../form-controls/docking-interval/docking-interval.helper';
import { AccountAddressLocationType, AccountListFilter, ListIdPrefix } from '../enums';
import { StorageAccountsService } from '../services/accounts-storage.service';
import { AccountListLightTreeNode, FlatNode } from './../interfaces/account-list-light.interface';

const titleCasePipe = new TitleCasePipe();

interface FlatAccountDataChildren extends FlatAccountData {
  children?: FlatAccountDataChildren[];
}

export class AccountListHelper {
  static readonly mapPMLocationTypeWithAccountType = {
    [LocationTypePM.pickupDelivery]: AccountType.pnd,
    [LocationTypePM.billTo]: AccountType.billTo,
    [LocationTypePM.corporate]: AccountType.corporate,
  };
  static readonly autocompleteAccountHintClass = 'ltlcc-AccountListTreeAutocompleteWrapper-field-hint';

  static generateListId(id: string, listIdPrefix: ListIdPrefix): string {
    return `${listIdPrefix}${id}`;
  }

  static removeListIdPrefix(id: string): string {
    if (!id?.length) {
      return '';
    }

    const regex = new RegExp(
      [
        ListIdPrefix.Associated,
        ListIdPrefix.Flat,
        ListIdPrefix.Shipper,
        ListIdPrefix.AddressBook,
        ListIdPrefix.User,
      ].join('|'),
      'g'
    );
    return id.replace(regex, '');
  }

  static mapUserGeneralInfo(userInfo: WebUser, translate: TranslateService): AccountListLightTreeNode | null {
    if (!userInfo) {
      return null;
    }
    const data: WebUser = deepmerge({}, userInfo);
    const listId = `${ListIdPrefix.User}${data.userName}`;
    const contactFullName = data.profile.firstName + ' ' + data.profile.lastName;
    const companyName = data.profile.companyName ?? contactFullName ?? null;
    const companyNameTranslate = companyName ? `- ${companyName}` : '';
    return {
      name: translate.instant('accountListLight.defaultXPOAccount', { companyName: companyNameTranslate }),
      address: AccountListHelper.address(data.profile),
      type: translate.instant(`locationType.A`),
      listId,
      rawData: {
        data,
        id: data.userName,
      },
      addressForm: <AccountForm>{
        companyName,
        countryCode: data.profile.countryCode,
        addressLine1: data.profile.addressLine1,
        addressLine2: data.profile.addressLine2,
        cityName: data.profile.cityName,
        stateCode: data.profile.stateCode,
        postalCode: data.profile.postalCode,
        phoneNumber: data.profile.phoneNumber,
        phoneExtension: data.profile.phoneAreaCode,
        email: data.profile.email,
        contactFullName,
        contactPhoneNumber: data.profile.phoneNumber,
        contactEmail: data.profile.email,
        locationType: AccountAddressLocationType.userAddress,
        listId,
        aliasName: translate.instant('accountListLight.defaultXPOAccount', { companyName: companyNameTranslate }),
        dockingInterval: defaultDockingInterval,
        comment: null,
      },
      matIconName: 'account_circle',
    };
  }

  static mapAddressBook(address: AddressBook, translate: TranslateService): AccountListLightTreeNode {
    const data: AddressBook = deepmerge({}, address);
    const name = titleCasePipe.transform(data.companyName);
    const listId = `${ListIdPrefix.AddressBook}${data.sequenceNbr}_${data.recordVersionNbr}`;
    const mapped = {
      name,
      address: AccountListHelper.address({
        addressLine1: data.companyAddressLine1,
        cityName: data.companyCityName,
        stateCode: data.companyStateCd,
        postalCode: data.companyPostalCd,
      }),
      type: translate.instant(`locationType.A`),
      listId,
      rawData: {
        data,
        id: data.userSysId.toString(),
      },
      addressForm: <AccountForm>{
        aliasName: name,
        listId,
        dataId: data.sequenceNbr?.toString(),
        companyName: data.companyName?.trim(),
        countryCode: CountryCodeHelper.handleBadCanadaCode(data.companyCountryCd, false),
        addressLine1: data.companyAddressLine1,
        addressLine2: null,
        cityName: data.companyCityName,
        stateCode: data.companyStateCd,
        postalCode: data.companyPostalCd,
        phoneNumber: data.companyPhoneNumber,
        phoneExtension: null,
        email: data.companyEmailAddress,
        contactFullName: data.contactName,
        contactPhoneNumber: data.contactPhoneNumber,
        contactEmail: data.contactEmailAddress,
        dockingInterval: {
          open: data.dockOpenTime,
          close: data.dockCloseTime,
        },
        comment: null,
        locationType: data.locationTypeCd ?? '',
      },
    };

    return mapped;
  }

  static mapNestedFlatAccounts(
    accounts: FlatAccountData[],
    translate: TranslateService,
    storageAccountsService: StorageAccountsService,
    preferredAccounts?: PreferredAccount[]
  ): AccountListLightTreeNode[] {
    const flatAccountMapped = (): FlatAccountDataChildren[] => {
      const sortedAccounts: FlatAccountDataChildren[] = ArraySortHelper.orderBy([...accounts], ['parentAccountIds']);
      const list: FlatAccountDataChildren[] = [];
      while (sortedAccounts.length) {
        const lastAccount = sortedAccounts.slice(-1)[0];
        const lastAccountParentAccountId = lastAccount.parentAccountIds.slice(-1)[0]?.toString();
        const lastAccountId = lastAccount.accountId.toString();
        sortedAccounts.pop();

        let found = false;
        let pushed = false;
        for (let i = 0; i < sortedAccounts.length; i++) {
          if (!Array.isArray(sortedAccounts[i].children)) {
            sortedAccounts[i].children = [];
          }
          const account = sortedAccounts[i];
          const accountId = account.accountId.toString();
          const parentAccountId = account.parentAccountIds.slice(-1)[0]?.toString();

          if (lastAccountParentAccountId === accountId) {
            pushed = true;
            sortedAccounts[i].children.push(lastAccount);
            if (i === sortedAccounts.length - 1) {
              sortedAccounts[i].children = ArraySortHelper.orderBy(sortedAccounts[i].children, ['name']);
            }
          }

          if (parentAccountId === lastAccountId) {
            found = true;
            lastAccount.children.push(account);
            lastAccount.children = ArraySortHelper.orderBy(lastAccount.children, ['name']);
          }
        }

        if (!found && !pushed) {
          list.push(lastAccount);
        }

        if (found) {
          list.push(lastAccount);
        }
      }

      return list;
    };

    const mappedFlatData = flatAccountMapped();

    const mapToAccountList = (accounts: FlatAccountDataChildren[]): AccountListLightTreeNode[] => {
      return (accounts ?? []).map((account) => {
        let newAccount = AccountListHelper.mapFlatAccount(account, translate);
        newAccount.disabled = storageAccountsService.storedRemovedAccounts[newAccount.addressForm.dataId] ?? false;
        newAccount.children = mapToAccountList(account.children);
        newAccount = AccountListHelper.checkPreferredAccount(newAccount, newAccount.rawData.id, preferredAccounts);
        return newAccount;
      });
    };

    const mapped = mapToAccountList(mappedFlatData);

    return mapped;
  }

  static mapShipperAccount(
    account: ShipperCustomerProfileAddress,
    translate: TranslateService
  ): AccountListLightTreeNode {
    const rawData = {
      id: account.acctInstId,
      data: deepmerge({}, account),
    };
    const name = titleCasePipe.transform(rawData.data.contactInfo.companyName);
    const listId = `${ListIdPrefix.Shipper}${account.acctInstId}`;
    return <AccountListLightTreeNode>{
      name,
      address: AccountListHelper.address({
        addressLine1: rawData.data.address.addressLine1,
        cityName: rawData.data.address.cityName,
        stateCode: rawData.data.address.stateCd,
        postalCode: rawData.data.address.postalCd,
      }),
      type: translate.instant(`locationType.P`),
      listId,
      children: [],
      rawData,
      addressForm: <AccountForm>{
        aliasName: name,
        listId,
        dataId: rawData.data.acctInstId.toString(),
        companyName: rawData.data.contactInfo.companyName?.trim(),
        countryCode: CountryCodeHelper.handleBadCanadaCode(account.address.countryCd, false),
        addressLine1: account.address.addressLine1,
        cityName: account.address.cityName,
        postalCode: account.address.postalCd,
        stateCode: account.address.stateCd,
        dockingInterval: defaultDockingInterval,
      },
    };
  }

  static populateWthDefaultUserContactInfo(
    nodes: AccountListLightTreeNode[],
    userInfo?: AccountListLightTreeNode
  ): AccountListLightTreeNode[] {
    if (Array.isArray(nodes) && userInfo) {
      for (const node of nodes) {
        node.addressForm = node.addressForm || {};
        node.addressForm.contactEmail = node.addressForm.contactEmail || userInfo.addressForm.contactEmail;
        node.addressForm.email = node.addressForm.email || userInfo.addressForm.contactEmail;
        node.addressForm.contactFullName = node.addressForm.contactFullName || userInfo.addressForm.contactFullName;
        node.addressForm.contactPhoneNumber =
          node.addressForm.contactPhoneNumber || userInfo.addressForm.contactPhoneNumber;
        node.addressForm.phoneNumber = node.addressForm.phoneNumber || userInfo.addressForm.contactPhoneNumber;
        node.addressForm.countryCode =
          CountryCodeHelper.handleBadCanadaCode(node.addressForm.countryCode, false) || CountryCode.UNITED_STATES;
        AccountListHelper.populateWthDefaultUserContactInfo(node.children, userInfo);
      }
    }

    return nodes;
  }

  static orderByFavoriteAndName(nodes: AccountListLightTreeNode[]): AccountListLightTreeNode[] {
    if (!Array.isArray(nodes)) {
      return [];
    }
    return nodes.sort((a: AccountListLightTreeNode, b: AccountListLightTreeNode) => {
      let order = a.name > b.name ? 1 : -1;
      if (a.matIconName === 'star') {
        order = -1;
      } else if (b.matIconName === 'star') {
        order = 1;
      }
      return order;
    });
  }

  static checkPreferredAccount(
    node: AccountListLightTreeNode,
    accountId: string,
    preferredAccounts: PreferredAccount[]
  ): AccountListLightTreeNode {
    if (Array.isArray(preferredAccounts) && typeof accountId === 'string') {
      for (const preferredAccount of preferredAccounts) {
        for (const preferredAccountAcc of preferredAccount.acctInfo) {
          if (accountId === preferredAccountAcc.acctInstId.toString()) {
            node.matIconName = 'star';
            // TODO: add fallback for accountTypeList and applicationName in rawData
            break;
          }
        }
      }
    }

    return node;
  }

  static mapAssociatedAccount(account: UserAccount, translate: TranslateService): AccountListLightTreeNode {
    const rawData = {
      data: deepmerge({}, account),
      id: account.acctInstId,
    };
    const listId = this.generateListId(account.acctInstId, ListIdPrefix.Associated);
    const name = titleCasePipe.transform(account.acctName?.trim());
    return <AccountListLightTreeNode>{
      name,
      address: this.address({
        addressLine1: account.address.addressLine1,
        cityName: account.address.addressLine2,
        stateCode: account.address.stateCd,
        postalCode: account.address.postalCd,
        countryCode: <CountryCode>CountryCodeHelper.handleBadCanadaCode(account.address.countryCd, false),
      }),
      type: translate.instant(`locationType.${account.locationTypeCode}`),
      listId,
      children: [],
      rawData,
      addressForm: <AccountForm>{
        aliasName: name,
        companyName: account.acctName.trim(),
        listId,
        dataId: account.acctInstId.toString(),
        addressLine1: account.address.addressLine1,
        cityName: account.address.cityName,
        stateCode: account.address.stateCd,
        dockingInterval: defaultDockingInterval,
        postalCode: account.address.postalCd,
        locationType: account.locationTypeCode,
        countryCode: <CountryCode>CountryCodeHelper.handleBadCanadaCode(account.address.countryCd, false),
        madCode: account.acctMadCd,
      },
      searchFields: [
        {
          value: account.acctInstId,
          label: translate.instant('accountListLight.idAccountOptionLabel'),
        },
        {
          value: account.acctMadCd,
          label: translate.instant('accountListLight.madCodeOptionLabel'),
        },
      ],
    };
  }

  static filterByLocationTypes(
    nodes: AccountListLightTreeNode[],
    locationTypes: (AccountType | string)[],
    includeUnknownLocationType = true
  ): AccountListLightTreeNode[] {
    if (!Array.isArray(nodes) || !Array.isArray(locationTypes) || !nodes.length) {
      return Array.isArray(nodes) ? nodes : [];
    }
    return [...nodes]
      .filter((node: AccountListLightTreeNode) => {
        if (includeUnknownLocationType && !node.addressForm?.locationType) {
          return true;
        }
        return locationTypes.includes(<AccountType>node.addressForm?.locationType);
      })
      .map((node: AccountListLightTreeNode) => {
        node = deepmerge(node, {});
        node.children = AccountListHelper.filterByLocationTypes(node.children, locationTypes);
        return node;
      });
  }

  static mapFlatAccount(flatAccount: FlatAccountData, translate: TranslateService): AccountListLightTreeNode {
    const rawData = {
      id: flatAccount.accountId.toString(),
      data: <FlatAccountData>deepmerge({}, flatAccount),
    };

    const listId = this.generateListId(flatAccount.accountId.toString(), ListIdPrefix.Flat);
    const name = titleCasePipe.transform(flatAccount.name);
    const mapped = <AccountListLightTreeNode>{
      name,
      address: AccountListHelper.address({
        addressLine1: flatAccount.addressLine1,
        cityName: flatAccount.cityName,
        stateCode: flatAccount.stateCode,
        postalCode: flatAccount.postalCode,
      }),
      type: translate.instant(`locationType.${flatAccount.locationType}`),
      listId,
      children: [],
      rawData,
      addressForm: <AccountForm>{
        listId,
        dataId: flatAccount.accountId.toString(),
        aliasName: name,
        countryCode: CountryCodeHelper.handleBadCanadaCode(flatAccount.countryCode, false),
        companyName: flatAccount.name.trim(),
        addressLine1: flatAccount.addressLine1,
        cityName: flatAccount.cityName,
        stateCode: flatAccount.stateCode,
        dockingInterval: defaultDockingInterval,
        postalCode: flatAccount.postalCode,
        locationType: flatAccount.locationType,
        madCode: flatAccount.madCode,
      },
      searchFields: [
        {
          value: flatAccount.accountId,
          label: translate.instant('accountListLight.idAccountOptionLabel'),
        },
        {
          value: flatAccount.madCode,
          label: translate.instant('accountListLight.madCodeOptionLabel'),
        },
      ],
    };

    return mapped;
  }

  static address(address: Partial<WebUserProfile>): string {
    return [
      address.addressLine1 ?? null,
      address.cityName ? titleCasePipe.transform(address.cityName) : null,
      address.stateCode ?? null,
      address.postalCode ?? null,
    ]
      .map((name) => name?.trim())
      .filter((v) => !!v)
      .join(', ');
  }

  static transformer = (node: AccountListLightTreeNode, level: number): FlatNode => {
    return {
      data: node,
      expandable: Array.isArray(node.children) && node.children.length > 0,
      level: level,
    };
  };

  static MatTreeFlatDataSourceAccountList(initialTreeData?: AccountListLightTreeNode[]) {
    const treeControl = new FlatTreeControl<FlatNode>(
      (node) => node.level,
      (node) => node.expandable
    );

    const treeFlattener = new MatTreeFlattener(
      AccountListHelper.transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.children
    );
    return {
      datasource: new MatTreeFlatDataSource(treeControl, treeFlattener, initialTreeData),
      treeControl,
      treeFlattener,
    };
  }

  // TODO: Do we need to filter for status === ACTIVE?
  static mapPartnerLocationsToAccountListNode(
    locationUsers: LocationUser[],
    translate: TranslateService
  ): AccountListLightTreeNode[] {
    let nodeList: AccountListLightTreeNode[] = [];
    locationUsers.forEach((location) => {
      const dataId = location.accountInstId ?? location.custLocationFuncId;
      const listId = `${ListIdPrefix.Flat}${dataId || location.locationId || location.partnerIdentifier || ''}`;
      const locationTypePM = AccountListHelper.mapPMLocationTypeWithAccountType[location.address.locationTypeCd];
      nodeList.push(<AccountListLightTreeNode>{
        name: location.address?.companyName?.trim() ?? '',
        address: AccountListHelper.formatAddress(location.address),
        type: translate.instant(`locationType.${locationTypePM}`),
        searchFields: [
          {
            value: location.madCode,
            label: translate.instant('accountListLight.madCodeOptionLabel'),
          },
        ],
        listId,
        children: Array.isArray(location.children)
          ? AccountListHelper.mapPartnerLocationsToAccountListNode(location.children, translate)
          : null,
        addressForm: {
          listId,
          dataId,
          aliasName: location.address.companyName,
          companyName: location.address.companyName,
          countryCode: location.address.countryCd,
          addressLine1: location.address.addressLine1,
          addressLine2: location.address.addressLine2,
          cityName: location.address.cityName,
          stateCode: location.address.stateCd,
          postalCode: location.address.postalCd,
          phoneNumber: null,
          phoneExtension: null,
          email: null,
          contactFullName: null,
          contactPhoneNumber: null,
          contactEmail: null,
          dockingInterval: defaultDockingInterval,
          locationType: locationTypePM,
          comment: null,
          madCode: location.madCode,
        },
        sticky: false,
        rawData: {
          id: dataId,
          data: location,
        },
      });
    });
    return nodeList;
  }

  static getLocationTypeByGroupId(groupId: number): AccountType | string {
    if (groupId === 3) {
      return AccountType.corporate;
    } else if (groupId === 7) {
      return AccountType.pnd;
    } else if (groupId === 8) {
      return AccountType.billTo;
    } else {
      return '';
    }
  }

  static filterData(
    nodes: AccountListLightTreeNode[],
    searchWord: string | undefined,
    filterPreferred: AccountListFilter = AccountListFilter.ALL_ACCOUNTS
  ): AccountListLightTreeNode[] {
    if (!searchWord && filterPreferred === AccountListFilter.ALL_ACCOUNTS) {
      return nodes;
    }

    const filter = (_nodes: AccountListLightTreeNode[]) => {
      _nodes = Array.isArray(_nodes) ? deepmerge([], _nodes) : [];
      let stickyNodes = [];
      const _filtered = _nodes.reduce((acc, node) => {
        node = deepmerge({}, node);
        if (node.sticky) {
          stickyNodes.push(node);
          return acc;
        }
        if (
          JSON.stringify(node).toLowerCase().includes(searchWord?.toLowerCase()) ||
          (filterPreferred === AccountListFilter.PREFERRED_ACCOUNTS && node.matIconName === 'star')
        ) {
          if (node.children?.length) {
            node.children = filter(node.children);
          }
          acc.push(node);
        }
        return acc;
      }, []);

      return stickyNodes.concat(_filtered);
    };

    const filtered = filter(nodes);

    return filtered;
  }

  static formatAddress(address: Address): string {
    const addressElements = [address.addressLine1, address.cityName, address.stateCd, address.postalCd];

    return addressElements.filter((addr) => !!addr).join(', ');
  }
}
