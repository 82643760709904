import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { BannerComponent } from './banner.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  declarations: [BannerComponent],
  exports: [BannerComponent],
})
export class BannerModule {}
