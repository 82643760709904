import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConnectTagComponent } from './tag.component';

@NgModule({
  declarations: [ConnectTagComponent],
  imports: [CommonModule],
  exports: [ConnectTagComponent],
})
export class ConnectTagModule {}
