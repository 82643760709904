import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlsModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { AddressFormModule } from '../address-form/address-form.module';
import { LtlConnectFormControlModule } from '../form-controls/form-controls.module';
import { AccountFormComponent } from './account-form.component';

@NgModule({
  declarations: [AccountFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormControlsModule,
    LtlConnectFormControlModule,
    TranslateModule,
    AddressFormModule,
  ],
  exports: [AccountFormComponent],
})
export class AccountFormModule {}
