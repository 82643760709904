import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateAsAgoPipe } from './date-as-ago.pipe';
import { HumanizePipe } from './humainze.pipe';

@NgModule({
  declarations: [DateAsAgoPipe, HumanizePipe],
  imports: [CommonModule, TranslateModule],
  exports: [DateAsAgoPipe, HumanizePipe],
})
export class ConnectPipeModule {}
