import { Pipe, PipeTransform } from '@angular/core';
import { FlatNode, TreeListConfig } from '../../interfaces/account-list-light.interface';

@Pipe({
  name: 'locationSelectable',
})
export class LocationSelectablePipe implements PipeTransform {
  transform(node: FlatNode, config?: TreeListConfig): boolean {
    return LocationSelectablePipe.isSelectable(node, config);
  }

  static isSelectable(node: FlatNode, config?: TreeListConfig): boolean {
    return !node.expandable || (config?.enableCorporateSelection && node.expandable);
  }
}
