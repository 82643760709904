import { AccountListType, ApplicationID } from '@ltlc/api';
import { AccountListLightTreeNode, AccountSelectItem } from '../interfaces';

export class AccountListLightTreeHelper {
  static mapAccountListLightTreeNodeToAccountSelectItem(data: AccountListLightTreeNode): Partial<AccountSelectItem> {
    const rawData = data.rawData.data;
    return {
      accountId: rawData.accountId || rawData.acctInstId || rawData.accountInstId,
      applicationName: ApplicationID.Rating,
      accountListType: data.matIconName === 'star' ? AccountListType.P : null,
      preferred: data.matIconName === 'star',
      madCode: rawData.madCode || rawData.acctMadCd,
      name: rawData.name || rawData.acctName,
      addressLine1: rawData.addressLine1 || rawData.address.addressLine1,
      cityName: rawData.cityName || rawData.address.cityName,
      stateCode: rawData.stateCode || rawData.address.stateCd,
      countryCode: rawData.countryCode || rawData.address.countryCd,
      postalCode: rawData.postalCode || rawData.address.postalCd,
    };
  }
}
