import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ScrollHelper } from '../../../../helpers/scroll.helper';
import { AlertService } from '../../../alerts/services/alerts.service';
import { InvalidFieldData } from '../interfaces/form-controls.interface';

type ControlElement = FormControl | FormArray | FormGroup;
@Injectable()
export class FormGroupTemplateService {
  private readonly formSubmittedSource$ = new Subject<boolean>();
  readonly formSubmitted$ = this.formSubmittedSource$.asObservable().pipe(distinctUntilChanged());

  constructor(private translate: TranslateService, private alertService: AlertService) {}

  updateFormSubmitted(submitted: boolean): void {
    this.formSubmittedSource$.next(submitted);
  }

  showErroredFieldsInGroup(formGroup: FormGroup, formElement: HTMLElement): void {
    if (formGroup?.invalid) {
      const invalidFields = this.getInvalidFields(formGroup, formElement);
      this.messageInvalidFields(invalidFields, formGroup);
      const element: HTMLElement = invalidFields[0]?.element ?? formElement;
      ScrollHelper.scrollTo(element);
    }
  }

  expandInvalidAccountSelectors(accountPickersToExpand: any[]): void {
    if (accountPickersToExpand) {
      // AccountDetailsComponent but we cannot refer to that here due to circular dependency. TODO: Correct with new account picker
      for (const accountPicker of accountPickersToExpand) {
        if (!accountPicker) {
          continue;
        }
        if ('parentControl' in accountPicker && accountPicker.parentControl.invalid) {
          // For old account picker
          accountPicker.expandPanel();
        }
        if (accountPicker.invalidForm) {
          // For new light account picker
          accountPicker.expandPanel();
        }
      }
    }
  }

  private getInvalidFields(
    abstractControl: ControlElement,
    formElement: HTMLElement,
    invalidFields: InvalidFieldData[] = [],
    recursive = false,
    field?: string
  ): InvalidFieldData[] {
    if ('controls' in abstractControl) {
      if (Array.isArray(abstractControl.controls)) {
        // Form Array
        const formArray = <FormArray>abstractControl;
        if (abstractControl.controls.length) {
          for (const arrayItem of formArray.controls) {
            if (arrayItem.invalid) {
              this.getInvalidFields(<ControlElement>arrayItem, formElement, invalidFields, true);
            }
          }
        } else {
          const fakeControl = new FormControl(formArray.value);
          this.getInvalidFields(fakeControl, formElement, invalidFields, true, field);
        }
      } else if (typeof abstractControl.value === 'object') {
        // FormGroup
        const formGroup: FormGroup = <FormGroup>abstractControl;
        if (Object.keys(formGroup.controls).length > 0) {
          for (const field in formGroup.controls) {
            if (formGroup.contains(field) && formGroup.get(field).invalid) {
              const formGroupElement: HTMLElement =
                formElement.querySelector(`[formcontrolname=${field}]`) ??
                formElement.querySelector(`[formgroupname=${field}]`) ??
                formElement.querySelector(`[ng-reflect-name=${field}]`) ??
                formElement.querySelector(`[formarrayname=${field}]`) ??
                formElement.querySelector(`[ng-reflect-account-group-name=${field}]`) ??
                formElement.querySelector(`[ltlccformcontroltemplate=${field}]`);

              if (formGroupElement) {
                this.getInvalidFields(
                  <ControlElement>formGroup.get(field),
                  formGroupElement,
                  invalidFields,
                  true,
                  field
                );
              } else {
                invalidFields.push({ label: field });
                console.error('field not found!', field);
              }
            }
          }
        } else {
          const fakeControl = new FormControl(formGroup.value);
          this.getInvalidFields(fakeControl, formElement, invalidFields, true, field);
        }
      }
    } else if (formElement && formElement.className.includes('ng-invalid')) {
      // FormControl
      invalidFields.push({
        element: formElement,
        label: formElement.getAttribute('ng-reflect-label') ?? formElement.getAttribute('data-label') ?? field,
      });
    }
    if (!recursive) {
      return invalidFields;
    }
  }

  messageInvalidFields(invalidFields: InvalidFieldData[], formGroup?: FormGroup): void {
    const title = this.translate.instant('form.errors.formTitle');
    let body: string;
    if (invalidFields.length) {
      body = this.translate.instant('form.template.missingFields', {
        fields: invalidFields.map((field) => field.label).join(', '),
      });
    } else {
      // if there are no fields that we can get the name from and the form is still invalid, lets show a generic message
      body = this.translate.instant('form.errors.formBody');
      console.error(formGroup); // for debugging purposes when fields are hidden
    }

    this.alertService.showApiError({ body, title });
  }
}
