import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

@UntilDestroy()
@Component({
  selector: 'ltlcc-form-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  host: { class: 'ltlcc-FormCurrency' },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyComponent extends FormControlsBaseComponent {
  @Input() inputSuffix: string;
  @Input() inputPrefix: string = '$';
  @Input() max: number;
  @Input() floatLabel: boolean = false;

  get currencyControl(): FormControl {
    return <FormControl>this.parentControl?.control ?? <FormControl>this.control;
  }
}
