<mat-form-field appearance="outline">
  <mat-label>
    {{ label }}
    {{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}
  </mat-label>
  <input
    [formControl]="companyControl"
    [required]="required"
    type="text"
    matInput
    [name]="id ? id : null"
    [id]="id ? id : null"
    [autocomplete]="autocomplete ? autocomplete : 'organization'"
    [attr.data-cy]="dataCy"
    (blur)="handleBlur($event)"
    [ltlccRemoveCharacters]="removeCharactersRegex"
  />
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
