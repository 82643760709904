import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';

@Pipe({ name: 'ltlccIsOptionInList' })
export class IsOptionInListPipe implements PipeTransform {
  static isOptionInList(option: string, options: string[] | Params): boolean {
    if (typeof options === 'object' && !Array.isArray(options)) {
      return option in options;
    }
    return (options ?? []).includes(option);
  }

  transform(option: string, options: string[]): boolean {
    return IsOptionInListPipe.isOptionInList(option, options);
  }
}

@Pipe({ name: 'ltlccIsListInOption' })
export class ltlccIsListInOptionPipe implements PipeTransform {
  transform(options: string[], option: string): boolean {
    return IsOptionInListPipe.isOptionInList(option, options);
  }
}
