<div
  class="ltlcc-FileDrop-boundary"
  [ltlccFileDrop]="files"
  [class.ltlcc-FileDrop-boundary--dragover]="dragover"
  [class.ltlcc-FileDrop-boundary--invalid]="files.invalid"
  (dragOver)="dragover = $event"
  [accept]="accept"
  [multiple]="multiple"
>
  <mat-icon class="ltlcc-FileDrop-boundary-uploadIcon" [@bounce]="dragover ? 'active' : ''">cloud_upload</mat-icon>
  <span class="ltlcc-FileDrop-boundary-text">Drop the files here</span>
  <span class="ltlcc-FileDrop-boundary-text">or</span>
  <button mat-button class="ltlcc-FileDrop-boundary-uploadButton" color="accent" (click)="fileButton.click()">
    {{ 'defaults.chooseFiles' | translate }}
  </button>
</div>

<ng-container *ngIf="files.invalid">
  <span class="mat-error"> <mat-icon>warning</mat-icon> {{ files.errors | ltlcFormErrorMessage }} </span>
</ng-container>

<mat-chip-list class="ltlcc-FileDrop-chips" cdkDropListOrientation="horizontal">
  <mat-chip
    *ngFor="let file of files.value; let i = index"
    class="ltlcc-FileDrop-chips-chip"
    (removed)="remove(i)"
    (click)="previewFile(file)"
  >
    {{ file.name }}
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
</mat-chip-list>

<input
  #fileButton
  [attr.multiple]="multiple"
  [attr.accept]="accept"
  type="file"
  class="ltlcc-FileDrop-hiddenFileButton"
  [attr.data-cy]="'fileDrop-chooseFiles'"
  (change)="selectFiles($event)"
/>
