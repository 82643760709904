import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertsModule } from '@ltlc/core';
import { LtlConnectApplicationRoutesData, LtlConnectUserService, LTL_CONNECT_APPLICATION_ROUTES } from '@ltlc/ltl-core';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../../environments/environment';
import { QuotesQueryParam } from './constants/query-params.enum';
import { APP_ROUTE_PATHS } from './constants/routes.const';

@NgModule({
  imports: [CommonModule, AlertsModule, NgxMaskModule.forRoot()],
  providers: [
    {
      provide: LTL_CONNECT_APPLICATION_ROUTES,
      useFactory: (userService: LtlConnectUserService): LtlConnectApplicationRoutesData => {
        return {
          getCommodityClassCalculator: () => {
            return userService.isLoggedIn()
              ? APP_ROUTE_PATHS.app.tools.densityAndClassCalculator
              : APP_ROUTE_PATHS.publicApp.tools.densityAndClassCalculator;
          },
          getRateQuotes: (accountId?: string) => {
            return accountId
              ? `${APP_ROUTE_PATHS.app.home.quotes}?${QuotesQueryParam.accountId}=${accountId}`
              : APP_ROUTE_PATHS.app.home.quotes;
          },
          getAddressBook: () => APP_ROUTE_PATHS.app.tools.addressBook,
          getTariffLibrary: () => environment.tariff_library,
        };
      },
      deps: [LtlConnectUserService],
    },
  ],
})
export class InitHelpersModule {}
