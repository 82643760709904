import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

//TODO: Check if we should just parse in parentControl.control in all controlValueAccessor components like this one
@UntilDestroy()
@Component({
  selector: 'ltlcc-form-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  host: { class: 'ltlcc-FormNumber' },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberComponent extends FormControlsBaseComponent {
  @Input() inputSuffix: string;
  @Input() inputPrefix: string;
  @Input() max: number;
  @Input() min: number;
  @ViewChild(MatInput, { read: ElementRef, static: true }) private inputElement: ElementRef<HTMLInputElement>;

  @HostListener('wheel')
  onWhell(): boolean {
    this.inputElement?.nativeElement.blur();
    return false;
  }

  get numberControl(): FormControl {
    return <FormControl>this.parentControl?.control ?? <FormControl>this.control;
  }
}
