import { OptionSelect } from '@ltlc/core';
import { addMinutes, format, Interval, set, startOfDay } from 'date-fns';

// Taken from legacy
// TODO: update functions to utilize date-fns
export class TimeOptionsHelper {
  static timeOptionsUS(interval: Interval, minuteInterval = 15): OptionSelect[] {
    if (
      minuteInterval < 1 ||
      minuteInterval > 60 ||
      !interval.start ||
      !interval.end ||
      interval.start > interval.end
    ) {
      console.error('erroneous interval or minuteInterval');
      return [];
    }
    let startDate = new Date(interval.start);
    const options: OptionSelect[] = [];

    while (startDate.valueOf() <= new Date(interval.end).valueOf()) {
      let option = {
        value: format(startDate, 'HH:mm'),
        label: format(startDate, 'h:mm a'),
      };
      options.push(option);
      startDate = addMinutes(startDate, minuteInterval);
    }

    return options;
  }

  static everyHourOptions = TimeOptionsHelper.timeOptionsUS(
    {
      start: set(startOfDay(new Date()), { hours: 0 }),
      end: set(startOfDay(new Date()), { hours: 23 }),
    },
    60
  );
}
