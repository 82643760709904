import { TIMEZONE_VALUES } from '@ltlc/core';
import { addMinutes, isValid, millisecondsToMinutes, set, startOfMinute } from 'date-fns';
import { format as format_tz, getTimezoneOffset } from 'date-fns-tz';
import { DockingInterval } from './docking-interval.form';

export class DockingHelper {
  static portlandTimeDate(incomingDate: number | Date, conversionDate: Date, clientDate = new Date()): Date {
    const iso = new Date(incomingDate).toISOString();
    const [year, month, date] = iso.split('T')[0].split('-').map(Number);
    const [hour, minutes] = iso.split('T')[1].split(':').map(Number);
    const dataTimezone = TIMEZONE_VALUES.PACIFIC;
    let portlandDiffMin = millisecondsToMinutes(getTimezoneOffset(dataTimezone)); // GMT -7h or -8h depending on DST

    const isDSTInPortlandByConversion = DockingHelper.hasDSTPortland(conversionDate);
    if (isDSTInPortlandByConversion !== DockingHelper.hasDSTPortland(clientDate)) {
      portlandDiffMin += isDSTInPortlandByConversion ? -60 : 60;
    }
    const dateInPortland = addMinutes(new Date(year, month - 1, date, hour, minutes, 0, 0), portlandDiffMin);

    return dateInPortland;
  }

  static hasDSTPortland(date: Date): boolean {
    const dataTimezone = TIMEZONE_VALUES.PACIFIC;
    const today = new Date();
    const january = set(today, {
      month: 0,
      date: 1,
    });
    const july = set(january, {
      month: 6,
    });
    const janOffset = getTimezoneOffset(dataTimezone, january);
    const julOffset = getTimezoneOffset(dataTimezone, july);
    const currentOffset = getTimezoneOffset(dataTimezone, date);

    return Math.max(janOffset, julOffset) !== currentOffset;
  }

  // TODO LWAPP-6478: remove this function once we know we are not using v1 bol anymore
  // static localDocking(pickupTime: number, closeTime: number, conversionDate: Date): LocalDocking {
  //   const pickupDateTime = DockingHelper.portlandTimeDate(pickupTime, conversionDate);
  //   const closeDateTime = DockingHelper.portlandTimeDate(closeTime, conversionDate);

  //   return {
  //     pickupDate: startOfDay(pickupDateTime),
  //     pickupDateTime,
  //     closeDateTime,
  //     dockingInterval: {
  //       open: format(pickupDateTime, 'HH:mm'),
  //       close: format(closeDateTime, 'HH:mm'),
  //     },
  //   };
  // }

  static dateTimeStringPortland(date: Date | number): string {
    if (!isValid(date) && typeof date !== 'number') {
      return '';
    }
    const timeZone = TIMEZONE_VALUES.PACIFIC;
    date = startOfMinute(date);
    const formatted = `${format_tz(date, 'yyyy-MM-dd', { timeZone })}T${format_tz(date, 'HH:mm:ssxx', { timeZone })}`;
    return formatted;
  }
}

export interface LocalDocking {
  pickupDate: Date;
  pickupDateTime: Date;
  closeDateTime: Date;
  dockingInterval: DockingInterval;
}

export const defaultDockingInterval: DockingInterval = {
  open: '13:00',
  close: '17:00',
};
