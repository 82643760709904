import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { DrawerWrapperComponent } from './drawer-wrapper/drawer-wrapper.component';
import { DrawerComponent } from './drawer.component';

@NgModule({
  declarations: [DrawerComponent, DrawerWrapperComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [DrawerComponent, DrawerWrapperComponent],
})
export class DrawerLauncherModule {}
