import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { TranslateHelper } from '@ltlc/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { take } from 'rxjs/operators';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function appTranslateInitializerFactory(translate: TranslateService, injector: Injector): () => Promise<any> {
  translate.setDefaultLang('en'); // Fallback language
  return () =>
    new Promise<any>((resolve: Function) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet = TranslateHelper.getClientPreferredLanguage(translate);
        TranslateHelper.changeLanguage$(translate, langToSet)
          .pipe(take(1))
          .subscribe(() => {
            resolve(null);
          });
      });
    });
}
@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      defaultLanguage: 'en',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appTranslateInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
})
export class InitTranslateModule {}
