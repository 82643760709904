import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URI } from '../tokens';
import {
  CreateSignupResponse,
  DeleteSignup,
  DeleteSignupResponse,
  EventSignup,
  SignupEventResponse,
  SignupUpdatePayload,
  UpdateSignupResponse,
} from './interfaces/eventnotification.interface';

@Injectable({ providedIn: 'root' })
export class EventNotificationService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get eventNotificationURI(): string {
    return `${this.apiUri}/eventnotification/1.0/`;
  }

  createShipmentNotification(shipmentNotification: SignupEventResponse): Observable<EventSignup> {
    return this.httpClient
      .post(
        `${this.eventNotificationURI}signups/${shipmentNotification.userProfileId}/${shipmentNotification.signupTypeCd}`,
        shipmentNotification
      )
      .pipe(
        map((response: CreateSignupResponse) => {
          return response.data.eventSignup[0];
        })
      );
  }

  deleteNotification(payload: DeleteSignup): Observable<DeleteSignupResponse> {
    const url = `${this.eventNotificationURI}signups/${payload.userProfileId}?signupId=${payload.signupId}`;
    return this.httpClient.delete<DeleteSignupResponse>(url);
  }

  updateNotification(signupUpdatePayload: SignupUpdatePayload) {
    const url = `${this.eventNotificationURI}signups/${signupUpdatePayload.userProfileId}/${signupUpdatePayload.signupId}/${signupUpdatePayload.signupTypeCd}`;
    return this.httpClient.put<UpdateSignupResponse>(url, signupUpdatePayload);
  }
}
