export enum SpecialEquipmentPurCode {
  noNeeded = '',
  liftgate = 'L',
  palletJack = 'P',
}

export enum AccessorialPurCodes {
  guaranteedService = 'garntInd',
  hazmat = 'hazmatInd',
  freezble = 'frzbleInd',
  weekendHolidayDelivery = 'holDlvrInd',
  rapidRemoteService = 'rapidRemoteInd',
  guaranteedByNoon = 'G12',
  appointment = 'appointment',
  apptInd = 'apptInd',
  prem = 'PREM',
}

export const ServiceTypeCodes: ServiceTypeService = {
  normal: 'N',
  deferred: 'D',
  rapidRemote: 'R',
  guaranteedByNoon: 'T',
};

export interface ServiceTypeService {
  normal: ServiceTypeLiteral;
  deferred: ServiceTypeLiteral;
  rapidRemote: ServiceTypeLiteral;
  guaranteedByNoon: ServiceTypeLiteral;
}

export type ServiceTypeLiteral = 'N' | 'D' | 'R' | 'T';
