<div [matMenuTriggerFor]="menu" class="ltlcc-LanguagePickerFooter">
  <mat-icon>language</mat-icon>
  <span> {{ control.value | languagePickerFooter }} </span>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of languages" (click)="control.setValue(lang.value)">
    <span>{{ lang.label }}</span>
  </button>
</mat-menu>
