import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { OptionSelect } from '../../form-controls';

@UntilDestroy()
@Component({
  selector: 'ltlc-ag-grid-select-filter',
  templateUrl: './ag-grid-select-filter.component.html',
  styleUrls: ['./ag-grid-select-filter.component.scss'],
  host: { class: 'ltlc-AgGridSelectFilter' },
  encapsulation: ViewEncapsulation.None,
})
export class AgGridSelectFilterComponent implements OnInit {
  filterSelectedControl = new FormControl();

  @Input() label: string;
  @Input() options: OptionSelect[] = [];
  @Input()
  set filterSelected(value: string) {
    this.filterSelectedControl.setValue(value);
  }
  get filterSelected() {
    return this.filterSelectedControl.value;
  }

  @Output() filterSelectedChange = new EventEmitter<string>();

  ngOnInit(): void {
    // Listens to changes in selection
    this.filterSelectedControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((selectedVal) => {
        // When selected we want to send back to the consumer the selected filter
        this.filterSelectedChange.emit(selectedVal);
      });
  }
}
