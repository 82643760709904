import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'ltlccSpecialEquipment' })
export class SpecialEquipmentPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string | null): string {
    return SpecialEquipmentPipe.format(value, this.translateService);
  }

  static format(code: string, translateService: TranslateService): string {
    let c: string;

    switch (code?.toUpperCase()) {
      case 'L':
        c = 'LIFT_GATE';
        break;
      case 'P':
        c = 'PALLET_JACK';
        break;
    }

    return translateService.instant('pur.specialEquipment.' + (c ?? 'NOT_NEEDED'));
  }
}
