import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormControlsModule, FormErrorMessageModule, FormGroupTemplateModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { EmailListComponent } from '../form-controls/email-list/email-list.component';
import { CommoditiesFormControlsModule } from './commodities-form-controls/commodities-form-controls.module';
import { DockingIntervalComponent } from './docking-interval/docking-interval.component';
import { PostalCodeComponent } from './postal-code/postal-code.component';
import { SelectAccessorialsPurComponent } from './select-accessorials-pur/select-accessorials-pur.component';
import { SelectFreightClassComponent } from './select-freight-class/select-freight-class.component';
import { SelectLocationTypeComponent } from './select-location-type/select-location-type.component';
import { SelectPaymentTermsComponent } from './select-payment-terms/select-payment-terms.component';
import { SelectStateComponent } from './select-state/select-state.component';

const ltlComponents = [
  SelectFreightClassComponent,
  DockingIntervalComponent,
  SelectLocationTypeComponent,
  SelectAccessorialsPurComponent,
  PostalCodeComponent,
  EmailListComponent,
  SelectPaymentTermsComponent,
  SelectStateComponent,
];

@NgModule({
  declarations: ltlComponents,
  imports: [
    MatIconModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatLegacyChipsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatDividerModule,
    MatMenuModule,
    TranslateModule,
    FormControlsModule,
    FormsModule,
    NgxMaskModule,
    MatButtonModule,
    FormGroupTemplateModule,
    CommoditiesFormControlsModule,
    FormErrorMessageModule,
  ],
  exports: [...ltlComponents, CommoditiesFormControlsModule],
})
export class LtlConnectFormControlModule {}
