import { Component } from '@angular/core';

@Component({
  selector: 'ltlcc-dialog-close-button',
  template: `<button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>`,
  styles: [
    `
      :host {
        margin-left: auto;
      }
    `,
  ],
})
export class ConnectDialogCloseButtonComponent {}
