import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  AddAccountPartnerRequest,
  AddAccountPartnerResponse,
  PmBaseResponse,
  BetaTestingResponse,
  LocationUser,
  Role,
  UpdateUserProfileRequest,
  UserAccess,
  UserDetailsRequest,
  UserDetailsResponse,
  UserProfileResponse,
} from '../user-management/interfaces/user-management.interface';
import { UserManagementApiService } from '../user-management/user-management.service';

export const MockUserManagementApiService: Provider = {
  provide: UserManagementApiService,
  useValue: <UserManagementApiService>{
    apiUri: '',
    backendMasterUri: '',
    adminRoleCode: 2,
    getUserAccessDetails: (): Observable<Array<UserAccess>> => of([]),
    getPartnerByUser: (): Observable<Array<LocationUser>> => of([]),
    getAllAccessLevels: (): Observable<Array<Role>> => of([]),
    upsert: (request: UserAccess): Observable<string> => of(''),
    updateUserProfile: (request: UpdateUserProfileRequest): Observable<UserDetailsResponse> => of(null),
    userHasAdminAccess: (): Observable<boolean> => of(true),
    getWebClient: (username: string): Observable<string> => of(''),
    getResetLink: (username: string): Observable<string> => of(''),
    getUserProfile: (username: string): Observable<UserProfileResponse> => of(<UserProfileResponse>{}),
    createUserDetails: (request: UserDetailsRequest): Observable<PmBaseResponse> => of(<PmBaseResponse>{}),
    addAccount: (account: AddAccountPartnerRequest): Observable<AddAccountPartnerResponse> =>
      of(<AddAccountPartnerResponse>{}),
    getUser: (userName: string): Observable<UserDetailsResponse> => of(<UserDetailsResponse>{}),
    getFeature: (featureName: string) => of([]),
    addUserFeature: (request: BetaTestingResponse) => of(<PmBaseResponse>{}),
  },
};
