import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';
import { FocusHelper } from '../base/helpers/focus.helper';

@UntilDestroy()
@Component({
  selector: 'ltlcc-form-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent extends FormControlsBaseComponent implements AfterViewInit {
  @Input() maxLength: number;
  @ViewChild('input', { static: true, read: ElementRef }) input: ElementRef;
  @Input() autofocus: boolean;

  ngAfterViewInit(): void {
    if (this.input && this.autofocus) {
      FocusHelper.focusElement(this.input.nativeElement);
      this.cd.markForCheck();
    }
    const textArea: HTMLTextAreaElement = this.input?.nativeElement;
    if (textArea) {
      if (this.maxLength) {
        textArea.maxLength = Number(this.maxLength);
      } else {
        delete textArea.maxLength;
      }
    }
  }

  get charCount(): number {
    return this.textAreaControl.value?.length ?? 0;
  }

  get textAreaControl(): FormControl {
    return <FormControl>(this.parentControl?.control ?? this.control);
  }
}
