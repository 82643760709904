import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { XrtSearchRequest } from '../elasticsearch/interfaces/elasicsearch.interface';
import { CUST_NOTIFICATION_PROXY_API_URI } from '../tokens';
import {
  AlertApiItem,
  AlertCustomSubscription,
  AlertGroupApiResponse,
  AlertsApiResponse,
  AlertsEventGroup,
  AlertSubscriptionEventGroupRequests,
  AlertSubscriptionResponse,
  AlertSubscriptionUpsertRequest,
  EventItem,
  ShipmentLevelSubscription,
  ShipmentSubscriptionSearchQuery,
  ShipmentSubscriptionUpsert,
  SubscriptionSearchQuery,
  UnsubscribeFromCustomRequest,
  UnsubscribeQuery,
} from './interfaces/alerts-api.interface';
@Injectable({ providedIn: 'root' })
export class AlertsApiService {
  constructor(private httpClient: HttpClient, @Inject(CUST_NOTIFICATION_PROXY_API_URI) private baseApiUri: string) {}

  get apiUri(): string {
    return `${this.baseApiUri}customer-notification/backend/`;
  }

  getShipmentsAlerts(request: XrtSearchRequest<AlertApiItem>): Observable<AlertsApiResponse> {
    return this.httpClient.post<AlertsApiResponse>(`${this.apiUri}notification/search`, request);
  }

  upsertSubscription(request: AlertSubscriptionUpsertRequest[]): Observable<AlertGroupApiResponse> {
    return this.httpClient.put<AlertGroupApiResponse>(`${this.apiUri}Subscription/Upsert`, request);
  }

  upsertShipmentSubscription(request: ShipmentSubscriptionUpsert): Observable<AlertGroupApiResponse> {
    return this.httpClient.put<AlertGroupApiResponse>(`${this.apiUri}Subscription/UpsertShipment`, request);
  }

  upsertGlobalSubscription(request: ShipmentSubscriptionUpsert): Observable<AlertGroupApiResponse> {
    return this.httpClient.put<AlertGroupApiResponse>(`${this.apiUri}Subscription/UpsertGlobal`, request);
  }

  upsertCustomSubscription(request: ShipmentSubscriptionUpsert): Observable<AlertGroupApiResponse> {
    return this.httpClient.put<AlertGroupApiResponse>(`${this.apiUri}Subscription/UpsertCustom`, request);
  }

  unsubscribeToCustomSubscription(request: UnsubscribeFromCustomRequest): Observable<AlertGroupApiResponse> {
    return this.httpClient.post<AlertGroupApiResponse>(`${this.apiUri}Subscription/UnsubscribeFromCustom`, request);
  }

  getSubscriptionsFromElastic(request: SubscriptionSearchQuery): Observable<AlertSubscriptionResponse> {
    return this.httpClient.post<AlertSubscriptionResponse>(`${this.apiUri}Subscription/ElasticSearch`, request);
  }

  getShipmentLevelSubscriptionsFromElastic(
    request: ShipmentSubscriptionSearchQuery
  ): Observable<ShipmentLevelSubscription[]> {
    return this.httpClient.post<ShipmentLevelSubscription[]>(
      `${this.apiUri}Subscription/ShipmentLevelElasticSearch`,
      request
    );
  }

  getCustomLevelSubscriptionsFromElastic(
    request: ShipmentSubscriptionSearchQuery
  ): Observable<AlertCustomSubscription[]> {
    return this.httpClient.post<AlertCustomSubscription[]>(
      `${this.apiUri}Subscription/CustomLevelElasticSearch`,
      request
    );
  }

  getGlobalSubscriptionFromElastic(
    request: ShipmentSubscriptionSearchQuery
  ): Observable<AlertSubscriptionEventGroupRequests[]> {
    return this.httpClient.post<AlertCustomSubscription[]>(
      `${this.apiUri}Subscription/GlobalLevelElasticSearch`,
      request
    );
  }

  unsubscribeFromSubscription(request: UnsubscribeQuery, unsubscribeFromAllEvents: boolean): Observable<string> {
    return this.httpClient.get<string>(
      `${this.apiUri}unsubscription?key=${request.key}&hash=${request.hash}&unsubscribeFromGroup=${unsubscribeFromAllEvents}`,
      {
        responseType: <any>'text',
      }
    );
  }

  getEventItems(): Observable<EventItem[]> {
    return this.httpClient.get<EventItem[]>(`${this.apiUri}Event/EventItems`);
  }

  getAllEventGroups(): Observable<AlertsEventGroup[]> {
    return this.httpClient.get<AlertsEventGroup[]>(`${this.apiUri}Event/EventGroups`);
  }
}
