import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { COMMON_REGEX } from '../base/constants/regex.const';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';
import { RemoveCharactersDirective } from '../base/directives/remove-characters.directive';

@Component({
  selector: 'ltlcc-form-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailComponent extends FormControlsBaseComponent {
  getValidatorFunctions(): ValidatorFn[] {
    return [Validators.email, Validators.pattern(COMMON_REGEX.STANDARD_EMAIL_ADDRESS)];
  }

  getDefaultLabel(): string {
    return this.translate.instant('form.labels.email');
  }

  get emailControl(): FormControl {
    return <FormControl>this.parentControl?.control ?? <FormControl>this.control;
  }

  get emailRemoveCharactersRegex(): RegExp {
    return this.removeCharactersRegex || RemoveCharactersDirective.RemoveCharactersRegex.cleanEmailInputRegex;
  }
}
