import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  AccountListType,
  AccountSetPreferredAccountData,
  AccountType,
  ApplicationID,
  CommonBaseResponse,
  ExternalUserData,
  MembershipService,
} from '@ltlc/api';
import { AlertService, WebConfigService } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { LtlConnectApplicationRoutesData, LTL_CONNECT_APPLICATION_ROUTES } from '../../../consts/tokens';
import { LtlConnectUserService } from '../../../services';
import { LoaderService } from '../../loader';
import { AccountCreditStatusCode } from '../enums/account-list-light.enum';
import { AccountInfoModalData } from '../interfaces/account-list-light.interface';
import { AccountInformationModalService } from './account-information-modal.service';

@UntilDestroy()
@Component({
  selector: 'ltlcc-account-information-modal',
  templateUrl: './account-information-modal.component.html',
  styleUrls: ['./account-information-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInformationModalComponent implements OnInit {
  readonly translatePrefix = 'accountList.';
  formAccountInfo: FormGroup;
  formatted = {
    nationalSales: {
      fullName: '',
      mailto: '',
    },
    invoicing: {
      fullName: '',
      phoneNumber: '',
    },
    accountClass: '-',
    creditStatusCode: '-',
  };
  historyRoute: string;
  historyQueryParams = {};
  accountEmail: string;

  constructor(
    private config: WebConfigService,
    private translate: TranslateService,
    public dialogRef: MatLegacyDialogRef<AccountInformationModalComponent>,
    private formBuilder: FormBuilder,
    private membershipService: MembershipService,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private userService: LtlConnectUserService,
    private accountInformationModalService: AccountInformationModalService,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: AccountInfoModalData,
    @Optional() @Inject(LTL_CONNECT_APPLICATION_ROUTES) public routes?: LtlConnectApplicationRoutesData
  ) {}

  get isCorporateAccount(): boolean {
    return <AccountType>this.data.userAccountDetail.locationTypeCode === AccountType.corporate;
  }

  ngOnInit(): void {
    this.accountInformationModalService.setDialogData(this.data);
    this.accountEmail = this.config.getSetting('accountEmail');
    this.formAccountInfo = this.generateAccountInfoForm();
    this.formatData();
    if (this.routes?.getRateQuotes) {
      this.historyRoute = this.routes.getRateQuotes();
      this.historyQueryParams = { accountId: this.data.account.accountId };
    }

    if (!this.data.account.user) {
      combineLatest([this.userService.getXpoAuthUserData$(), this.userService.webUser$])
        .pipe(take(1))
        .subscribe(([user, webUser]) => {
          this.data.account.user = {
            ...(<ExternalUserData>{
              userId: webUser.userId,
              profileInstId: webUser.profileInstId,
              givenName: webUser.profile.firstName,
              lastName: webUser.profile.lastName,
              emailAddress: webUser.profile.email,
              telephone: webUser.profile.phoneNumber,
              streetAddress: webUser.profile.addressLine1,
              stateCd: webUser.profile.stateCode,
              postalCode: webUser.profile.postalCode,
              roles: webUser.roles,
            }),
            profile: user.profile,
            profileInstId: webUser.profileInstId,
          };
        });
    }
    this.formAccountInfo
      .get('preferredAccount')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((preferred: boolean) => {
        this.setPreferredAccount(preferred);
      });
  }
  private setPreferredAccount(isPreferred: boolean): void {
    const userProfileId = this.data.account.user.profileInstId;
    const cisCustNbr = this.data.account.accountId.toString();
    const applicationName: ApplicationID = this.data.account.applicationName;
    const accountListType: AccountListType = this.data.account.accountListType;
    const api$: Observable<AccountSetPreferredAccountData | CommonBaseResponse> = isPreferred
      ? this.membershipService.setPreferredAccount(userProfileId, cisCustNbr, { applicationName, accountListType })
      : this.membershipService.deletePreferredAccount(userProfileId, cisCustNbr, { applicationName, accountListType });

    this.loaderService
      .loadData(api$)
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) => {
          this.formAccountInfo.get('preferredAccount').setValue(!isPreferred, { emitEvent: false, onlySelf: true });
          this.alertService.showApiError({ error });
          return throwError(error);
        })
      )
      .subscribe(() => {
        this.data.account.preferred = isPreferred;
        this.accountInformationModalService.setDialogData(this.data);
      });
  }
  private formatData(): void {
    const fullNameArr: string[] = this.data.userAccountFinancialInfo.natlContact.fullName.split(',');
    this.formatted.nationalSales.fullName = fullNameArr
      .map((n) => n.trim())
      .reverse()
      .join(' ');
    this.formatted.nationalSales.mailto = `mailto:${this.data.userAccountFinancialInfo.natlContact.email?.emailAddr}`;
    const collectorArr: string[] = this.data.userAccountFinancialInfo.collectorInfo
      .split(',')
      .join(' ')
      .split(' ')
      .map((n) => n.trim())
      .filter((n) => n);
    this.formatted.invoicing.phoneNumber = collectorArr[0];
    this.formatted.invoicing.fullName = `${collectorArr.slice(1).reverse().join(' ')}`;
    this.formatted.accountClass = this.data.userAccountFinancialInfo.acctClassCd
      ? this.data.userAccountFinancialInfo.acctClassCd
      : '-';
    if (
      [AccountCreditStatusCode.N, AccountCreditStatusCode.C].some(
        (code) => this.data.userAccountFinancialInfo.creditStatusCd
      )
    ) {
      // Translated credit codes
      this.formatted.creditStatusCode = this.translate.instant(
        this.translatePrefix + this.data.userAccountFinancialInfo.creditStatusCd
      );
    } else {
      this.formatted.creditStatusCode = this.data.userAccountFinancialInfo.creditStatusDesc;
    }
  }
  generateAccountInfoForm(): FormGroup {
    return this.formBuilder.group({
      preferredAccount: this.data.account.preferred,
    });
  }
  get mailToUpdate(): string {
    let subject = `Request for Change: Customer Info Code: ${this.data.account.madCode} Name: ${this.data.account.name}`;
    subject = subject.split(' ').join('%20');
    const body = '';
    return `mailto:${this.accountEmail}?subject=${subject}&body=${body}`;
  }
}
