import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ArrayHelper, WebConfigService } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export enum Canonical {
  'Canonical' = 1,
  'Canonical2' = 2,
}

@Injectable({ providedIn: 'root' })
export class MetaTitleService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private translate: TranslateService,
    private webConfigService: WebConfigService
  ) {}

  init() {
    if (!this.webConfigService.isProd) {
      return;
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot),
        map((route) => {
          let newRoute = route;

          while (newRoute.firstChild) {
            newRoute = newRoute.firstChild;
          }

          return newRoute;
        }),
        map((route) => route.data),
        distinctUntilChanged(ArrayHelper.isEqual)
      )
      .subscribe(({ metaTitleKey, descriptionKey, noIndex, canonical }) => {
        this.setTitle(metaTitleKey ?? 'SEO.default.title');
        this.setDescription(descriptionKey ?? 'SEO.default.desc');
        this.removeIndexing(noIndex);
        this.addCanonical(canonical);
      });
  }

  private setTitle(titleKey: string) {
    this.titleService.setTitle(this.translate.instant(titleKey));
  }

  private setDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: this.translate.instant(description) });
  }

  private removeIndexing(noIndex: boolean) {
    noIndex ? this.meta.addTag({ name: 'robots', content: 'noindex' }) : this.meta.removeTag('name=robots');
  }

  private addCanonical(canonical: number) {
    let canonicalURL;

    if (!canonical) {
      this.meta.removeTag('name=canonical');
      return;
    }

    switch (canonical) {
      case 1:
        canonicalURL = `https://ext-web.ltl-xpo.com/public-app/create-rate-quote-dynamic`;
        break;
      case 2:
        canonicalURL = ` https://ext-web.ltl-xpo.com/landing`;
        break;
    }

    this.meta.addTag({ name: 'canonical', content: canonicalURL });
  }
}
