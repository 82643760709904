import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PackageType } from '../api-shared/enums/package-type.enum';
import { FlatAccountData } from '../user-maintenance/interfaces/accounts.interface';
import {
  AddressBook,
  AddressBookApiResponse,
  AddressBookData,
} from '../user-maintenance/interfaces/address-book.interface';
import {
  CommoditiesDeleteApiResponse,
  CommodityItemApiResponse,
  CommodityItemRequest,
  CommodityItemResponse,
  NewRegistration,
} from '../user-maintenance/interfaces/commodity.interface';
import {
  PasswordResetApiRequest,
  PasswordResetApiResponseData,
} from '../user-maintenance/interfaces/public-password-reset.interface';
import { UserMaintenanceService } from '../user-maintenance/user-maintenance.service';

export const MockUserMaintenanceService: Provider = {
  provide: UserMaintenanceService,
  useValue: <UserMaintenanceService>{
    userMaintenanceURI: '',
    getCommodities: (): Observable<CommodityItemApiResponse[]> =>
      of([
        {
          userSystemId: 0,
          commodityId: 0,
          commodityDescription: 'test',
          commodityPackageCd: PackageType.BAG,
          commodityClass: 'test',
          commodityNmfc: 'test',
          commodityHazmatInd: true,
          commodityHazmatUnnaId: 'test',
          commodityLengthInch: 0,
          commodityWidthInch: 0,
          commodityHeightInch: 0,
          commodityWeightLbs: 0,
          commodityPieceCount: 0,
          protectFromFreezingInd: true,
          excessValueCoverageAmount: 0,
          excessiveLengthFeet: 0,
          correlationId: 'test',
          listActionCd: 'test',
          auditInfo: {
            createdById: 'test',
            createdTimestamp: new Date(),
            updateById: 'test',
            updatedTimestamp: new Date(),
            createByPgmId: 'test',
            updateByPgmId: 'test',
            correlationId: 'test',
            createdApplicationId: 'test',
            lastUpdateApplicationId: 'test',
          },
        },
      ]),
    getCommodityById: (commodityId: number): Observable<CommodityItemApiResponse> => of(<CommodityItemApiResponse>{}),
    saveCommodity: (request: CommodityItemRequest): Observable<CommodityItemResponse> => of(<CommodityItemResponse>{}),
    updateCommodity: (request: CommodityItemRequest, commodityId: number): Observable<CommoditiesDeleteApiResponse> =>
      of(<CommoditiesDeleteApiResponse>{}),
    deleteCommodity: (commodityId: number): Observable<CommoditiesDeleteApiResponse> =>
      of(<CommoditiesDeleteApiResponse>{}),
    getFlatAccountList: (accountIds: string[], excludeParentsIndicator = false): Observable<FlatAccountData[]> =>
      of([]),
    newRegistration: (newRegistration: NewRegistration) => of({}),
    passwordReset: (resetPasswordData: PasswordResetApiRequest): Observable<PasswordResetApiResponseData> =>
      of(<PasswordResetApiResponseData>{}),
    updateFavoriteShipment: (shipmentInstId: number) => of({}),
    getAddressBookEntries: (): Observable<AddressBook[]> => of([]),
    deleteAddressBook: (sequenceNbr: number): Observable<AddressBookApiResponse> => of(<AddressBookApiResponse>{}),
    addNewAddressBook: (addressBook: AddressBookData): Observable<AddressBook> => of(<AddressBook>{}),
    saveAddressBook: (addressBook: AddressBookData, sequenceNbr: number): Observable<AddressBookApiResponse> =>
      of(<AddressBookApiResponse>{}),
  },
};
