import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URI } from '../tokens';
import { ClaimsResp, ClaimsResultsResultsInfo, SearchClaimsResponse } from './interfaces/claims.interface';
import { XrtSearchQuery, XrtSearchQuery2 } from './interfaces/elasicsearch.interface';
import { HazmatItemResponse, HazmatListResponse } from './interfaces/hazmat.interface';

@Injectable({
  providedIn: 'root',
})
export class ElasticsearchService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get elasticsearchURI(): string {
    return `${this.apiUri}/elasticsearch/1.0/`;
  }

  getHazmatRefs(filter: XrtSearchQuery<HazmatItemResponse>): Observable<HazmatItemResponse[]> {
    const url = `${this.elasticsearchURI}xrt-search/hazmat-refs`;
    return this.httpClient.post(url, filter).pipe(
      map((response: HazmatListResponse) => {
        return response.data.result;
      })
    );
  }

  searchClaims(options: XrtSearchQuery2<ClaimsResultsResultsInfo>): Observable<ClaimsResp> {
    const url = `${this.elasticsearchURI}xrt-search/claims`;

    return this.httpClient.post<SearchClaimsResponse>(url, options).pipe(
      map((response: SearchClaimsResponse) => {
        return response.data;
      })
    );
  }
}
