import { HazmatItemResponse } from '@ltlc/api';
import { HazmatItem } from '../interfaces/hazmat.interface';

export class HazmatApiResponseHelper {
  static transformHazmatResponse(hazmatItem: HazmatItemResponse[]): HazmatItem[] {
    return hazmatItem.map((response: HazmatItemResponse) => {
      return {
        unnaCd: response.unnaCd,
        description: response.description,
        display: response?.unnaCd ? response.unnaCd + ' - ' + response.description : '',
      };
    });
  }
}
