import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ArrayHelper } from 'libs/connect-core/src/lib/helpers';
import { distinctUntilChanged } from 'rxjs/operators';
import { OptionSelect } from '../interfaces';
import { FormGroupTemplateService } from '../services/form-group-template.service';

@UntilDestroy()
@Directive()
export class FormControlsBaseComponent implements OnInit, ControlValueAccessor {
  control: AbstractControl = new FormControl(null);

  @Input()
  get required(): boolean {
    return this._required || (this.parentControl?.control ?? this.control)?.hasValidator(Validators.required) || false;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  private _required: boolean;

  @Input() label?: string = this.getDefaultLabel();
  @Input() dataCy?: string;
  @Input() hint?: string;
  @Input() placeholder?: string;
  @Input() autocomplete?: string;
  @Input() id?: string;
  @Input() customError: OptionSelect | OptionSelect[];
  @Input() removeCharactersRegex?: RegExp;

  @Output() blur = new EventEmitter<FocusEvent>();

  constructor(
    public translate: TranslateService,
    public cd: ChangeDetectorRef,
    @Optional() @Self() public parentControl?: NgControl,
    @Optional() public formGroupTemplateService?: FormGroupTemplateService
  ) {
    if (parentControl) {
      this.parentControl.valueAccessor = this;
    }
  }

  /**
   * Method to override if a control has a default label to put on their input/
   * @returns
   */
  getDefaultLabel(): string | null {
    return '';
  }

  /**
   * Method to override if there are default validators that are placed on a control regardless of its context
   * @returns
   */
  getValidatorFunctions(): ValidatorFn[] {
    return [];
  }

  /**
   * Method to override if there is any formatting to a value that needs to be done before onChange is called.
   * i.e. ensure input is a number type instead of a string
   *
   * @param value
   * @returns
   */
  formatValueBeforeUpdate(value: any): any {
    return value;
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((prev, after) => JSON.stringify(prev) === JSON.stringify(after))
      )
      .subscribe((value: any) => {
        this.onChange(this.formatValueBeforeUpdate(value));
        this.cd.markForCheck();
      });

    this.initializeValidators();
    this.initializeTouchedState();
    this.listenSubmittedForm();
  }

  onChange(value: string): void {}
  onTouched(): void {}

  private listenSubmittedForm(): void {
    this.formGroupTemplateService?.formSubmitted$.pipe(untilDestroyed(this)).subscribe(() => {
      this.control.markAsTouched();
      this.parentControl?.control.markAsTouched();
      this.onTouched();
      this.cd.markForCheck();
    });
  }

  handleBlur($event: FocusEvent): void {
    this.onTouched();
    this.blur.emit($event);
    const control = this.parentControl?.control || this.control;
    if (control && typeof control.value === 'string' && control.value !== control.value.trim()) {
      control.setValue(control.value.trim());
    }
  }

  writeValue(value: string): void {
    if (this.control.value !== value) {
      this.control.setValue(value);
    }
    setTimeout(() => {
      // TO avoid labels to overlap values in masked fields. Check if there is another way without timeout.
      this.cd.markForCheck();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      if (this.control.enabled) {
        this.control.disable({ emitEvent: false });

        this.cd.markForCheck();
      }
    } else {
      if (this.control.disabled) {
        this.control.enable({ emitEvent: false });
        this.cd.markForCheck();
      }
    }
  }

  private initializeValidators(): void {
    const validators = [];

    // if the parent control has any validators, we want to attach these validators to the inner control
    if (!!this.parentControl?.control?.validator) {
      validators.push(this.parentControl.control.validator);
    }

    const componentValidators = this.getValidatorFunctions();

    // If the component that extends this base class has default validators to put on,  we want to add it to the
    // control
    if (componentValidators.length) {
      validators.push(ArrayHelper.flattenMatrix(componentValidators));

      // Also we want to attach the component's validators to the host form control so that it affects the validation
      // of the form
      if (this.parentControl?.control) {
        this.parentControl.control.addValidators(componentValidators);
      }
    }

    // Merging both host validators and component's validators into one and setting it to the component's form control
    if (validators.length) {
      this.control.setValidators(ArrayHelper.flattenMatrix(validators));
      this.control.updateValueAndValidity({ emitEvent: false });
    }
  }

  private initializeTouchedState(): void {
    // Check if the control state of the parent is touched. was trying to subscribe to statusChanges of the parent
    // but it does not trigger anything.
    if (this.parentControl?.touched) {
      this.control.markAllAsTouched();
    }
  }
}
