export enum AlertApplicationId {
  Shipment = 'Shipment',
  PnD = 'PnD',
  Billing = 'Billing',
}

export enum AlertLevel {
  alert = 'alert',
  exception = 'exception',
  message = 'message',
}

export enum AlertType {
  new = 'new',
  update = 'update',
  delete = 'delete',
}

export enum AlertPriority {
  low = 'low',
  medium = 'medium',
  high = 'high',
  urgent = 'urgent',
}

export enum AlertReferenceType {
  ProNumber = 'ProNumber',
  Invoice = 'Invoice',
  User = 'User',
}

export type TimeOfDay =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

export enum ShipmentSubscriptionLevel {
  Global = 0,
  Account = 1,
  Pro = 2,
}
