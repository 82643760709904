import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccountInformationModalComponent } from './account-information-modal.component';

@NgModule({
  declarations: [AccountInformationModalComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatCheckboxModule,
    MatDividerModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [AccountInformationModalComponent],
})
export class AccountInformationModalModule {}
