import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ProChipListComponent } from './pro-chip-list.component';

@NgModule({
  declarations: [ProChipListComponent],
  imports: [CommonModule, TranslateModule, MatFormFieldModule, ReactiveFormsModule, MatIconModule, MatChipsModule],
  exports: [ProChipListComponent],
})
export class ProChipListModule {}
