import { Component } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { FormControlsBaseComponent } from '@ltlc/core';
import { RateQuoteValidatorLimitationsEnum } from '../../../../enums/validator-limitations.enum';

@Component({
  selector: 'ltlcc-commodity-quantity',
  templateUrl: './commodity-quantity.component.html',
  styleUrls: ['./commodity-quantity.component.scss'],
})
export class CommodityQuantityComponent extends FormControlsBaseComponent {
  getValidatorFunctions(): ValidatorFn[] {
    return [
      Validators.min(RateQuoteValidatorLimitationsEnum.MIN_QUANTITY),
      Validators.max(RateQuoteValidatorLimitationsEnum.MAX_QUANTITY),
      Validators.pattern(/^\d*\.*\d{1,2}/),
    ];
  }
}
