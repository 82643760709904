import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreditCardTokenRequest, InvoiceList } from '../invoice/interfaces/invoice.interface';
import { CONNECT_API_URI } from '../tokens';
import { RequestTokenResponse, UserAccountBilling } from './billing-api.models';

@Injectable({
  providedIn: 'root',
})
export class BillingApiService {
  static BILLING_TEST_ACCOUNT = '1-1-test';
  constructor(private httpClient: HttpClient, @Inject(CONNECT_API_URI) private connectUri: string) {}

  private get billingUri(): string {
    return this.connectUri;
  }

  accountGetAuthorizedAccounts(): Observable<UserAccountBilling[]> {
    return this.httpClient.get<UserAccountBilling[]>(`${this.billingUri}account/GetAuthorizedAccounts`);
  }

  accountGetConvenienceFeePercent(partnerCode: string): Observable<number> {
    return this.httpClient.get<number>(`${this.billingUri}account/GetConvenienceFeePercent`, {
      params: { partnerCode },
    });
  }

  getInvoiceList(request: any): Observable<InvoiceList> {
    return this.httpClient.post<InvoiceList>(
      `${this.billingUri}v2/InvoiceService/${BillingApiService.BILLING_TEST_ACCOUNT}/invoiceDetails`,
      request
    );
  }

  requestToken(request: CreditCardTokenRequest): Observable<RequestTokenResponse> {
    return this.httpClient.post<RequestTokenResponse>(`${this.billingUri}v2/onboarding/requestToken`, request);
  }

  getCreditCardTransactionFee(orderAmount: number): Observable<number> {
    return this.httpClient.post<number>(`${this.billingUri}v2/onboarding/GetCreditCardTransactionFee`, { orderAmount });
  }

  getCreditCardToken(userId: string, partnerIdentifierCode: string, paymentType: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('userId', userId);
    params = params.append('partnerIdentifierCode', partnerIdentifierCode);
    if (paymentType === 'ACH') {
      params = params.append('paymentMethodCode', 'ACH');
    }

    return this.httpClient.get<any>(`${this.billingUri}payment/creditcardtokens`, {
      params,
    });
  }
}
