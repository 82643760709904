import { XpoAuthConfig, XpoAuthConfigData } from '@xpo/ngx-auth';

export class XpoLtlAuthConfig extends XpoAuthConfig {
  appName: string;
  consumerKey: string;
  consumerSecret: string;
  apiUri: string;
  scopeOptions: string[];
  authGrantType: string;

  constructor(
    public readonly xpoAuthConfig: XpoAuthConfigData,
    public readonly xpoLtlAuthConfig: XpoLtlAuthConfigData
  ) {
    super(xpoAuthConfig);

    this.appName = xpoLtlAuthConfig.appName;
    this.consumerKey = xpoLtlAuthConfig.consumerKey;
    this.consumerSecret = xpoLtlAuthConfig.consumerSecret;
    this.apiUri = xpoLtlAuthConfig.apiUri;
    this.scopeOptions = Array.isArray(xpoLtlAuthConfig.scopeOptions)
      ? xpoLtlAuthConfig.scopeOptions
      : [xpoLtlAuthConfig.scopeOptions].filter((v) => !!v);
    this.authGrantType = xpoLtlAuthConfig.authGrantType;
  }
}

export interface XpoLtlAuthConfigData {
  appName: string;
  consumerKey: string;
  consumerSecret: string;
  apiUri: string;
  scopeOptions?: string[];
  authGrantType: string;
}

export interface XpoLtlAuthFetchTokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
  refresh_token?: string;
}
