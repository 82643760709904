import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import {
  AlertService,
  ErrorHandlerService,
  FormGroupTemplateService,
  InvalidFieldData,
  OptionSelect,
} from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, startWith, switchMap, take } from 'rxjs/operators';
import { CommodityHelper } from '../../helpers/commodity.helper';
import { Commodity, SaveCommodityState } from '../../interfaces/commodity.interface';
import { LtlConnectCommodityService } from '../../services/commodity.service';
import { LtlConnectUserService } from '../../services/user.service';
import { HazmatItem } from '../hazmat-dropdown/interfaces/hazmat.interface';
import { CommodityInformationDialogComponent } from './commodity-information-dialog/commodity-information-dialog.component';
import { CommodityFormService } from './services/commodity-form.service';

@UntilDestroy()
@Component({
  selector: 'ltlcc-commodity-form',
  templateUrl: './commodity-form.component.html',
  styleUrls: ['./commodity-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormGroupTemplateService],
})
export class CommodityFormComponent implements OnInit {
  readonly packagingOptions: OptionSelect[] = this.commodityFormService.getPackagingOptions();
  readonly yesNoOptions: OptionSelect[] = this.commodityFormService.getYesNoOptions();
  readonly excessiveLengthFeetOptions: OptionSelect[] = this.commodityFormService.getExcessiveLengthFeetOptions();
  readonly errorDuplicateCommodityDescription = 'duplicateCommodityDescription';

  excessiveLengthIsAllowed: boolean;
  saveLoading: boolean;
  updateLoading: boolean;

  @Input() enableSaveCommodity: boolean;
  @Input()
  get isMxSelected(): boolean {
    return this._isMxSelected;
  }
  set isMxSelected(v: boolean) {
    const newValue = coerceBooleanProperty(v);

    if (newValue !== this._isMxSelected) {
      this._isMxSelected = newValue;

      if (this._isMxSelected) {
        this.commodityGroup?.get('evcValue').reset();
        this.commodityGroup?.get('evcValue').disable({ emitEvent: false });
        this.hazmatIndicator?.reset();
      } else {
        if (this.excessiveLengthIsAllowed) {
          this.commodityGroup?.get('excessiveLengthInch').enable({ emitEvent: false });
        }
        this.commodityGroup?.get('evcValue').enable({ emitEvent: false });
      }
    }
  }
  private _isMxSelected = false;

  @Input() index: number;
  // @Input()
  // get displayHazmatTypeSelector() {
  //   return this._displayHazmatTypeSelector;
  // }
  // set displayHazmatTypeSelector(v: boolean) {
  //   this._displayHazmatTypeSelector = coerceBooleanProperty(v);
  // }
  // private _displayHazmatTypeSelector = false;

  @Input()
  get hideNMFCInput(): boolean {
    return this._hideNMFCInput;
  }
  set hideNMFCInput(v: boolean) {
    this._hideNMFCInput = coerceBooleanProperty(v);
  }
  private _hideNMFCInput = false;

  @Input() commodityGroupInput: FormGroup;

  @Input() set fieldsRequired(fieldsRequired: string[]) {
    (fieldsRequired || []).forEach((controlName) => {
      if (this.commodityGroup.contains(controlName)) {
        const dimensionControl = this.commodityGroup?.get(controlName);
        const isRequired = dimensionControl.hasValidator(Validators.required);
        if (!isRequired) {
          dimensionControl.addValidators(Validators.required);
        } else {
          dimensionControl.removeValidators(Validators.required);
        }
        dimensionControl.updateValueAndValidity();
      }
    });
  }

  @Output() removedIndex = new EventEmitter<number>();

  constructor(
    private mainForm: FormGroupDirective,
    private translateService: TranslateService,
    private commodityFormService: CommodityFormService,
    private ltlConnectUserService: LtlConnectUserService,
    private errorHandlerService: ErrorHandlerService,
    private alertService: AlertService,
    private commodityService: LtlConnectCommodityService,
    private cd: ChangeDetectorRef,
    private dialog: MatLegacyDialog,
    private formGroupTemplateService: FormGroupTemplateService,
    private element: ElementRef
  ) {}

  private get rqForm(): FormGroup | null {
    return <FormGroup>this.mainForm?.form;
  }

  private get freightArray(): FormArray | null {
    return <FormArray>this.rqForm?.get('commodities');
  }

  get excessiveLengthInch(): FormControl | null {
    return <FormControl>this.rqForm?.get('excessiveLengthInch');
  }

  get commodityGroup(): FormGroup | null {
    return <FormGroup>this.freightArray?.at(this.index) || this.commodityGroupInput;
  }

  get isCommodityAlreadySaved(): boolean {
    return !!this.commodityGroup.value.id;
  }

  get hazmatIndicator(): FormControl {
    return <FormControl>this.commodityGroup?.get('hazmatIndicator');
  }

  get formInvalid(): boolean {
    return this.commodityGroup.invalid;
  }

  get disabledSave(): boolean {
    return this.saveLoading || this.formInvalid || this.commodityGroup.pristine;
  }

  get disabledUpdate(): boolean {
    return !this.isCommodityAlreadySaved || this.updateLoading || this.formInvalid || this.commodityGroup.pristine;
  }

  ngOnInit(): void {
    this.excessiveLengthInch?.valueChanges
      .pipe(
        startWith(this.excessiveLengthInch.value),
        map((v) => !!v),
        untilDestroyed(this),
        distinctUntilChanged()
      )
      .subscribe((excessive) => {
        if (excessive) {
          this.commodityGroup.get('length').disable();
          this.commodityGroup.get('width').disable();
          this.commodityGroup.get('height').disable();
        } else {
          this.commodityGroup.get('length').enable();
          this.commodityGroup.get('width').enable();
          this.commodityGroup.get('height').enable();
        }
      });
  }

  remove(index: number): void {
    this.removedIndex.emit(index);
  }

  saveNewCommodity(): void {
    if (this.invalidUpsertFields() || this.saveLoading) {
      return;
    }

    if (this.formInvalid) {
      this.alertService.showApiError({
        body: this.translateService.instant('commodity.commodityInvalid'),
      });
      return;
    }

    this.saveLoading = true;
    this.ltlConnectUserService
      .getProfileInstId$()
      .pipe(
        switchMap((profileInstId) => {
          const request = CommodityHelper.transformCommodityItemRequest(this.commodityGroup.value, profileInstId);
          return this.commodityService.saveCommodity(request);
        }),
        take(1),
        catchError((error) => {
          this.saveLoading = false;
          return throwError(error);
        }),
        this.errorHandlerService.snackbarOnError(),
        finalize(() => {
          this.saveLoading = false;
          this.cd.markForCheck();
        })
      )
      .subscribe((savedCommodityState: SaveCommodityState) => {
        if (savedCommodityState.errorState === 'duplicate') {
          this.setDuplicateNameError();
          return;
        }

        this.commodityGroup.get('id').setValue(savedCommodityState.savedCommodity.commodityId);
        this.commodityGroup.get('id').updateValueAndValidity();
        this.commodityGroup.markAsPristine();
        this.alertService.showApiSuccess({
          title: this.translateService.instant('tools.COMMODITIES_LIST.ADDED_MESSAGE_TITLE'),
          body: this.translateService.instant('tools.COMMODITIES_LIST.ALERTS.ADDED', {
            name: `${this.commodityGroup.value.name} (${savedCommodityState.savedCommodity.commodityId})`,
          }),
        });
      });
  }

  updateCommodity(): void {
    if (this.invalidUpsertFields() || this.updateLoading) {
      return;
    }

    if (this.formInvalid) {
      this.alertService.showApiError({
        body: this.translateService.instant('commodity.commodityInvalid'),
      });
      return;
    }
    this.updateLoading = true;
    this.ltlConnectUserService
      .getProfileInstId$()
      .pipe(
        switchMap((profileInstId) => {
          const request = CommodityHelper.transformCommodityItemRequest(
            this.commodityGroup.value,
            profileInstId,
            this.commodityGroup.value.id
          );
          this.updateLoading = true;
          return this.commodityService.updateCommodity(request);
        }),
        take(1),
        catchError((error) => {
          this.saveLoading = false;
          return throwError(error);
        }),
        this.errorHandlerService.snackbarOnError(),
        finalize(() => {
          this.updateLoading = false;
          this.cd.markForCheck();
        })
      )
      .subscribe((savedState: SaveCommodityState) => {
        if (savedState.errorState === 'duplicate') {
          this.setDuplicateNameError();
          return;
        }

        this.commodityGroup.markAsPristine();
        this.alertService.showApiSuccess({
          title: this.translateService.instant('tools.COMMODITIES_LIST.UPDATED_MESSAGE_TITLE'),
          body: this.translateService.instant('tools.COMMODITIES_LIST.ALERTS.UPDATED', {
            name: `${this.commodityGroup.value.name}`,
          }),
        });
      });
  }

  hazmatSelectedChange(hazmatValue: HazmatItem): void {
    this.commodityGroup.get('hazmatUnnaId').patchValue(hazmatValue.unnaCd);
  }

  commodityDetails(): void {
    this.dialog.open(CommodityInformationDialogComponent, {
      width: '672px',
    });
  }

  private invalidUpsertFields(): boolean {
    const formElement: HTMLElement = this.element.nativeElement;
    const commodity: Commodity = this.commodityGroup.value;

    if (!commodity.name || !commodity.packaging) {
      const invalidFields: InvalidFieldData[] = [];
      if (!commodity.name) {
        invalidFields.push({
          element: formElement.querySelector(`[formcontrolname=name]`),
          label: this.translateService.instant('commodity.commodityDescription'),
        });
      }
      if (!commodity.packaging) {
        invalidFields.push({
          element: formElement.querySelector(`[formcontrolname=packaging]`),
          label: this.translateService.instant('commodity.packaging'),
        });
      }
      this.formGroupTemplateService.messageInvalidFields(invalidFields);
      return true;
    }

    return false;
  }

  private setDuplicateNameError(): void {
    this.alertService.showApiError({
      body: this.translateService.instant('commodity.commodityDuplicateDescription'),
    });
    const descriptionControl: FormControl = <FormControl>this.commodityGroup.get('name');
    descriptionControl.setErrors({ [this.errorDuplicateCommodityDescription]: true });
  }
}
