import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';
import { FocusHelper } from '../base/helpers/focus.helper';

@UntilDestroy()
@Component({
  selector: 'ltlcc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-Search' },
})
export class SearchComponent extends FormControlsBaseComponent implements OnInit {
  searchControl: FormControl = new FormControl('');
  @ViewChild('input', { static: true, read: ElementRef }) input: ElementRef;
  @Input() set disabled(isDisabled: boolean) {
    if (isDisabled && this.searchControl.enabled) {
      this.searchControl.disable();
    }
    if (!isDisabled && this.searchControl.disabled) {
      this.searchControl.enable();
    }
  }
  @Input() hideClearButton: boolean;
  @Input() searchByEnter: boolean;
  @Input() hint: string;
  @Input() set autofocus(autofocus: boolean) {
    if (this.input && coerceBooleanProperty(autofocus)) {
      FocusHelper.focusElement(this.input.nativeElement);
    }
  }
  @Output() searchWord = new EventEmitter<string>(null);

  ngAfterContentInit(): void {
    if (this.input && this.autofocus) {
      FocusHelper.focusElement(this.input.nativeElement);
      this.cd.markForCheck();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.searchControl.setValue(this.control.value, { emitEvent: false, onlySelf: true });

    this.searchControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((searchWord) => {
        if (searchWord !== this.control.value) {
          this.control.setValue(searchWord, { emitEvent: false, onlySelf: true });

          if (!this.searchByEnter) {
            this.searchWord.emit(searchWord);
          }
        }
      });

    this.control.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
      this.searchControl.setValue(value ?? null);
    });
  }

  setValue(value: string): void {
    this.searchControl.patchValue(value);
  }

  clear(): void {
    this.searchControl.reset();
    this.searchWord.emit(this.searchControl.value);
  }
}
