<form [formGroup]="signInForm" (ngSubmit)="next()" ltlccFormGroupTemplate class="ltlc-SignIn-form">
  <mat-card #card class="ltlc-SignIn-card">
    <div class="ltlc-SignIn-card-content">
      <ltlcc-icon iconName="xpoLogoBlack" class="ltlc-SignIn-card-logo"></ltlcc-icon>
      <div class="ltlc-SignIn-card-text">{{ 'signIn.signIn' | translate }}</div>
      <ltlcc-form-text
        autofocus
        id="username"
        autocomplete="username"
        formControlName="username"
        [label]="'signIn.userName' | translate"
        [removeCharactersRegex]="cleanEmailInputRegex"
      >
      </ltlcc-form-text>
    </div>
    <mat-card-actions class="ltlc-SignIn-card-actions">
      <span>
        {{ 'signIn.noAccount' | translate }}
        <a [routerLink]="APP_ROUTE_PATHS.registerUser">{{ 'defaults.clickHere' | translate }}</a>
      </span>
      <button #nextButton mat-flat-button color="primary" type="submit" [disabled]="loaderService.isLoading$ | async">
        {{ 'REGISTER_USER.NEXT' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</form>
