import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatDialogConfig } from '@angular/material/dialog';
import { matExpansionAnimations, MatExpansionPanelState } from '@angular/material/expansion';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatRadioDefaultOptions, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { AccountFinancialInfoResponseData, AccountType, InfrastructureService } from '@ltlc/api';
import { AlertService, ConfirmationDialogService } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { EmailTemplateService } from '../../add-account-modal';
import { LoaderService } from '../../loader';
import { AccountInformationModalComponent } from '../account-information-modal';
import { AccountInformationModalService } from '../account-information-modal/account-information-modal.service';
import { AccountListFilter, TreeListOption } from '../enums/account-list-light.enum';
import { AccountListHelper } from '../helpers/account-list-light.helper';
import { AccountDataSourcesService, StorageAccountsService } from '../services';
import {
  AccountInfoModalData,
  AccountListLightTreeNode,
  FlatNode,
  TreeListConfig,
} from './../interfaces/account-list-light.interface';
import { AccountListLightTreeHelper } from './account-list-light-tree.helper';
import { AccountListLightTreeNodeDimensions, IndentationMap } from './account-list-light-tree.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// TODO: As an alternative to the Mat Tree class we may also be able to utilize AG Grid as they also provide nested checkbox functionality
// TODO: See if we can handle the template better with pure
@UntilDestroy()
@Component({
  selector: 'ltlcc-account-list-light-tree',
  templateUrl: './account-list-light-tree.component.html',
  styleUrls: ['./account-list-light-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [matExpansionAnimations.indicatorRotate],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } as MatRadioDefaultOptions },
  ],
})
export class AccountListLightTreeComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  private readonly _dataState$ = new BehaviorSubject<AccountListLightTreeNode[] | null>(null);
  readonly dataState$: Observable<AccountListLightTreeNode[] | null> = this._dataState$.asObservable().pipe(
    shareReplay(),
    tap(() => this.indentation())
  );
  onChange(value: string): void {}
  onTouched(): void {}
  private destroy$ = new Subject<void>();

  readonly dims: AccountListLightTreeNodeDimensions = {
    heightRow: 56,
    paddingIndent: 16,
    indentation: 24, //If changed. also change in css
    gap: 4,
    shownRows: 6,
    loaderRows: 3,
  };

  readonly loaderArray = new Array(this.dims.loaderRows).fill(<AccountListLightTreeNode>{
    listId: TreeListOption.loader,
  });
  private initExpanded = false;

  private treeControl: FlatTreeControl<FlatNode>;
  private treeFlattener: MatTreeFlattener<AccountListLightTreeNode, FlatNode, FlatNode>;
  private control: AbstractControl = new FormControl(null);

  readonly AccountType = AccountType;

  dataSource: MatTreeFlatDataSource<AccountListLightTreeNode, FlatNode, FlatNode>;
  indentationMap = new Map<string, IndentationMap>();
  accountInfo: { [accountId: string]: AccountFinancialInfoResponseData } = {};

  @Input() dataCy = '';
  @Input()
  set nodes(nodes: AccountListLightTreeNode[] | null) {
    this._dataState$.next(this.orderNodes(nodes));
  }

  @Input()
  get searchWord(): string {
    return this._searchWord$.getValue();
  }
  set searchWord(value: string) {
    this._searchWord$.next(value);
  }
  private _searchWord$ = new BehaviorSubject<string>('');
  @Input()
  get filterPreferred(): AccountListFilter {
    return this._filterPreferred$.getValue();
  }
  set filterPreferred(value: AccountListFilter) {
    this._filterPreferred$.next(value);
  }
  private _filterPreferred$ = new BehaviorSubject<AccountListFilter>(AccountListFilter.ALL_ACCOUNTS);

  @Input()
  set config(v: TreeListConfig) {
    this._config = v;
  }
  get config(): TreeListConfig {
    return this._config;
  }
  private _config: TreeListConfig;
  @Output() selectedNode = new EventEmitter<FlatNode>();
  @Output() filteredNodes = new EventEmitter<AccountListLightTreeNode[]>();

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  listHeight = this.dims.shownRows * this.dims.heightRow;

  TreeListOption = TreeListOption;

  constructor(
    private dialog: MatLegacyDialog,
    private emailTemplateService: EmailTemplateService,
    private infraStructureService: InfrastructureService,
    private alertService: AlertService,
    private storageAccountsService: StorageAccountsService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private accountDataSourcesService: AccountDataSourcesService,
    private accountInformationModalService: AccountInformationModalService,
    private cd: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    @Optional() @Self() private parentControl?: NgControl
  ) {
    if (parentControl) {
      this.parentControl.valueAccessor = this;
    }
  }
  writeValue(value: string): void {
    if (this.selectedIdsControl.value !== value) {
      this.selectedIdsControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    if (!this._dataState$.getValue()) {
      this._dataState$.next(this.loaderArray);
    }

    const initDatasource = AccountListHelper.MatTreeFlatDataSourceAccountList(this._dataState$.getValue());
    this.treeControl = initDatasource.treeControl;
    this.treeFlattener = initDatasource.treeFlattener;
    this.dataSource = initDatasource.datasource;

    this.listenDataFiltering();

    // This is needed to ensure chips and account list is in sync when removing accounts from chip
    this.selectedIdsControl.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
      this.cd.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.calculateListHeightByVisibleNodes();
  }

  ngOnDestroy(): void {
    //Not using untilDestroyed to keep this component dependency-light
    this._dataState$?.next(null);
    this._dataState$?.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  get localDataCy(): string {
    return (this.dataCy ? this.dataCy + '-' : '') + 'AccountListLightTree';
  }

  get selectedIdsControl(): FormControl {
    return <FormControl>(this.parentControl?.control || this.control);
  }

  get selectedIds(): string[] {
    const selectedIds = Array.isArray(this.selectedIdsControl?.value) ? this.selectedIdsControl.value : [];
    return selectedIds;
  }

  get dataLength(): number {
    return this.viewport?.getDataLength() ?? this.dims.shownRows;
  }

  get nodesValue(): AccountListLightTreeNode[] {
    return (this._dataState$.getValue() ?? []).slice();
  }

  getExpansionState(node: FlatNode): MatExpansionPanelState {
    return this.treeControl.isExpanded(node) ? 'expanded' : 'collapsed';
  }

  selectItem($event: PointerEvent, node: FlatNode): void {
    // Fixes issue of when clicking checkbox in multiselect, it not selecting.
    $event.stopPropagation();
    if (this.config?.enableSingleSelection) {
      this.assignControlValue([node.data.listId]);
      return;
    }

    // Select parent node
    this.handleNodeSelection(node);

    // Expand and select children if applicable
    const parentSelected = this.selectedIds.includes(node.data.listId);
    if (node.expandable) {
      // Expand child nodes
      this.treeControl.expandDescendants(node);

      // Ensure nested account selections match the parent node selection state
      const children: FlatNode[] = this.treeControl.getDescendants(node);
      const selectedIds = this.selectedIds.slice();

      for (const child of children) {
        const childSelectedIndex = selectedIds.indexOf(child.data.listId);
        const childSelected = childSelectedIndex !== -1;
        if (!parentSelected && childSelected) {
          selectedIds.splice(childSelectedIndex, 1);
        } else if (parentSelected && !childSelected) {
          selectedIds.push(child.data.listId);
        }
      }
      this.assignControlValue(selectedIds);
    }
  }

  toggleSelection(node: FlatNode, $event: PointerEvent): void {
    if ([TreeListOption.reload].includes(<any>node.data.listId)) {
      return;
    }

    const clickedElement = <HTMLElement>$event.target;
    const isChevron = clickedElement.classList.contains(
      'ltlcc-AccountListLightTree-viewport-tree-node-container-chevron-button-icon'
    );

    if ((isChevron || !this.config.enableCorporateSelection) && node.expandable) {
      this.treeControl.toggle(node);
      this.calculateListHeightByVisibleNodes();
      return;
    }

    if (!this.config.enableCorporateSelection && node.data.addressForm?.locationType === AccountType.corporate) {
      return;
    }

    if ((node.expandable && this.config.enableCorporateSelection) || !node.expandable) {
      this.handleNodeSelection(node);
    }

    this.selectedNode.emit(node); // Always send what is selected

    if (this.config?.enableShowAccountInfo) {
      this.openAccountInfo(node);
    }
  }

  deleteAccount(account: FlatNode): void {
    if (account.data.disabled) {
      return;
    }
    const addressForm = account.data.addressForm;

    if ([AccountType.corporate, AccountType.pnd].includes(<AccountType>addressForm.locationType)) {
      this.confirmationDialogService.open({
        title: this.translate.instant('accountList.deleteAccount.header'),
        body: this.translate.instant('accountList.deleteAccount.body'),
        actionText: this.translate.instant('accountList.deleteAccount.confirm'),
        handleConfirmation: () => {
          const emailTemplate = this.emailTemplateService.removeAccountEmailTemplate({
            fromEmail: addressForm.email,
            fromName: addressForm.contactFullName,
            accountName: addressForm.companyName,
            locationType: <AccountType>addressForm.locationType,
            country: addressForm.countryCode,
            companyName: addressForm.companyName,
            addressLine1: addressForm.addressLine1,
            city: addressForm.cityName,
            stateCode: addressForm.stateCode,
            postalCode: addressForm.postalCode,
          });

          return this.loaderService.loadData(this.infraStructureService.sendEmail(emailTemplate)).pipe(
            catchError((error: HttpErrorResponse) => {
              this.alertService.showApiError({ error });
              return throwError(error);
            }),
            tap((result: boolean) => {
              if (result) {
                account.data.disabled = true;
                this.storageAccountsService.storeRemovedAccounts(+addressForm.dataId);
                this.alertService.showApiSuccess({
                  body: this.translate.instant('accountList.deleteAccount.success'),
                });
                this.cd.markForCheck();
              }
            })
          );
        },
      });
    }
  }

  private openAccountInfo(data: FlatNode): void {
    const idAccount = data.data.addressForm?.dataId;
    if (!idAccount) {
      return;
    }
    const financialInfo$ = of(this.accountInfo[idAccount] ?? null).pipe(
      switchMap((accountInfo: AccountFinancialInfoResponseData | null) => {
        if (!accountInfo) {
          const financialInfo$ = this.accountDataSourcesService.financialInfo(idAccount);
          return financialInfo$.pipe(
            take(1),
            catchError((error: HttpErrorResponse) => {
              this.alertService.showApiError({ error });
              return throwError(error);
            }),
            tap((_accountInfo: AccountFinancialInfoResponseData) => {
              this.accountInfo[idAccount] = _accountInfo;
            })
          );
        }
        return of(accountInfo);
      }),
      map((accountInfo: AccountFinancialInfoResponseData) => {
        return {
          closeOnNavigation: true,
          data: {
            ...accountInfo,
            account: AccountListLightTreeHelper.mapAccountListLightTreeNodeToAccountSelectItem(data.data),
          } as AccountInfoModalData,
        };
      })
    );
    this.loaderService
      .loadData(financialInfo$)
      .pipe(
        take(1),
        switchMap((config: MatDialogConfig) => this.dialog.open(AccountInformationModalComponent, config).afterClosed())
      )
      .subscribe(() => {
        const accountInfoModalData: AccountInfoModalData = this.accountInformationModalService.getDialogData();
        accountInfoModalData.account.preferred ? (data.data.matIconName = 'star') : delete data.data.matIconName;
        const indexData = this.dataSource.data.findIndex((d) => d.listId === data.data.listId);
        this.dataSource.data[indexData] = { ...data.data };
        this.nodesValue[indexData] = { ...data.data };
        this.dataSource.data = [...this.dataSource.data];
        this.cd.markForCheck();
      });
  }

  private handleNodeSelection(node: FlatNode): void {
    let selectedIds = this.selectedIds.slice();
    if (selectedIds.includes(node.data.listId)) {
      selectedIds.splice(selectedIds.indexOf(node.data.listId), 1);
    } else {
      if (this.config?.enableMultipleSelection) {
        selectedIds.push(node.data.listId);
      } else {
        selectedIds = [node.data.listId];
      }
    }

    this.assignControlValue(selectedIds);
  }

  private listenDataFiltering() {
    combineLatest([
      this._searchWord$.pipe(startWith(this._searchWord$.value), debounceTime(200), distinctUntilChanged()),
      this._filterPreferred$.pipe(startWith(this._filterPreferred$.value), distinctUntilChanged()),
      this.dataState$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dataSource.data = AccountListHelper.filterData(this.nodesValue, this.searchWord, this.filterPreferred);

        this.filteredNodes.emit(this.dataSource.data);
        if (
          (this.nodesValue?.[0]?.listId !== TreeListOption.loader &&
            !this.config?.disableInitExpanded &&
            !this.initExpanded) ||
          this.searchWord
        ) {
          this.initExpanded = true;
          this.treeControl.expandAll();
        }

        this.calculateListHeightByVisibleNodes();
      });
  }

  private calculateListHeightByVisibleNodes(): void {
    if (!this.viewport) {
      return;
    }
    const { start, end }: ListRange = this.viewport.getRenderedRange();
    this.listHeight = (end - start >= this.dims.shownRows ? this.dims.shownRows : end - start) * this.dims.heightRow;
    this.viewport.checkViewportSize();
    this.cd.markForCheck();
  }

  private assignControlValue(value): void {
    if (!Array.isArray(value)) {
      console.error('selectedIds must be an array value');
      return;
    }
    value = value.sort();
    this.selectedIdsControl.setValue(value);
    this.cd.markForCheck();
  }

  private indentation(): void {
    this.indentationMap.clear();
    const levelIndentationMap: {
      [level: string]: {
        position: number;
        widthActions: number;
        padding: number;
      };
    } = {};

    const flatList = this.treeFlattener?.flattenNodes(this.nodesValue).slice();
    const basePadding = this.dims.paddingIndent / 2;
    const selectionInputWidth = this.dims.indentation;
    const gapActions = this.dims.gap;
    const gapText = this.dims.gap;

    const calcWidthActions = (node: FlatNode) => {
      let isSelectable = this.config?.enableSingleSelection || this.config?.enableMultipleSelection;
      if (isSelectable && node.expandable && !this.config?.enableCorporateSelection) {
        isSelectable = false;
      }
      let widthActions = 0;
      if (node.expandable) {
        widthActions += selectionInputWidth;
      }
      if (isSelectable) {
        widthActions += selectionInputWidth;
      }
      if (node.data.matIconName) {
        widthActions += selectionInputWidth + this.dims.gap;
      }
      const actionGaps = widthActions > 0 ? widthActions / selectionInputWidth - 1 : 0;
      widthActions += actionGaps * gapActions;
      return widthActions;
    };

    for (let index = 0; index < flatList.length; index++) {
      const node: FlatNode = flatList[index];
      const indentKey = `${node.level}`;

      let position = basePadding;
      let prevIndent: IndentationMap | undefined = undefined;
      let prevNode: FlatNode | undefined = undefined;
      if (index > 0) {
        prevNode = flatList[index - 1];
        prevIndent = this.indentationMap.get(prevNode.data.listId.toString());
      }

      const widthActions = calcWidthActions(node);
      let padding = 0;
      if (prevNode) {
        const sameLevelNode = levelIndentationMap[indentKey];
        if (sameLevelNode) {
          padding = sameLevelNode.padding;
          position = sameLevelNode.position - sameLevelNode.widthActions + widthActions;
        } else {
          position = prevIndent.position + widthActions + gapText;
          padding = position - widthActions - gapText;
        }
      } else {
        position += widthActions + gapText;
        padding = position - widthActions - gapText;
      }

      if (!(indentKey in levelIndentationMap)) {
        levelIndentationMap[indentKey] = {
          position,
          widthActions,
          padding,
        };
      }

      this.indentationMap.set(node.data.listId.toString(), {
        padding,
        position,
        widthActions,
      });
    }
  }

  private orderNodes(nodes: AccountListLightTreeNode[] | null): AccountListLightTreeNode[] | null {
    if (!Array.isArray(nodes) || !nodes.length) {
      return nodes;
    }
    return nodes.sort((a: AccountListLightTreeNode, b: AccountListLightTreeNode) => {
      let order = 0;
      if (a.listId === TreeListOption.reload) {
        order = -1;
      } else if (b.listId === TreeListOption.reload) {
        order = 1;
      }

      return order;
    });
  }
}
