export enum ProStatusCode {
  ASSIGNED_TO_EBILL = 'AssignedToEbill',
  AVAILABLE = 'Available',
  IN_USE_BY_EBILL = 'InUseByEbill',
  NO_STICKER_AVAILABLE = 'NoStickerAvailable',
  RECEIVED_ON_FILE_AND_BILLED = 'ReceivedOnFileAndBilled',
  RECEIVED_ON_FILE_BUT_NOT_BILLED = 'ReceivedOnFileButNotBilled',
  RECEIVED_PURGED_MAYBE_ON_FBA = 'ReceivedPurgedMaybeOnFba',
  VOIDED = 'Voided',
}
