import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_ROUTE_NAMES } from './app-core/constants/routes.const';
import { IsAuthenticatedGuard } from './authenticated-app/guards/is-authenticated.guard';
import { AutoLoginGuard } from './shared/guards/auto-login.guard';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        redirectTo: APP_ROUTE_NAMES.landing,
        pathMatch: 'full',
      },
      {
        path: APP_ROUTE_NAMES.landing,
        loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
        canActivate: [AutoLoginGuard],
      },
      {
        path: APP_ROUTE_NAMES.signIn,
        loadChildren: () => import('./sign-in/sign-in.module').then((m) => m.SignInModule),
        canActivate: [AutoLoginGuard],
      },
      {
        path: APP_ROUTE_NAMES.registerUser,
        // TODO: delete the RegisterUserModule when registration pilot is finished
        // loadChildren: () => import('./register-user/register-user.module').then((m) => m.RegisterUserModule),
        redirectTo: `${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.userRegistration}`,
        pathMatch: 'prefix',
      },
      // Compatibility redirect to the billing routes from the connect external library to the app/billing route
      {
        path: APP_ROUTE_NAMES.billing,
        redirectTo: `${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.billing}`,
        pathMatch: 'prefix',
      },
      // Compatibility redirect for old password-reset route to work with new structure
      {
        path: APP_ROUTE_NAMES.passwordReset,
        redirectTo: `${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.passwordReset}`,
        pathMatch: 'prefix',
      },
      {
        path: APP_ROUTE_NAMES.app,
        loadChildren: () =>
          import('./authenticated-app/authenticated-app.module').then((m) => m.AuthenticatedAppModule),
        canLoad: [IsAuthenticatedGuard],
      },
      {
        path: APP_ROUTE_NAMES.publicApp,
        loadChildren: () => import('./public-app/public-app.module').then((m) => m.PublicAppModule),
      },
      {
        path: APP_ROUTE_NAMES.forbidden,
        loadChildren: () => import('./app-core/forbidden/forbidden.module').then((m) => m.ForbiddenModule),
      },
      {
        path: '**',
        loadChildren: () => import('./app-core/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
