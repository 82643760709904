import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LtlConnectUserService } from '@ltlc/ltl-core';
import { interval } from 'rxjs';
import { filter, skipWhile, take, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DynatraceService {
  constructor(private userService: LtlConnectUserService, @Inject(DOCUMENT) private document: Document) {}

  init(): void {
    // loading script for only UAT and production
    if (environment.production) {
      // Load the script
      const scriptElement = this.document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = './assets/ltl_xpo_dynatrace.js';
      scriptElement.defer = true;
      scriptElement.crossOrigin = 'anonymous';

      scriptElement.onload = () => {
        this.userService
          .getXpoAuthUserData$()
          .pipe(
            filter((user) => !!user),
            take(1)
          )
          .subscribe((user) => {
            this.setDynatraceUserIdentity(user);
          });
      };

      this.document.getElementsByTagName('body')[0].appendChild(scriptElement);
    }
  }

  private setDynatraceUserIdentity(user): void {
    const setUser = (): void => window['dtrum']?.identifyUser(user?.profile?.email);

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
      return;
    }

    let retryCount = 0;
    interval(1000)
      .pipe(
        tap(() => retryCount++),
        skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
        take(1)
      )
      .subscribe(() => {
        setUser();
      });
  }
}
