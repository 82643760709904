import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { InfoButtonComponent } from './info-button.component';

@NgModule({
  declarations: [InfoButtonComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [InfoButtonComponent],
})
export class InfoButtonModule {}
