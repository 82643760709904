import { FormArray, FormControl } from '@angular/forms';

export class FileHelper {
  static addFiles(files: FileList, filesFormArray: FormArray, accept: string, multiple: boolean): void {
    for (const file of Array.from(files)) {
      if (!multiple && filesFormArray.length > 0) {
        console.warn('Only one file accepted');
        return;
      }
      if (FileHelper.isAccepted(file, accept)) {
        filesFormArray.push(new FormControl(file));
      } else {
        console.warn('File type not accepted', file);
      }
    }
  }

  static isAccepted(file: File, accept: string): boolean {
    // Credits: https://github.com/peterfreeman/ngx-dropzone/blob/master/projects/ngx-dropzone/src/lib/ngx-dropzone.service.ts
    if (!accept || accept === '*') {
      return true;
    }

    const acceptFiletypes = accept.split(',').map((it) => it.toLowerCase().trim());
    const filetype = file.type.toLowerCase();
    const filename = file.name.toLowerCase();

    const matchedFileType = acceptFiletypes.find((acceptFiletype) => {
      // check for wildcard mimetype (e.g. image/*)
      if (acceptFiletype.endsWith('/*')) {
        return filetype.split('/')[0] === acceptFiletype.split('/')[0];
      }

      // check for file extension (e.g. .csv)
      if (acceptFiletype.startsWith('.')) {
        return filename.endsWith(acceptFiletype);
      }

      // check for exact mimetype match (e.g. image/jpeg)
      return acceptFiletype == filetype;
    });

    return !!matchedFileType;
  }
}
