import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { XpoSnackBarStatus } from '../alerts';

@Component({
  selector: 'ltlcc-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConnectTagComponent {
  private readonly baseClass = 'ltlcc-Tag';

  @HostBinding('class')
  class = this.baseClass;

  @Input()
  get status(): XpoSnackBarStatus {
    return this._status;
  }
  set status(v: XpoSnackBarStatus) {
    this._status = v;
    this.class = `${this.baseClass} ltlcc-Tag--${this._status}`;
  }
  private _status: XpoSnackBarStatus;
}
