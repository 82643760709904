import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { CountryCode } from '@ltlc/api';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { WebConfigService } from './web-config.service';

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
  private autocompleteService: google.maps.places.AutocompleteService | undefined;
  private geocoder: google.maps.Geocoder | undefined;
  private readonly _mapsLoaded$ = new ReplaySubject<typeof google.maps | null>();
  readonly mapsLoaded$ = this._mapsLoaded$.pipe(
    filter((maps) => !!maps),

    shareReplay()
  );

  constructor(private config: WebConfigService) {
    this.load().then((maps) => {
      if (maps) {
        this._mapsLoaded$.next(maps.maps);
      }
    });
  }

  searchAddress(
    address: string,
    country?: CountryCode | CountryCode[],
    bias?: { lat: number; lng: number }
  ): Observable<google.maps.places.AutocompletePrediction[] | null> {
    const request: google.maps.places.AutocompletionRequest = { input: address };

    if (country) {
      request.componentRestrictions = { country };
    } else {
      request.componentRestrictions = { country: [CountryCode.UNITED_STATES, CountryCode.CANADA, CountryCode.MEXICO] };
    }

    if (bias) {
      request.locationBias = new google.maps.Circle({
        center: new google.maps.LatLng(bias.lat, bias.lng),
        radius: 1000,
      });
    }

    return this.getPlacePredictions(request);
  }

  addressCoordinates(request: google.maps.GeocoderRequest): Observable<google.maps.LatLngLiteral | null> {
    request = { ...request };
    if (!request.componentRestrictions?.country) {
      delete request.componentRestrictions.country;
    }
    return this.geocode(request).pipe(
      map((results) => {
        const coordinates = results && results.length > 0 ? results[0] : null;
        const location = coordinates?.geometry.location.toJSON() ?? null;
        return location;
      })
    );
  }

  geocode(request: google.maps.GeocoderRequest): Observable<google.maps.GeocoderResult[] | null> {
    if (!this.isGoogleLibExists()) {
      throw new Error('Google maps library can not be found');
    }

    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }

    return new Observable((subscriber) => {
      this.mapsLoaded$.pipe(take(1)).subscribe(() => {
        this.geocoder.geocode(request, (results: google.maps.GeocoderResult[] | null) => {
          subscriber.next(results);
          subscriber.complete();
        });
      });
    });
  }

  getPlacePredictions(
    request: google.maps.places.AutocompletionRequest
  ): Observable<google.maps.places.AutocompletePrediction[]> {
    if (!this.isGoogleLibExists()) {
      throw new Error('Google maps library can not be found');
    }
    if (!this.autocompleteService) {
      this.autocompleteService = new google.maps.places.AutocompleteService();
    }

    return new Observable((subscriber) => {
      this.mapsLoaded$.pipe(take(1)).subscribe(() => {
        this.autocompleteService.getPlacePredictions(request, (predictions) => {
          subscriber.next(predictions);
          subscriber.complete();
        });
      });
    });
  }

  private isGoogleLibExists(): boolean {
    return !(!google || !google.maps || !google.maps.places);
  }

  private load(): Promise<typeof google | null> {
    const mapsAPILoader = new Loader({
      apiKey: this.config.getSetting('apiKeyGmap'),
      version: 'weekly',
      libraries: ['geometry', 'places'],
    });
    return mapsAPILoader.load().catch((error) => {
      console.error(error);
      this._mapsLoaded$.next(null);
      return null;
    });
  }
}
