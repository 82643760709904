import { AccountType } from '../../api-shared/enums/account-type.enum';
import { CountryCode } from '../../api-shared/enums/country-code.enum';

export class FlatAccountData {
  accountId: number;
  addressLine1: string;
  cityName: string;
  countryCode: CountryCode | string;
  locationType: AccountType;
  madCode: string;
  name: string;
  parentAccountIds: number[];
  postalCode: string;
  stateCode: string;
  useIndicator: boolean;
  secondaryName?: string;
}

export interface FlatAccountListResponse {
  code: string;
  transactionTimestamp: number;
  data: {
    accounts: FlatAccountData[];
  };
}
