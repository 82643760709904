import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControlsBaseComponent, OptionSelect } from '@ltlc/core';

@Component({
  selector: 'ltlcc-toolbar-select',
  templateUrl: './toolbar-select.component.html',
  styleUrls: ['./toolbar-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'ltlcc-toolbarSelect' },
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarSelectComponent extends FormControlsBaseComponent {
  @Input() options: OptionSelect[] = [];
}
