import { ApplicationRef, Injectable } from '@angular/core';
// import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  constructor(
    // private dialog: MatLegacyDialog,
    private update: SwUpdate,
    private appRef: ApplicationRef
  ) {}

  init(): void {
    if (!this.update.isEnabled) {
      return;
    }
    console.info('Auto update is enabled');

    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everyHour$ = interval(60 * 60 * 1000);

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    concat(appIsStable$, everyHour$).subscribe(() => {
      this.update.checkForUpdate();
      console.info('Checking for updates');
    });

    // This should update the latest app version on the first load if there is any version available
    this.update.activateUpdate();
    // This should update the latest app version if there is any version available
    // TODO: Update this method to versionUpdates observable on latest PWA version
    // Commenting this out till we use version updates, i think this triggers even though the user has the lastest code.
    // this.update.available
    //   .pipe(
    //     tap((v) => console.log(v)),
    //     filter((event) => event.available.hash !== event.current.hash)
    //   )
    //   .subscribe((event) => {
    //     this.dialog.open(GlobalErrorHandlerDialogComponent, { width: '500px', data: this.router.url });
    //   });
  }
}
