import { TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControlTemplateDirective } from '../directives/form-control-template.directive';
import { FormGroupTemplateDirective } from '../directives/form-group-template.directive';
import { FormGroupTemplateService } from '../services/form-group-template.service';

@NgModule({
  declarations: [FormGroupTemplateDirective, FormControlTemplateDirective],
  providers: [FormGroupTemplateService, TitleCasePipe],
  exports: [FormGroupTemplateDirective, FormControlTemplateDirective],
})
export class FormGroupTemplateModule {}
