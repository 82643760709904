import { Pipe, PipeTransform } from '@angular/core';
import { ArraySortHelper } from '@ltlc/core';
import { CommodityList } from '../../../interfaces/commodity.interface';

@Pipe({ name: 'commodityListOrderBy' })
export class CommodityListOrderByPipe implements PipeTransform {
  transform(values: CommodityList[], text = 'description'): CommodityList[] {
    const orderedCommodityList = ArraySortHelper.orderBy(values, [text]);
    return orderedCommodityList;
  }
}
