import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonBaseResponseData } from '../api-shared/interfaces/api-shared.interface';
import { API_URI } from '../tokens';
import {
  CalculatorApiRequest,
  CalculatorApiResponse,
  CurrencyExchangeApiResponse,
  ExchangeListApiResponse,
  ExchangeListDataApiResponse,
} from './interfaces/currency-exchange.interface';
import {
  CalculateShipmentDensityResp,
  CalculateShipmentDensityRqst,
  DensityClassResponse,
} from './interfaces/density-class.interface';
import {
  geoCityStateCountryResponse,
  GeoCityStateCountryResponseData,
  ServiceCenter,
  ServiceCenterResponse,
} from './interfaces/service-center-api.models';
import {
  CalculateTransitTimeApiResponse,
  CalculateTransitTimeDataApiResponse,
  CalculateTransitTimeRequest,
} from './interfaces/transit-time.interface';

@Injectable({
  providedIn: 'root',
})
export class ShippingtoolsService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get shippingtoolURI(): string {
    return `${this.apiUri}/shippingtools/1.0/`;
  }

  geoCityStateCountry(postalCd: string): Observable<GeoCityStateCountryResponseData[]> {
    //Not available in i
    return this.httpClient
      .get<geoCityStateCountryResponse>(`${this.shippingtoolURI}geo-city-state-country/${postalCd}`)
      .pipe(map((response: geoCityStateCountryResponse) => response.data.geoLocation));
  }

  getServiceCenterInfo(params: { postalCd?: string; sicCode?: string }): Observable<ServiceCenter> {
    const url = new URL(`${this.shippingtoolURI}service-center`);
    if (params.postalCd) {
      url.searchParams.append('postalCd', params.postalCd);
    }

    if (params.sicCode) {
      url.searchParams.append('sicCd', params.sicCode);
    }

    return this.httpClient
      .get<ServiceCenterResponse>(url.toString())
      .pipe(map((response: ServiceCenterResponse) => response.data.serviceCenter));
  }

  getExchange(calculation: CalculatorApiRequest): Observable<CurrencyExchangeApiResponse> {
    const options = {
      params: {
        fromCrncyAmt: calculation.fromCrncyAmt,
        fromCrncyCd: calculation.fromCrncyCd,
        toCrncyCd: calculation.toCrncyCd,
        exchDate: calculation.exchDate,
      },
    };

    return this.httpClient.get<CalculatorApiResponse>(`${this.shippingtoolURI}currencyexchange`, options).pipe(
      map((response) => {
        return response.data.currencyExchange;
      })
    );
  }

  getExchangeList(period: string): Observable<ExchangeListDataApiResponse> {
    const options = { params: { exchDate: period } };

    return this.httpClient.get<ExchangeListApiResponse>(`${this.shippingtoolURI}currencyexchange/rates`, options).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  calculateTransitTime(
    calcTransitTimeRequest: CalculateTransitTimeRequest
  ): Observable<CalculateTransitTimeDataApiResponse> {
    return this.httpClient
      .post(`${this.shippingtoolURI}transit-time-calculator?specialServicesInd=true`, calcTransitTimeRequest)
      .pipe(
        map((response: CalculateTransitTimeApiResponse) => {
          return response.data;
        })
      );
  }

  calculateDensity(transformedRequest: CalculateShipmentDensityRqst): Observable<CalculateShipmentDensityResp> {
    return this.httpClient
      .post<DensityClassResponse>(`${this.shippingtoolURI}shipment-density-calculator`, transformedRequest)
      .pipe(
        map((response: DensityClassResponse) => {
          return response.data;
        })
      );
  }

  postalCodeIsAddressNeeded(zipCode: string): Observable<boolean> {
    return this.httpClient.get(`${this.shippingtoolURI}postal-codes/${zipCode}/is-address-needed`).pipe(
      map((response: any) => {
        return response.data.isAddressNeeded;
      })
    );
  }

  isProNumberLegacy(proNumber: string): Observable<boolean> {
    const url = `${this.shippingtoolURI}plt/shipments/${proNumber}/legacy-validations`;
    return this.httpClient.get<CommonBaseResponseData<{ pltLegacyIndicator: boolean }>>(url).pipe(
      map((response) => {
        return response.data.pltLegacyIndicator;
      })
    );
  }
}
