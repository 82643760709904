import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ArrayHelper, FormControlsBaseComponent } from '@ltlc/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ltlcc-form-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss'],
})
export class EmailListComponent extends FormControlsBaseComponent implements OnInit {
  readonly translateShipmentDigestAlias: string = 'tools.SHIPMENT_DIGEST.';

  emailForm: FormGroup = new FormGroup({
    emailList: new FormControl(null),
  });

  emails: string[] = [];

  @ViewChild('emailChipList') emailChipList;
  @Input() isInvalid: boolean;
  @Input() max: number;
  @Input() hint: string;
  @Input()
  get isEditMode(): boolean {
    return this._isEditMode;
  }
  set isEditMode(v: boolean) {
    this._isEditMode = v;
    if (this._isEditMode) {
      this.emails = this.control.value;
    } else {
      this.emails = [];
    }
  }
  private _isEditMode = false;

  ngOnInit(): void {
    super.ngOnInit();
    if (this._isEditMode) {
      this.emails = this.control.value;
    }
  }

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;

  add(event: MatChipInputEvent): void {
    // Clear the input value
    event.chipInput!.clear();

    // allow comma seperated input paste
    this.emailForm.get('emailList').markAsTouched();
    let duplicate = false;

    const emailsInput: string[] = ArrayHelper.splitCommaSeparatedStringIntoArray(this.emailForm.get('emailList').value)
      .map((email) => email?.trim()?.toLowerCase())
      .filter((email) => {
        duplicate = this.emails.includes(email);

        return !!email && !duplicate;
      });

    const uniqEmailsInput = ArrayHelper.removeDuplicates(emailsInput);

    for (const email of uniqEmailsInput) {
      const tempEmailControl = new FormControl(email, [Validators.required, Validators.email]);

      if (tempEmailControl.errors) {
        this.emailForm.get('emailList').setErrors(tempEmailControl.errors);
        return;
      }
    }

    for (const email of uniqEmailsInput) {
      this.emails.push(email);
    }
    this.emailForm.get('emailList').reset();
    this.control.setValue([]);
    this.updateEmailList();
  }

  remove(email: any): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.control.setValue([]);
      this.updateEmailList();
    }
  }

  removeAll(): void {
    while (this.emails.length) {
      this.emails.pop();
    }

    this.updateEmailList();
  }

  deleteRow(index: number): void {
    this.emails.splice(index, 1);
    this.control.setValue([]);
    this.updateEmailList();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.emailForm.get('emailList').enabled) {
      this.emailForm.get('emailList').disable();
    }
    if (!isDisabled && this.emailForm.get('emailList').disabled) {
      this.emailForm.get('emailList').enable();
    }
  }

  private updateEmailList(): void {
    this.control.setValue(this.emails?.length ? this.emails : null);
    if (this.max && this.emails?.length > this.max) {
      this.emailChipList.errorState = true;
    } else {
      this.emailChipList.errorState = false;
    }
    this.cd.markForCheck();
  }
}
