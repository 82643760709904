import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StandardLanguageCode } from '@ltlc/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { TranslateHelper } from '../translate-helper';

@UntilDestroy()
@Component({
  selector: 'ltlcc-language-picker-footer',
  templateUrl: './language-picker-footer.component.html',
  styleUrls: ['./language-picker-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguagePickerFooterComponent implements OnInit {
  languages = TranslateHelper.languageList;
  control = new FormControl(null);

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.control.setValue(TranslateHelper.getClientPreferredLanguage(this.translate));
    this.control.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((language) => this.changeLanguage(language));
  }

  private changeLanguage(language: StandardLanguageCode): void {
    TranslateHelper.changeLanguage$(this.translate, language)
      .pipe(take(1))
      .subscribe((changed) => {
        if (changed) {
          location.reload();
        }
      });
  }
}
