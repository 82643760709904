import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

@UntilDestroy()
@Component({
  selector: 'ltlcc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends FormControlsBaseComponent {
  @HostBinding('class') classes = 'ltlcc-Checkbox';
}
