import { Component } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { FormControlsBaseComponent } from '@ltlc/core';
import { RateQuoteValidatorLimitationsEnum } from '../../../../enums/validator-limitations.enum';

@Component({
  selector: 'ltlcc-commodity-width',
  templateUrl: './commodity-width.component.html',
  styleUrls: ['./commodity-width.component.scss'],
})
export class CommodityWidthComponent extends FormControlsBaseComponent {
  getValidatorFunctions(): ValidatorFn[] {
    return [
      Validators.min(RateQuoteValidatorLimitationsEnum.MIN_WIDTH_IN),
      Validators.max(RateQuoteValidatorLimitationsEnum.MAX_WIDTH_IN),
      Validators.pattern(/^\d*\.*\d{1,2}/),
    ];
  }
}
