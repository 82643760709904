<ltlcc-search
  *ngIf="!isTextArea"
  [hideClearButton]="hideClearButton"
  [placeholder]="placeholder"
  [autofocus]="autofocus"
  [searchByEnter]="!suppressSearchByEnter"
  [hint]="hint"
  (paste)="handlePasteEvent($event)"
  (searchWord)="searchByProNumbers($event)"
  [formControl]="textAreaForm.get('proNumbers')"
>
</ltlcc-search>
<!-- Handle the text area search for adding tracking shipments -->
<div [formGroup]="textAreaForm" *ngIf="isTextArea">
  <ltlcc-form-textarea
    [autofocus]="autofocus"
    #search
    [placeholder]="placeholder"
    formControlName="proNumbers"
    [hint]="hint"
  >
  </ltlcc-form-textarea>
</div>
