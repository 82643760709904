import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { of } from 'rxjs';
import { PasswordFormField } from '../enums/password-form-field.enum';

export class ConnectValidators {
  static DECIMAL = /^[0-9]*\.?[0-9]*$/;

  static decimalValue(control: AbstractControl) {
    if (control.value && !RegExp(ConnectValidators.DECIMAL).test(control.value)) {
      return { number: true };
    }
    return null;
  }

  /**
   * Validates if the input is greater than the minimum value (and not equal) difference within Validators.min()
   * Validators.min() validates > = minValue, ValidatorGreaterThan validates > value
   * @type {AsyncValidatorFn}
   */
  static greaterThan = (minValue: number = 0): AsyncValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value || control.value === '') {
        return null;
      }
      if (control.value <= minValue) {
        return of({ notGreaterThan: minValue });
      } else {
        return null;
      }
    };
  };

  static POSITIVE_INTEGER = /^[0-9]{1,16}$/;

  static positiveIntegerNumber = (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = new RegExp(ConnectValidators.POSITIVE_INTEGER);
    if (!(regex.test(control.value) || control.value === '' || !control.value)) {
      return { positiveIntegerNumber: true };
    }

    return null;
  };

  static checkIfMatchingPasswords: ValidatorFn = (control: AbstractControl): ValidationErrors => {
    const passwordInput = control.get(PasswordFormField.newPassword),
      passwordConfirmationInput = control.get(PasswordFormField.confirmPassword);

    if (passwordConfirmationInput?.errors && !passwordConfirmationInput?.errors.notMatching) {
      return null;
    }
    if (passwordInput.value !== passwordConfirmationInput.value) {
      return { notMatching: true };
    } else {
      return null;
    }
  };

  static checkIfMatchingEmails = (emailInput: string, emailConfirm: string) => {
    return (group: FormGroup) => {
      const firstEmailInput = group.controls[emailInput],
        emailConfirmationInput = group.controls[emailConfirm];
      if (firstEmailInput.value !== emailConfirmationInput.value) {
        return emailConfirmationInput.setErrors({ notMatching: true });
      } else {
        return emailConfirmationInput.setErrors(null);
      }
    };
  };
}
