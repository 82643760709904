<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }} {{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <input
    [required]="required"
    [formControl]="control"
    matInput
    [dropSpecialCharacters]="false"
    mask="Hh:m0"
    autocomplete="off"
    (blur)="handleBlur($event)"
  />
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
