<mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    *matTreeNodeDef="let node"
    isExpanded
    class="ltlcc-ImagedDocumentTree-childNode"
    [attr.data-cy]="'documentFinder-documentFound-checkbox-' + node?.proNumber"
  >
    <div class="ltlcc-ImagedDocumentTree-childNode-checkboxWrapper ltlcc-ImagedDocumentTree-childNode-item">
      <mat-checkbox [checked]="checklistSelection.isSelected(node)" (change)="todoLeafItemSelectionToggle(node)">
      </mat-checkbox>
      <span (click)="todoLeafItemSelectionToggle(node, true)" class="ltlcc-ImagedDocumentTree-childNode-label">{{
        prefixShipmentDocument + '.TYPES.' + node.name | translate
      }}</span>
    </div>
    <span class="ltlcc-ImagedDocumentTree-childNode-item">{{ node.uploadedOn | date : 'MM/dd/yyyy hh:mm' }}</span>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" isExpanded class="ltlcc-ImagedDocumentTree-parentTree">
    <mat-checkbox
      *ngIf="node.level > 0"
      [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)"
      (change)="todoItemSelectionToggle(node)"
      class="ltlcc-ImagedDocumentTree-parentTree-header"
      [attr.data-cy]="'documentFinder-documentFound-parentTree-' + node.level"
    >
      {{ node.name | uppercase }}
      <span *ngIf="node.level === 1">({{ node.length }})</span>
    </mat-checkbox>
    <span
      *ngIf="node.level === 0"
      class="ltlcc-ImagedDocumentTree-parentTree-header ltlcc-ImagedDocumentTree-parentTree-title"
    >
      {{ node.name | uppercase }}
    </span>
    <span *ngIf="node.level === 0" class="ltlcc-ImagedDocumentTree-parentTree-header">
      {{ prefixShipmentDocument + '.TABLE.UPLOADED_ON' | translate }}
    </span>
    <span *ngIf="node.level !== 0"></span>
    <button
      mat-icon-button
      matTreeNodeToggle
      class="ltlcc-ImagedDocumentTree-parentTree-header"
      [attr.aria-label]="'Toggle ' + node.name"
    >
      <mat-icon>
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
  </mat-tree-node>
</mat-tree>
