import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

@Component({
  selector: 'ltlcc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent extends FormControlsBaseComponent {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() datepickerFilter?: (d: Date | null) => boolean = (d: Date | null) => true;

  getDefaultLabel(): string {
    return this.translate.instant('form.labels.datePicker');
  }
}
