import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mapTo, share, startWith } from 'rxjs/operators';

export class HandheldHelper {
  static resizeScreen$: Observable<number> = fromEvent(window, 'resize').pipe(
    startWith(window.innerWidth),
    debounceTime(500),
    map((event: any) => event?.target?.innerWidth ?? window.innerWidth),
    distinctUntilChanged(),
    share()
  );

  static isHandheld(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      navigator.userAgent
    );
  }

  static isHandheld$(): Observable<boolean> {
    return this.resizeScreen$.pipe(mapTo(this.isHandheld()));
  }

  static isMobileView$(): Observable<boolean> {
    return this.resizeScreen$.pipe(
      map((screenWidth) => {
        return screenWidth < 600;
      })
    );
  }
}
