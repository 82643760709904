import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-community';
import { take } from 'rxjs/operators';
import { StringFormatHelper } from '../../../helpers';
@Component({
  selector: 'ltlcc-ag-grid-quick-search-filter',
  templateUrl: './ag-grid-quick-search-filter.component.html',
  styleUrls: ['./ag-grid-quick-search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-AgGridQuickSearchFilter' },
})
export class AgGridQuickSearchFilterComponent {
  searchControl = new FormControl(null);
  showSearch = false;

  @HostBinding('class.ltlcc-AgGridQuickSearchFilter--fullWidth')
  @Input()
  get isFullWidth() {
    return this._isFullWidth;
  }
  set isFullWidth(v: boolean) {
    this._isFullWidth = v;
    this.showSearch = v;
  }
  private _isFullWidth = false;

  @Input()
  set grid(v: AgGridAngular) {
    this._grid = v;
    if (this.grid) {
      this.grid.api
        ? this.initExternalFilter(this.grid.api)
        : this.grid.gridReady.pipe(take(1)).subscribe((params) => {
            this.initExternalFilter(params.api);
            this.cd.markForCheck();
          });
    }
  }

  get grid(): AgGridAngular {
    return this._grid;
  }
  private _grid: AgGridAngular;

  @Input() placeholder: string;
  @Input()
  set filterSelected(value) {
    this.searchControl.setValue(value);
    this.showSearch = !!value;
  }
  get filterSelected() {
    return this.searchControl.value;
  }

  @Output() searchChange = new EventEmitter<string>();

  constructor(private cd: ChangeDetectorRef) {}

  search(): void {
    this.showSearch = true;
  }

  setSearchWord(word: string): void {
    this.searchControl.setValue(word);
    this.searchChange.emit(word);
    this.grid?.api.onFilterChanged();
  }

  private stringifyData(data: any): string {
    return data instanceof Array
      ? data.map((v) => this.stringifyData(v)).join(', ')
      : typeof data === 'object' && data
      ? Object.values(data)
          .map((v) => this.stringifyData(v))
          .join(', ')
      : data;
  }

  private initExternalFilter(gridApi: GridApi): void {
    gridApi.setIsExternalFilterPresent(() => true);
    gridApi.setDoesExternalFilterPass((node) => {
      if (this.searchControl.value) {
        const rowData = this.stringifyData(node.data).replace(StringFormatHelper.SearchStringRegex, '');
        const searched = this.searchControl.value.split(',').map((p) => p.trim());

        // Will replace any trailing 0's after (-), leading zeros, and (-)
        return searched.some((x) =>
          RegExp(`.*(${x?.replace(StringFormatHelper.SearchStringRegex, '')}).*`, 'i').test(rowData)
        );
      }

      return true;
    });
  }
}
