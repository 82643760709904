import {
  AccountListType,
  ApplicationID,
  CountryCode,
  ExternalUserData,
  UserAccountDetail,
  UserAccountFinancialInfo,
} from '@ltlc/api';
import { OptionSelect } from '@ltlc/core';
import { Profile } from 'oidc-client';
import { AccountForm } from '../../account-form';
import { AccountEmailTemplateParams } from '../../add-account-modal/add-account-modal.interface';
import { DockingInterval } from '../../form-controls/docking-interval/docking-interval.form';
import { AccountListLocationType } from '../enums/account-list-light.enum';

export interface AccountListLightTreeNode {
  /** Visible UI */
  name: string;
  address?: string;
  type?: string;
  matIconName?: string;
  /**
   * By default , the search will filter by name, address and type.
   * If you also want to search for instance by madCode for accounts,
   * you can add {
   *  value: <madCode>,
   *  label: Madcode
   * }
   * If there is a match, it will appear on the bottom right of the row
   */
  searchFields?: OptionSelect[];

  /** Internal logic */
  listId: string; // List id for UI logic
  children?: AccountListLightTreeNode[]; // Nested same interface
  rawData?:
    | {
        id?: string;
        data?: any;
        [key: string]: any;
      }
    | any; // A placeholder for the raw API data
  addressForm?: AccountForm; // This will be mapped to the account details form in the ltlcc-address-form component
  sticky?: boolean; // Allows a node to always be shown regardless of search criteria
  disabled?: boolean;
}

export interface TreeListConfig {
  enableSingleSelection?: boolean;
  enableMultipleSelection?: boolean;
  enableDelete?: boolean;
  enableBorder?: boolean;
  enableDynamicHeight?: boolean;
  enableCorporateSelection?: boolean; // When selected, all child accounts are being selected by default. TODO: Consider making the all-select optional with another property.
  disableInitExpanded?: boolean;
  enableClearButton?: boolean;
  enableShowAccountInfo?: boolean;
  hideSearchIcon?: boolean;
  hideLabelOptional?: boolean;
}
/** Flat node with expandable and level information */
export interface FlatNode {
  data: AccountListLightTreeNode;
  expandable: boolean;
  level: number; //Starting from level 0
}

export interface ToolbarConfig {
  title?: string;
  enableSearch?: boolean;
  enableFilterList?: boolean;
  enableAddAccount?: boolean;
}

export interface AccountFieldFormConfig {
  label?: string;
  placeholder?: string;
  options?: OptionSelect[];
  enableAutocomplete?: boolean;
  hidden?: boolean;
}

export type AccountFormConfig = {
  enableAllAutoComplete?: boolean;
  fields: {
    [Property in keyof AccountForm]: AccountFieldFormConfig;
  };
};

export class AccountSelectItem {
  address: string;
  preferred: boolean;
  preferredParent: boolean;
  applicationName: ApplicationID;
  accountListType: AccountListType;
  nameHTML: string;
  addressHTML: string;
  selected: boolean;
  isParentSelected: boolean;
  removeAccountEmailParams: AccountEmailTemplateParams;
  removeRequested: boolean;
  user?: AccountUser;
  contactPhoneNumber?: string;
  contactEmail?: string;
  locationType: AccountListLocationType;
  accountId: number;
  addressLine1: string;
  cityName: string;
  countryCode: CountryCode | string;
  madCode: string;
  companyName?: string;
  name: string;
  parentAccountIds: number[];
  postalCode: string;
  stateCode: string;
  useIndicator: boolean;
  id: string;
  dockingInterval?: DockingInterval;
}

export interface AccountUser extends ExternalUserData {
  profile: Profile;
}

export interface AccountInfoModalData {
  userAccountDetail: UserAccountDetail;
  userAccountFinancialInfo: UserAccountFinancialInfo;
  account: AccountSelectItem;
}
