<div class="ltlcc-AccountListTreeToolbarWrapper">
  <mat-toolbar class="ltlcc-AccountListTreeToolbarWrapper-toolbar">
    <span class="ltlcc-AccountListTreeToolbarWrapper-toolbar-title" *ngIf="toolbarConfig?.title">
      {{ toolbarConfig.title }}
    </span>
    <ltlcc-toolbar-select
      *ngIf="toolbarConfig?.enableFilterList"
      class="ltlcc-AccountListTreeToolbarWrapper-toolbar-filterList"
      [options]="listConfig.filterList"
      [formControl]="filterList"
      [label]="'accountList.show' | translate"
    ></ltlcc-toolbar-select>
    <ltlcc-search
      *ngIf="toolbarConfig?.enableSearch"
      class="ltlc-Utils-toolbarInput ltlcc-AccountListTreeToolbarWrapper-toolbar-search"
      [formControl]="searchControl"
      (searchWord)="search($event)"
    ></ltlcc-search>
  </mat-toolbar>
  <ltlcc-account-list-light-tree
    class="ltlcc-AccountListTreeToolbarWrapper-treeList"
    [formControl]="selectedIds"
    [nodes]="nodes"
    [searchWord]="searchControl.value"
    [filterPreferred]="filterList.value"
    [config]="config"
  ></ltlcc-account-list-light-tree>

  <div
    class="ltlcc-AccountListTreeToolbarWrapper-bottom"
    *ngIf="toolbarConfig.enableAddAccount || config.enableMultipleSelection"
  >
    <div>
      <ng-container *ngIf="toolbarConfig.enableAddAccount">
        <div class="ltlcc-AccountListTreeToolbarWrapper-bottom-add-account cursor-pointer" (click)="addAccount()">
          <mat-icon>add_circle</mat-icon>
          <span> {{ 'accountList.addAccount.addAccountRequest' | translate }} </span>
        </div>
      </ng-container>
    </div>

    <div *ngIf="selectedIds.value?.length > 0 && config.enableMultipleSelection">
      <span>
        <b>
          {{ selectedIds.value.length | number : '1.0-0' }}
        </b>
      </span>
      <span>
        {{
          (selectedIds.value.length > 1 ? 'accountList.accountsSelected' : 'accountList.accountSelected') | translate
        }}
      </span>
    </div>
  </div>
</div>
