import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  AuthInterceptor,
  XpoLtlAuthConfig,
  XpoLtlAuthenticationService,
  XpoLtlAuthFetchTokenResponse,
} from '@ltlc/auth';
import { AlertService, WebConfigService } from '@ltlc/core';
import { LtlConnectUserService } from '@ltlc/ltl-core';
import { XpoAuthenticationService, XPO_AUTH_CONFIG, XPO_AUTH_HEADER_KEY } from '@xpo/ngx-auth';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, take, tap } from 'rxjs/operators';
interface TokenStored {
  token: string;
  expireAt?: number;
  created: number;
}

interface JWTDecoded extends JwtPayload {
  client_id: string;
  scope: string[];
}
const unauthenticatedWebToken = '.unauthenticatedWebToken'; // Apigee;
const dotnetToken = '.DotnetToken';

/**
 * Since this project calls both dotnet and java apis AND has both a public and authenticated views that require
 * different tokens; this service handles the complexity to attach what token where depending on were the api is being
 * called from
 */
@Injectable()
export class WebTokenHandlerInterceptor implements HttpInterceptor {
  // we want to conditionally run this interceptor based on what api we are calling. we are
  private readonly ltlAuthInterceptor: AuthInterceptor;
  private unauthenticatedTokenSet = false;

  constructor(private injector: Injector) {
    this.ltlAuthInterceptor = new AuthInterceptor(this.injector);
    this.checkScope();
  }

  private get cachedUnauthenticatedLtlToken(): string | undefined {
    return WebTokenHandlerInterceptor.deriveValidToken(unauthenticatedWebToken);
  }

  private get cachedUnauthenticatedDotnetToken(): string | undefined {
    return WebTokenHandlerInterceptor.deriveValidToken(dotnetToken);
  }

  private get base64TokenUnregistered(): string {
    const webExternalBasicUnregisteredToken = this.configManager.getSetting('webExternalBasicUnregisteredToken');
    return `Basic ${webExternalBasicUnregisteredToken}`;
  }

  private get configManager() {
    return this.injector.get(WebConfigService);
  }

  private get httpClient(): HttpClient {
    return this.injector.get(HttpClient);
  }

  private get authConfig(): XpoLtlAuthConfig {
    return this.injector.get<XpoLtlAuthConfig>(XPO_AUTH_CONFIG);
  }

  private get xpoLtlAuthenticationService(): XpoLtlAuthenticationService {
    return this.injector.get(XpoLtlAuthenticationService);
  }

  private get loggedInUserService(): XpoAuthenticationService {
    return this.injector.get(XpoAuthenticationService);
  }

  private get connectUserService(): LtlConnectUserService {
    return this.injector.get(LtlConnectUserService);
  }

  private get alertService(): AlertService {
    return this.injector.get(AlertService);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // We are assuming all calls to a url ending with `.json` do not need our token
    // This is for our translation files and config.json files that are fetched when app is initialize
    if (request.url.includes('.json') || request.url.includes('/token')) {
      return next.handle(request);
    }

    return this.loggedInUserService.getUser$().pipe(
      take(1),
      switchMap((user) => {
        const isDotnetApi = this.isDotnetApi(request.url);

        // if the user is logged in, handle request as usual
        if (!!user) {
          if (this.unauthenticatedTokenSet) {
            this.clearUnauthenticatedTokens();
          }

          if (isDotnetApi) {
            return this.xpoLtlAuthenticationService.getAccessToken().pipe(
              catchError((error) => {
                // Sometimes PM users don't have profile instance IDs immediately after they create their profile.
                // has to do with the sync to legacy. We are going to let the user go though with using the site if
                // this is the case. the apis that don't use this token will work, but  the ones that do won't.
                // Hopefully this will be an edge cae.
                if (this.connectUserService.isPartnerManagerUser) {
                  return next.handle(request);
                }
                return throwError(error);
              }),
              switchMap((token) => {
                // Adding token to allow Dotnet Api's to access JAVA Api's
                // if (typeof token === 'string') {
                //   request = request.clone({ setHeaders: { apigeeToken: token } });
                // }
                return next.handle(request);
              })
            );
          }

          return this.ltlAuthInterceptor.intercept(request, next);
        }

        this.unauthenticatedTokenSet = true;

        // if its an unregistered user calling an api, if its a dot net api,fetch the authxpo token and continue
        if (isDotnetApi) {
          return this.dotnetToken$().pipe(
            switchMap((tokenObj: TokenStored) => this.delegateRequest(request, next, tokenObj.token))
          );
        }

        // if its an unregistered user calling an api, if its a java api,fetch the ltl token and continue
        return !!this.cachedUnauthenticatedLtlToken
          ? this.delegateRequest(request, next, this.cachedUnauthenticatedLtlToken)
          : this.fetchUnauthenticatedLtlToken().pipe(
              retry(2),
              this.snackbarOnTokenError(),
              tap((tokenObj: XpoLtlAuthFetchTokenResponse) => {
                this.storeToken(unauthenticatedWebToken, tokenObj);
              }),
              switchMap((tokenObj: XpoLtlAuthFetchTokenResponse) => {
                return this.delegateRequest(request, next, tokenObj.access_token);
              })
            );
      })
    );
  }

  dotnetToken$(): Observable<TokenStored> {
    return !!this.cachedUnauthenticatedDotnetToken
      ? of(WebTokenHandlerInterceptor.getStoredToken(dotnetToken))
      : this.fetchUnauthenticatedDotnetToken().pipe(
          retry(2),
          this.snackbarOnTokenError(),
          map((tokenObj: XpoLtlAuthFetchTokenResponse) => {
            const storedToken = this.storeToken(dotnetToken, tokenObj);
            return storedToken;
          })
        );
  }

  private isDotnetApi(api: string): boolean {
    const dotNetApi: string = this.configManager.getSetting('dotNetApi');
    const connectApiUri: string = this.configManager.getSetting('connectApiUri');
    return [dotNetApi, connectApiUri].some(
      (uri) =>
        api.includes(uri) ||
        api.includes('localhost') ||
        api.includes('webSubmission') ||
        api.includes('user-management') ||
        api.includes('customer-notification')
    );
  }

  private delegateRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    token: string
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request.clone({ setHeaders: { [XPO_AUTH_HEADER_KEY]: `Bearer ${token}` } }));
  }

  private fetchUnauthenticatedLtlToken(): Observable<XpoLtlAuthFetchTokenResponse> {
    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'client_credentials');
    return this.xpoLtlAuthenticationService.request('POST', urlencoded, this.base64TokenUnregistered).pipe(
      map((xhr) => {
        const responseJson = JSON.parse(xhr.responseText);
        return responseJson;
      })
    );
  }

  private fetchUnauthenticatedDotnetToken(): Observable<XpoLtlAuthFetchTokenResponse> {
    // TODO: Consider turning literals into env variables
    const clientSecret = 'd57c66d97b5db2ae2d666c38b606a9fc';
    const appCode: string = this.configManager.getSetting('appCode');
    const connectTokenUrl = `${this.authConfig.authorityUri}/connect/token`;

    const body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    body.set('client_id', appCode);
    body.set('scope', this.dotnetScopes.join(' '));
    body.set('client_secret', clientSecret);
    return this.httpClient.post<XpoLtlAuthFetchTokenResponse>(connectTokenUrl, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    /**
     * TODO: we may want to be more strategic about what scopes we request.
     * ltl-web-dotnet-api: For the Freedom Pay API
     */
  }

  private snackbarOnTokenError<T>(): MonoTypeOperatorFunction<T> {
    return catchError((error) => {
      this.alertService.showApiError({ error });
      return throwError(error);
    });
  }

  private clearUnauthenticatedTokens(): void {
    sessionStorage.removeItem(unauthenticatedWebToken);
    sessionStorage.removeItem(dotnetToken);
    this.unauthenticatedTokenSet = false;
  }

  static deriveValidToken(tokenKey: string): string | null {
    const tokenObj: TokenStored | undefined = WebTokenHandlerInterceptor.getStoredToken(tokenKey);
    if (!tokenObj?.expireAt || Number(tokenObj?.expireAt) < Date.now()) {
      sessionStorage.removeItem(tokenKey);
      return null;
    }
    return tokenObj?.token || null;
  }

  private get dotnetScopes(): string[] {
    return ['xpo-partner-master-api', 'xpo-auth-api', 'ltl-web-pub-hrc', 'ltl-web-dotnet-api'];
  }

  private storeToken(tokenName: string, tokenObj: XpoLtlAuthFetchTokenResponse): TokenStored {
    const latencyBuffer = 240;
    const storeToken: TokenStored = {
      token: tokenObj.access_token,
      expireAt: Date.now() + (tokenObj.expires_in - latencyBuffer) * 1000,
      created: Date.now(),
    };
    sessionStorage.setItem(tokenName, JSON.stringify(storeToken));
    return storeToken;
  }

  private static getStoredToken(tokenName: string): TokenStored | undefined {
    return sessionStorage.getItem(tokenName) ? JSON.parse(sessionStorage.getItem(tokenName)) : undefined;
  }

  private decodedToken(token: string): JWTDecoded | null {
    if (!token) {
      return null;
    }
    return jwt_decode(token);
  }

  private checkScope(): void {
    if (this.cachedUnauthenticatedDotnetToken) {
      const decoded = this.decodedToken(this.cachedUnauthenticatedDotnetToken);
      const hasAllScopes = this.dotnetScopes.every((scope) => decoded.scope.includes(scope));
      if (!hasAllScopes) {
        this.clearUnauthenticatedTokens();
      }
    }
  }
}
