<form [formGroup]="accountDetailForm" class="ltlcc-AccountDetailsForm-form">
  <mat-accordion>
    <mat-expansion-panel class="ltlc-AccountDetailsForm-expansionPanel" [expanded]="expanded">
      <ltlcc-form-company-name
        *ngIf="'companyName' | ltlccIsOptionInList : shownFields"
        formControlName="companyName"
        [label]="'accountDetails.companyName' | translate"
        [required]="'companyName' | ltlccIsOptionInList : fieldsRequired"
        dataCy="accountDetailForm-companyName"
      ></ltlcc-form-company-name>

      <ltlcc-form-text
        *ngIf="'careOfName' | ltlccIsOptionInList : shownFields"
        formControlName="careOfName"
        [label]="'accountDetails.careOfName' | translate"
        [required]="'careOfName' | ltlccIsOptionInList : fieldsRequired"
        dataCy="accountDetailForm-careOfName"
      ></ltlcc-form-text>

      <ltlcc-form-select-country
        *ngIf="'countryCode' | ltlccIsOptionInList : shownFields"
        formControlName="countryCode"
        [required]="true"
        [label]="'form.labels.country' | translate"
        [required]="'countryCode' | ltlccIsOptionInList : fieldsRequired"
        [countries]="countries"
        dataCy="accountDetailForm-countryCode"
      ></ltlcc-form-select-country>

      <ltlcc-address-lines
        *ngIf="'addressLines' | ltlccIsOptionInList : shownFields"
        formControlName="addressLines"
        [required]="'addressLines' | ltlccIsOptionInList : fieldsRequired"
        [attr.data-cy]="'accountDetailForm-addressLines'"
      >
      </ltlcc-address-lines>
      <ltlcc-form-text
        *ngIf="'cityName' | ltlccIsOptionInList : shownFields"
        formControlName="cityName"
        [label]="'form.labels.cityName' | translate"
        [required]="'cityName' | ltlccIsOptionInList : fieldsRequired"
        dataCy="accountDetailForm-cityName"
      ></ltlcc-form-text>

      <ltlcc-select-state
        *ngIf="'stateCode' | ltlccIsOptionInList : shownFields"
        formControlName="stateCode"
        [countryCode]="accountDetailForm.get('countryCode').value"
        [required]="'stateCode' | ltlccIsOptionInList : fieldsRequired"
        dataCy="accountDetailForm-stateCode"
      ></ltlcc-select-state>

      <div class="ltlc-AccountDetailsForm-form-multiInputRow">
        <ltlcc-postal-code
          *ngIf="'postalCode' | ltlccIsOptionInList : shownFields"
          formControlName="postalCode"
          [label]="'form.labels.postalCode' | translate"
          [countryCode]="accountDetailForm.get('countryCode').value"
          [required]="'postalCode' | ltlccIsOptionInList : fieldsRequired"
          [checkPostalCodeInDb]="true"
          dataCy="accountDetailForm-postalCode"
        ></ltlcc-postal-code>
        <ltlcc-form-phone-number
          *ngIf="'phoneNumber' | ltlccIsOptionInList : shownFields"
          formControlName="phoneNumber"
          [label]="'form.labels.phoneNumber' | translate"
          [required]="'phoneNumber' | ltlccIsOptionInList : fieldsRequired"
          dataCy="accountDetailForm-phoneNumber"
          [countryCode]="accountDetailForm.get('countryCode').value"
        ></ltlcc-form-phone-number>

        <ltlcc-form-phone-extension
          *ngIf="'phoneExtension' | ltlccIsOptionInList : shownFields"
          formControlName="phoneExtension"
          [label]="'form.labels.phoneExtension' | translate"
          [required]="'phoneExtension' | ltlccIsOptionInList : fieldsRequired"
          dataCy="accountDetailForm-phoneExtension"
        >
        </ltlcc-form-phone-extension>
      </div>

      <ltlcc-form-email
        *ngIf="'email' | ltlccIsOptionInList : shownFields"
        formControlName="email"
        [label]="'form.labels.email' | translate"
        [required]="'email' | ltlccIsOptionInList : fieldsRequired"
        dataCy="accountDetailForm-email"
      >
      </ltlcc-form-email>
      <ng-container *ngIf="expanded">
        <ng-container *ngTemplateOutlet="alwaysShownFields"></ng-container>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
  <ng-container *ngIf="!expanded">
    <ng-container *ngTemplateOutlet="alwaysShownFields"></ng-container>
  </ng-container>
  <ng-template #alwaysShownFields>
    <div class="ltlc-AccountDetailsForm-form-multiInputRow">
      <ltlcc-form-text
        *ngIf="'contactFullName' | ltlccIsOptionInList : shownFields"
        formControlName="contactFullName"
        [label]="'accountDetails.contactFullName' | translate"
        [required]="'contactFullName' | ltlccIsOptionInList : fieldsRequired"
        dataCy="accountDetailForm-contactFullName"
      ></ltlcc-form-text>

      <ltlcc-form-phone-number
        *ngIf="'contactPhoneNumber' | ltlccIsOptionInList : shownFields"
        formControlName="contactPhoneNumber"
        [label]="'accountDetails.contactPhoneNumber' | translate"
        [required]="'contactPhoneNumber' | ltlccIsOptionInList : fieldsRequired"
        dataCy="accountDetailForm-contactPhoneNumber"
        [countryCode]="accountDetailForm.get('countryCode').value"
      ></ltlcc-form-phone-number>
    </div>

    <ltlcc-form-email
      *ngIf="'contactEmail' | ltlccIsOptionInList : shownFields"
      formControlName="contactEmail"
      [label]="'accountDetails.contactEmail' | translate"
      [required]="'contactEmail' | ltlccIsOptionInList : fieldsRequired"
      dataCy="accountDetailForm-contactEmail"
    ></ltlcc-form-email>

    <ltlcc-docking-interval
      *ngIf="'dockingInterval' | ltlccIsOptionInList : shownFields"
      formGroupName="dockingInterval"
    >
    </ltlcc-docking-interval>

    <ltlcc-form-textarea
      *ngIf="'comment' | ltlccIsOptionInList : shownFields"
      class="ltlcc-AccountDetailsForm-comment"
      formControlName="comment"
      [label]="'accountDetails.comment' | translate"
      [placeholder]="'accountDetails.commentPlaceholder' | translate"
      [maxLength]="400"
      [required]="'comment' | ltlccIsOptionInList : fieldsRequired"
      dataCy="accountDetailForm-commentPlaceholder"
    ></ltlcc-form-textarea>
  </ng-template>
</form>
