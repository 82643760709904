import { CommodityItemRequest } from '@ltlc/api';
import { Commodity } from '../interfaces/commodity.interface';

export class CommodityHelper {
  static transformCommodityItemRequest(
    data: Commodity,
    profileInstId: string,
    commodityId?: number | string
  ): CommodityItemRequest {
    const request: CommodityItemRequest = {
      userCommodity: {
        userSystemId: +parseInt(profileInstId),
        commodityId: !isNaN(+commodityId) ? Math.abs(+commodityId) : 0,
        commodityDescription: data.name,
        commodityPackageCd: data.packaging,
        commodityClass: data.freightClass.toString(),
        commodityNmfc: data.nmfc,
        commodityHazmatInd: data.hazmatIndicator ?? false,
        // commodityHazmatUnnaId: string;
        commodityLengthInch: CommodityHelper.toNumber(data.length),
        commodityWidthInch: CommodityHelper.toNumber(data.width),
        commodityHeightInch: CommodityHelper.toNumber(data.height),
        commodityWeightLbs: CommodityHelper.toNumber(data.weight),
        commodityPieceCount: data.pieces,
        protectFromFreezingInd: !!data.freezableIndicator,
        excessValueCoverageAmount: CommodityHelper.toNumber(data.evcValue),
        // correlationId: string;
        // listActionCd: string;
      },
    };

    return request;
  }

  private static toNumber(v: string | number): number | undefined {
    return !+v ? undefined : +v;
  }

  static isFreezable(commodities: Commodity[]): boolean {
    return commodities?.some((commodity: Commodity) => commodity.freezableIndicator);
  }

  static isHazmat(commodities: Commodity[]): boolean {
    return commodities?.some((commodity: Commodity) => commodity.hazmatIndicator);
  }

  static isWeightGreaterThan20k(commodities: Commodity[]): boolean {
    return commodities?.some((commodity: Commodity) => Number(commodity.weight) > 20_000);
  }

  static isTotalWeightLessThan500Pounds(commodities: Commodity[] = []): boolean {
    return commodities.map((c) => +c?.weight ?? 0).reduce((total, weight) => total + weight, 0) < 500;
  }
}
