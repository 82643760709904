import { ClaimDocument } from './claims.interface';
import { WebFormBody } from './web-forms.interface';

export interface RebuttalForm extends WebFormBody {
  apigeeToken: string;
  claimId: string;
  claimedAmount: number;
  comment: string;
  profileInstanceId: string;
  reasonCode: RebuttalReasons & string;
  sequenceNbr: string;
  supportingDocuments: ClaimDocument[];
}

export enum RebuttalReasons {
  addDocuments = 'AddDocuments',
  amendAmount = 'AmendingClaimAmount',
  concealedDamage = 'ConcealedDamage',
  contractDiscrepancy = 'ContractDiscrepancy',
  freightCharges = 'FreightCharges',
  other = 'Other',
}
