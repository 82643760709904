import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ToolbarSelectComponent } from './toolbar-select.component';

@NgModule({
  declarations: [ToolbarSelectComponent],
  imports: [CommonModule, MatSelectModule, MatDividerModule, ReactiveFormsModule],
  exports: [ToolbarSelectComponent],
})
export class ToolbarSelectModule {}
