export class FocusHelper {
  static focusElement(element: HTMLElement): void {
    FocusHelper.blur();

    element.focus();
  }

  static blur(): void {
    if (document?.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
}
