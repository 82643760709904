import { PartialPaymentDetails } from '../../payments';

interface InvoiceApiBase {
  partnerCodes?: string[];
}
export interface InvoiceSearchRequest extends InvoiceApiBase {
  invoiceInfoList: InvoiceInfo[];
  partnerIdentifier: string;
}

export interface MultiDocumentExportRequest extends InvoiceApiBase {
  partnerIdentifierCode: string;
  documentIdentifiers: string[];
  isCombinedDocumentRequested?: boolean;
}

export interface InvoiceSummaryRequest extends InvoiceApiBase {
  invoices: InvoiceInfo[];
  paymentMethodCode: string;
  isPartialPayment?: boolean;
  partialPaymentAmount?: number;
}

export interface InvoiceFilter extends InvoiceApiBase {
  number: string;
  dueDateRange: DueDateFilter;
  invoiceGroupId: string;
  isRange: boolean;
  balanceDueRange: BalanceDueFilter;
  pageNumber: number;
  pageSize: number;
  childPartners: any[];
  invoiceStatus?: any[];
  amountDue?: number;
  sortExpressions?: any[];
}

export class UnpaidInvoice {
  invoiceNumber: string;
  shipmentNumber: string;
  dueDate: string;
  amountDue: number;
  amountDueCurrencyCode: string;
  outstandingBalance: number;
  outstandingBalanceCurrencyCode: string;
  fileId: string;
}

export class InvoicesData {
  paidInvoiceCount: number;
  unpaidInvoiceCount: number;
  unpaidInvoices: UnpaidInvoice[];
}

export class InvoicesSummary {
  balanceDue: number;
  convienceFee: number;
  totalCost: number;
  currencySymbolCode: string;
  customerCode: string;
  invoices: InvoiceInfo[];
  allowedPaymentMethods?: string[];
}
export interface InvoiceInfo {
  invoiceNumber?: string;
  orderNumber?: string;
  dueDate?: string;
  mode?: string; // TODO: this is a UI concept, remove from api model
  amount?: number;
  currencyCode?: string;
  sourceApplicationKey?: string;
  customerCode?: string;
  balanceDue?: string;
  isAccessorialPaymentPending?: boolean;
  isUnInvoiced?: boolean;
  accessorialCharges?: AccessorialCharges[];
  partialPaymentDetails?: PartialPaymentDetails; // TODO: this is a UI concept, remove from api model
  amountPaid?: number; // TODO: this is a UI concept, remove from api model
}

export interface AccessorialCharges {
  accountsReceivablesChargeId?: Number;
  accountsReceivablesId?: Number;
  code?: string;
  cost: Number;
  name: string;
}

export interface InvoiceGroup {
  invoiceGroupId: string;
  invoiceNumber: string;
  username: string;
}

export interface InvoiceGroupModel {
  invoiceGroupId: string;
  data: InvoiceGroupData;
  isEmailSent: boolean;
  username: string;
}

export interface InvoiceGroupData {
  billingInvoiceNumbers: string[];
  companyName: string;
  companyAddress: string;
  reportName: string;
  recipients: string[];
}

export interface InvoiceList {
  invoices: Invoice[];
  totalInvoiceCount: number;
  currentPage: number;
  pageSize: number;
  totalPagesCount: number;
  isInvoicePaymentFeatureEnabled: boolean;
}

export interface Invoice {
  invoiceNumber: string;
  shipmentNumber: string;
  dueDate: string;
  amountDue: number;
  amountApplied: number;
  amountDueCurrencyCode: string;
  outstandingBalance: number;
  outstandingBalanceCurrencyCode: string;
  fileId: string;
  shipmentFinancialStatusTypeId: number;
  invoiceFinancialStatusTypeId: number;
  lastUpdateDateTime: string;
  customerCode: string;
  mode?: string;
  isChecked?: boolean;
  sourceApplicationKey?: string;
  sourceApplicationCode?: string;
  invoiceStatus?: string;
  isAccessorialPaymentPending?: boolean;
  isUnInvoiced?: boolean;
  disputes?: Dispute[];
}

export interface Dispute {
  disputeId: string;
  disputeStatus: string;
  primaryCategory: string;
  secondaryCategory: string;
  tertiaryCategory: string;
  disputeSubmissionResult: string;
  disputeCreationSystem: string;
  lastUpdatedTimeStamp: string;
}

export interface FilterInvoiceCriteria {
  moduleType: string;
  customerCode: string;
  partnerIdentifier: string;
  userEmail: string;
  role: string;
  systemId?: number;
}

export interface BillingDocumentDownloadRequest {
  id: string;
  docClass: string;
}

export interface InvoiceDetailsRequest {
  invoiceId: number;
  prefferedCurrency: string;
  customerCode: string;
  applicationCode: string;
  businessUnit: string;
  groupCode: string;
}

export interface InvoicePaymentStatus {
  invoiceNumber: string;
  isPaid: boolean;
  sourceApplicationKey: string;
  orderNumber: string;
  proNumber: string;
  dueDate: string;
  amount: number;
  currencyCode: string;
  customerCode: string;
}

export interface InvoicePaymentStatusResponse {
  invoices: InvoicePaymentStatus[];
  isSuccess: boolean;
  numberOfInvoices: number;
  numberOfPaidInvoices: number;
}

export interface FilterInvoiceList {
  code: string;
  name: string;
  description: string;
}

export interface DueDateFilter {
  // TODO: any what?
  fromDate?: any;
  toDate?: any;
}
export interface BalanceDueFilter {
  minValue?: number;
  maxValue?: number;
}

export interface CreditCardTokenRequest {
  creditCardTokenId?: number;
  cardIssuer: string | null;
  cardHolderPartnerCodeId?: number;
  creditCardToken?: string | null;
  cardExpirationYear: number;
  cardExpirationMonth: number;
  userName: string | null;
  paymentKey: string | null;
  sessionKey: string | null;
  attemptNumber: number;
  cardHolderName: string | null;
  emailAddress: string | null;
  phoneNumber?: string | null;
  cardHolderId?: number;
  partnerCode?: string | null;
  systemId?: number;
  partnerTypeId?: number;
  attributes: FreedomPayAttribute[] | null;
  maskedCardNumber: string | null;
  referenceNumber: string | null;
  isMITRequest?: boolean;
  isVerifyOnboardingRequest?: boolean;
}

export interface FreedomPayFrame {
  // For type === 2
  height: number;
}

export interface FreedomPayPaymentData {
  // For type === 3
  paymentType: CreditCardPaymentType;
  paymentKeys: string[];
  attributes: FreedomPayAttribute[];
  tokenInformation?: any;

  // Additional info
  sessionKey: string;
  cardHolderName: string;
  cardHolderEmail: string | null;
}
export interface FreedomPayMessage {
  data: FreedomPayFrame | FreedomPayPaymentData | any;
  messageCode: string | undefined;
  type: number;
}

export interface FreedomPayAttribute {
  Key: string;
  Value: string;
}

export enum CreditCardPaymentType {
  card = 'Card',
  cardOnFile = 'CardOnFile',
}

export enum DisputeStatusType {
  submitted = 'submitted',
  declined = 'declined',
  approved = 'approved',
}
