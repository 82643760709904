import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

@Component({
  selector: 'ltlcc-form-phone-extension',
  templateUrl: './phone-extension.component.html',
  styleUrls: ['./phone-extension.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-FormPhoneExtension' },
})
export class PhoneExtensionComponent extends FormControlsBaseComponent {
  getDefaultLabel(): string {
    return this.translate.instant('form.labels.phoneExtension');
  }
}
