export class HighlightSearchTextHelper {
  static htmlize(searchWord: string, content: string, bold = false): string {
    let text = `<span>`;
    content = content?.toString() ?? '';
    searchWord = searchWord?.toString() ?? '';

    if (!searchWord || !content?.toUpperCase().includes(searchWord.toUpperCase())) {
      text += `${content}</span>`;
    } else {
      let contentPart = content;
      let index = 0;

      while (index !== -1 && contentPart?.length > 0) {
        index = contentPart.toUpperCase().indexOf(searchWord.toUpperCase());

        if (index !== -1) {
          text += contentPart.slice(0, index);
          text += `<mark>${contentPart.slice(index, searchWord.length + index)}</mark>`;
          contentPart = contentPart.slice(index + searchWord.length);
        }
      }

      text += `${contentPart}</span>`;
    }

    if (bold) {
      text = `<b>${text}</b>`;
    }
    return text;
  }
}
