export class DocumentsNode {
  name: string;
  children?: DocumentsNode[];
  proNumber?: string;
  uploadedOn?: string;
  corpCode?: string;
  docClass?: string;
  timeStamp?: string;
}
export class DocumentsFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  proNumber?: string;
  uploadedOn?: string;
  corpCode?: string;
  docClass?: string;
  timeStamp?: string;
  length?: number;
}

export class DocumentsType {
  name: string;
  completed: boolean;
  types?: types[];
  corpCode?: string;
  docClass?: string;
  timeStamp?: string;
}

export class types {
  name: string;
  value: string;
  completed: boolean;
}
