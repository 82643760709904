<div class="ltlcc-ArrayValueCellRenderer-firstItem">
  <span class="ltlcc-ArrayValueCellRenderer-firstItem-text">
    {{ arrayValue[0] }}
    <ng-container *ngIf="arrayValue.length > 1">
      <span class="ltlcc-ArrayValueCellRenderer-firstItem-arrayLength">(+{{ arrayValue.length }})</span>
    </ng-container>
  </span>
  <button
    *ngIf="arrayValue.length > 1 && !hideExpand"
    mat-icon-button
    class="ltlcc-ArrayValueCellRenderer-toggleButton"
    (click)="handleToggleClick($event)"
  >
    <mat-icon>{{ showMore ? 'expand_less' : 'expand_more' }}</mat-icon>
  </button>
</div>

<ng-container *ngIf="arrayValue.length > 1 && showMore">
  <span *ngFor="let v of arrayValue | slice : 1">{{ v }}</span>
</ng-container>
