import { HttpErrorResponse, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { XpoAuthenticationService, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap, timeout } from 'rxjs/operators';
import { LTL_AUTH_ROUTE_PATHS } from '../constants/auth-routes.const';
import { AuthService } from './auth-service-base';
import { XpoLtlAuthConfig, XpoLtlAuthFetchTokenResponse } from './authentication.interface';

const OVERRIDE_KEY = '.OverrideToken';
const ACCESS_KEY = '.AccessToken';
const ACCESS_TOKEN_EXPIRATION_KEY = '.AccessTokenExpiration';
const REFRESH_KEY = '.RefreshToken';
const REFRESH_TOKEN_EXPIRATION_KEY = '.RefreshTokenExpiration';

@Injectable()
export class XpoLtlAuthenticationService implements AuthService {
  private svcAcct: string;
  private svcAcctKey: string;
  private fetchingToken: boolean = false;
  private tokenSubject = new Subject<XMLHttpRequest>();

  constructor(
    private xpoAuthenticationService: XpoAuthenticationService,
    @Inject(XPO_AUTH_CONFIG) private authConfigService: XpoLtlAuthConfig,
    private router: Router
  ) {}

  get appName(): string {
    return this.authConfigService.appName;
  }

  get accessKey(): string {
    return `${this.appName}${ACCESS_KEY}`;
  }

  get refreshKey(): string {
    return `${this.appName}${REFRESH_KEY}`;
  }

  get accessTokenExpKey(): string {
    return `${this.appName}${ACCESS_TOKEN_EXPIRATION_KEY}`;
  }

  get refreshTokenExpKey(): string {
    return `${this.appName}${REFRESH_TOKEN_EXPIRATION_KEY}`;
  }

  get base64Token(): string {
    return `Basic ${btoa(
      unescape(encodeURIComponent(`${this.authConfigService.consumerKey}:${this.authConfigService.consumerSecret}`))
    )}`;
  }

  get tokenURL(): string {
    return `${this.authConfigService.apiUri}/token`;
  }

  initAuthSetup$(): Observable<Partial<XpoLtlAuthConfig>> {
    return of({
      consumerKey: this.authConfigService.consumerKey,
      consumerSecret: this.authConfigService.consumerSecret,
    });
  }

  // #region AuthService implementation
  isAuthorized(): Observable<boolean> {
    const accessTokenExpiration = this.getAccessTokenExpiration();
    const isAuthorized = Date.now() < accessTokenExpiration;

    const accessKey = this.accessKey;

    if (!isAuthorized && !!sessionStorage.getItem(accessKey)) {
      sessionStorage.removeItem(accessKey);
    }

    return of(isAuthorized);
  }

  getAccessToken(): Observable<string> {
    const overrideKey = `${this.appName}${OVERRIDE_KEY}`;
    const overrideToken = sessionStorage.getItem(overrideKey);

    if (!!overrideToken) {
      return of(overrideToken);
    } else {
      const accessKey = this.accessKey;
      const accessTokenExpiration = this.getAccessTokenExpiration();
      const isAuthUserExpired = this.xpoAuthenticationService.getUser()?.expired;

      // TODO: this should be added in the @xpo/ngx-auth library to refresh its token when it expires. as well as the
      // ltl-auth library should catch that event, and refresh its token when a silent refresh from ngx-auth occurs.
      // remove the access token if it has expired
      if (Date.now() >= accessTokenExpiration || isAuthUserExpired) {
        sessionStorage.removeItem(accessKey);
      }

      const accessToken = sessionStorage.getItem(accessKey);

      if (accessToken) {
        // we have a valid token
        return of(accessToken);
      } else {
        // need to load a token

        return isAuthUserExpired
          ? this.xpoAuthenticationService.startSilentRefresh$().pipe(switchMap(() => this.loadTokens()))
          : this.loadTokens();
      }
    }
  }

  refreshToken(): Observable<any> {
    const refreshKey = this.refreshKey;
    const refreshTokenExpiration = this.getRefreshTokenExpiration();

    // remove the refresh token if it has expired
    if (Date.now() >= refreshTokenExpiration) {
      sessionStorage.removeItem(refreshKey);
    }

    // return the refresh token, if it exists
    const refreshToken = sessionStorage.getItem(refreshKey);
    if (refreshToken) {
      sessionStorage.removeItem(refreshKey); // can only be used once
      // we have a valid refresh token, so try to refresh the access token with it
      const body = new URLSearchParams();
      body.append('grant_type', 'refresh_token');
      body.append('refresh_token', refreshToken);

      return this.fetchToken(body, this.base64Token).pipe(
        catchError((err) => {
          return this.loadTokens();
        })
      );
    } else {
      // no refresh token, so get all new tokens
      return this.loadTokens();
    }
  }

  refreshShouldHappen(response: HttpErrorResponse, request?: HttpRequest<any>): boolean {
    return response.status === HttpStatusCode.Unauthorized;
  }

  verifyTokenRequest(url: string): boolean {
    return url.endsWith('refresh-token');
  }

  logout(): void {
    [this.accessKey, this.refreshKey, this.accessTokenExpKey, this.refreshTokenExpKey].forEach((key) =>
      sessionStorage.removeItem(key)
    );
  }

  fetchTokenByPwd(): Observable<string> {
    if (!this.svcAcct || !this.svcAcctKey) {
      throw new Error(`initAuthSetup$ needs to be finished before fetching token by pwd | App: ${this.appName}`);
    }
    const body = new URLSearchParams();
    const scopeOptions = this.authConfigService.scopeOptions;

    body.append('grant_type', 'password');
    body.append('username', this.svcAcct);
    body.append('password', this.svcAcctKey);

    if (scopeOptions && scopeOptions.length > 0) {
      body.append('scope', this.authConfigService.scopeOptions.join(' '));
    }
    return this.request('POST', body, this.base64Token).pipe(
      catchError((val) => {
        return throwError(val);
      }),
      map((xhr) => {
        const responseJson = JSON.parse(xhr.responseText);
        return responseJson.access_token;
      })
    );
  }

  revokeToken(): Observable<any> {
    return new Observable((observer) => {
      if (!this.fetchingToken) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${this.authConfigService.apiUri}/revoke`);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.setRequestHeader('Authorization', this.base64Token);
        xhr.setRequestHeader('Accept', 'application/json');

        const body = new URLSearchParams();
        body.append('token', sessionStorage.getItem(this.accessKey));

        xhr.addEventListener('error', (event) => {
          observer.error(event);
        });

        xhr.addEventListener('abort', (event) => {
          observer.error(event);
        });

        xhr.addEventListener('readystatechange', () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === HttpStatusCode.Ok) {
              this.logout();
              observer.next(xhr);
            } else {
              observer.error(xhr);
            }
          }
        });

        xhr.send(body);
      }
    });
  }

  /**
   * Fetch new WSO2 tokens
   */
  private loadTokens(): Observable<string> {
    return this.xpoAuthenticationService.getUser$().pipe(
      filter((user) => !!user),
      // if user does not come back in 3 seconds something might be wrong, timeout so observable doesn't hang
      timeout(3000),
      switchMap((user) => {
        const body = new URLSearchParams();
        const scopeOptions = this.authConfigService.scopeOptions;

        body.append('grant_type', this.authConfigService.authGrantType);
        body.append('assertion', user.access_token);

        if (scopeOptions && scopeOptions.length > 0) {
          body.append('scope', this.authConfigService.scopeOptions.join(' '));
        }

        return this.fetchToken(body, this.base64Token);
      })
    );
  }
  /**
   * Return the token string from the API, re-authenticating if needed
   */
  fetchToken(urlSearchParams: URLSearchParams, base64Token: string): Observable<string> {
    return this.request('POST', urlSearchParams, base64Token).pipe(
      catchError((val) => {
        return throwError(val);
      }),
      tap((xhr) => {
        // Save token information into storage
        const responseJson: XpoLtlAuthFetchTokenResponse = JSON.parse(xhr.responseText);

        sessionStorage.setItem(this.accessKey, responseJson.access_token);
        const accessTokenExpiration: number = Date.now() + (responseJson.expires_in - 240) * 1000;
        sessionStorage.setItem(this.accessTokenExpKey, `${accessTokenExpiration}`);

        sessionStorage.setItem(this.refreshKey, responseJson.refresh_token);
        const refreshTokenExpiration: number = Date.now() + (responseJson.expires_in - 240) * 2000;
        sessionStorage.setItem(this.refreshTokenExpKey, `${refreshTokenExpiration}`);
      }),
      map((xhr) => {
        const responseJson = JSON.parse(xhr.responseText);
        return responseJson.access_token;
      })
    );
  }

  request(method: string, urlSearchParams: URLSearchParams, base64Token: string): Observable<XMLHttpRequest> {
    if (!this.fetchingToken) {
      this.fetchingToken = true;
      const xhr = new XMLHttpRequest();
      xhr.open(method, this.tokenURL);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader('Authorization', base64Token);
      xhr.setRequestHeader('Accept', 'application/json');

      xhr.addEventListener('error', (event) => {
        this.tokenSubject.error(event);
        this.fetchingToken = false;
        this.router.navigate([LTL_AUTH_ROUTE_PATHS.systemIssue]);
      });

      xhr.addEventListener('abort', (event) => {
        this.tokenSubject.error(event);
        this.fetchingToken = false;
      });

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === HttpStatusCode.Ok) {
            this.tokenSubject.next(xhr);
            // tslint:disable-next-line:no-bitwise
          } else if ((xhr.status & HttpStatusCode.BadRequest) === HttpStatusCode.BadRequest) {
            // Getting an error here probably means we need to redo our SSO login
            if (
              xhr.status === HttpStatusCode.BadRequest &&
              (xhr.response as string).includes('"error":"invalid_grant"')
            ) {
              // WSO2 returns a 400 when the JWT token is not valid. It needs to be 401 so that ngx-auth
              // weill refresh the token and we can try this all again.
              const modifiedXhr = {
                status: HttpStatusCode.Unauthorized,
                statusText: 'Unauthorized',
                responseURL: xhr.responseURL,
              };
              this.tokenSubject.error(modifiedXhr);
            } else {
              this.tokenSubject.error(xhr);
            }
          } else {
            this.tokenSubject.error(xhr);
          }

          this.fetchingToken = false;
        }
      });

      xhr.send(urlSearchParams);
    }

    return this.tokenSubject;
  }

  getAccessTokenExpiration(): number | null {
    const timeMilliSecString = sessionStorage.getItem(this.accessTokenExpKey);
    if (!timeMilliSecString) {
      return null;
    }
    return parseInt(timeMilliSecString, 10);
  }

  getRefreshTokenExpiration(): number {
    const timeMilliSecString = sessionStorage.getItem(this.refreshTokenExpKey);
    return parseInt(timeMilliSecString, 10);
  }
}
