import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { FormControlsBaseComponent } from '../base/directives';

@Component({
  selector: 'ltlcc-form-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordComponent extends FormControlsBaseComponent {
  @Input() passwordRules = {
    minLengthPass: 8,
    maxLengthPass: 15,
  };

  @Input() isCurrentPassword: boolean = false;

  getValidatorFunctions(): ValidatorFn[] {
    return [
      Validators.minLength(this.passwordRules.minLengthPass),
      Validators.maxLength(this.passwordRules.maxLengthPass),
    ];
  }
}
