import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FormErrorMessageModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { AccountListLightTreeModule } from '../account-list-light-tree/account-list-light-tree.module';
import { AccountListTreeAutocompleteWrapperComponent } from './account-list-tree-autocomplete-wrapper.component';

@NgModule({
  declarations: [AccountListTreeAutocompleteWrapperComponent],
  imports: [
    CommonModule,
    AccountListLightTreeModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatButtonModule,
    MatAutocompleteModule,
    FormErrorMessageModule,
  ],
  exports: [AccountListTreeAutocompleteWrapperComponent],
})
export class AccountListTreeAutocompleteWrapperModule {}
