<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }}{{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <input
    matInput
    [min]="minDate"
    [max]="maxDate"
    [matDatepicker]="datepicker"
    [attr.data-cy]="dataCy"
    [placeholder]="placeholder"
    [formControl]="control"
    [required]="required"
    [matDatepickerFilter]="datepickerFilter"
  />
  <mat-datepicker-toggle matSuffix [for]="datepicker" />
  <mat-datepicker #datepicker color="primary"></mat-datepicker>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
