import { PackageType } from '../../api-shared';
import { PaymentTerms } from '../../rating';

// The BOL API return a pakageTxt instead of the 3-character package type code.
export enum BolInstancePackageCodeToPackageType {
  Carton = PackageType.CTN,
  Pallet = PackageType.PLT,
  Bag = PackageType.BAG,
  Pieces = PackageType.PCS,
  Skid = PackageType.SKD,
  Bale = PackageType.BAL,
  Barrel = PackageType.BBL,
  Bundle = PackageType.BDL,
  Bin = PackageType.BIN,
  Box = PackageType.BOX,
  'Basket/Hampr' = PackageType.BSK,
  Bottle = PackageType.BTL,
  Bucket = PackageType.BXT,
  Cabinet = PackageType.CAB,
  Cage = PackageType.CAG,
  Can = PackageType.CAN,
  Case = PackageType.CAS,
  'Carby/Gaylrd' = PackageType.CBY,
  Chest = PackageType.CHS,
  Container = PackageType.CNT,
  Coil = PackageType.COL,
  Crate = PackageType.CRT,
  Cylinder = PackageType.CYL,
  Drum = PackageType.DRM,
  EnvelopeV = PackageType.ENV,
  Frame = PackageType.FRM,
  'Int Bulk Ctr' = PackageType.IBC,
  Jar = PackageType.JAR,
  Jerrycan = PackageType.JER,
  Jug = PackageType.JUG,
  Keg = PackageType.KEG,
  Kit = PackageType.KIT,
  Loose = PackageType.LSE,
  Miscellaneous = PackageType.MSC,
  Pail = PackageType.PAL,
  Package = PackageType.PKG,
  Platform = PackageType.PLF,
  Rack = PackageType.RCK,
  Reel = PackageType.REL,
  Roll = PackageType.ROL,
  Sack = PackageType.SAK,
  Sleeve = PackageType.SLV,
  Spool = PackageType.SPL,
  Tube = PackageType.TBE,
  'Tote bin' = PackageType.TBN,
  Tank = PackageType.TNK,
  Tray = PackageType.TRY,
  Tub = PackageType.TUB,
}

export enum PaymentBolTermInstance {
  Ppd = PaymentTerms.prepaid,
  Coll = PaymentTerms.collect,
}
