import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FooterLink } from '../../../interfaces/shell.interface';

@Component({
  selector: 'ltlcc-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLinksComponent {
  @Input() primaryLinks: FooterLink[] = [];
  @Input() secondaryLinks: FooterLink[] = [];
  @Input() showLanguagePicker = false;
}
