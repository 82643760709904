import { ChangeDetectionStrategy, Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LoaderConfigData } from './loader.interface';
import { PORTAL_DATA } from './loader.token';

@UntilDestroy()
@Component({
  selector: 'ltlcc-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ltlcc-Loader cdk-overlay-dark-backdrop',
  },
})
export class LoaderComponent {
  constructor(@Optional() @Inject(PORTAL_DATA) public data?: LoaderConfigData) {}
}
