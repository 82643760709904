import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ltla-system-issue',
  templateUrl: './system-issue.component.html',
  styleUrls: ['./system-issue.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltla-SystemIssue' },
})
export class SystemIssueComponent {
  redirectUrl = sessionStorage.getItem('xpo-auth-redirect-key') ?? '/';
}
