import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { XrtSearchRequest, XrtSearchResponse } from '../elasticsearch/interfaces/elasicsearch.interface';
import { API_URI } from '../tokens';
import {
  CustomerRateQuote,
  CustomerRateQuotesApiResponse,
  SearchPickupRequestApiRequest,
  SearchPickupRequestApiResponse,
  SearchShipmentsApiResponseData,
  ShipmentDataApiResponse,
  ShipmentDetailsApiResponseHb,
  ShipmentNotificationResponse,
} from './interfaces/haveblue.interface';

@Injectable({
  providedIn: 'root',
})
export class HaveblueService {
  //TODO: Remove Haveblue and use legacy api's. It was a POC project working as a proxy but not adding any value.
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get haveblueUrl(): string {
    return `${this.apiUri}/haveblue/1.0/`;
  }

  getShipmentStatusDetailHb(proNumber: string): Observable<ShipmentDataApiResponse> {
    const url = `${this.haveblueUrl}shipments?proNumber=${proNumber}`;
    return this.httpClient.get<ShipmentDetailsApiResponseHb>(url).pipe(map((v) => v.data.shipment));
  }

  getQuotes(accountId: string | number): Observable<CustomerRateQuote[]> {
    const url = `${this.haveblueUrl}customers/${accountId}/rate-quotes`;

    return this.httpClient.get<CustomerRateQuotesApiResponse>(url).pipe(
      map((response) => {
        return response.data.results;
      })
    );
  }

  searchShipments(
    request: XrtSearchRequest<SearchShipmentsApiResponseData>
  ): Observable<XrtSearchResponse<SearchShipmentsApiResponseData>> {
    const url = `${this.haveblueUrl}search/shipments`;
    return this.httpClient.post<any>(url, request).pipe(map((results) => results.data));
  }

  getShipmentNotification(proNumber: string): Observable<ShipmentNotificationResponse> {
    return this.httpClient.get<ShipmentNotificationResponse>(`${this.haveblueUrl}shipments/notifications`, {
      params: { proNumber },
    });
  }

  getPickupRequests(request: SearchPickupRequestApiRequest): Observable<SearchPickupRequestApiResponse> {
    const url = `${this.haveblueUrl}search/pickup-requests`;
    return this.httpClient.post<any>(url, request).pipe(map((results) => results.data));
  }
}
