// TODO: Import MockbillingApiService when shortpay branch has been merged

import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RequestTokenResponse, UserAccountBilling } from '../billing/billing-api.models';
import { CreditCardTokenRequest, InvoiceList } from '../invoice/interfaces/invoice.interface';
import { BillingApiService } from './../billing/billing-api.service';

export const MockBillingApiService: Provider = {
  provide: BillingApiService,
  useValue: <BillingApiService>{
    accountGetAuthorizedAccounts: (): Observable<UserAccountBilling[]> => of([]),
    accountGetConvenienceFeePercent: (partnerCode: string): Observable<number> => of(2.8),
    getInvoiceList: (request: any): Observable<InvoiceList> => of(<InvoiceList>{}),
    requestToken: (request: CreditCardTokenRequest): Observable<RequestTokenResponse> => of(<RequestTokenResponse>{}),
    getCreditCardTransactionFee: (orderAmount: number): Observable<number> => of(1000),
    getCreditCardToken: (userId: string, partnerIdentifierCode: string, paymentType: string): Observable<any> => of({}),
  },
};
