import { Provider } from '@angular/core';
import { of } from 'rxjs';
import { EmailMessage, InfrastructureService } from '../infrastructure';

export const MockInfrastructureService: Provider = {
  provide: InfrastructureService,
  useValue: <InfrastructureService>{
    infrastructureURI: '',
    sendEmail: (emailMessage: EmailMessage) => of(true),
  },
};
