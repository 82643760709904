<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }} {{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <textarea
    #input
    matInput
    class="ltlcc-FormTextArea-disableResize"
    [formControl]="textAreaControl"
    [placeholder]="placeholder"
    [required]="required"
    [attr.data-cy]="dataCy"
    cdkTextareaAutosize
    [cdkAutosizeMinRows]="2"
    (blur)="handleBlur($event)"
  ></textarea>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-hint *ngIf="maxLength" align="end">{{ charCount }}/{{ maxLength }}</mat-hint>
</mat-form-field>
