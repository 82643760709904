<mat-form-field appearance="outline" [floatLabel]="floatLabel ? 'always' : 'auto'">
  <mat-label *ngIf="label"
    >{{ label }}{{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <input
    [formControl]="currencyControl"
    [required]="required"
    type="text"
    matInput
    [attr.data-cy]="dataCy"
    [placeholder]="placeholder"
    mask="separator.2"
    thousandSeparator=","
    autocomplete="off"
    [max]="max"
  />

  <span matPrefix *ngIf="inputPrefix">{{ inputPrefix }} &nbsp;</span>
  <span matSuffix *ngIf="inputSuffix">{{ inputSuffix }}</span>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
