import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONNECT_API_URI } from '../tokens';
import { InvoiceFilter, InvoiceList, InvoicesSummary } from './interfaces';
import {
  FilterInvoiceCriteria,
  FilterInvoiceList,
  InvoicePaymentStatusResponse,
  InvoiceSearchRequest,
  InvoiceSummaryRequest,
  MultiDocumentExportRequest,
} from './interfaces/invoice.interface';

@Injectable({
  providedIn: 'root',
})
export class InvoiceApiService {
  private readonly mockPartnerCode = '1-1-test';

  constructor(@Inject(CONNECT_API_URI) private apiUri: string, private http: HttpClient) {}

  get invoiceURI(): string {
    return `${this.apiUri}v2/InvoiceService/`;
  }

  getInvoices(request: InvoiceFilter, partnerCode?: string): Observable<InvoiceList> {
    const url = `${this.invoiceURI}${partnerCode ?? this.mockPartnerCode}/invoiceDetails`;
    return this.http.post<InvoiceList>(url, request);
  }

  exportInvoices(request: InvoiceFilter, partnerCode?: string): Observable<Blob> {
    const url = `${this.invoiceURI}${partnerCode ?? this.mockPartnerCode}/invoiceDetails/export`;
    return this.http.post(url, request, {
      responseType: 'blob',
    });
  }

  getInvoiceFilterListOptions(request: FilterInvoiceCriteria): Observable<FilterInvoiceList[]> {
    const url = `${this.apiUri}dynamiclabels/getFilterList`;
    return this.http.post<FilterInvoiceList[]>(url, request);
  }

  getInvoiceSummary(request: InvoiceSummaryRequest): Observable<InvoicesSummary> {
    const url = `${this.invoiceURI}summary`;
    return this.http.post<InvoicesSummary>(url, request);
  }

  downloadBulkInvoices(request: MultiDocumentExportRequest): Observable<Blob> {
    const url = `${this.invoiceURI}exportdocuments`;
    return this.http.post(url, request, {
      responseType: 'blob',
    });
  }

  getInvoicePaymentStatus(request: InvoiceSearchRequest): Observable<InvoicePaymentStatusResponse> {
    const url = `${this.invoiceURI}paymentstatus`;
    return this.http.post<InvoicePaymentStatusResponse>(url, request);
  }
}
