import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { DrawerComponent } from '../drawer.component';

@Component({
  selector: 'ltlcc-drawer-wrapper',
  templateUrl: './drawer-wrapper.component.html',
  styleUrls: ['./drawer-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerWrapperComponent {
  constructor(@Optional() public parentDrawerComponent?: DrawerComponent) {}

  close(): void {
    if (this.parentDrawerComponent) {
      this.parentDrawerComponent.close();
    }
  }
}
