import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FooterLink, ShellRoutes, UserAccountMenu } from './interfaces/shell.interface';

@Component({
  selector: 'ltlcc-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-Shell' },
})
export class ShellComponent {
  @Input() configUser: UserAccountMenu;
  @Input() routes: ShellRoutes[];
  @Input() appVersion: string;
  @Input() primaryLinks?: FooterLink[];
  @Input() secondaryLinks?: FooterLink[];
  @Input() showLanguagePicker: boolean;
}
