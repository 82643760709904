import { ChangeDetectionStrategy, Component, Directive, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: 'ltlcc-ag-grid-toolbar-filters',
  host: { class: 'ltlcc-AgGridToolbarFilters' },
})
export class AgGridToolbarFiltersDirective {}

@Component({
  selector: 'ltlcc-ag-grid-toolbar',
  templateUrl: './ag-grid-toolbar.component.html',
  styleUrls: ['./ag-grid-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AgGridToolbarComponent {}
