import { CountryCode } from '@ltlc/api';

export const Masks = {
  phone: {
    [CountryCode.UNITED_STATES]: '(000) 000-0000',
    [CountryCode.CANADA]: '(000) 000-0000',
    [CountryCode.MEXICO]: '000 000 000',
  },
  zip: {
    US: '00000',
    CA: 'S0S0S0',
    CA2: 'S0S 0S0',
    CAAux: 'S0S-0S0',
    default: 'SSSSS',
    default2: '',
  },
  date: 'd0/M0/0000',
};
