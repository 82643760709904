import { CreateSpotQuoteResponse, RatingResponse } from '../../rating/interfaces/rating.interface';

export const mockRateQuote: RatingResponse = {
  code: '200',
  transactionTimestamp: 1646048453584,
  data: {
    rateQuote: {
      confirmationNbr: '6038907122283',
      spotQuoteNbr: '0',
      quoteCreateDate: 1645730711966,
      quoteCreatedByCd: 'C',
      accessorialTariffName: 'CTS599',
      actlDiscountPct: 25.0,
      amcInd: false,
      totAccessorialAmt: {
        amt: '251.1',
        currencyCd: 'USD',
      },
      totCharge: [
        {
          amt: '987.27',
          currencyCd: 'USD',
        },
      ],
      totDiscountAmt: {
        amt: '245.39',
        currencyCd: 'USD',
      },
      totGarntAmt: {
        amt: '105.0',
        currencyCd: 'USD',
      },
      shipmentInfo: {
        accessorials: [
          {
            accessorialCd: 'HCD',
            accessorialDesc: 'HIGH COST DELIVERY REGION SRCHG',
            chargeAmt: {
              amt: '70.0',
              currencyCd: 'USD',
            },
          },
          {
            accessorialCd: 'FSC',
            accessorialDesc: 'FUEL SURCHARGE 24.60%',
            chargeAmt: {
              amt: '181.1',
              currencyCd: 'USD',
            },
          },
        ],
        commodity: [
          {
            grossWeight: {
              weight: '10.0',
              weightUom: 'LBS',
            },
            charge: {
              chargeAmt: {
                amt: '0.0',
                currencyCd: 'USD',
              },
              calcMethod: {},
            },
            nmfcClass: '100.0',
            hazmatInd: false,
          },
          {
            grossWeight: {
              weight: '10.0',
              weightUom: 'LBS',
            },
            charge: {
              chargeAmt: {
                amt: '981.56',
                currencyCd: 'USD',
              },
              calcMethod: {},
            },
            nmfcClass: ' 60.0',
            hazmatInd: false,
          },
        ],
        shipper: {
          acctInstId: '721687723',
          acctMadCd: 'ACBHHNMF000',
          address: {
            name: 'AC CARGO INTERNATIONAL',
            addressLine1: '1970 NW 129TH AVE STE 106',
            addressLine2: '',
            cityName: 'MIAMI',
            stateCd: 'FL',
            countryCd: 'US',
            postalCd: '33182',
          },
        },
        consignee: {
          address: {
            cityName: 'BOSTON',
            stateCd: 'MA',
            countryCd: 'US',
            postalCd: '02111',
          },
        },
        paymentTermCd: 'P',
        garntInd: false,
        g12Ind: false,
        rrsInd: false,
        linealFt: 0,
        shipmentDate: 1645689600000,
        comment:
          'Reminder: Single Shipments may be subject to a special service charge if the weight is less than 500LBS|',
        palletCnt: 0,
        totCommodityCharge: {
          amt: '981.56',
          currencyCd: 'USD',
        },
        totCommodityWeight: {
          weight: '20.0',
          weightUom: 'LBS',
        },
      },
      vspApplied: false,
      guarantdEligible: true,
      sqEligible: false,
      g12Eligible: true,
      rrsEligible: false,
      specialServiceCharges: [
        {
          serviceCharge: [
            {
              accessorialCd: 'GUR',
              accessorialDesc: 'GUR XPO LOGISTICS GUARANTEED!',
              chargeAmt: {
                amt: '105.0',
                currencyCd: 'USD',
              },
            },
          ],
          totChargeAmt: {
            amt: '1092.27',
            currencyCd: 'USD',
          },
        },
        {
          serviceCharge: [
            {
              accessorialCd: 'G12',
              accessorialDesc: 'G12 GUARANTEED! BY NOON',
              chargeAmt: {
                amt: '215.0',
                currencyCd: 'USD',
              },
            },
          ],
          totChargeAmt: {
            amt: '1202.27',
            currencyCd: 'USD',
          },
        },
      ],
    },
    transitTime: {
      destPostalCd: '02111',
      destSicCd: 'XEE',
      estdDlvrDate: 1646121600000,
      garntInd: true,
      origPostalCd: '33182',
      origSicCd: 'NMF',
      requestedPkupDate: 1645718400000,
      transitDays: 3,
      isPkupDateHoliday: false,
    },
  },
};

export const mockRateQuoteVolume: RatingResponse = {
  code: '201',
  transactionTimestamp: 1646851463869,
  data: {
    rateQuote: {
      confirmationNbr: '6050114714976',
      spotQuoteNbr: '0',
      quoteCreateDate: 1646851471353,
      quoteCreatedByCd: 'I',
      totAccessorialAmt: {
        amt: 95.88,
        currencyCd: 'USD',
      },
      totCharge: [
        {
          amt: 6928.96,
          currencyCd: 'USD',
        },
      ],
      totDiscountAmt: {
        amt: 18891.2,
        currencyCd: 'USD',
      },
      shipmentInfo: {
        accessorials: [
          {
            accessorialCd: 'TLF',
            accessorialDesc: 'VOLUME SHIPMENT FUEL SURCHARGE',
            chargeAmt: {
              amt: 95.88,
              currencyCd: 'USD',
            },
          },
        ],
        commodity: [
          {
            grossWeight: {
              weight: 10000.0,
              weightUom: 'LBS',
            },
            nmfcClass: '300.0',
            hazmatInd: false,
          },
        ],
        shipper: {
          address: {
            cityName: 'GENERAL ELECTRIC',
            stateCd: 'NY',
            countryCd: 'US',
            postalCd: '12345',
          },
        },
        consignee: {
          address: {
            cityName: 'BROOKLYN',
            stateCd: 'NY',
            countryCd: 'US',
            postalCd: '11221',
          },
        },
        paymentTermCd: 'P',
        garntInd: false,
        g12Ind: false,
        rrsInd: false,
        linealFt: 0,
        shipmentDate: 1646812800000,
        comment: '',
        palletCnt: 0,
        totCommodityCharge: {
          amt: 6833.08,
          currencyCd: 'USD',
        },
        totCommodityWeight: {
          weight: 10000.0,
          weightUom: 'LBS',
        },
      },
      vspApplied: true,
      guarantdEligible: false,
      sqEligible: false,
      g12Eligible: false,
      rrsEligible: false,
      specialServiceCharges: [],
    },
    msgs: [
      {
        errorCd: 'API-Err',
        message: 'Error calling Transit Time Calculator.',
      },
    ],
  },
};

export const mockRateQuoteGUR = {
  code: '201',
  transactionTimestamp: 1646857855397,
  data: {
    rateQuote: {
      confirmationNbr: '6050178562416',
      spotQuoteNbr: '0',
      quoteCreateDate: 1646857856144,
      quoteCreatedByCd: 'I',
      accessorialTariffName: 'CTS599',
      actlDiscountPct: 40.0,
      amcInd: false,
      totAccessorialAmt: {
        amt: 186.89,
        currencyCd: 'USD',
      },
      totCharge: [
        {
          amt: 496.75,
          currencyCd: 'USD',
        },
      ],
      totDiscountAmt: {
        amt: 206.58,
        currencyCd: 'USD',
      },
      shipmentInfo: {
        accessorials: [
          {
            accessorialCd: 'GUR',
            accessorialDesc: 'XPO LOGISTICS GUARANTEED!',
            chargeAmt: {
              amt: 115.0,
              currencyCd: 'USD',
            },
          },
          {
            accessorialCd: 'FSC',
            accessorialDesc: 'FUEL SURCHARGE 23.20%',
            chargeAmt: {
              amt: 71.89,
              currencyCd: 'USD',
            },
          },
        ],
        commodity: [
          {
            grossWeight: {
              weight: 100.0,
              weightUom: 'LBS',
            },
            charge: {
              chargeAmt: {
                amt: 516.44,
                currencyCd: 'USD',
              },
              calcMethod: {},
            },
            nmfcClass: ' 60.0',
            hazmatInd: false,
          },
        ],
        shipper: {
          address: {
            cityName: 'GENERAL ELECTRIC',
            stateCd: 'NY',
            countryCd: 'US',
            postalCd: '12345',
          },
        },
        consignee: {
          address: {
            cityName: 'S U N Y',
            stateCd: 'NY',
            countryCd: 'US',
            postalCd: '12222',
          },
        },
        paymentTermCd: 'P',
        garntInd: true,
        g12Ind: false,
        rrsInd: false,
        linealFt: 0,
        shipmentDate: 1646812800000,
        comment:
          'Reminder: Single Shipments may be subject to a special service charge if the weight is less than 500LBS|',
        palletCnt: 0,
        totCommodityCharge: {
          amt: 516.44,
          currencyCd: 'USD',
        },
        totCommodityWeight: {
          weight: 100.0,
          weightUom: 'LBS',
        },
      },
      vspApplied: false,
      guarantdEligible: false,
      sqEligible: false,
      g12Eligible: false,
      rrsEligible: false,
      specialServiceCharges: [],
    },
    msgs: [],
    transitTime: {
      destPostalCd: '12222',
      destSicCd: 'XAB',
      estdDlvrDate: 1646899200000,
      garntInd: true,
      origPostalCd: '12345',
      origSicCd: 'XAB',
      requestedPkupDate: 1646841600000,
      transitDays: 1,
      isPkupDateHoliday: false,
    },
  },
};

// For mockRateQuoteGUR
export const RatingPDFBodyGUR = {
  rateQuotePDFHeader: {
    quoteCreateDate: 1646857856144,
    quoteExpiryDate: 1646812800000,
    shipmentDate: 1646812800000,
    paymentTerms: 'Prepaid',
    origin: '12345 NY (US)',
    destination: '12222 NY (US)',
    linealFt: 0,
    palletCnt: 0,
    isMXQuote: false,
    isSpotQuote: false,
    isVSPApplied: false,
    isGuarantd: true,
    isPalletPricingApplied: false,
    isLFPricingApplied: false,
    isDirectPoint: true,
    isPkupDateHoliday: false,
  },
  rateQuotePDFDetail: {
    quoteType: 'GUARANTEED',
    confirmationNbr: '-',
    estDeliveryDate: 1646899200000,
    transitDays: 1,
    accessorials: [
      {
        accessorialCd: 'GUR',
        accessorialDesc: 'Guaranteed (G!)',
        chargeAmt: {
          amt: 115,
          currencyCd: 'USD',
        },
      },
      {
        accessorialCd: 'FSC',
        accessorialDesc: 'Fuel Surcharge 23.20%',
        chargeAmt: {
          amt: 71.89,
          currencyCd: 'USD',
        },
      },
    ],
    commodity: [
      {
        grossWeight: {
          weight: 100,
          weightUom: 'lbs',
        },
        charge: {
          chargeAmt: {
            amt: 516.44,
            currencyCd: 'USD',
          },
          calcMethod: {
            perUnitRate: 0,
          },
        },
        nmfcClass: '60',
      },
    ],
    actlDiscountPct: 40,
    totalDiscountAmt: {
      amt: 206.58,
      currencyCd: 'USD',
    },
    totalCommodityCharges: {
      amt: 516.44,
      currencyCd: 'USD',
    },
    totalCommodityWeight: {
      weight: 100,
      weightUom: 'lbs',
    },
    totalAccessorialCharges: {
      amt: 186.89,
      currencyCd: 'USD',
    },
    totalNetCharges: {
      amt: 496.75,
      currencyCd: 'USD',
    },
    comment: [
      'Reminder: Single Shipments may be subject to a special service charge if the weight is less than 500LBS',
      '',
    ],
  },
};

export const mockSpotRating = {
  spotQuote: <CreateSpotQuoteResponse>{
    code: '200',
    transactionTimestamp: 1664898502971,
    data: {
      confirmationNbr: '6229953120148',
      requestDateTime: 1664780400000,
      spotQuoteNbr: 'SQ2003249',
      spotQuoteEffDate: 1664780400000,
      spotQuoteExpDate: 1664780400000,
      rtgOrigZipCd: '33182 ',
      rtgDestZipCd: '33182 ',
      totWeightGross: {
        weight: 3000,
        weightUom: 'LBS',
      },
      totChargeAmt: {
        amt: 383.0,
        currencyCd: 'USD',
      },
      lnhChargeAmt: {
        amt: 295.0,
        currencyCd: 'USD',
      },
      totAccessorialAmt: {
        amt: 88.0,
        currencyCd: 'USD',
      },
      commodityLine: [
        {
          pieceCnt: 2,
          packageCd: 'PLT ',
          stackableInd: 'N',
          length: 12.0,
          width: 11.0,
          height: 15.0,
          dimensionUOM: 'INCH',
          grossWeight: 3000.0,
          grossWeightUOM: 'LBS',
          nmfcClass: ' 50.0',
        },
      ],
      accessorials: [
        {
          accessorialCd: 'EVC',
          accessorialDesc: 'EXCESSIVE VALUE',
          chargeAmt: {
            amt: 88,
            currencyCd: 'USD',
          },
        },
      ],
    },
  },
  quoteDetail: <RatingResponse>{
    code: '200',
    transactionTimestamp: 1664831804335,
    data: {
      rateQuote: {
        confirmationNbr: '6229953120148',
        spotQuoteNbr: 'SQ2003249',
        quoteCreateDate: 1664831726272,
        quoteCreatedByCd: 'C',
        accessorialTariffName: 'CTS599',
        actlDiscountPct: 25.0,
        amcInd: false,
        totAccessorialAmt: {
          amt: 418.12,
          currencyCd: 'USD',
        },
        totCharge: [
          {
            amt: 1597.12,
            currencyCd: 'USD',
          },
        ],
        totDiscountAmt: {
          amt: 393.0,
          currencyCd: 'USD',
        },
        totGarntAmt: {
          amt: 121.0,
          currencyCd: 'USD',
        },
        shipmentInfo: {
          accessorials: [
            {
              accessorialCd: 'EVC',
              accessorialDesc: 'EXCESSIVE VALUE',
              chargeAmt: {
                amt: 88.0,
                currencyCd: 'USD',
              },
            },
            {
              accessorialCd: 'FSC',
              accessorialDesc: 'FUEL SURCHARGE  28.00%',
              chargeAmt: {
                amt: 330.12,
                currencyCd: 'USD',
              },
            },
          ],
          commodity: [
            {
              dimensions: {
                length: 12.0,
                width: 11.0,
                height: 15.0,
                dimensionsUom: 'INCH',
              },
              pieceCnt: 2,
              grossWeight: {
                weight: 3000.0,
                weightUom: 'LBS',
              },
              charge: {
                chargeAmt: {
                  amt: 1572.0,
                  currencyCd: 'USD',
                },
                calcMethod: {
                  perUnitRate: 52.4,
                },
              },
              nmfcClass: ' 50.0',
              hazmatInd: false,
            },
          ],
          shipper: {
            acctInstId: '721687723',
            acctMadCd: 'ACBHHNMF000',
            address: {
              name: 'AC CARGO INTERNATIONAL',
              addressLine1: '1970 NW 129TH AVE STE 106',
              addressLine2: '',
              cityName: 'MIAMI',
              stateCd: 'FL',
              countryCd: 'US',
              postalCd: '33182',
            },
          },
          consignee: {
            address: {
              cityName: 'DORAL',
              stateCd: 'FL',
              countryCd: 'US',
              postalCd: '33182',
            },
          },
          paymentTermCd: 'P',
          garntInd: false,
          g12Ind: false,
          rrsInd: false,
          linealFt: 0,
          shipmentDate: 1664780400000,
          comment: '',
          palletCnt: 2,
          totCommodityCharge: {
            amt: 1572.0,
            currencyCd: 'USD',
          },
          totCommodityWeight: {
            weight: 3000.0,
            weightUom: 'LBS',
          },
        },
        vspApplied: false,
        guarantdEligible: true,
        sqEligible: false,
        g12Eligible: true,
        rrsEligible: false,
        specialServiceCharges: [
          {
            serviceCharge: [
              {
                accessorialCd: 'GUR',
                accessorialDesc: 'GUR XPO LOGISTICS GUARANTEED!',
                chargeAmt: {
                  amt: 121.0,
                  currencyCd: 'USD',
                },
              },
            ],
            totChargeAmt: {
              amt: 1718.12,
              currencyCd: 'USD',
            },
          },
          {
            serviceCharge: [
              {
                accessorialCd: 'G12',
                accessorialDesc: 'G12 GUARANTEED! BY NOON',
                chargeAmt: {
                  amt: 240.0,
                  currencyCd: 'USD',
                },
              },
            ],
            totChargeAmt: {
              amt: 1837.12,
              currencyCd: 'USD',
            },
          },
        ],
      },
      transitTime: {
        destPostalCd: '33182',
        destSicCd: 'NMF',
        estdDlvrDate: 1664866800000,
        garntInd: true,
        origPostalCd: '33182',
        origSicCd: '33182',
        requestedPkupDate: 1664805600000,
        transitDays: 1,
        isPkupDateHoliday: false,
      },
    },
  },
};
