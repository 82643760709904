<h1 mat-dialog-title>{{ 'globalErrorHandlerDialog.title' | translate }}</h1>
<div mat-dialog-content>
  <span>{{ 'globalErrorHandlerDialog.body' | translate }} </span>
</div>

<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="reloadPage()">
    {{ 'globalErrorHandlerDialog.load' | translate }}
  </button>
  <button mat-button (click)="close()">
    {{ 'defaults.close' | translate }}
  </button>
</mat-dialog-actions>
