<ng-container *ngFor="let link of primaryLinks">
  <a class="ltlcc-FooterLinks-link" rel="dns-prefetch" [href]="link.url" *ngIf="link && link.isExternalUrl">{{
    link.name
  }}</a>
  <a class="ltlcc-FooterLinks-link" [routerLink]="link.url" *ngIf="link && !link.isExternalUrl">{{ link.name }}</a>
</ng-container>

<ng-container *ngIf="!!secondaryLinks?.length">
  <mat-divider [vertical]="true" class="ltlcc-FooterLinks-divider ltlcc-FooterLinks-divider--vertical"></mat-divider>
  <a class="ltlcc-FooterLinks-link" [href]="link.url" *ngFor="let link of secondaryLinks">{{ link.name }}</a>
</ng-container>

<ng-container *ngIf="showLanguagePicker">
  <mat-divider [vertical]="true" class="ltlcc-FooterLinks-divider ltlcc-FooterLinks-divider--vertical"></mat-divider>

  <ltlcc-language-picker-footer></ltlcc-language-picker-footer>
</ng-container>
