import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InquirerInfo } from '../api-shared/interfaces/api-shared.interface';
import { API_URI } from '../tokens';
import { CreatePurBody, CreatePurResponse } from './interfaces/create-pickup-request.interface';
import {
  CustomerProfile,
  CustomerProfileRequester,
  GetCustomerPickupRequestDetailResponse,
  PickupRequestInfo,
  ShipperCustomerProfiles,
} from './interfaces/pickup-request.interface';

@Injectable({
  providedIn: 'root',
})
export class PickupRequestApiService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get pickupRequestURI(): string {
    return `${this.apiUri}/pickuprequest/1.0/`;
  }

  getCustomerPickupRequestDetail(pickupReferenceNumber: string): Observable<PickupRequestInfo> {
    return this.httpClient
      .get<GetCustomerPickupRequestDetailResponse>(
        `${this.pickupRequestURI}cust-pickup-requests/${pickupReferenceNumber}`
      )
      .pipe(map((resp) => resp.data.pickupRequestInfo));
  }

  createPickupRequest(pickupRequest: CreatePurBody): Observable<string> {
    const url = `${this.pickupRequestURI}cust-pickup-requests`;
    return this.httpClient.post(url, pickupRequest).pipe(
      map((pickupRequestApiResponse: CreatePurResponse) => {
        return pickupRequestApiResponse.data.confirmationNbr;
      })
    );
  }

  updatePickupRequest(pickupRequest: CreatePurBody, confirmationNumber: string): Observable<string> {
    const url = `${this.pickupRequestURI}cust-pickup-requests`;
    return this.httpClient.put(`${url}/${confirmationNumber}`, pickupRequest).pipe(
      map((pickupRequestApiResponse: Partial<CreatePurResponse>) => {
        return pickupRequestApiResponse.code;
      })
    );
  }

  cancelPickupRequest(confirmationNumber: string, cancelReason: string): Observable<string> {
    const url = `${this.pickupRequestURI}pickuprequests`;
    return this.httpClient.put(`${url}/${confirmationNumber}/cancel`, { cancelReason }).pipe(
      map((cancelPickupRequestsApiResponse: Partial<CreatePurResponse>) => {
        return cancelPickupRequestsApiResponse.code;
      })
    );
  }

  deletePickupItem(confirmationNumber: string, itemIndex: number): Observable<string> {
    const url = `${this.pickupRequestURI}pickuprequests`;
    return this.httpClient.delete(`${url}/${confirmationNumber}/lineitems/${itemIndex}`).pipe(
      map((deletePickupItemApiResponse: Partial<CreatePurResponse>) => {
        return deletePickupItemApiResponse.code;
      })
    );
  }

  getShipperCustomerProfiles(profileInstId: string): Observable<InquirerInfo[]> {
    const url = `${this.pickupRequestURI}custprofiles/${profileInstId}/shippers`;
    return this.httpClient.get<ShipperCustomerProfiles>(url).pipe(map((resp) => resp.data.shippers));
  }

  getCustomerProfile(profileInstId: string): Observable<CustomerProfileRequester> {
    const url = `${this.pickupRequestURI}custprofiles/${profileInstId}`;
    return this.httpClient.get<CustomerProfile>(url).pipe(map((resp) => resp.data.requester));
  }
}
