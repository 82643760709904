import { ShipmentTrackingStatus } from '../enums';

export const ShipmentTrackingStatusOrder = {
  [ShipmentTrackingStatus.NOT_PICKED_UP]: 0,
  [ShipmentTrackingStatus.PICKED_UP]: 1,
  [ShipmentTrackingStatus.IN_TRANSIT]: 2,
  [ShipmentTrackingStatus.OUT_FOR_DELIVERY]: 3,
  [ShipmentTrackingStatus.DELIVERED]: 4,
  // TODO: confirm how we show canceled in the shipment progress bar
  // [ShipmentTrackingStatus.CANCELLED]: 5,
};
