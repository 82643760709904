import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatTreeModule } from '@angular/material/tree';
import { ConfirmationDialogModule, FormGroupTemplateModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { IsOptionInListPipeModule } from '../../../pipes/option-in-list/option-in-list-pipe.module';
import { AccountListLightPipeModule } from '../pipes/account-list-light-pipe.module';
import { AccountActionsComponent } from './account-actions';
import { AccountListLightTreeLeftPaddingDirective } from './account-list-light-directives/account-list-light-tree-left-padding.directive';
import { LocationSelectablePipe } from './account-list-light-pipes/location-selectable.pipe';
import { AccountListLightTreeComponent } from './account-list-light-tree.component';

@NgModule({
  declarations: [
    AccountListLightTreeComponent,
    AccountListLightTreeLeftPaddingDirective,
    LocationSelectablePipe,
    AccountActionsComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    MatTreeModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    MatCheckboxModule,
    TranslateModule,
    FormGroupTemplateModule,
    MatLegacyDialogModule,
    MatAutocompleteModule,
    AccountListLightPipeModule,
    IsOptionInListPipeModule,
    ConfirmationDialogModule,
  ],
  exports: [AccountListLightTreeComponent],
})
export class AccountListLightTreeModule {}
