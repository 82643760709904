import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FooterLink } from '../../interfaces/shell.interface';
@Component({
  selector: 'ltlcc-shell-footer',
  templateUrl: './shell-footer.component.html',
  styleUrls: ['./shell-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellFooterComponent {
  @Input() appVersion: string;
  @Input() primaryLinks: FooterLink[] = [];
  @Input() secondaryLinks: FooterLink[] = [];
  @Input() showLanguagePicker: boolean;
}
