import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorMessageModule } from '../form-controls/base/form-error-message/form-error-message.module';
import { FileDropComponent } from './file-drop.component';
import { FileDropDirective } from './file-drop.directive';

@NgModule({
  declarations: [FileDropComponent, FileDropDirective],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    FormErrorMessageModule,
    TranslateModule,
  ],
  exports: [FileDropComponent, FileDropDirective],
})
export class FileDropModule {}
