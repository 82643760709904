import { AccountListLightTreeNode } from './../interfaces/account-list-light.interface';

// DO NOT DELETE! USED FOR INTEGRATION AND TESTING OF ACCOUNT LIST LIGHT TREE
export const TREE_DATA: AccountListLightTreeNode[] = [
  {
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium culpa doloremque ipsam voluptatem explicabo',
    address: 'street 4',
    listId: '1',
    type: 'Pickup',
    matIconName: 'person',
    searchFields: [
      {
        label: 'MadCode',
        value: 123,
      },
      {
        label: 'ID',
        value: 357,
      },
    ],
  },
  {
    name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium culpa doloremque ipsam voluptatem explicabo',
    address: 'street 4',
    listId: '1',
    type: 'Pickup',
    searchFields: [
      {
        label: 'MadCode',
        value: 123,
      },
      {
        label: 'ID',
        value: 357,
      },
    ],
    children: [
      { name: 'Apple', address: 'street 4', listId: '2', type: 'Pickup' },
      { name: 'Banana', address: 'street 4', listId: '3', type: 'Pickup' },
      { name: 'Fruit loops', address: 'street 4', listId: '4', type: 'Pickup' },
    ],
  },
  {
    name: 'Vegetables',
    address: 'street 4',
    listId: '5',
    type: 'Pickup',
    children: [
      {
        name: 'Green',
        address: 'street 4',
        listId: '6',
        children: [
          { name: 'Broccoli', address: 'street 4', listId: '7', type: 'Pickup', matIconName: 'person' },
          {
            name: 'Brussels sprouts',
            address: 'street 4',
            listId: '8',
            type: 'Pickup',
            children: [
              {
                name: 'Foo',
                address: 'Boo street 8',
                listId: '9',
                type: 'Pickup',
              },
              { name: 'Lettuce One', address: 'street 11', listId: '10', type: 'Pickup' },
            ],
          },
          { name: 'Lettuce', address: 'street 11', listId: '11', type: 'Pickup' },
        ],
      },
      {
        name: 'Orange',
        address: 'street 4',
        listId: '12',
        type: 'Pickup',
        children: [
          { name: 'Pumpkins', address: 'street 4', listId: '13', type: 'Pickup' },
          { name: 'Carrots', address: 'street 4', listId: '14', type: 'Pickup' },
        ],
      },
    ],
  },
];

export const TREE_DATA_ACCOUNTS: AccountListLightTreeNode[] = [
  {
    listId: 'newAdress',
    matIconName: 'add_location',
    name: 'New Location',
    type: 'Address',
  },
  {
    listId: 'sameAsRequester',
    matIconName: 'copy_all',
    name: 'Same as Requester',
    type: 'Address',
  },
  {
    address: '400 RIVERSIDE DR, Sharon, PA, 16146',
    listId: 'associated_1420391',
    matIconName: 'star',
    name: 'Victor Printing ',
    type: 'Pickup/Delivery',
  },
  {
    address: '1 VICTOR WAY, Sharon, PA, 16146',
    listId: 'associated_36077',
    matIconName: 'star',
    name: 'Victor Printing ',
    type: 'Pickup/Delivery',
  },
  {
    address: '307 27TH ST, Orlando, FL, 32806',
    listId: 'associated_622966215',
    matIconName: 'star',
    name: 'Gilmore Products ',
    type: 'Pickup/Delivery',
  },
  {
    address: '1970 NW 129TH AVE STE 106, Miami, FL, 33182',
    listId: 'associated_721687723',
    matIconName: 'star',
    name: 'Ac Cargo International ',
    type: 'Pickup/Delivery',
  },
  {
    address: '1405 US HWY 41S, Calhoun, GA, 30701',
    listId: 'flat_336424272',
    matIconName: 'star',
    name: 'Lg Hausys',
    type: 'Pickup/Delivery',
  },
  {
    address: '959 5TH ST, Perrysburg, OH, 43551',
    listId: 'flat_501',
    matIconName: 'star',
    name: 'Ace Hardware Distr Ctrs',
    type: 'Pickup/Delivery',
  },
  {
    address: '741 S MAIN ST, Greensburg, PA, 15601',
    listId: 'flat_271198838',
    matIconName: 'star',
    name: 'Carboline Co',
    type: 'Pickup/Delivery',
  },
  {
    address: '3632 CENTURY PL, Charlotte, NC, 28206',
    listId: 'associated_512943375',
    name: '209 Fast Mile Logistics ',
    type: 'Pickup/Delivery',
  },
];
