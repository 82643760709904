import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageAccountsService {
  private removedAccountsMap: { [idAccount: string]: boolean } = {};

  storeRemovedAccounts(idAccount: number): void {
    this.removedAccountsMap[idAccount.toString()] = true;
  }

  get storedRemovedAccounts(): { [idAccount: string]: boolean } {
    return this.removedAccountsMap;
  }
}
