import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ShellRoutes } from '../../interfaces/shell.interface';
@Component({
  selector: 'ltlcc-shell-routes',
  templateUrl: './shell-routes.component.html',
  styleUrls: ['./shell-routes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-ShellRoutes' },
})
export class ShellRoutesComponent {
  @Input() routes: ShellRoutes[];

  constructor(private router: Router) {}

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, false);
  }
}
