import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as deepmerge from 'deepmerge';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonBaseResponse, CommonBaseResponseData, YesNo } from '../api-shared';
import { ExternalUserApiResponse, ExternalUserData } from '../membership';
import { API_URI } from '../tokens';
import { BillOfLadingV2 } from './interfaces/bill-of-lading-get-instance-v2.interface';
import { BillOfLadingInstanceData } from './interfaces/bill-of-lading-instance.interface';
import {
  BillOfLading,
  BillOfLadingTemplate,
  BolInfoResponse,
  BOLPDFData,
  BOLPDFDataResponse,
  BOLPDFResponse,
  BOLPDFV2DataResponse,
  BOLRequestBody,
  BOLRequestResponse,
  BolTemplate,
  BolTemplateItemApiResponse,
  BolTemplateItemApiResponseV2,
  CancelBolApiRequest,
  DeleteBolTemplateResponse,
  GetBillsOfLadingApiResponse,
  GetBolTemplateApiResponse,
  GetBolTemplateApiResponseV2,
  GetPapsLabelsPdfRequest,
  GetPapsLabelsPdfResponse,
  GetPapsLabelsPdfResponseData,
  GetShippingLabelsEmailResponse,
  GetShippingLabelsPdfRequest,
  GetShippingLabelsPdfResponse,
  GetShippingLabelsPdfResponseData,
  SendBolByEmail,
} from './interfaces/bill-of-lading.interface';

@Injectable({
  providedIn: 'root',
})
export class BillOfLadingApiService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get billOfLadingURI(): string {
    return `${this.apiUri}/billoflading/1.0/`;
  }

  get billOfLadingV2URI(): string {
    return `${this.apiUri}/billofladingv2/2.0/`;
  }

  getBols(): Observable<BillOfLading[]> {
    return this.httpClient
      .get<GetBillsOfLadingApiResponse>(`${this.billOfLadingV2URI}bills-of-lading`)
      .pipe(map((resp) => resp.data.billsOfLading));
  }

  getExternalUserData(): Observable<ExternalUserData> {
    return this.httpClient.get(`${this.billOfLadingV2URI}appusers/logged-in-user`).pipe(
      map((externalUserData: ExternalUserApiResponse) => {
        return externalUserData.data;
      })
    );
  }

  // From BD: Deprecated in favor of getBOLV2
  getBOL(bolInstId: string): Observable<BillOfLadingInstanceData> {
    const url = new URL(`${this.billOfLadingURI}billsoflading`);
    url.searchParams.append('bolInstId', bolInstId);

    return this.httpClient
      .get<CommonBaseResponseData<BillOfLadingInstanceData>>(url.toString())
      .pipe(map((resp) => resp.data));
  }

  getBOLV2(bolInstId: string): Observable<BillOfLadingV2> {
    const url = `${this.billOfLadingV2URI}bills-of-lading/${bolInstId}`;
    return this.httpClient.get<CommonBaseResponseData<BillOfLadingV2>>(url).pipe(map((resp) => resp.data));
  }

  createBOLRequest(BOLRequestBody: BOLRequestBody): Observable<BolInfoResponse> {
    const url = `${this.billOfLadingURI}billsoflading`;
    const params = new HttpParams().set('testMode', YesNo.N);

    return this.httpClient
      .post<BOLRequestResponse>(url, BOLRequestBody, {
        params,
      })
      .pipe(map((response: BOLRequestResponse) => response.data.bolInfo));
  }

  updateBOLRequest(BOLRequestBody: BOLRequestBody): Observable<BolInfoResponse> {
    const copyBOLRequest = deepmerge({}, BOLRequestBody);
    const url = `${this.billOfLadingURI}billsoflading/${BOLRequestBody.bol.bolInstId}`;
    const params = new HttpParams().set('testMode', YesNo.N);
    delete copyBOLRequest.bol.bolInstId;

    return this.httpClient
      .put<BOLRequestResponse>(url, copyBOLRequest, { params })
      .pipe(map((response: BOLRequestResponse) => response.data.bolInfo));
  }

  getPltShippingLabelsPDF(
    request: GetShippingLabelsPdfRequest,
    printOneLabelPerPage: boolean
  ): Observable<GetShippingLabelsPdfResponseData> {
    return this.httpClient
      .post<GetShippingLabelsPdfResponse>(
        `${this.billOfLadingURI}billsoflading/${request.bolInstId}/plt/shippinglabels/pdf?printOneLabelPerPage=${printOneLabelPerPage}`,
        request
      )
      .pipe(map((pdfResponse) => pdfResponse.data));
  }

  getPltShippingLabelsPDFV2(request: GetShippingLabelsPdfRequest): Observable<GetShippingLabelsPdfResponseData> {
    const bolId = request.bolInstId;
    delete request.bolInstId;
    delete request.nbrOfLabels;
    delete request.handlingUnits;
    return this.httpClient
      .post<GetShippingLabelsPdfResponse>(
        `${this.billOfLadingV2URI}bills-of-lading/${bolId}/shipping-labels/pdf`,
        request
      )
      .pipe(map((pdfResponse) => pdfResponse.data));
  }

  getLegacyShippingLabelsPDF(
    request: Omit<GetShippingLabelsPdfRequest, 'handlingUnits'>,
    printOneLabelPerPage: boolean
  ): Observable<GetShippingLabelsPdfResponseData> {
    return this.httpClient
      .post<GetShippingLabelsPdfResponse>(
        `${this.billOfLadingURI}billsoflading/${request.bolInstId}/shippinglabels/pdf?printOneLabelPerPage=${printOneLabelPerPage}`,
        request
      )
      .pipe(map((pdfResponse) => pdfResponse.data));
  }

  sendBolByEmail(payload: SendBolByEmail): Observable<string> {
    return this.httpClient
      .post<CommonBaseResponseData<{ bolInstId: string }>>(
        `${this.billOfLadingURI}billsoflading/${payload.bolInstId}/email`,
        payload
      )
      .pipe(map((emailResponse) => emailResponse.data.bolInstId));
  }

  getPltShippingLabelsEmail(
    bolInstId: number,
    request: GetShippingLabelsPdfRequest,
    printOneLabelPerPage: boolean
  ): Observable<string> {
    return this.httpClient
      .post<GetShippingLabelsEmailResponse>(
        `${this.billOfLadingURI}billsoflading/${bolInstId}/plt/shippinglabels/email?printOneLabelPerPage=${printOneLabelPerPage}`,
        request
      )
      .pipe(map((emailResponse) => emailResponse.data.bolInstId));
  }

  getLegacyShippingLabelsEmail(
    bolInstId: number,
    request: Omit<GetShippingLabelsPdfRequest, 'handlingUnits'>,
    printOneLabelPerPage: boolean
  ): Observable<string> {
    return this.httpClient
      .post<GetShippingLabelsEmailResponse>(
        `${this.billOfLadingURI}billsoflading/${bolInstId}/shippinglabels/email?printOneLabelPerPage=${printOneLabelPerPage}`,
        request
      )
      .pipe(map((emailResponse) => emailResponse.data.bolInstId));
  }

  getPapsLabelsPDF(bolInstId: number, request: GetPapsLabelsPdfRequest): Observable<GetPapsLabelsPdfResponseData> {
    return this.httpClient
      .post<GetPapsLabelsPdfResponse>(`${this.billOfLadingURI}billsoflading/${bolInstId}/papslabels/pdf`, request)
      .pipe(map((pdfResponse) => pdfResponse.data));
  }

  getBolTemplates(): Observable<BolTemplate[]> {
    return this.httpClient
      .get<GetBolTemplateApiResponse>(`${this.billOfLadingURI}billsofladingtemplate/requester`)
      .pipe(map((results) => results.data.bolTemplate));
  }

  getBolTemplatesV2(): Observable<BolTemplate[]> {
    return this.httpClient
      .get<GetBolTemplateApiResponseV2>(`${this.billOfLadingV2URI}bills-of-lading/templates`)
      .pipe(map((results) => results.data.billOfLadingTemplates));
  }

  deleteBolTemplate(templateId: string): Observable<DeleteBolTemplateResponse> {
    return this.httpClient.delete<DeleteBolTemplateResponse>(
      `${this.billOfLadingURI}billsofladingtemplate/${templateId}`
    );
  }

  cancelBol(bolId: number, request: CancelBolApiRequest): Observable<CommonBaseResponse> {
    return this.httpClient.put<CommonBaseResponse>(`${this.billOfLadingURI}billsoflading/${bolId}/cancel`, request);
  }

  getBolTemplateItem(bolInstanceId: string): Observable<BillOfLadingTemplate> {
    const url = `${this.billOfLadingURI}billsofladingtemplate/${bolInstanceId}/requester`;

    return this.httpClient.get<BolTemplateItemApiResponse>(url).pipe(
      map((response: BolTemplateItemApiResponse) => {
        return response.data.bolTemplate;
      })
    );
  }

  getBolTemplateItemV2(bolInstanceId: string): Observable<BillOfLadingV2> {
    const url = `${this.billOfLadingV2URI}bills-of-lading/${bolInstanceId}/templates`;

    return this.httpClient.get<BolTemplateItemApiResponseV2>(url).pipe(
      map((response: BolTemplateItemApiResponseV2) => {
        return response.data;
      })
    );
  }

  getBOLPdf(bolId: string, requesterToSign = false): Observable<BOLPDFData> {
    const params = new HttpParams().set('requesterToSign', requesterToSign ? YesNo.Y : YesNo.N);
    const url = `${this.billOfLadingURI}billsoflading/${bolId}/pdf`;

    return this.httpClient
      .get<BOLPDFResponse<BOLPDFDataResponse>>(url, {
        params,
      })
      .pipe(map((response) => response.data.bolpdf));
  }

  getBOLPdfV2(bolInstanceId: string, requesterToSign = false): Observable<BOLPDFData> {
    const params = new HttpParams().appendAll({
      requesterToSign: requesterToSign ? YesNo.Y : YesNo.N,
      bolInstanceId,
    });
    const url = `${this.billOfLadingV2URI}bill-of-lading-pdf`;

    return this.httpClient
      .get<BOLPDFResponse<BOLPDFV2DataResponse>>(url, {
        params,
      })
      .pipe(map((response) => response.data.bolPDF));
  }

  createBOLTemplate(
    bolTemplate: BillOfLadingTemplate,
    templateName: string,
    autoAssignPro: boolean
  ): Observable<BolInfoResponse> {
    const url = `${this.billOfLadingURI}billsofladingtemplate`;
    const params = new HttpParams().set('testMode', YesNo.N);
    bolTemplate.bolDocNm = templateName;
    delete bolTemplate.bolInstId;
    return this.httpClient
      .post<BOLRequestResponse>(url, { bolTemplate, autoAssignPro, testMode: YesNo.N }, { params })
      .pipe(map((response: BOLRequestResponse) => response.data.bolInfo));
  }

  updateBOLTemplate(bolInstId: string, bolTemplate: BillOfLadingTemplate): Observable<BolInfoResponse> {
    const url = `${this.billOfLadingURI}billsofladingtemplate/${bolInstId}`;
    const params = new HttpParams().set('testMode', YesNo.N);
    return this.httpClient
      .put<BOLRequestResponse>(url, { bolTemplate, autoAssignPro: false, testMode: YesNo.N }, { params })
      .pipe(map((response: BOLRequestResponse) => response.data.bolInfo));
  }

  handlingUnitCount(bolInstId: string): Observable<number> {
    const url = `${this.billOfLadingV2URI}bills-of-lading/${bolInstId}/handling-unit-count`;
    return this.httpClient
      .get<CommonBaseResponseData<{ bolDocHandlingUnitCount: number }>>(url)
      .pipe(map((response) => response.data.bolDocHandlingUnitCount));
  }
}
