import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  CreditCardIframeResponse,
  CreditCardTokenResponse,
  MultiInvoicePaymentInformation,
  MultiInvoiceTransactionRequest,
  PartialPaymentReasonCodeItem,
  PaymentConfirmation,
  PaymentResponse,
  PaymentsApiService,
  QuoteRequest,
  SavedCardItem,
  TransactionFeeResponse,
  UpdateSavedCardRequest,
} from '../payments';

export const MockPaymentService: Provider = {
  provide: PaymentsApiService,
  useValue: <PaymentsApiService>{
    paymentsURI: '',
    paymentV2URI: '',
    getCreditCardIframe: (quoteRequest: Partial<QuoteRequest>): Observable<CreditCardIframeResponse> =>
      of(<CreditCardIframeResponse>{}),
    getTransactionFee: (orderAmount: number): Observable<TransactionFeeResponse> => of(<TransactionFeeResponse>{}),
    savedcreditcarddetails: (partnerIdentifierCode: string, userName: string): Observable<CreditCardTokenResponse[]> =>
      of([]),
    deleteSavedCreditCard: (request: SavedCardItem): Observable<CreditCardTokenResponse> =>
      of(<CreditCardTokenResponse>{}),
    updateSavedCreditCard: (request: UpdateSavedCardRequest): Observable<CreditCardTokenResponse> =>
      of(<CreditCardTokenResponse>{}),
    submitInvoice: (paymentDetails: MultiInvoicePaymentInformation): Observable<PaymentResponse> =>
      of(<PaymentResponse>{}),
    getInvoiceConfirmationDetails: (
      invoiceDetails: MultiInvoiceTransactionRequest
    ): Observable<PaymentConfirmation[]> => of([]),
    getPartialPaymentReasonCodeItems: (): Observable<PartialPaymentReasonCodeItem[]> => of([]),
  },
};

export const MockPaymentsApiService = MockPaymentService;
