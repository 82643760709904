import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/array.helper';
import { FormControlsBaseComponent } from '../base/directives';

@UntilDestroy()
@Component({
  selector: 'ltlc-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent extends FormControlsBaseComponent implements OnInit {
  readonly placeholder = 'MM/DD/YYYY';

  dateRangeForm: FormGroup = new FormGroup({
    start: new FormControl(null, { updateOn: 'blur' }),
    end: new FormControl(null, { updateOn: 'blur' }),
  });

  @Input() minDate: Date;
  @Input() maxDate: Date;

  ngOnInit(): void {
    super.ngOnInit();

    this.dateRangeForm.valueChanges
      .pipe(
        distinctUntilChanged(ArrayHelper.isEqual),
        // Adding debounce since when doing start and end date, then hitting apply, it emits twice
        debounceTime(10),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        this.control.setValue(
          value
            ? ({
                start: this.setTimeToMin(value.start),
                end: this.setTimeToMax(value.end),
              } as DateRange<Date>)
            : null
        );
      });

    this.control.valueChanges
      .pipe(distinctUntilChanged(ArrayHelper.isEqual), untilDestroyed(this))
      .subscribe((value) => {
        this.dateRangeForm.setValue({
          start: this.setTimeToMin(value?.start) ?? null,
          end: this.setTimeToMax(value?.end) ?? null,
        });
        this.cd.markForCheck();
      });
  }

  clear(): void {
    this.dateRangeForm.reset({});

    // TODO Fixes a weird error where when the user clears the filter, it marks the control as an error state even though
    // there is no validators?
    // this.dateRangeForm.con();
  }

  getDefaultLabel(): string {
    return this.translate.instant('form.labels.dateRangePicker');
  }

  private setTimeToMin(date: Date): Date {
    return date ? new Date(date.setHours(0, 0, 0, 0)) : date;
  }

  private setTimeToMax(date: Date): Date {
    return date ? new Date(date.setHours(23, 59, 59, 999)) : date;
  }
}
