import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WeightUnit } from '@ltlc/api';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';
import { OptionSelect } from '../base/interfaces/form-controls.interface';

@Component({
  selector: 'ltlcc-select-weight-unit',
  templateUrl: './select-weight-unit.component.html',
  styleUrls: ['./select-weight-unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWeightUnitComponent extends FormControlsBaseComponent {
  weightUnits: OptionSelect[] = [
    {
      label: this.translate.instant('units.lbs'),
      value: WeightUnit.lbs,
    },
    {
      label: this.translate.instant('units.kg'),
      value: WeightUnit.kg,
    },
  ];

  getDefaultLabel(): string {
    return this.translate.instant('form.labels.weight');
  }
}
