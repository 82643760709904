import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgIdleModule } from '@ng-idle/core';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { environment } from '../environments/environment';
import { GlobalErrorHandlerDialogModule } from './app-core/global-error-handler/global-error-handler.module';
import { InitConfigModule } from './app-core/init-config.module';
import { InitHelpersModule } from './app-core/init-helpers.module';
import { InitLtlAuthModule } from './app-core/init-ltl-auth.module';
import { InitRecaptchaModule } from './app-core/init-recaptcha.module';
import { InitTranslateModule } from './app-core/init-translate.module';
import { NotAcceptableInterceptor } from './app-core/services/not-acceptable-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CypressUtilities } from './cypress/cypress-utilities';

let animations: any[] = [BrowserAnimationsModule];
if (environment.bypassAuth) {
  animations = [NoopAnimationsModule];
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ...animations,
    HttpClientModule,
    InitConfigModule,
    InitTranslateModule,
    InitLtlAuthModule,
    InitHelpersModule,
    InitRecaptchaModule,
    MatLegacyDialogModule,
    AppRoutingModule,
    GlobalErrorHandlerDialogModule,
    TourMatMenuModule,
    NgIdleModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: NotAcceptableInterceptor, multi: true },
    ...GlobalErrorHandlerDialogModule.globalProviders,
    ...CypressUtilities.getProviders(),
  ],
})
export class AppModule {}
