import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LTL_AUTH_ROUTE_NAMES } from './constants/auth-routes.const';
import { SystemIssueComponent } from './pages/system-issue/system-issue.component';

const routes: Routes = [
  {
    path: LTL_AUTH_ROUTE_NAMES.auth,
    children: [{ path: LTL_AUTH_ROUTE_NAMES.systemIssue, component: SystemIssueComponent }],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LtlAuthRoutingModule {}
