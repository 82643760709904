import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CountryCode } from '@ltlc/api';
import { BehaviorSubject } from 'rxjs';
import { Masks } from '../../../consts';
import { PhoneCountryCode } from '../../../consts/country-code.const';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

@Component({
  selector: 'ltlcc-form-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-FormPhoneNumber' },
})
export class PhoneNumberComponent extends FormControlsBaseComponent {
  private _mask$ = new BehaviorSubject(Masks.phone[CountryCode.UNITED_STATES]);
  readonly mask$ = this._mask$.asObservable();
  countryDigit = `+${PhoneCountryCode[CountryCode.UNITED_STATES]} `;

  @Input()
  get countryCode(): CountryCode {
    return this._countryCode;
  }
  set countryCode(countryCode: CountryCode) {
    if (countryCode) {
      this._countryCode = countryCode;
      if (Masks.phone[countryCode] !== this._mask$.getValue() || this.countryDigit !== this.prefixNumber(countryCode)) {
        this.setMask(countryCode);
      }
    }
  }
  private _countryCode: CountryCode = CountryCode.UNITED_STATES;

  getDefaultLabel(): string {
    return this.translate.instant('form.labels.phoneNumber');
  }

  get phoneControl(): FormControl {
    return <FormControl>(this.parentControl?.control ?? this.control);
  }

  private setMask(countryCode): void {
    const mask = Masks.phone[countryCode];
    this._mask$.next(mask);
    this.countryDigit = this.prefixNumber(countryCode);
    this.phoneControl.updateValueAndValidity();
    this.cd.markForCheck();
  }

  private prefixNumber(countryCode: CountryCode): string {
    return `+${PhoneCountryCode[countryCode]} `;
  }
}
