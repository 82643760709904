import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalErrorHandlerDialogComponent } from './global-error-handler-dialog/global-error-handler-dialog.component';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  private lastStartEvent: NavigationStart;

  constructor(private dialog: MatLegacyDialog, private router: Router) {
    // saving the last place that the user wanted to go so that when we refresh teh page, to that route instead of the
    // current route
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((lastStartEvent: NavigationStart) => (this.lastStartEvent = lastStartEvent));
  }

  handleError(error: Error | HttpErrorResponse): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error?.message)) {
      this.dialog.open(GlobalErrorHandlerDialogComponent, { width: '500px', data: this.lastStartEvent?.url });
    }

    console.error(error);
  }
}
