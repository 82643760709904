<mat-divider [vertical]="true"></mat-divider>
<mat-form-field appearance="outline">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select disableOptionCentering panelClass="ltlcc-toolbarSelect-panelClass" [formControl]="control">
    <mat-option
      class="ltlcc-toolbarSelect-select-option"
      [value]="option.value"
      *ngFor="let option of options; let i = index"
      >{{ option.label }}</mat-option
    >
  </mat-select>
</mat-form-field>
<mat-divider [vertical]="true"></mat-divider>
