<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }}{{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <input
    matInput
    type="tel"
    [autocomplete]="autocomplete ? autocomplete : 'tel'"
    name="tel"
    [formControl]="phoneControl"
    [required]="required"
    [prefix]="countryDigit"
    [mask]="mask$ | async"
    [attr.data-cy]="dataCy"
    [name]="id ? id : null"
    [id]="id ? id : null"
    (blur)="handleBlur($event)"
  />
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
