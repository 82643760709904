import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, NgControl, Validators } from '@angular/forms';
import { ArrayHelper, FormControlsBaseComponent, FormGroupTemplateService, OptionSelect } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AccountDetailsFormHelper } from './account-details-form.helper';

@UntilDestroy()
@Component({
  selector: 'ltlcc-account-details-form',
  templateUrl: './account-details-form.component.html',
  styleUrls: ['./account-details-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlc-AccountDetailsForm' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDetailsFormComponent extends FormControlsBaseComponent implements OnInit {
  private isUpdating = false;

  accountDetailForm: FormGroup = AccountDetailsFormHelper.accountDetailsForm();

  @Input() countries?: OptionSelect[];
  @Input() shownFields: string[] = Object.keys(this.accountDetailForm.controls);
  @Input() expanded: boolean = true;
  @Input()
  get contentEditable(): boolean {
    return this._contentEditable;
  }
  set contentEditable(v: boolean) {
    const value = coerceBooleanProperty(v);

    if (value !== this._contentEditable) {
      this._contentEditable = value;

      if (this.contentEditable) {
        this.accountDetailForm.enable({ emitEvent: false, onlySelf: true });
      } else {
        this.accountDetailForm.disable({ emitEvent: false, onlySelf: true });
      }
    }
  }
  private _contentEditable: boolean;

  @Input()
  get fieldsRequired(): string[] {
    return this._fieldsRequired;
  }
  set fieldsRequired(v: string[]) {
    this._fieldsRequired = v ?? [];

    for (const key in this.accountDetailForm.controls) {
      if (this.accountDetailForm.contains(key)) {
        this.accountDetailForm.get(key).clearValidators();
      }
    }

    if (!Array.isArray(this.fieldsRequired)) {
      return;
    }

    for (const fieldName of this.fieldsRequired) {
      if (this.accountDetailForm.contains(fieldName)) {
        this.accountDetailForm.get(fieldName).setValidators(Validators.required);
      }
    }
  }
  private _fieldsRequired: string[] = [];

  @Input()
  get disabledFields(): string[] {
    return this._disabledFields;
  }
  set disabledFields(v: string[]) {
    this._disabledFields = v ?? [];

    for (const fieldName of this.disabledFields) {
      if (this.accountDetailForm.contains(fieldName)) {
        this.accountDetailForm.get(fieldName).disable({ emitEvent: false });
      }
    }
  }
  private _disabledFields: string[] = [];

  constructor(
    translate: TranslateService,
    cd: ChangeDetectorRef,
    @Optional() @Self() parentControl?: NgControl,
    @Optional() formGroupTemplateService?: FormGroupTemplateService
  ) {
    super(translate, cd, parentControl, formGroupTemplateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.accountDetailForm.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(ArrayHelper.isEqual),
        filter(() => !this.isUpdating)
      )
      .subscribe(() => {
        this.control.setValue(this.accountDetailForm.getRawValue());
        this.control.markAsTouched();
      });

    this.accountDetailForm.patchValue(this.control.value, { emitEvent: false });

    this.control.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged(ArrayHelper.isEqual))
      .subscribe((value) => {
        this.isUpdating = true;
        const resetFormValue = AccountDetailsFormHelper.accountDetailsForm();
        resetFormValue.patchValue(value);
        this.accountDetailForm.reset(resetFormValue.value, { emitEvent: false });
        this.control.markAsTouched();
        this.isUpdating = false;
        this.cd.markForCheck();
      });

    this.cd.detectChanges(); // to avoid detect changes error
  }
}
