import { StandardLanguageCode } from '@ltlc/api';
import { OptionSelect } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const iso639 = new Map<StandardLanguageCode | string, string>();
iso639
  .set(StandardLanguageCode.en, 'en-US')
  .set(StandardLanguageCode.fr, 'fr-CA')
  .set(StandardLanguageCode.es, 'es-ES');
export class TranslateHelper {
  static readonly languageList: OptionSelect[] = [
    { value: StandardLanguageCode.en, label: 'English' },
    { value: StandardLanguageCode.es, label: 'Spanish / Español' },
    { value: StandardLanguageCode.fr, label: 'French / Français' },
  ];
  static readonly languageSaveKey = `xpo-language`;

  static checkLanguageSupported(language: StandardLanguageCode | string): StandardLanguageCode {
    return StandardLanguageCode[language] ? StandardLanguageCode[language] : StandardLanguageCode.en;
  }

  static changeLanguage$(translate: TranslateService, language: string | StandardLanguageCode): Observable<boolean> {
    language = TranslateHelper.standardizeLanguageCode(language);
    language = TranslateHelper.checkLanguageSupported(language);

    if (language === translate.currentLang) {
      return of(false);
    }

    localStorage.setItem(TranslateHelper.languageSaveKey, language);

    return translate.use(language).pipe(
      catchError((error) => {
        console.error(`An error occurred changing to ${language}!`, error);
        return of(false);
      }),
      map((result) => (result === false ? false : true))
    );
  }

  static standardizeLanguageCode(language: string): string | StandardLanguageCode | undefined {
    return language?.trim().split('-')[0].toLowerCase();
  }

  static getClientPreferredLanguage(translate: TranslateService): StandardLanguageCode {
    const clientLanguage: string =
      localStorage.getItem(TranslateHelper.languageSaveKey) ||
      translate.getBrowserLang()?.slice(0, 2) ||
      translate.defaultLang;

    return TranslateHelper.checkLanguageSupported(clientLanguage);
  }

  static getISOLanguageName(translate: TranslateService): string {
    //  ISO 639-1
    const language = TranslateHelper.getClientPreferredLanguage(translate);
    return iso639.get(language) || 'en-US';
  }
}
