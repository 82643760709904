// Forked from https://github.com/serhiisol/ngx-auth
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { XpoLtlAuthenticationService } from './authentication/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private attemptsToRefreshToken = 0;
  private readonly attemptsToRefreshTokenMax = 3;
  /**
   * Is refresh token is being executed
   */
  private refreshInProgress = false;

  /**
   * Notify all outstanding requests through this subject
   */
  private refreshSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private injector: Injector) {}

  /**
   * Intercept an outgoing `HttpRequest`
   */
  intercept(req: HttpRequest<any>, delegate: HttpHandler): Observable<HttpEvent<any>> {
    // We are assuming all calls to a url ending with `.json` do not need our token
    // This is for our translation files and config.json files that are fetched when app is initialized
    if (req.url.includes('.json') || this.skipRequest(req)) {
      return delegate.handle(req);
    }

    return this.processIntercept(req, delegate);
  }

  /**
   * Process all the requests via custom interceptors.
   */
  private processIntercept(original: HttpRequest<any>, delegate: HttpHandler): Observable<HttpEvent<any>> {
    const clone: HttpRequest<any> = original.clone();

    return this.request(clone).pipe(
      switchMap((req: HttpRequest<any>) => delegate.handle(req)),
      catchError((res: HttpErrorResponse) => this.responseError(clone, res))
    );
  }

  /**
   * Request interceptor. Delays request if refresh is in progress
   * otherwise adds token to the headers
   */
  private request(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (this.refreshInProgress) {
      return this.delayRequest(req);
    }

    return this.addToken(req);
  }

  /**
   * Failed request interceptor, check if it has to be processed with refresh
   */
  private responseError(req: HttpRequest<any>, res: HttpErrorResponse): Observable<HttpEvent<any>> {
    const authService = this.getAuthenticationService();
    const refreshShouldHappen: boolean =
      authService.refreshShouldHappen(res, req) && this.attemptsToRefreshToken < this.attemptsToRefreshTokenMax;

    if (refreshShouldHappen) {
      this.attemptsToRefreshToken++;
    }

    if (refreshShouldHappen && !this.refreshInProgress) {
      this.refreshInProgress = true;

      // We are just going to get a fresh token instead of using the refresh token. Solves the case where the token
      // is revoked from an external source, where it also revokes the refresh token too.
      authService.logout();
      authService
        .refreshToken()
        .pipe(take(1))
        .subscribe(
          () => {
            this.refreshInProgress = false;
            this.refreshSubject.next(true);
          },
          () => {
            this.refreshInProgress = false;
            this.refreshSubject.next(false);
          }
        );
    }

    if (refreshShouldHappen && this.refreshInProgress) {
      return this.retryRequest(req, res);
    }

    return throwError(res);
  }

  /**
   * Add access token to headers or the request
   */
  private addToken(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    const authService = this.getAuthenticationService();

    return authService.getAccessToken().pipe(
      map((token: string) => {
        if (token) {
          let setHeaders: { [name: string]: string | string[] };
          setHeaders = { Authorization: `Bearer ${token}` };

          return req.clone({ setHeaders });
        }

        return req;
      }),
      first()
    );
  }

  /**
   * Delay request, by subscribing on refresh event, once it finished, process it
   * otherwise throw error
   */
  private delayRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.refreshSubject.pipe(
      first(),
      switchMap((status: boolean) => (status ? this.addToken(req) : throwError(req)))
    );
  }

  /**
   * Retry request, by subscribing on refresh event, once it finished, process it
   * otherwise throw error
   */
  private retryRequest(req: HttpRequest<any>, res: HttpErrorResponse): Observable<HttpEvent<any>> {
    const http: HttpClient = this.injector.get<HttpClient>(HttpClient);

    return this.refreshSubject.pipe(
      first(),
      switchMap((status: boolean) => (status ? http.request(req) : throwError(res || req)))
    );
  }

  /**
   * Checks if request must be skipped by interceptor.
   */
  private skipRequest(req: HttpRequest<any>) {
    const skipRequest = this.exec('skipRequest', req);
    const verifyRefreshToken = this.exec('verifyRefreshToken', req);

    // deprecated, will be removed soon
    const verifyTokenRequest = this.exec('verifyTokenRequest', req.url);

    return skipRequest || verifyRefreshToken || verifyTokenRequest;
  }

  /**
   * Exec optional method, will be removed in upcoming updates.
   * Temp method until `verifyTokenRequest` will be completely replaced with skipRequest
   */
  private exec(method: string, ...args: any[]) {
    const authService = this.getAuthenticationService();

    if (typeof authService[method] === 'function') {
      return authService[method](...args);
    }
  }

  private getAuthenticationService(): XpoLtlAuthenticationService {
    return this.injector.get<XpoLtlAuthenticationService>(XpoLtlAuthenticationService);
  }
}
