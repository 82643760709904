import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ltlcc-account-requests-sent-dialog',
  templateUrl: './account-requests-sent-dialog.component.html',
  styleUrls: ['./account-requests-sent-dialog.component.scss'],
})
export class AccountRequestsSentDialogComponent {
  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public requestsSent = 1) {}
}
