import { Provider } from '@angular/core';

export const MockService = (service: Provider, mockParams?: { [key: string]: any }): Provider => {
  return {
    provide: service,
    useValue: {
      ...service,
      ...mockParams,
    },
  };
};
