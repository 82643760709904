export enum ApplicationID {
  Rating = 'Rating',
  RateQuote = 'RateQuote',
  ShipmentRpt = 'ShipmentRpt',
  MnfstRpts = 'MnfstRpts',
}

export enum AccountListType {
  R = 'R', // Recently Viewed
  P = 'P', // Preferred
}
