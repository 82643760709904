import { Provider } from '@angular/core';
import { of } from 'rxjs';
import { HrcInterfaceService, OcaFormExternal } from '../hrc-interface';

export const MockHrcInterfaceService: Provider = {
  provide: HrcInterfaceService,
  useValue: <HrcInterfaceService>{
    baseUrl: '',
    submitForm: (request: OcaFormExternal, isAuth: boolean = false) => of({}),
  },
};
