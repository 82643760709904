<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }}{{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <input
    type="password"
    matInput
    [formControl]="control"
    [required]="required"
    [attr.data-cy]="dataCy"
    [autocomplete]="isCurrentPassword ? 'current-password' : 'new-password'"
    [minlength]="passwordRules.minLengthPass"
    [maxlength]="passwordRules.maxLengthPass"
    (blur)="handleBlur($event)"
  />
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
