import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonBaseResponse } from '../api-shared';
import {
  AccountDeletePreferredAccountParams,
  AccountFinancialInfoResponseData,
  AccountSearchFilter,
  AccountSetPreferredAccountData,
  ContactMeBody,
  GeneralInfoDataApiResponse,
  PreferredAccount,
  ResetPasswordApiRequest,
  ResetPasswordApiResponseData,
  ShipmentAccessApiResponseData,
  UserAccount,
} from '../membership/interfaces/membership.interface';
import { MembershipService } from '../membership/membership.service';

export const MockMembershipService: Provider = {
  provide: MembershipService,
  useValue: {
    membershipURI: '',
    setPreferredAccount: (
      userProfileId: string,
      accountId: string,
      paramsDelete: AccountDeletePreferredAccountParams
    ): Observable<AccountSetPreferredAccountData> => of(<AccountSetPreferredAccountData>{}),
    deletePreferredAccount: (
      userProfileId: string,
      accountId: string,
      paramsDelete: AccountDeletePreferredAccountParams
    ) => of({}),
    associatedAccounts: (profileInstanceId: string): Observable<UserAccount[]> => of([]),
    searchUserProNumber: (proNumber: string) => of([]),
    searchUserAccounts: (searchFilter: AccountSearchFilter) => of(null),
    contactMe: (contactMeBody: ContactMeBody) => of(''),
    preferredAccounts: (profileInstanceId: string): Observable<PreferredAccount[]> => of([]),
    getGeneralInformation: () => of({}),
    getShipmentAccess: (proNumber: string): Observable<ShipmentAccessApiResponseData> =>
      of(<ShipmentAccessApiResponseData>{}),
    financialInfoAccounts: (accountId: string | number): Observable<AccountFinancialInfoResponseData> =>
      of(<AccountFinancialInfoResponseData>{}),
    saveGeneralInformation: (generalInfoUser: GeneralInfoDataApiResponse): Observable<CommonBaseResponse> =>
      of(<CommonBaseResponse>{}),
    changePassword: (resetRequest: ResetPasswordApiRequest): Observable<ResetPasswordApiResponseData> =>
      of(<ResetPasswordApiResponseData>{}),
  },
};
