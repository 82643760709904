import { Pipe, PipeTransform } from '@angular/core';
import { PurRoles } from '@ltlc/api';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'ltlccPurRole' })
export class PurRolePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: PurRoles): string {
    let returnValue = value;
    switch (value) {
      case PurRoles.SHIPPER:
        returnValue = this.translateService.instant('roles.shipper');
        break;
      case PurRoles.CONSIGNEE:
        returnValue = this.translateService.instant('roles.consignee');
        break;
      case PurRoles.THIRD_PARTY:
        returnValue = this.translateService.instant('roles.billToParty');
        break;
    }
    return returnValue;
  }
}
