import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'dateAsAgo' })
export class DateAsAgoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any): string {
    if (!value) {
      return '';
    }

    let time = (Date.now() - Date.parse(value)) / 1000;

    if (time < 10) {
      return this.translate.instant('pipes.dateAsAgo.justNow');
    } else if (time < 60) {
      return this.translate.instant('pipes.dateAsAgo.aMomentAgo');
    }

    const divider = [60, 60, 24, 30, 12];
    const string = ['second', 'minute', 'hour', 'day', 'month', 'year'];

    let i;

    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }

    const plural = Math.floor(time) > 1 ? 's' : '';

    return `${Math.floor(time)} ${this.translate.instant('pipes.dateAsAgo.' + string[i])}${plural} ago`;
  }
}
