import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountType } from '@ltlc/api';
import { FormControlsBaseComponent, OptionSelect } from '@ltlc/core';

@Component({
  selector: 'ltlcc-select-location-type',
  templateUrl: './select-location-type.component.html',
  styleUrls: ['./select-location-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLocationTypeComponent extends FormControlsBaseComponent {
  locationTypes: OptionSelect[] = [
    {
      label: this.translate.instant('locationType.P'),
      value: AccountType.pnd,
    },
    {
      label: this.translate.instant('locationType.C'),
      value: AccountType.corporate,
    },
    {
      label: this.translate.instant('locationType.B'),
      value: AccountType.billTo,
    },
  ];
}
