<mat-form-field appearance="outline">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker" [min]="minDate" [max]="maxDate">
    <input matStartDate formControlName="start" placeholder="Start date" [placeholder]="placeholder" />
    <input matEndDate formControlName="end" placeholder="End date" [placeholder]="placeholder" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker>
    <mat-datepicker-actions>
      <button mat-button matDatepickerApply type="button" (click)="clear()">
        {{ 'defaults.clear' | translate }}
      </button>
      <button mat-flat-button matDatepickerApply type="button" color="primary">
        {{ 'defaults.apply' | translate }}
      </button>
    </mat-datepicker-actions>
  </mat-date-range-picker>

  <mat-error><ltlcc-form-error-message [control]="dateRangeForm.controls.start"></ltlcc-form-error-message></mat-error>
  <mat-error><ltlcc-form-error-message [control]="dateRangeForm.controls.end"></ltlcc-form-error-message></mat-error>
</mat-form-field>
