<nav mat-tab-nav-bar class="ltlcc-ShellRoutes-nav">
  <ng-container *ngFor="let route of routes">
    <a
      *ngIf="route.children?.length <= 0; else routeWithChildren"
      class="ltlcc-ShellRoutes-nav-route {{ route.routeClass || '' }}"
      mat-tab-link
      routerLinkActive
      #rla="routerLinkActive"
      [routerLink]="route.route"
      [active]="rla.isActive"
      [attr.data-cy]="'toolbar-tab'"
      [disabled]="route.disabled"
    >
      {{ route.title }}
      <mat-chip-list *ngIf="route.isNew">
        <mat-chip ltlccAChip>
          {{ 'defaults.new' | translate | titlecase }}
        </mat-chip>
      </mat-chip-list>
    </a>
    <ng-template #routeWithChildren>
      <span
        mat-tab-link
        [active]="isRouteActive(route.route)"
        class="ltlcc-ShellRoutes-nav-route"
        [matMenuTriggerFor]="menu"
        [attr.data-cy]="'toolbar-tab'"
      >
        {{ route.title }} <mat-icon>keyboard_arrow_down</mat-icon>
      </span>
      <mat-menu #menu="matMenu" class="ltlcc-ShellRoutes-menuPanel">
        <ng-container *ngFor="let childRoute of route.children">
          <ng-container *ngIf="childRoute">
            <ng-container *ngIf="childRoute.route">
              <a
                *ngIf="childRoute.isExternalUrl; else localRoute"
                class="ltlcc-ShellRoutes-nav-menuItem"
                mat-menu-item
                routerLinkActive="ltlcc-ShellRoutes-childRoute--active"
                #childRla="routerLinkActive"
                rel="dns-prefetch"
                [href]="childRoute.route"
                target="_blank"
                [disabled]="route.disabled || childRoute.disabled"
              >
                {{ childRoute.title }}
                <mat-chip-list *ngIf="childRoute.isBeta || childRoute.isNew">
                  <mat-chip *ngIf="childRoute.isBeta" ltlccAChip>
                    {{ 'defaults.beta' | translate | titlecase }}
                  </mat-chip>
                  <mat-chip *ngIf="childRoute.isNew" ltlccAChip>
                    {{ 'defaults.new' | translate | titlecase }}
                  </mat-chip>
                </mat-chip-list>
              </a>

              <ng-template #localRoute>
                <a
                  class="ltlcc-ShellRoutes-nav-menuItem"
                  mat-menu-item
                  routerLinkActive="ltlcc-ShellRoutes-childRoute--active"
                  #childRla="routerLinkActive"
                  [routerLink]="childRoute.route"
                  [disabled]="route.disabled || childRoute.disabled"
                >
                  {{ childRoute.title }}
                  <mat-chip-list *ngIf="childRoute.isBeta || childRoute.isNew">
                    <mat-chip *ngIf="childRoute.isBeta" ltlccAChip>
                      {{ 'defaults.beta' | translate | titlecase }}
                    </mat-chip>
                    <mat-chip *ngIf="childRoute.isNew" ltlccAChip>
                      {{ 'defaults.new' | translate | titlecase }}
                    </mat-chip>
                  </mat-chip-list>
                </a>
              </ng-template>
            </ng-container>
            <button
              *ngIf="childRoute.action"
              mat-menu-item
              class="ltlcc-ShellRoutes-nav-menuItem"
              (click)="childRoute.action()"
              [disabled]="route.disabled || childRoute.disabled"
            >
              {{ childRoute.title }}
            </button>
          </ng-container>

          <mat-divider *ngIf="childRoute.withDivider" class="ltlcc-ShellRoutes-divider"></mat-divider>
        </ng-container>
      </mat-menu>
    </ng-template>
  </ng-container>
</nav>
