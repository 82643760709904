import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SwUpdate } from '@angular/service-worker';
import { race, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ltlc-global-error-handler-dialog',
  templateUrl: './global-error-handler-dialog.component.html',
  styleUrls: ['./global-error-handler-dialog.component.scss'],
})
export class GlobalErrorHandlerDialogComponent {
  constructor(
    public dialogRef: MatLegacyDialogRef<GlobalErrorHandlerDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private url: string | null,
    private update: SwUpdate
  ) {}

  reloadPage(): void {
    if (this.update.isEnabled) {
      // Seen cases where this observable does not complete? lets wait 3 seconds, then reload anyway
      race(this.update.activateUpdate(), timer(3000))
        .pipe(take(1))
        .subscribe(() => {
          document.location.reload();
        });
    } else {
      document.location.reload();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
