import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlsModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommodityHeightComponent } from './commodity-height/commodity-height.component';
import { CommodityLengthComponent } from './commodity-length/commodity-length.component';
import { CommodityQuantityComponent } from './commodity-quantity/commodity-quantity.component';
import { CommodityWeightComponent } from './commodity-weight/commodity-weight.component';
import { CommodityWidthComponent } from './width/commodity-width.component';

@NgModule({
  declarations: [
    CommodityLengthComponent,
    CommodityWidthComponent,
    CommodityHeightComponent,
    CommodityWeightComponent,
    CommodityQuantityComponent,
  ],
  imports: [CommonModule, FormControlsModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [
    CommodityLengthComponent,
    CommodityWidthComponent,
    CommodityHeightComponent,
    CommodityWeightComponent,
    CommodityQuantityComponent,
  ],
})
export class CommoditiesFormControlsModule {}
