import { AbstractControl, ValidatorFn } from '@angular/forms';

export const NMFC_REGEX = /^\d{4}\d?\d?(-\d\d?)?$/;

export class LtlConnectValidators {
  static NMFC: ValidatorFn = (control: AbstractControl) => {
    const value = control.value;

    if (!value?.trim()) {
      return null;
    }
    return NMFC_REGEX.test(value) ? null : { invalidNMFC: true };
  };
}
