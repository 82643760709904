export enum ActionTypes {
  Unknown = 0,
  Get = 100,
  List = 101,
  Count = 102,
  Export = 103,
  Add = 200,
  AddNote = 201,
  Update = 300,
  Delete = 400,
  Cancel = 401,
  Execute = 1000,
}
