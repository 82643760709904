import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  FilterInvoiceCriteria,
  FilterInvoiceList,
  InvoiceFilter,
  InvoiceList,
  InvoicePaymentStatusResponse,
  InvoiceSearchRequest,
  InvoicesSummary,
  InvoiceSummaryRequest,
  MultiDocumentExportRequest,
} from '../invoice/interfaces/invoice.interface';
import { InvoiceApiService } from '../invoice/invoice.service';

export const MockInvoiceService: Provider = {
  provide: InvoiceApiService,
  useValue: <InvoiceApiService>{
    invoiceURI: '',
    getInvoices: (request: InvoiceFilter, partnerCode?: string): Observable<InvoiceList> => of(<InvoiceList>{}),
    exportInvoices: (request: InvoiceFilter, partnerCode?: string): Observable<Blob> => of(<Blob>{}),
    getInvoiceFilterListOptions: (request: FilterInvoiceCriteria): Observable<FilterInvoiceList[]> => of([]),
    getInvoiceSummary: (request: InvoiceSummaryRequest): Observable<InvoicesSummary> => of(<InvoicesSummary>{}),
    downloadBulkInvoices: (request: MultiDocumentExportRequest): Observable<Blob> => of(<Blob>{}),
    getInvoicePaymentStatus: (request: InvoiceSearchRequest): Observable<InvoicePaymentStatusResponse> =>
      of(<InvoicePaymentStatusResponse>{}),
  },
};

export const MockInvoiceApiService = MockInvoiceService;
