import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { AccountFinancialInfoResponseData } from '@ltlc/api';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AccountListFilter } from '../enums/account-list-light.enum';
import { AccountListLightTreeNode, ToolbarConfig, TreeListConfig } from './../interfaces/account-list-light.interface';

@UntilDestroy()
@Component({
  selector: 'ltlcc-account-list-tree-toolbar-wrapper',
  templateUrl: './account-list-tree-toolbar-wrapper.component.html',
  styleUrls: ['./account-list-tree-toolbar-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountListTreeToolbarWrapperComponent {
  searchControl = this.formBuilder.control(null);
  filterList = this.formBuilder.control(AccountListFilter.ALL_ACCOUNTS);
  accountInfo: { [accountId: string]: AccountFinancialInfoResponseData } = {};
  listConfig = {
    filterList: [
      {
        label: this.translate.instant('accountList.preferredAccounts'),
        value: AccountListFilter.PREFERRED_ACCOUNTS,
      },
      {
        label: this.translate.instant('accountList.allAccounts'),
        value: AccountListFilter.ALL_ACCOUNTS,
      },
    ],
    changeList: false,
    changeWord: false,
  };

  @Input() selectedIds: FormControl = this.formBuilder.control([]);
  @Input() nodes: AccountListLightTreeNode[] | null;
  @Input() toolbarConfig: ToolbarConfig = {
    title: this.translate.instant('userPreferences.myShippingAccounts'),
    enableSearch: true,
    enableFilterList: true,
  };
  @Input() set searchWord(searchWord: string) {
    this.searchControl.setValue(searchWord);
  }

  @Input() config: TreeListConfig = {
    enableDelete: true,
    enableMultipleSelection: true,
    enableBorder: true,
  };

  constructor(private translate: TranslateService, private formBuilder: FormBuilder) {}

  search(searchWord: string): void {
    this.searchControl.setValue(searchWord);
  }
}
