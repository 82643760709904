import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Optional } from 'ag-grid-community';
import { LtlConnectApplicationRoutesData, LTL_CONNECT_APPLICATION_ROUTES } from '../../../consts';

@Component({
  selector: 'ltlc-commodity-information-dialog',
  templateUrl: './commodity-information-dialog.component.html',
  styleUrls: ['./commodity-information-dialog.component.scss'],
  host: { class: 'ltlc-CommodityInformationDialog' },
})
export class CommodityInformationDialogComponent {
  readonly translatePrefix = 'commodity.dialogCommodityInformation.';
  readonly tariffLibraryLink: string | null;

  constructor(
    public dialogRef: MatLegacyDialogRef<CommodityInformationDialogComponent>,
    @Optional() @Inject(LTL_CONNECT_APPLICATION_ROUTES) public routes?: LtlConnectApplicationRoutesData
  ) {
    this.tariffLibraryLink = routes.getTariffLibrary?.() ?? null;
  }
}
