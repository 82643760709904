<form [formGroup]="addressLines" class="ltlcc-AddressLines-form">
  <ltlcc-form-text
    formControlName="addressLine1"
    autocomplete="address-line1"
    [required]="true"
    [label]="'form.labels.addressLine1' | translate"
    [dataCy]="dataCyAddressLine1"
  ></ltlcc-form-text>

  <ltlcc-form-text
    formControlName="addressLine2"
    autocomplete="address-line2"
    [label]="'form.labels.addressLine2' | translate"
    [dataCy]="dataCyAddressLine2"
  ></ltlcc-form-text>
</form>
