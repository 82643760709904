import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LauncherLaunchData } from './launcher-data';

@Injectable({ providedIn: 'root' })
export class DrawerLauncherService {
  readonly launchComponent$: Observable<LauncherLaunchData>;

  private launchedComponentSource$ = new BehaviorSubject<LauncherLaunchData>(null);

  constructor() {
    this.launchComponent$ = this.launchedComponentSource$.asObservable();
  }

  launch<D = any>(data: LauncherLaunchData<D>): void {
    this.launchedComponentSource$.next(data);
  }

  close(): void {
    this.launchedComponentSource$.getValue()?.destroyDrawer$?.next(null);
  }
}
