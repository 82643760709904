import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FormControlsModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { LtlConnectFormControlModule } from '../form-controls/form-controls.module';
import { HazmatDropdownModule } from '../hazmat-dropdown';
import { InfoButtonModule } from '../info-button';
import { CommodityFormComponent } from './commodity-form.component';
import { CommodityInformationDialogComponent } from './commodity-information-dialog/commodity-information-dialog.component';

@NgModule({
  declarations: [CommodityFormComponent, CommodityInformationDialogComponent],
  imports: [
    CommonModule,
    FormControlsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    LtlConnectFormControlModule,
    ReactiveFormsModule,
    TranslateModule,
    HazmatDropdownModule,
    MatCheckboxModule,
    MatLegacyDialogModule,
    InfoButtonModule,
  ],
  exports: [CommodityFormComponent],
})
export class CommodityFormModule {}
