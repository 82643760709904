<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }}{{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <input
    [formControl]="emailControl"
    [required]="required"
    matInput
    type="email"
    [attr.data-cy]="dataCy"
    autocomplete="email"
    (blur)="handleBlur($event)"
    [ltlccRemoveCharacters]="emailRemoveCharactersRegex"
  />
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint" [innerHTML]="hint"></mat-hint>
</mat-form-field>
