import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CopyableComponent } from './copyable.component';

@NgModule({
  declarations: [CopyableComponent],
  imports: [CommonModule, MatTooltipModule, TranslateModule, ClipboardModule, MatIconModule, MatButtonModule],
  exports: [CopyableComponent],
})
export class CopyableModule {}
