import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { LoaderComponent } from './loader.component';
import { LoaderConfig, LoaderConfigData } from './loader.interface';
import { PORTAL_DATA } from './loader.token';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading$.pipe(shareReplay());
  private overlayRef: OverlayRef | null = null;
  constructor(private overlay: Overlay, private injector: Injector) {}

  get isLoading(): boolean {
    return this._isLoading$.getValue();
  }

  loadData<T = any>(obs$: Observable<T>, loaderConfig?: LoaderConfig): Observable<T> {
    if (!loaderConfig?.data?.hideLoader) {
      this.open(loaderConfig);
    }
    this._isLoading$.next(true);
    return obs$.pipe(
      catchError((err) => {
        this.close();
        return throwError(err);
      }),
      tap(() => this.close())
    );
  }

  private open(loaderConfig: LoaderConfig): void {
    this.close();
    const config = <OverlayConfig>{
      width: '100%',
      height: '100%',
    };
    let loaderConfigData: LoaderConfigData = loaderConfig?.data ?? {};
    if (loaderConfig?.overlayElement) {
      const parentElement: HTMLElement = loaderConfig.overlayElement;
      const { offsetHeight, offsetLeft, offsetTop, offsetWidth } = parentElement;
      config.width = `${offsetWidth}px`;
      config.height = `${offsetHeight}px`;
      config.positionStrategy = this.overlay.position().global().left(`${offsetLeft}px`).top(`${offsetTop}px`);
    }

    this.overlayRef = this.overlay.create(config);
    const filePreviewPortal = new ComponentPortal(LoaderComponent, null, this.createInjector(loaderConfigData));
    this.overlayRef.attach(filePreviewPortal);
  }

  private createInjector(data: LoaderConfigData): PortalInjector {
    const injectorTokens = new WeakMap<InjectionToken<any>, LoaderConfigData>([[PORTAL_DATA, data]]);

    return new PortalInjector(this.injector, injectorTokens);
  }

  private close(): void {
    this._isLoading$.next(false);

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
