<div class="ltlcc-CommodityForm-header" *ngIf="index >= 0">
  <ltlcc-info-button (click)="commodityDetails()">
    <b>{{ 'commodity.commodity' | translate }} {{ index + 1 }}</b>
  </ltlcc-info-button>
  <a mat-button color="accent" (click)="remove(index)" [attr.data-cy]="'commodity-removeShipment'">
    <mat-icon>delete</mat-icon>
    {{ 'commodity.removeShipment' | translate }}
  </a>
</div>
<div class="ltlcc-CommodityForm-row ltlc-Utils-grid ltlc-Utils-grid--xLarge">
  <ltlcc-form-text
    class="ltlc-Utils-grid-spanColumn2 ltlc-Utils-grid-item"
    [formControl]="commodityGroup.get('name')"
    [label]="'commodity.commodityDescription' | translate"
    dataCy="commodity-commodityDescription"
    [customError]="{
      value: errorDuplicateCommodityDescription,
      label: 'commodity.duplicateErrorDescription' | translate
    }"
  ></ltlcc-form-text>
  <ltlcc-form-number
    [formControl]="commodityGroup.get('weight')"
    [label]="'commodity.weight' | translate"
    [attr.data-cy]="'commodity-weight'"
    [hint]="'units.lbs' | translate"
    [customError]="[
      {
        value: 'max',
        label: 'commodity.maxWeightError4500lbs' | translate
      }
    ]"
  ></ltlcc-form-number>
  <ltlcc-form-select-freight-class
    [formControl]="commodityGroup.get('freightClass')"
    [attr.data-cy]="'commodity-freightClass'"
  ></ltlcc-form-select-freight-class>
  <ltlcc-commodity-length
    [formControl]="commodityGroup.get('length')"
    [label]="'commodity.length' | translate"
    [attr.data-cy]="'commodity-length'"
  ></ltlcc-commodity-length>
  <ltlcc-commodity-width
    [formControl]="commodityGroup.get('width')"
    [label]="'commodity.width' | translate"
    [attr.data-cy]="'commodity-width'"
  ></ltlcc-commodity-width>
  <ltlcc-commodity-height
    [formControl]="commodityGroup.get('height')"
    [label]="'commodity.height' | translate"
    [attr.data-cy]="'commodity-height'"
  ></ltlcc-commodity-height>
  <ltlcc-form-number
    [formControl]="commodityGroup.get('pieces')"
    [label]="'commodity.pieces' | translate"
    [attr.data-cy]="'commodity-pieces'"
  >
  </ltlcc-form-number>
  <ltlcc-select
    class="ltlc-Utils-grid-spanColumn2 ltlc-Utils-grid-item"
    [formControl]="commodityGroup.get('packaging')"
    [label]="'commodity.packaging' | translate"
    [options]="packagingOptions"
    [attr.data-cy]="'commodity-packaging'"
  ></ltlcc-select>
  <div *ngIf="hideNMFCInput" class="ltlc-Utils-grid-spanColumn2 ltlc-Utils-grid-item"></div>
  <ltlcc-form-text
    *ngIf="!hideNMFCInput"
    class="ltlc-Utils-grid-spanColumn2 ltlc-Utils-grid-item"
    [formControl]="commodityGroup.get('nmfc')"
    [label]="'commodity.nmfcCode' | translate"
    [attr.data-cy]="'commodity-nmfcCode'"
  >
  </ltlcc-form-text>

  <div class="ltlc-Utils-grid-spanColumn4 ltlc-Utils-form-noInput">
    <mat-checkbox
      [formControl]="commodityGroup.get('freezableIndicator')"
      [attr.data-cy]="'commodity-freezableProtection'"
    >
      <span>{{ 'accessorials.PROTECT_FROM_FREEZING' | translate }}</span>
    </mat-checkbox>
  </div>
  <div class="ltlc-Utils-form-noInput">
    <mat-checkbox
      *ngIf="!isMxSelected"
      [formControl]="commodityGroup.get('hazmatIndicator')"
      [attr.data-cy]="'hazmat_checkbox'"
    >
      <span>{{ 'hazmat.hazmatItem' | translate }}</span>
    </mat-checkbox>
  </div>
  <!-- <ng-container *ngIf="hazmatIndicator.value"> -->
  <!-- TODO: Convert this component to formControl and make it a ControlValueAccessor with FormControlsBaseComponent -->
  <!-- <ltlcc-hazmat-dropdown
      *ngIf="displayHazmatTypeSelector"
      class="ltlc-Utils-grid-spanColumn3 ltlc-Utils-grid-item"
      (hazmatSelected)="hazmatSelectedChange($event)"
    ></ltlcc-hazmat-dropdown> -->
  <!-- </ng-container> -->
  <div *ngIf="enableSaveCommodity" class="ltlc-Utils-grid-spanColumn3 ltlcc-CommodityForm-actions">
    <button
      mat-button
      color="accent"
      type="button"
      [disabled]="disabledSave"
      [attr.data-cy]="'commodity-save'"
      (click)="saveNewCommodity()"
    >
      <div class="ltlc-Utils-form-actions-loading">
        {{ 'tools.COMMODITIES_LIST.SAVE_AS_NEW' | translate }}
        <mat-spinner *ngIf="saveLoading" [diameter]="15" [strokeWidth]="2"></mat-spinner>
      </div>
    </button>
    <button
      mat-button
      color="accent"
      type="button"
      [disabled]="disabledUpdate"
      [attr.data-cy]="'commodity-update'"
      (click)="updateCommodity()"
    >
      <div class="ltlc-Utils-form-actions-loading">
        {{ 'tools.COMMODITIES_LIST.UPDATE_COMMODITY' | translate }}
        <mat-spinner *ngIf="updateLoading" [diameter]="15" [strokeWidth]="2"></mat-spinner>
      </div>
    </button>
  </div>
</div>
