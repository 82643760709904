import { Pipe, PipeTransform } from '@angular/core';
import { OptionSelect } from '@ltlc/core';
import { HighlightSearchTextHelper } from '../helpers/highlight-search-text.helper';

@Pipe({
  name: 'htmlSearchText',
})
export class HtmlSearchTextPipe implements PipeTransform {
  transform(content: string, searchWord: string, bold: boolean): string {
    return HighlightSearchTextHelper.htmlize(searchWord, content, bold);
  }
}

@Pipe({
  name: 'htmlSearchOptions',
})
export class HtmlSearchOptionsPipe implements PipeTransform {
  transform(options: OptionSelect[], searchWord: string): string {
    if (Array.isArray(options) && searchWord) {
      searchWord = searchWord.toLowerCase().trim();
      const results = [];
      for (const option of options) {
        if (option.value.toString().toLowerCase().trim().includes(searchWord)) {
          const result = `${option.label}: ${HighlightSearchTextHelper.htmlize(searchWord, option.value, false)}`;
          results.push(result);
        }
      }
      const stringResult = results.join(', ');
      return stringResult;
    }

    return '';
  }
}
