import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OptionSelect } from '../interfaces';

@Pipe({
  name: 'ltlcFormErrorMessage',
})
export class FormErrorMessagePipe implements PipeTransform {
  private readonly translatePrefix = 'form.errors.';

  constructor(private translate: TranslateService) {}

  transform(errors: ValidationErrors | undefined, customError?: OptionSelect | OptionSelect[]): string {
    return this.getError(errors, customError);
  }

  private getError(errors: ValidationErrors | undefined, customError?: OptionSelect | OptionSelect[]): string {
    if (errors) {
      const e = errors;

      if (customError) {
        const customErrors = Array.isArray(customError) ? customError : [customError];
        for (const _customError of customErrors) {
          if (!_customError || !('value' in _customError) || !('label' in _customError)) {
            continue;
          }
          const { value, label } = _customError;
          if (e[value]) {
            return label;
          }
        }
      }

      if (e.required === true) {
        return this.translate.instant(this.translatePrefix + 'required');
      }

      if (e.zipCodeUS || e.zipCodeCA || e.invalidNMFC) {
        return this.translate.instant(this.translatePrefix + 'invalidFormat');
      }

      if (e.email) {
        return this.translate.instant(this.translatePrefix + 'emailInvalid');
      }

      if (e.max) {
        return this.translate.instant(this.translatePrefix + 'max', { maxAllowed: e.max.max });
      }

      if (e.min) {
        return this.translate.instant(this.translatePrefix + 'min', { minAllowed: e.min.min });
      }

      if (e.maxlength) {
        return this.translate.instant(this.translatePrefix + 'maxlength', {
          requiredLength: e.maxlength.requiredLength,
        });
      }

      if (e.minlength) {
        return this.translate.instant(this.translatePrefix + 'minlength', {
          requiredLength: e.minlength.requiredLength,
        });
      }

      if (e.invalidProNumber) {
        //TODO: should be placed in ltlcc-search-by-pro-number once refactored
        return this.translate.instant('shipments.trackingShipment.PRO_NUMBER_ERROR');
      }

      if (e.matStartDateInvalid) {
        return this.translate.instant(this.translatePrefix + 'matStartDateInvalid');
      }

      if (e.matEndDateInvalid) {
        return this.translate.instant(this.translatePrefix + 'matEndDateInvalid');
      }

      if (e.mask) {
        return this.translate.instant(this.translatePrefix + 'invalidMask', { requiredMask: e.mask.requiredMask });
      }

      if (e.pattern) {
        return this.translate.instant(this.translatePrefix + 'pattern');
      }
    }

    return '';
  }
}
