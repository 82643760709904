import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserManager } from 'oidc-client';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { APP_ROUTE_PATHS } from '../../app-core/constants/routes.const';

@Injectable({ providedIn: 'root' })
export class AutoLoginGuard implements CanActivate {
  constructor(private router: Router, private userManager: UserManager) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.userManager.getUser()).pipe(
      take(1),
      map((user) => {
        if (!!user) {
          //TODO: this guard is for landing and sign-in pages only. Reuse it to navigate the public ones to their auth pages.
          this.router.navigateByUrl(APP_ROUTE_PATHS.app.root);
        }

        return true;
      })
    );
  }
}
