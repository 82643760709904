import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { excessiveLengthInchOptions, PackageType } from '@ltlc/api';
import { BUSINESS_CONSTRAINTS, FormValidatorHelper, OptionSelect } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LtlConnectValidators, NMFC_REGEX } from '../../../helpers';
import { Commodity } from '../../../interfaces';
import { CommodityFormHelper } from '../helper/commodity-form.helper';

@Injectable({
  providedIn: 'root',
})
export class CommodityFormService {
  private _showSfrOptions$ = new BehaviorSubject<boolean>(false);
  showSfrOptions$: Observable<boolean> = this._showSfrOptions$.asObservable();

  constructor(private translate: TranslateService) {}

  toggleSfrOptions(showSfr: boolean): void {
    this._showSfrOptions$.next(showSfr);
  }

  createCommodityFromGroup(data?: Commodity): FormGroup {
    return new FormGroup({
      id: new FormControl(data?.id),
      name: new FormControl(data?.name),
      length: new FormControl(data?.length, [Validators.min(1)]),
      width: new FormControl(data?.width, [Validators.min(1)]),
      height: new FormControl(data?.height, [Validators.min(1)]),
      weight: new FormControl(data?.weight, [
        Validators.required,
        Validators.min(BUSINESS_CONSTRAINTS.MIN_COMMODITY_WEIGHT),
        Validators.max(BUSINESS_CONSTRAINTS.MAX_COMMODITY_WEIGHT_COUT),
      ]),
      packaging: new FormControl(data?.packaging || PackageType.PLT),
      pieces: new FormControl(data?.pieces || 1, [
        Validators.required,
        Validators.min(1),
        FormValidatorHelper.noDecimalOrNegativeValidator(),
      ]),
      evcValue: new FormControl(data?.evcValue),
      // Only add nmfc value if its valid. some commodities from the api have invalid nmfc at the moment.
      nmfc: new FormControl(data?.nmfc && NMFC_REGEX.test(data.nmfc) ? data.nmfc : null, LtlConnectValidators.NMFC),
      freightClass: new FormControl(data?.freightClass, Validators.required),
      freezableIndicator: new FormControl(data?.freezableIndicator ?? false),
      hazmatIndicator: new FormControl(data?.hazmatIndicator),
      hazmatUnnaId: new FormControl(data?.hazmatUnnaId),
    });
  }

  getPackagingOptions(): OptionSelect[] {
    return [
      { value: PackageType.PLT, label: this.translate.instant('packagingList.PACKAGE_TYPE_PLT') },
      { value: PackageType.CTN, label: this.translate.instant('packagingList.PACKAGE_TYPE_CTN') },
      { value: PackageType.PCS, label: this.translate.instant('packagingList.PACKAGE_TYPE_PCS') },
      { value: PackageType.SKD, label: this.translate.instant('packagingList.PACKAGE_TYPE_SKD') },
      { value: PackageType.BAG, label: this.translate.instant('packagingList.PACKAGE_TYPE_BAG') },
      { value: PackageType.BAL, label: this.translate.instant('packagingList.PACKAGE_TYPE_BAL') },
      { value: PackageType.BBL, label: this.translate.instant('packagingList.PACKAGE_TYPE_BBL') },
      { value: PackageType.BDL, label: this.translate.instant('packagingList.PACKAGE_TYPE_BDL') },
      { value: PackageType.BIN, label: this.translate.instant('packagingList.PACKAGE_TYPE_BIN') },
      { value: PackageType.BOX, label: this.translate.instant('packagingList.PACKAGE_TYPE_BOX') },
      { value: PackageType.BSK, label: this.translate.instant('packagingList.PACKAGE_TYPE_BSK') },
      { value: PackageType.BTL, label: this.translate.instant('packagingList.PACKAGE_TYPE_BTL') },
      { value: PackageType.BXT, label: this.translate.instant('packagingList.PACKAGE_TYPE_BXT') },
      { value: PackageType.CAB, label: this.translate.instant('packagingList.PACKAGE_TYPE_CAB') },
      { value: PackageType.CAG, label: this.translate.instant('packagingList.PACKAGE_TYPE_CAG') },
      { value: PackageType.CAN, label: this.translate.instant('packagingList.PACKAGE_TYPE_CAN') },
      { value: PackageType.CAS, label: this.translate.instant('packagingList.PACKAGE_TYPE_CAS') },
      { value: PackageType.CBY, label: this.translate.instant('packagingList.PACKAGE_TYPE_CBY') },
      { value: PackageType.CHS, label: this.translate.instant('packagingList.PACKAGE_TYPE_CHS') },
      { value: PackageType.CNT, label: this.translate.instant('packagingList.PACKAGE_TYPE_CNT') },
      { value: PackageType.COL, label: this.translate.instant('packagingList.PACKAGE_TYPE_COL') },
      { value: PackageType.CRT, label: this.translate.instant('packagingList.PACKAGE_TYPE_CRT') },
      { value: PackageType.CYL, label: this.translate.instant('packagingList.PACKAGE_TYPE_CYL') },
      { value: PackageType.DRM, label: this.translate.instant('packagingList.PACKAGE_TYPE_DRM') },
      { value: PackageType.ENV, label: this.translate.instant('packagingList.PACKAGE_TYPE_ENV') },
      { value: PackageType.FRM, label: this.translate.instant('packagingList.PACKAGE_TYPE_FRM') },
      { value: PackageType.IBC, label: this.translate.instant('packagingList.PACKAGE_TYPE_IBC') },
      { value: PackageType.JAR, label: this.translate.instant('packagingList.PACKAGE_TYPE_JAR') },
      { value: PackageType.JER, label: this.translate.instant('packagingList.PACKAGE_TYPE_JER') },
      { value: PackageType.JUG, label: this.translate.instant('packagingList.PACKAGE_TYPE_JUG') },
      { value: PackageType.KEG, label: this.translate.instant('packagingList.PACKAGE_TYPE_KEG') },
      { value: PackageType.KIT, label: this.translate.instant('packagingList.PACKAGE_TYPE_KIT') },
      { value: PackageType.LSE, label: this.translate.instant('packagingList.PACKAGE_TYPE_LSE') },
      { value: PackageType.MSC, label: this.translate.instant('packagingList.PACKAGE_TYPE_MSC') },
      { value: PackageType.PAL, label: this.translate.instant('packagingList.PACKAGE_TYPE_PAL') },
      { value: PackageType.PKG, label: this.translate.instant('packagingList.PACKAGE_TYPE_PKG') },
      { value: PackageType.PLF, label: this.translate.instant('packagingList.PACKAGE_TYPE_PLF') },
      { value: PackageType.RCK, label: this.translate.instant('packagingList.PACKAGE_TYPE_RCK') },
      { value: PackageType.REL, label: this.translate.instant('packagingList.PACKAGE_TYPE_REL') },
      { value: PackageType.ROL, label: this.translate.instant('packagingList.PACKAGE_TYPE_ROL') },
      { value: PackageType.SAK, label: this.translate.instant('packagingList.PACKAGE_TYPE_SAK') },
      { value: PackageType.SLV, label: this.translate.instant('packagingList.PACKAGE_TYPE_SLV') },
      { value: PackageType.SPL, label: this.translate.instant('packagingList.PACKAGE_TYPE_SPL') },
      { value: PackageType.TBE, label: this.translate.instant('packagingList.PACKAGE_TYPE_TBE') },
      { value: PackageType.TBN, label: this.translate.instant('packagingList.PACKAGE_TYPE_TBN') },
      { value: PackageType.TNK, label: this.translate.instant('packagingList.PACKAGE_TYPE_TNK') },
      { value: PackageType.TRY, label: this.translate.instant('packagingList.PACKAGE_TYPE_TRY') },
      { value: PackageType.TUB, label: this.translate.instant('packagingList.PACKAGE_TYPE_TUB') },
    ];
  }

  getYesNoOptions(): OptionSelect[] {
    return [
      { value: true, label: this.translate.instant('defaults.yes') },
      { value: false, label: this.translate.instant('defaults.no') },
    ];
  }

  getExcessiveLengthFeetOptions(): OptionSelect[] {
    return excessiveLengthInchOptions.map((lengthInch) => {
      const lengthFeet = CommodityFormHelper.convertInchToFeet(lengthInch);
      const value = lengthInch;

      if (lengthInch === 0) {
        return { label: this.translate.instant('defaults.none'), value };
      }
      return { label: `${lengthFeet} ${this.translate.instant('units.feet')}`, value };
    });
  }
}
