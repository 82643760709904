import * as deepmerge from 'deepmerge';

export class DeepMergeHelper {
  private static isMergeableObject = (val: any): boolean => {
    return val !== null && !Array.isArray(val);
  };

  // This customMerge method keeps same destination values and overrides 'null' source values
  static customMerge = (key: string, options?: deepmerge.Options): any => {
    return (destination: any, source: any): any => {
      if (destination == null || Object.values(destination).every((v) => !v)) {
        return source;
      }
      if (DeepMergeHelper.isMergeableObject(destination)) {
        const newSource = { ...source };
        for (const key in destination) {
          if (destination[key] !== null && destination[key] !== undefined && destination[key] !== '') {
            newSource[key] = destination[key];
          }
        }
        return newSource;
      }
      return deepmerge(destination, source);
    };
  };
}
