import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ConfirmationDialogService } from '../modules/confirmation-dialog/confirmation-dialog.service';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private confirmationDialogService: ConfirmationDialogService, private translate: TranslateService) {}

  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (component.canDeactivate) {
      const canDeactivateValue = component.canDeactivate();
      const canDeactivate$ = canDeactivateValue instanceof Observable ? canDeactivateValue : of(canDeactivateValue);

      return canDeactivate$.pipe(
        switchMap((canDeactivate) => {
          return canDeactivate
            ? of(true)
            : this.confirmationDialogService
                .open({
                  title: this.translate.instant('createPURDynamic.backTitle'),
                  body: this.translate.instant('createPURDynamic.backBody'),
                  actionText: this.translate.instant('defaults.yes'),
                  actionColor: 'warn',
                })
                .pipe(take(1));
        })
      );
    }

    return of(true);
  }
}
