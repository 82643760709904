import { Provider } from '@angular/core';
import { of } from 'rxjs';
import { ElasticsearchService } from '../elasticsearch';

export const MockElasticsearchService: Provider = {
  provide: ElasticsearchService,
  useValue: <ElasticsearchService>{
    elasticsearchURI: '',
    getHazmatRefs: (filter) => of([]),
  },
};
