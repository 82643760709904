import { CommodityItemApiResponse } from '@ltlc/api';
import { TranslateService } from '@ngx-translate/core';
import { Commodity, CommodityList } from '../../../interfaces/commodity.interface';

export class CommodityApiResponseHelper {
  static transformCommodityResponse(commodity: CommodityItemApiResponse): Commodity {
    const response: Commodity = {
      id: commodity.commodityId,
      name: commodity.commodityDescription,
      length: commodity.commodityLengthInch?.toString(),
      width: commodity.commodityWidthInch?.toString(),
      height: commodity.commodityHeightInch?.toString(),
      weight: commodity.commodityWeightLbs?.toString(),
      freightClass: Number(commodity.commodityClass),
      hazmatIndicator: commodity.commodityHazmatInd,
      evcValue: commodity.excessValueCoverageAmount?.toString(),
      freezableIndicator: commodity.protectFromFreezingInd,
      pieces: commodity.commodityPieceCount,
      packaging: commodity.commodityPackageCd,
      nmfc: commodity.commodityNmfc,
    };
    return response;
  }

  static transformCommodityListItems(
    commoditiesApiResponse: CommodityItemApiResponse[],
    translate: TranslateService
  ): CommodityList[] {
    const items: CommodityList[] = [];
    if (commoditiesApiResponse?.length) {
      commoditiesApiResponse.forEach((item) => {
        const newCommodity: CommodityList = {
          rawData: item,
          commodityId: item.commodityId,
          description: item.commodityDescription,
          class: item.commodityClass,
          dimensions: this.getDimensionList(
            item.commodityLengthInch,
            item.commodityWidthInch,
            item.commodityHeightInch
          ),
          hazmat: item.commodityHazmatInd ? 'Yes' : 'No',
          nmfc: item.commodityNmfc ?? '-',
          package: translate.instant('packagingList.PACKAGE_TYPE_' + item.commodityPackageCd),
          weight: item.commodityWeightLbs ?? null,
        };
        items.push(newCommodity);
      });
    }
    return items;
  }

  static getDimensionList(length: number, width: number, height: number): string {
    if (length && width && height) return `${length} x ${width} x ${height}`;
    return '-';
  }
}
