import { Injectable } from '@angular/core';
import { AccountInfoModalData } from '../interfaces/account-list-light.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountInformationModalService {
  private dialogData: AccountInfoModalData;

  setDialogData(data: AccountInfoModalData): void {
    this.dialogData = data;
  }

  getDialogData(): AccountInfoModalData {
    return this.dialogData;
  }
}
