import { interval, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';

export class ScrollHelper {
  static scrollTo(element: HTMLBaseElement | HTMLElement, destroyed$ = new Subject()): void {
    if (!element) {
      return;
    }
    interval(500)
      .pipe(
        startWith(0),
        take(20),
        takeUntil(destroyed$),
        map(() => {
          const rect: DOMRect = element.getBoundingClientRect();
          return rect.y;
        }),
        filter((y: number) => {
          return y !== 0;
        }),
        take(1)
      )
      .subscribe(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      });
  }

  static get viewPortHeight(): number {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }
}
