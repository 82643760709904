import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'ltlcc-ag-grid-loading-overlay',
  templateUrl: './ag-grid-loading-overlay.component.html',
  styleUrls: ['./ag-grid-loading-overlay.component.scss'],
})
export class AgGridLoadingOverlayComponent implements ILoadingOverlayAngularComp {
  agInit(params: ILoadingOverlayParams): void {
    // noop
  }
}
