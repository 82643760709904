import { Provider } from '@angular/core';
import { of } from 'rxjs';
import { GetShipmentStatusResp, GetShipmentTrackingEventsResponseData, TrackingService } from '../tracking';

export const MockTrackingService: Provider = {
  provide: TrackingService,
  useValue: <TrackingService>{
    shipmentTrackingURI: '',
    getShipmentEvents: (proNumber: string, showDetailed?: boolean) => of(<GetShipmentTrackingEventsResponseData>{}),
    getShipmentStatusDetailTracking: (proNumbers: string[]) => of(<GetShipmentStatusResp>{}),
  },
};
