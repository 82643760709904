<ng-template #accountListLightTree>
  <ltlcc-account-list-light-tree
    [class.ltlcc-AccountListTreeAutocompleteWrapper-listTree]="inline"
    [class.ltlcc-AccountListTreeAutocompleteWrapper-toggleVisibility]="toggleVisibility && !inline"
    [formControl]="control"
    [nodes]="nodes"
    [searchWord]="searchControl.value"
    [config]="configList"
    [dataCy]="dataCy"
    (filteredNodes)="filteredNodes = $event"
    (selectedNode)="selectedNode.emit($event)"
    (click)="$event.stopPropagation()"
  ></ltlcc-account-list-light-tree>
</ng-template>

<mat-form-field appearance="outline" class="ltlcc-AccountListTreeAutocompleteWrapper-field">
  <mat-label *ngIf="label"
    >{{ label
    }}{{ !required && !configList.hideLabelOptional ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}
  </mat-label>

  <mat-chip-list
    #accountChipList
    class="ltlcc-AccountListTreeAutocompleteWrapper-chips"
    aria-label="Selected accounts"
    *ngIf="selectedNodes$ | async; else inputWithoutChip"
  >
    <mat-chip
      class="ltlcc-AccountListTreeAutocompleteWrapper-chips-chip"
      *ngFor="let node of selectedNodes$ | async"
      [removable]="true"
      (removed)="removeAccount(node)"
    >
      <span class="ltlcc-AccountListTreeAutocompleteWrapper-chips-chip-text">
        {{ node?.name || node?.address }}
      </span>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      type="text"
      [attr.data-cy]="dataCy + '-input'"
      [placeholder]="placeholder"
      matInput
      [matAutocomplete]="auto"
      [matChipInputFor]="accountChipList"
      [formControl]="searchControl"
      (keydown)="$event.stopImmediatePropagation()"
      [required]="required"
      (blur)="handleBlurOnSearch()"
    />
  </mat-chip-list>

  <ng-template #inputWithoutChip>
    <input
      type="text"
      [attr.data-cy]="dataCy + '-input'"
      [placeholder]="placeholder"
      matInput
      [matAutocomplete]="auto"
      [formControl]="searchControl"
      (keydown)="$event.stopImmediatePropagation()"
      [required]="required"
      (blur)="handleBlurOnSearch()"
    />
  </ng-template>

  <mat-autocomplete
    [disableRipple]="true"
    #auto="matAutocomplete"
    class="ltlcc-AccountListTreeAutocompleteWrapper-autocomplete"
    (closed)="scrollToStart()"
  >
    <ng-container>
      <mat-option style="display: none"></mat-option>
      <ng-container *ngIf="!inline" [ngTemplateOutlet]="accountListLightTree"></ng-container>
      <span
        *ngIf="filteredNodes && !filteredNodes.length"
        class="ltlcc-AccountListTreeAutocompleteWrapper-autocomplete-noLocationsFound"
        >{{ 'accountList.noLocationsFound' | translate }}</span
      >
    </ng-container>
  </mat-autocomplete>
  <mat-error><ltlcc-form-error-message [customError]="customError"></ltlcc-form-error-message></mat-error>
  <mat-spinner
    class="ltlcc-AccountListTreeAutocompleteWrapper-field-spinner"
    *ngIf="!nodes"
    matSuffix
    diameter="20"
  ></mat-spinner>

  <button matPrefix type="button" mat-icon-button>
    <mat-icon class="ltlcc-AccountListTreeAutocompleteWrapper-field-businessIcon">
      {{ control.value || configList?.hideSearchIcon ? 'business' : 'search' }}
    </mat-icon>
  </button>

  <button
    *ngIf="nodes && searchControl.value && configList?.enableClearButton"
    class="ltlcc-AccountListTreeAutocompleteWrapper-field-clearButton"
    matSuffix
    type="button"
    mat-icon-button
    (click)="clear()"
  >
    <mat-icon class="ltlcc-AccountListTreeAutocompleteWrapper-field-clearButton-icon">cancel</mat-icon>
  </button>

  <mat-hint [attr.data-cy]="dataCy + '-hint'" [ngClass]="autocompleteAccountHintClass" *ngIf="hint">{{
    hint
  }}</mat-hint>
</mat-form-field>

<ng-container *ngIf="inline" [ngTemplateOutlet]="accountListLightTree"></ng-container>
