import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

/**
 * Directive to suppress default behavior of MatExpansionPanel.toggle()
 * Add this to the mat-expansion-panel element to disable toggling
 */
@Directive({
  selector: '[ltlcDisableToggle]',
})
export class DisableToggleDirective {
  @Input('ltlcDisableToggle')
  get disableToggle(): boolean {
    return this._disableToggle;
  }
  set disableToggle(value: boolean) {
    this._disableToggle = coerceBooleanProperty(value);
    if (this.disableToggle) {
      if (!this._toggle) {
        this._toggle = this.expansionPanel.toggle;
      }
      this.expansionPanel.toggle = () => {};
    } else if (this._toggle) {
      this.expansionPanel.toggle = this._toggle;
    }
  }
  private _disableToggle = true;
  private _toggle: () => void;

  constructor(private expansionPanel: MatExpansionPanel) {}
}
