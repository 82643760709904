import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BillOfLadingV2 } from '../bill-of-lading';
import { BillOfLadingApiService } from '../bill-of-lading/bill-of-lading.service';
import {
  BillOfLadingTemplate,
  BolInfoResponse,
  BOLRequestBody,
  GetShippingLabelsPdfRequest,
  SendBolByEmail,
} from '../bill-of-lading/interfaces/bill-of-lading.interface';
import { billOfLadingsResponse } from './data/bills-of-lading.data';

export const MockBillOfLadingApiService: Provider = {
  provide: BillOfLadingApiService,
  useValue: <BillOfLadingApiService>{
    billOfLadingURI: '',
    billOfLadingV2URI: '',
    getBols: () => of(billOfLadingsResponse.data.billsOfLading),
    getBOL: (bolInstId: string) => of({}),
    getBOLV2: (bolInstId: string) => of({}),
    createBOLRequest: (BOLRequestBody) => of({}),
    getExternalUserData: () => of({}),
    updateBOLRequest: (BOLRequestBody: BOLRequestBody): Observable<BolInfoResponse> => of(<BolInfoResponse>{}),
    getPltShippingLabelsPDF: (request, printOneLabelPerPage) => of({}),
    getPltShippingLabelsPDFV2: (request) => of({}),
    getLegacyShippingLabelsPDF: (request, printOneLabelPerPage) => of({}),
    sendBolByEmail: (payload: SendBolByEmail): Observable<string> => of(''),
    getPltShippingLabelsEmail: (
      bolInstId: number,
      request: GetShippingLabelsPdfRequest,
      printOneLabelPerPage: boolean
    ): Observable<string> => of(''),
    getLegacyShippingLabelsEmail: (
      bolInstId: number,
      request: GetShippingLabelsPdfRequest,
      printOneLabelPerPage: boolean
    ): Observable<string> => of(''),
    getPapsLabelsPDF: (bolInstId, request) => of({}),
    getBolTemplates: () => of([]),
    getBolTemplatesV2: () => of([]),
    deleteBolTemplate: (templateId) => of({}),
    cancelBol: (bolId, request) => of({}),
    getBolTemplateItem: (bolInstanceId: string): Observable<BillOfLadingTemplate> => of(<BillOfLadingTemplate>{}),
    getBolTemplateItemV2: (bolInstanceId: string): Observable<BillOfLadingV2> => of(<BillOfLadingV2>{}),
    getBOLPdf: (bolId) => of({}),
    getBOLPdfV2: (bolId) => of({}),
    createBOLTemplate: (
      bolTemplate: BillOfLadingTemplate,
      templateName: string,
      autoAssignPro: boolean
    ): Observable<BolInfoResponse> => of(<BolInfoResponse>{}),
    updateBOLTemplate: (bolInstId: string, bolTemplate: BillOfLadingTemplate): Observable<BolInfoResponse> =>
      of(<BolInfoResponse>{}),
    handlingUnitCount: (bolInstId: string): Observable<number> => of(3),
  },
};
