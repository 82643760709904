import { AddressBase } from '../../api-shared';
import { WebFormBody } from './web-forms.interface';

export interface ClaimsForm extends WebFormBody {
  proNumber: string;
  shipmentInstId: string;
  pickupDate: string;
  profileInstId?: string;
  currencyCode: string;
  commodityDetails: ClaimsCommodity[];
  claimant: ClaimsClaimant;
  claimFiler: ClaimsFiler;
  supportingDocuments: ClaimDocument[];
  claimId?: string;
  claimCustomerReferenceNumber?: string;
  payeeSameAsClaimantFlag?: boolean;
  payee?: ClaimsPayee;
  comment?: string;
  isDraft?: boolean;
}
export interface ClaimDocument {
  name: string;
  contentType: string;
  data: string;
  dmsUrl?: string;
}
export interface ClaimsFiler {
  userId: string;
  emailAddress: string;
  profileInstanceId?: string;
}

export enum ClaimsType {
  damage = 'Damage',
  freight = 'Freight',
  shortage = 'Shortage',
}

export enum ClaimsCondition {
  New = 'New',
  Used = 'Used',
}

export interface ClaimsCommodity {
  claimType: ClaimsType;
  condition: ClaimsCondition;
  claimedWeight: number;
  claimedAmount: number;
  description: string;
  commodityWeightUnitOfMeasure: string;
  claimedPieces?: number;
  partNumber?: string;
}

export interface ClaimsParty extends AddressBase {
  companyOrIndividualName: string;
  name2?: string;
}

export interface ClaimDetails {
  claimId: string;
  proNumber: string;
  shipmentInstId?: string;
  claimCustomerReferenceNumber?: string; // claimantRefNbr
  claimFiledDate: string;
  currencyCode: string;
  claimStatus: ClaimStatus | string;
  decisionCode?: string; // decisionCategoryCd
  decisionSubCategoryCd?: string; // decisionSubCategoryCd
  claimedAmountTotal: number;
  adjustedAmount?: number;
  approvedAmount?: number;
  payments: ClaimPayment[];
  pickupDate: string;
  deliveryDate: string;
  claimFiler: ClaimsParty;
  shipper: ClaimsParty;
  consignee: ClaimsParty;
  billTo: ClaimsParty;
  customerReferenceNumbers: string[]; // shipment refrence numbers
  commodityDetails: ClaimsCommodity[];
  claimant: ClaimsClaimant;
  payeeSameAsClaimantFlag?: boolean;
  payee?: ClaimsPayee;
  supportingDocuments?: DocumentInformation[];
  notes?: ClaimNote[];
  emails?: ClaimEmail[];
  rebuttals?: ClaimRebuttal[];
  claimCurrencyCode: string;
  companyOrIndividualName: string;
}

export interface ClaimPayment {
  claimPaymentId: string;
  checkNumber: string;
  checkDate: string;
  paymentDate?: string;
  cashedDate?: string;
  checkCancelDate?: string;
  bankName?: string;
  amount?: number;
  currencyCode?: string;
}

export interface ClaimNote {
  claimNoteId: string;
  createdOnDate: string;
  note: string;
  fromEmail: string;
}

export interface ClaimsClaimant extends ClaimsParty {
  role?: string;
  email: string;
  lastName: string;
  phoneNumber: string;
  contactName?: string;
  contactFirstName?: string;
  contactLastName?: string;
  phoneNumberExtension?: string;
  onBehalfOf?: string;
  madCode?: string;
  accountInstanceId?: string;
}

export interface ClaimsPayee extends ClaimsParty {
  madCode?: string;
  accountInstanceId?: string;
}

export interface ClaimEmail {
  claimEmailId: string;
  createdOnDate: string;
  content: string;
  fromEmail: string;
  subject?: string;
  attachments?: File[];
  ccEmailAddress?: string;
  recipientEmailAddress?: string;
  templateTypeCode?: string; // figure out actual types, apprival, declination
  emailAttachments?: DocumentInformation[];
}

export interface ClaimRebuttal {
  claimRebuttalId: string;
  claimId: string;
  status: ClaimStatus | string;
  rebuttalFiledDate: string;
  closeDate: string;
  // finalResolutionDescription: string; // check types of email templates, there might be a RebuttalApproved/declined type.
  finalApprovedDate?: string;
  finalDeclinedDate?: string;
  claimedAmount?: number;
  currencyCode?: string;
  reasonCode?: string;
  comment?: string; // noteTxt
  supportingDocuments?: DocumentInformation[];
}

export interface DocumentInformation {
  name: string;
  contentType: string;
  dmsUrl: string;
}

export enum ClaimStatus {
  Approved = 'Approved',
  CustomerCancelled = 'CustomerCancelled',
  Declined = 'Declined',
  Submitted = 'Submitted',
  UnderReview = 'UnderReview',
  Unsubmitted = 'Unsubmitted',
}
