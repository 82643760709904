import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ltlccAChip]',
})
export class AChipDirective implements AfterViewInit {
  constructor(private el: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    if (this.chipEl) {
      this.chipEl.style.opacity = '1';
      this.chipEl.style.pointerEvents = 'none';
    }
  }

  private get chipEl() {
    return this.el?.nativeElement;
  }
}
