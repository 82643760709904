import { Injectable } from '@angular/core';
import { ElasticsearchService, HazmatItemResponse } from '@ltlc/api';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HazmatDropdownService {
  private readonly cachedHazmatList$: Observable<HazmatItemResponse[]>;

  constructor(private elasticsearchService: ElasticsearchService) {
    this.cachedHazmatList$ = this.elasticsearchService
      .getHazmatRefs({ query: { type: 'hazmatRefSearch' } })
      .pipe(shareReplay());
  }

  getHazmatList(): Observable<HazmatItemResponse[]> {
    return this.cachedHazmatList$;
  }
}
