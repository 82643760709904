import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

@UntilDestroy()
@Component({
  selector: 'ltlcc-address-lines',
  templateUrl: './address-lines.component.html',
  styleUrls: ['./address-lines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressLinesComponent extends FormControlsBaseComponent implements OnInit {
  @Input() dataCyAddressLine1: string;
  @Input() dataCyAddressLine2: string;

  addressLines: FormGroup = new FormGroup({
    addressLine1: new FormControl(''),
    addressLine2: new FormControl(''),
  });

  ngOnInit(): void {
    super.ngOnInit();
    this.addressLines.valueChanges.pipe(untilDestroyed(this)).subscribe((addressLines) => {
      this.control.setValue(addressLines);
    });

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((addressLines) => {
      if (addressLines) {
        this.addressLines.patchValue(addressLines, { emitEvent: false });
      } else {
        this.addressLines.reset({}, { emitEvent: false });
      }
    });

    this.addressLines.patchValue(this.control.value);

    if (this.control.touched) {
      this.addressLines.markAllAsTouched();
    }

    this.formGroupTemplateService?.formSubmitted$.pipe(untilDestroyed(this)).subscribe(() => {
      this.addressLines.markAllAsTouched();
    });
  }

  setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
  }
}
