<div *ngIf="!!accountDetailsForm" class="ltlcc-AccountForm-form" #grid>
  <ltlcc-form-text
    *ngIf="accountDetailsForm.controls.companyName"
    formControlName="companyName"
    [dataCy]="dataCy ? dataCy + '-companyName' : ''"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfigs?.get('companyName')?.enableAutocomplete
        ? addressFormGroupName + ' organization'
        : 'off'
    "
    [id]="addressGroupName ? addressGroupName + '-companyName' : ''"
    [label]="fieldConfigs?.get('companyName')?.label || ('accountDetails.companyName' | translate)"
    [placeholder]="fieldConfigs?.get('companyName')?.placeholder"
  ></ltlcc-form-text>

  <ltlcc-form-text
    *ngIf="accountDetailsForm.controls.careOfName"
    formControlName="careOfName"
    [dataCy]="dataCy ? dataCy + '-careOfName' : ''"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfigs?.get('careOfName')?.enableAutocomplete
        ? addressFormGroupName + ' organization'
        : 'off'
    "
    [id]="addressGroupName ? addressGroupName + '-careOfName' : ''"
    [label]="fieldConfigs?.get('careOfName')?.label || ('accountDetails.careOfName' | translate)"
    [placeholder]="fieldConfigs?.get('careOfName')?.placeholder"
  ></ltlcc-form-text>

  <ltlcc-address-form [fieldConfig]="fieldConfig" [dataCy]="dataCy" [ngClass]="fullWidthClass"></ltlcc-address-form>

  <ltlcc-form-phone-number
    *ngIf="accountDetailsForm.controls.phoneNumber"
    formControlName="phoneNumber"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfigs?.get('phoneNumber')?.enableAutocomplete
        ? addressFormGroupName + ' tel'
        : 'off'
    "
    [dataCy]="dataCy ? dataCy + '-phoneNumber' : ''"
    [id]="addressGroupName ? addressGroupName + '-phoneNumber' : ''"
    [countryCode]="countryCode"
    [label]="fieldConfigs?.get('phoneNumber')?.label || ('form.labels.phoneNumber' | translate)"
    [placeholder]="fieldConfigs?.get('phoneNumber')?.placeholder"
  ></ltlcc-form-phone-number>

  <ltlcc-form-phone-extension
    *ngIf="accountDetailsForm.controls.phoneExtension"
    formControlName="phoneExtension"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfigs?.get('phoneExtension')?.enableAutocomplete
        ? addressFormGroupName + ' tel-extension'
        : 'off'
    "
    [dataCy]="dataCy ? dataCy + '-phoneExtension' : ''"
    [id]="addressGroupName ? addressGroupName + '-phoneExtension' : ''"
    [label]="fieldConfigs?.get('phoneExtension')?.label || ('form.labels.phoneExtension' | translate)"
    [placeholder]="fieldConfigs?.get('phoneExtension')?.placeholder"
  >
  </ltlcc-form-phone-extension>

  <ltlcc-form-email
    *ngIf="accountDetailsForm.controls.email"
    formControlName="email"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfigs?.get('email')?.enableAutocomplete
        ? addressFormGroupName + ' email'
        : 'off'
    "
    [ngClass]="fullWidthClass"
    [id]="addressGroupName ? addressGroupName + '-email' : ''"
    [dataCy]="dataCy ? dataCy + '-email' : ''"
    [label]="fieldConfigs?.get('email')?.label || ('form.labels.email' | translate)"
    [placeholder]="fieldConfigs?.get('email')?.placeholder"
  >
  </ltlcc-form-email>

  <ltlcc-form-text
    *ngIf="accountDetailsForm.controls.contactFullName"
    formControlName="contactFullName"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfigs?.get('contactFullName')?.enableAutocomplete
        ? addressFormGroupName + ' name'
        : 'off'
    "
    [id]="addressGroupName ? addressGroupName + '-contactFullName' : ''"
    [dataCy]="dataCy ? dataCy + '-contactFullName' : ''"
    [label]="fieldConfigs?.get('contactFullName')?.label || ('accountDetails.contactFullName' | translate)"
    [placeholder]="fieldConfigs?.get('contactFullName')?.placeholder"
  ></ltlcc-form-text>

  <ltlcc-form-phone-number
    *ngIf="accountDetailsForm.controls.contactPhoneNumber"
    formControlName="contactPhoneNumber"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfigs?.get('contactPhoneNumber')?.enableAutocomplete
        ? addressFormGroupName + ' tel'
        : 'off'
    "
    [id]="addressGroupName ? addressGroupName + '-contactPhoneNumber' : ''"
    [dataCy]="dataCy ? dataCy + '-contactPhoneNumber' : ''"
    [countryCode]="countryCode"
    [label]="fieldConfigs?.get('contactPhoneNumber')?.label || ('accountDetails.contactPhoneNumber' | translate)"
    [placeholder]="fieldConfigs?.get('contactPhoneNumber')?.placeholder"
  ></ltlcc-form-phone-number>

  <ltlcc-form-email
    *ngIf="accountDetailsForm.controls.contactEmail"
    formControlName="contactEmail"
    [ngClass]="fullWidthClass"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfigs?.get('contactEmail')?.enableAutocomplete
        ? addressFormGroupName + ' email'
        : 'off'
    "
    [id]="addressGroupName ? addressGroupName + '-contactEmail' : ''"
    [dataCy]="dataCy ? dataCy + '-contactEmail' : ''"
    [label]="fieldConfigs?.get('contactEmail')?.label || ('accountDetails.contactEmail' | translate)"
    [placeholder]="fieldConfigs?.get('contactEmail')?.placeholder"
  ></ltlcc-form-email>

  <ltlcc-docking-interval
    *ngIf="accountDetailsForm.controls.dockingInterval"
    formGroupName="dockingInterval"
    [ngClass]="fullWidthClass"
  >
  </ltlcc-docking-interval>

  <ltlcc-form-textarea
    *ngIf="accountDetailsForm.controls.comment"
    [ngClass]="fullWidthClass"
    formControlName="comment"
    [maxLength]="400"
    [id]="addressGroupName ? addressGroupName + '-commentPlaceholder' : ''"
    [dataCy]="dataCy ? dataCy + '-commentPlaceholder' : ''"
    [label]="fieldConfigs?.get('comment')?.label || ('accountDetails.comment' | translate)"
    [placeholder]="fieldConfigs?.get('comment')?.placeholder"
  ></ltlcc-form-textarea>
</div>
