import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LtlAuthModule, XpoLtlAuthConfig } from '@ltlc/auth';
import { AlertsModule, WebConfigService } from '@ltlc/core';
import { XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { environment } from '../../environments/environment';
import { SessionStorageKeys } from './constants/session-storage-keys.const';
import { WebTokenHandlerInterceptor } from './services/web-token-handler.interceptor';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export function authConfigFactory(configManagerService: WebConfigService): XpoLtlAuthConfig {
  const kMap = {
    tctsi: 'Tlkb9B9g_f49V0NONeUY0yMksUwa',
    tcts5: 'UcUyZvq7asPDRfmfhr3KxsFR_wIa',
    tctss: 'eLMLjD7oR9mstsXEmxfdfw4pvAQa',
    wave: 'hJMWy21k6KdjLClXq6sHrulIzsca',
    prod: 'hJMWy21k6KdjLClXq6sHrulIzsca',
  };

  const sMap = {
    tctsi: 'X050XADDxkh4vOX9NMOA3nhahzwa',
    tcts5: 'I9rH5SIHapPHqQNByfQgGaoyTwca',
    tctss: 'E5DafPjgrS4JFLEN1MAUfUVEmGYa',
    wave: 'XNpXOQpHtUmMW2MzPmHQpXbS6twa',
    prod: 'XNpXOQpHtUmMW2MzPmHQpXbS6twa',
  };

  let sessionStorageClientId = localStorage.getItem(SessionStorageKeys.clientId);

  // TODO: hack, sorry, remove when we are not dependant on pm2 dev for UAT-ing admin, switch do dev when in dev
  const dotNetApi: string = configManagerService.getSetting('dotNetApi');
  if (
    sessionStorageClientId &&
    sessionStorageClientId === 'ltl-ext-web-pilot' &&
    !environment.production &&
    !dotNetApi.includes('-uat')
  ) {
    sessionStorageClientId = 'ltl-ext-web-dev';
  }

  const region: string = configManagerService.getSetting('region');
  return new XpoLtlAuthConfig(
    {
      appCode: sessionStorageClientId ?? configManagerService.getSetting('appCode'),
      scopes: configManagerService.getSetting<string[]>('scopes'),
      isProd: coerceBooleanProperty(configManagerService.getSetting('isProd')),
      applicationRootUri: configManagerService.getSetting('applicationRootUri'),
    },
    {
      appName: configManagerService.getSetting('appName'),
      consumerKey: kMap[region.toLowerCase()] ?? kMap.prod,
      consumerSecret: sMap[region.toLowerCase()] ?? sMap.prod,
      apiUri: configManagerService.getSetting('apiUri'),
      scopeOptions: configManagerService.getSetting('scopeOptions'),
      authGrantType: configManagerService.getSetting('authGrantType'),
    }
  );
}
@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    LtlAuthModule.forRoot(
      {
        provide: XPO_AUTH_CONFIG,
        useFactory: authConfigFactory,
        deps: [WebConfigService],
      },
      { provide: HTTP_INTERCEPTORS, useClass: WebTokenHandlerInterceptor, multi: true }
    ),
  ],
})
export class InitLtlAuthModule {}
