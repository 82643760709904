import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers';

export interface AgGridDateRangeFilter {
  min: string;
  max: string;
  selected: 'date';
}

@UntilDestroy()
@Component({
  selector: 'ltlc-ag-grid-date-picker-filter',
  templateUrl: './ag-grid-date-picker-filter.component.html',
  styleUrls: ['./ag-grid-date-picker-filter.component.scss'],
  host: { class: 'ltlc-AgGridDatePickerFilter' },
  encapsulation: ViewEncapsulation.None,
})
export class AgGridDatePickerFilterComponent implements OnInit {
  dateRangeControl: FormControl = new FormControl(null);

  private _filterSelected: AgGridDateRangeFilter;

  @Input() label: string = '';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input()
  set filterSelected(value: AgGridDateRangeFilter) {
    if (JSON.stringify(value) !== JSON.stringify(this._filterSelected)) {
      this._filterSelected = value;
      this.dateRangeControl.setValue(
        value ? <DateRange<Date>>{ start: new Date(value.min), end: new Date(value.max) } : null
      );
    }
  }
  get filterSelected(): AgGridDateRangeFilter {
    return this._filterSelected;
  }

  // Matching model of what xrt expects
  @Output() dateRangeChange = new EventEmitter<AgGridDateRangeFilter>(null);

  ngOnInit(): void {
    this.dateRangeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        map((v: DateRange<Date>) => {
          return {
            min: this.removeTimezoneFromDate(v.start),
            max: this.removeTimezoneFromDate(v.end),
            selected: 'date',
          };
        }),
        distinctUntilChanged(ArrayHelper.isEqual)
      )
      .subscribe((v: AgGridDateRangeFilter) => {
        this.dateRangeChange.emit(v);
      });
  }

  private removeTimezoneFromDate(date: Date): string | undefined {
    if (!date || isNaN(+date)) {
      return undefined;
    }

    const offset = date.getTimezoneOffset() * 60000;
    return new Date(+date - offset).toISOString().slice(0, -1);
  }
}
