export enum FormProcessingStatus {
  Created = 0,
  FailedDoNoReattempt,
  FailedReattempt,
  InstantResponseReceived,
  QueueResponseReceived,
}

export enum FormProcessingStatusText {
  Created = 'Created',
  FailedDoNoReattempt = 'FailedDoNoReattempt',
  FailedReattempt = 'FailedReattempt',
  InstantResponseReceived = 'InstantResponseReceived',
  QueueResponseReceived = 'QueueResponseReceived',
}
