export enum XpoIcon {
  xpoLogoBlack = 'xpoLogoBlack',
  shipments = 'shipments',
  pickupRequest = 'pickupRequest',
  quote = 'quote',
  bol = 'bol',
  sheet = 'sheet',
  note = 'note',
  chartBar = 'chart-bar',
}
