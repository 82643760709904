import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FileHelper } from './file.helper';

@Directive({
  selector: '[ltlccFileDrop]',
})
export class FileDropDirective implements OnInit, OnDestroy {
  @Input('ltlccFileDrop') files: FormArray = new FormArray([]);
  @Output() dragOver = new EventEmitter(false);
  @Input() accept: string;
  @Input() multiple;

  private _dragOver$ = new Subject();
  private _destroy$ = new Subject<void>();

  @HostListener('dragover', ['$event']) onDragOver(event): void {
    event.preventDefault();
    event.stopPropagation();
    this._dragOver$.next(true);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event): void {
    event.preventDefault();
    event.stopPropagation();
    this._dragOver$.next(false);
  }

  @HostListener('drop', ['$event']) onDrop(event): void {
    event.preventDefault();
    event.stopPropagation();
    const files: FileList = event.dataTransfer.files;
    FileHelper.addFiles(files, this.files, this.accept, this.multiple);
    this._dragOver$.next(false);
  }

  ngOnInit(): void {
    this._dragOver$
      .asObservable()
      .pipe(takeUntil(this._destroy$), distinctUntilChanged())
      .subscribe((overlap) => {
        this.dragOver.emit(overlap);
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
