import { Injectable } from '@angular/core';
import { XpoLtlAuthenticationService } from '@ltlc/auth';
import { Observable, of } from 'rxjs';

/**
 * Auth service that prevents logout
 */
@Injectable()
export class XpoLtlAuthenticationServiceNoLogout extends XpoLtlAuthenticationService {
  isAuthorized(): Observable<boolean> {
    return of(!window.location.href.includes('public-app'));
  }

  logout() {
    console.log('CYPRESS TESTING - Disable Logout');
  }
}
