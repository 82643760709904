import { formatDate, formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DateHelper } from './date.helper';

// Using this instead of directly injecting the ag-grid library's valueFormatter interface
// since this can be used with anything with a value aparam
export interface ObjectWithValue<T = any> {
  value: T;
}

export class ConnectAgGridValueFormatters {
  static nullValueFallbackString: string = '-';

  static booleanAsYesNo(params: ObjectWithValue, translate: TranslateService): string {
    return params?.value === true ? translate.instant('defaults.yes') : translate.instant('defaults.no');
  }
  static zeroAsDash(params: ObjectWithValue): string {
    return params?.value !== 0 ? params.value : ConnectAgGridValueFormatters.nullValueFallbackString;
  }

  static nullAsDash(params: ObjectWithValue): string {
    return params?.value ?? ConnectAgGridValueFormatters.nullValueFallbackString;
  }

  static formatNumber(params: ObjectWithValue, locale: string = 'en-US'): string {
    try {
      return isNaN(params.value) ? params.value : formatNumber(params.value, locale);
    } catch (err) {
      return params.value;
    }
  }

  /** format options: https://angular.io/api/common/DatePipe#usage-notes in angular */
  static formatToDateTime(
    params: ObjectWithValue,
    format: string = 'MM/dd/yy hh:mm aa',
    locale: string = 'en-US'
  ): string {
    try {
      if (params.value == null) {
        return null;
      }

      return DateHelper.isNullDate(params.value)
        ? ConnectAgGridValueFormatters.nullValueFallbackString
        : formatDate(params.value, format, locale);
    } catch (err) {
      return ConnectAgGridValueFormatters.nullValueFallbackString;
    }
  }

  static formatToDate(params: ObjectWithValue): string {
    return ConnectAgGridValueFormatters.formatToDateTime(params, 'MM/dd/yy');
  }
}
