import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { ImagedDocumentTreeComponent } from './imaged-document-tree.component';

@NgModule({
  declarations: [ImagedDocumentTreeComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatTreeModule,
  ],
  exports: [ImagedDocumentTreeComponent],
})
export class ImagedDocumentTreeModule {}
