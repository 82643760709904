import { CountryCode, ServiceTypeLiteral, YesNo } from '../../api-shared';
import { PackageType } from '../../api-shared/enums/package-type.enum';
import { PurRoles } from '../../api-shared/enums/roles.enums';
import {
  Address,
  Charge,
  ContactInfo,
  Dimensions,
  Email,
  InquirerInfo,
  Phone,
  Volume,
  Weight,
} from '../../api-shared/interfaces/api-shared.interface';
import { AccessorialsCodes } from '../../rating/enums/accessorials.enum';
import { PaymentTerms } from '../../rating/enums/payment-terms.enum';
import { BolResponseStatus } from '../enums/bill-of-lading.enum';
import { BillOfLadingV2 } from './bill-of-lading-get-instance-v2.interface';

export interface BolTemplate {
  bolDocNm?: string; // legacy only
  bolDocumentName?: string; //V2 only
  bolInstId: string;
  checked?: boolean;
}

export interface Packaging {
  packageCd: PackageType;
}

export interface GetBolTemplateApiResponse {
  code: string;
  transactionTimestamp: number;
  data: {
    bolTemplate: BolTemplate[];
  };
}

export interface GetBolTemplateApiResponseV2 {
  code: string;
  transactionTimestamp: number;
  data: {
    billOfLadingTemplates: BolTemplate[];
  };
}

export interface DeleteBolTemplateResponse {
  code: string;
  transactionTimestamp: number;
  data: null;
}

export interface GetBillsOfLadingApiResponse {
  code: string;
  transactionTimestamp: number;
  data: BillOfLadingDataApiResponse;
}

export interface GetBillOfLadingRequestorApiResponse {
  code: string;
  transactionTimestamp: number;
  data: {
    billOfLading: BillOfLadingTemplate;
  };
}

export interface BillOfLadingDataApiResponse {
  billsOfLading: BillOfLading[];
}

export interface BillOfLading {
  bolId: number;
  originName: string;
  originCityName: string;
  originStateCd: string;
  originPostalCd: string;
  destinationName: string;
  destinationCityName: string;
  destinationStateCd: string;
  destinationPostalCd: string;
  bolDocumentName: string;
  proNumber: string;
  pickupDate?: string;
  pickupTime?: string;
  creationDate?: string;
  referenceNumbers: string[];
  statusCd?: string;
  totalPiecesCount?: number;
}

/**
 * In V2, printerSettings adds this value:
 * OLPP (One Label Per Page)
 */
export interface GetShippingLabelsPdfRequest {
  handlingUnitsNbr?: number; // V2 only
  handlingUnits?: number; // legacy
  labelPosition: number;
  nbrOfLabels?: number; // legacy
  labelsNbr?: number; // V2 only
  printerSettings: PrintLabelsOptionsFull;
  bolInstId?: number; // legacy
}

export type PrintLabelsOptionsFull = PrintLabelsOptionsText | PrintLabelsOptionsV2;

export enum PrintLabelsOptionsText {
  NormalPrinterSettings = 'Normal Printer Settings',
  Alternate_1 = 'Alternate 1',
  Alternate_2 = 'Alternate 2',
  Zebra = 'Zebra',
  OneLabelPerPage = 'One Label Per Page',
}

export enum PrintLabelsOptionsV2 {
  OLPP = 'OLPP',
}

export interface GetShippingLabelsPdfResponse {
  code: string;
  transactionTimestamp: number;
  data: GetShippingLabelsPdfResponseData;
}
export interface GetShippingLabelsPdfResponseData {
  shippingLabel: LabelsFile;
}

export interface GetShippingLabelsEmailRequest extends GetShippingLabelsPdfRequest {
  emailId: string;
}

export interface SendBolByEmail {
  bolInstId: string;
  emailId: string;
}
export interface GetShippingLabelsEmailResponse {
  code: string;
  transactionTimestamp: number;
  data: {
    bolInstId: string;
  };
}

export interface LabelsFile {
  fileName: string;
  fileContents?: string;
  contentType: string;
}

export interface GetPapsLabelsPdfRequest {
  labelPosition: number;
  nbrOfLabels: number;
  bolInstId: number;
}

export interface GetPapsLabelsPdfResponse {
  code: string;
  transactionTimestamp: number;
  data: GetPapsLabelsPdfResponseData;
}
export interface GetPapsLabelsPdfResponseData {
  papsLabel: LabelsFile;
}
export interface CancelBolApiRequest {
  requesterId?: string;
  testMode?: string;
  bolInstId: string;
}

export interface CancelBolApiResponse {
  code: string;
  transactionTimestamp: number;
}

export interface RequesterMainInfo {
  requester?: {
    fullName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    salutation: string;
    title: string;
    address: Address[];
    contactInfo: {
      contactFirstName: string;
      contactLastName: string;
      contactTitle: string;
      emailAddress: Email[];
      telephone: Phone[];
      chatIMAddress: [
        {
          name: string;
          value: string;
        }
      ];
      contactModePreference: [
        {
          name: string;
          value: string;
        }
      ];
      contactWebSiteURL: {
        name: string;
        value: string;
      };
    };
    preferredLanguage: string;
    suffix: string;
  };
  role: string | PurRoles;
  userId?: string;
  userTypeCd?: string;
}

export interface BillOfLadingCustomBrokerInformation {
  brokerInstId: string;
  brokerBusinessKey: string;
  address: Address;
  contactInfo: ContactInfo;
}

export interface BillOfLadingTemplate {
  additionalService: AdditionalServiceRequest[];
  billToCust: InquirerInfo;
  bolDateTime?: string;
  bolDocNm?: string;
  bolDocVer?: string;
  bolInstId?: number;
  chargeToCd?: PaymentTerms | string;
  cod?: CodRequest;
  commodityLine: BOLCommodity[];
  consignee: InquirerInfo;
  customsBroker?: BillOfLadingCustomBrokerInformation;
  declaredValueAmt?: Charge;
  declaredValueAmtPerLb?: Charge;
  emergencyContactName?: string;
  emergencyContactPhone?: Phone;
  excessLiabilityChargeInit?: string;
  pickupInfo?: PurRequest;
  proTypeCd?: string;
  proNbr?: string;
  remarks?: string;
  requester: RequesterMainInfo;
  sendBolEmail?: boolean;
  shipper: InquirerInfo;
  suppRef?: ReferenceNumbersApiInterface;
}

export interface BOLRequester {
  requester: {
    fullName: string;
    contactInfo: {
      contactFirstName: string;
      emailAddress: Email[];
      telephone: Phone[];
    };
  };
  role: PurRoles;
  userId: string;
  userTypeCd: string;
}
export interface BolTemplateItemApiResponse {
  code: string;
  transactionTimestamp: number;
  data: {
    bolTemplate: BillOfLadingTemplate;
  };
}

export interface BolTemplateItemApiResponseV2 {
  code: string;
  transactionTimestamp: number;
  data: BillOfLadingV2;
}
export interface BolInfoResponse {
  bolInstId: string;
  link?: {
    method: string;
    rel: string;
    uri: string;
  }[];
  pkupCallSeq?: number;
  pkupTrmnlSic?: string;
  pkupCallDate?: number;
  pkupConfNbr?: string;
}

export interface BOLRequestResponse {
  code: string;
  data: {
    bolInfo: BolInfoResponse;
  };
  transactionTimestamp: number;
}

export interface BolAddress {
  addressLine1: string;
  addressLine2: string;
  careOfName: string;
  cityName: string;
  stateCd: string;
  countryCd: CountryCode | string;
  postalCd: string;
}

export interface PurRequest {
  pkupDate: number | string;
  pkupTime: number | string;
  dockCloseTime: number | string;
  contact: ContactInfo;
  pkupCallSeq?: number | string;
  pkupCallDate?: number | string;
  pkupInstId?: string;
  pkupTrmnlSic?: string;
  palletCnt?: number;
  totVolumeCft?: Volume;
  frzbleInd?: boolean;
  holDlvrInd?: boolean;
  totLoosePieceCnt?: number;
  pickupShipDimensions?: Dimensions;
}
export interface CodRequest {
  paymentTypeCd: string;
  remit: InquirerInfo;
  remitAmount: Charge;
  acceptCheckType: string;
}

export interface BOLRequestBody {
  bol: BillOfLadingTemplate;
  autoAssignPro: boolean;
  testMode?: YesNo;
}

export interface AdditionalServiceRequest {
  accsrlCode: string | AccessorialsCodes;
  prepaidOrCollect?: string;
}

export interface OtherReferenceNumberApiInterface {
  referenceCode: string;
  reference: string;
  referenceDescr: string;
  referenceTypeCd: string;
}

export interface ReferenceNumbersApiInterface {
  shippersRefNbr?: string; // Does not work with array
  purchaseOrderNbr?: string[];
  otherRefs?: OtherReferenceNumberApiInterface[];
}

export interface BOLCommodity {
  pieceCnt: number;
  packaging: {
    packageCd: string;
    packageWeight?: Weight;
    packageDimensions?: Dimensions;
    packageVolume?: Volume;
  };
  grossWeight: Weight;
  tareWeight?: Weight;
  volume?: Volume;
  desc: string;
  nmfcClass: string;
  nmfcItemCd?: string;
  freezableInd?: boolean;
  hazmatInd: boolean;
  hazmatUnnaId?: string;
  hazmatInfo?: {
    countryCd?: CountryCode;
    hazardousCd: string;
    desc?: string;
  };
  chemicalCharacteristics?: {
    chemProperShippingName: string;
    chemTechnicalName1: string;
    chemTechnicalName2: string;
    chemTechnicalName3: string;
    classPrimary: string;
    classSecondary: string;
    classTertiary: string;
    contentWeight: number;
    contentWeightUOM: string;
    reportableQuantityInd: boolean;
    reportQuantityWeight: number;
    reportQuantityWeightUOM: string;
    packagingGroupCd: string;
    specialProvision: string;
    emergencyGuideBookName: string;
    hotInd: boolean;
    residueInd: boolean;
    poisonousInd: boolean;
    notOtherwiseSpecifiedInd: boolean;
    limitedQuantityInd: boolean;
    toxicInhalationInd: boolean;
    passengerAircraftForbidInd: boolean;
    commercialAircraftForbidInd: boolean;
    massivePollutantInd: boolean;
    oilContainedInd: boolean;
    temperatureUOM: string;
    flashPointTemperature: number;
    emergencyTemperature: number;
    controlTemperature: string;
  };
  emergencyContactName?: string;
  emergencyContactPhone?: Phone;
}
export interface BOLPDFData {
  fileName?: string;
  contentType?: string;
}
export interface BOLPDFResponse<T> {
  code: string;
  transactionTimestamp: number;
  data: T;
}

export interface BOLPDFDataResponse {
  bolpdf: BOLPDFData;
}
export interface BOLPDFV2DataResponse {
  bolPDF: BOLPDFData;
}
export interface APIError {
  code: string;
  transactionTimestamp: number;
  error: {
    errorCode: string;
    message: string;
    trace: string;
    srcApplicationId: string;
  };
}

export interface BOLDetailResponseData {
  bolPrimaryDetails: {
    billTo: {
      addressLine1: string;
      addressLine2: string;
      cisCustomerNumber: number;
      cisMadCode: string;
      city: string;
      countryCode: string;
      customerName1: string;
      customerName2: string;
      emailAddress: string;
      matchStatusCode: string;
      pacdNumber: string;
      phonNumber: string;
      phonrextensionNumber: string;
      postalCode: string;
      stateCode: string;
      zip4: string;
      zip6: string;
      zipCode: string;
    };
    bolCodCustomer: {
      codAmount: number;
      codCompanyName: string;
      codCustomer: {
        addressLine1: string;
        addressLine2: string;
        cisCustomerNumber: number;
        cisMadCode: string;
        city: string;
        countryCode: string;
        customerName1: string;
        customerName2: string;
        emailAddress: string;
        matchStatusCode: string;
        pacdNumber: string;
        phonNumber: string;
        phonrextensionNumber: string;
        postalCode: string;
        stateCode: string;
        zip4: string;
        zip6: string;
        zipCode: string;
      };
      codRemitToName: string;
      codSequenceNumber: number;
    };
    bolDoc: {
      acceptedWithExceptionInd: boolean;
      archiveInd: boolean;
      bolStatusCd: BolResponseStatus;
      chargeCd: string;
      cisCustIdentifier: number;
      createDt: number;
      createTmst: number;
      custSuppliedProNbrTxt: number;
      deferredInd: boolean;
      destTrmnlSic: string;
      documentName: string;
      documentTypeCd: string;
      emrCntctName: string;
      emrCntctPhone: string;
      freezableInd: boolean;
      guaranteedInd: boolean;
      hazmatInd: boolean;
      holidayInd: boolean;
      lstUpdtTmst: number;
      lstUsedDt: number;
      origTrmnlSic: string;
      proNumberTxt: string;
      proTypeCd: string;
      refNumberTxt: string;
      shpInstanceId: number;
      shpmntRemarksTxt: string;
      sourcecd: string;
      templateSrcCd: string;
      testModeInd: boolean;
      versionNbr: number;
      svcTypCd: ServiceTypeLiteral;
    };
    bolInstanceId: number;
    bolRequestor: {
      countryCd: CountryCode;
      declPerLb: string;
      declTot: string;
      ediSenderId: string;
      ediSenderShpId: string;
      exLiaInit: string;
      pccdPhone: number;
      requestorAreaCd: number;
      requestorCompanyName: string;
      requestorEmail: string;
      requestorName: string;
      requestorPhone: number;
      role: PurRoles;
      userId: string;
      userIdNumberTxt: number;
      userTypeCd: number;
    };
    bolpickLst: {
      consZip6: number;
      consigneeAddressTxt: string;
      consigneeName1: string;
      destAreaCd: number;
      destTrmnlSic: string;
      excludeInd: boolean;
      lstUpdtTmst: number;
      origAreacd: number;
      origTrmnlSic: string;
      pkupDt: number;
      proNumberTxt: string;
      reserveInd: boolean;
      shipperCisCustNumber: number;
      shipperMadCd: string;
      shipperName1: string;
      sourcecd: number;
      suppRefNbrtxt: string;
    };
    consignee: {
      addressLine1: string;
      addressLine2: string;
      cisCustomerNumber: number;
      cisMadCode: string;
      city: string;
      countryCode: CountryCode;
      customerName1: string;
      customerName2: string;
      emailAddress: string;
      matchStatusCode: number;
      pacdNumber: number;
      phonNumber: number;
      phonrextensionNumber: string;
      postalCode: string;
      stateCode: string;
      zip4: string;
      zip6: string;
      zipCode: string;
    };
    custProfInstId: number;
    hazmatRemarkTxt: string;
    hazmatRemarkTypeCd: string;
    shipper: {
      addressLine1: string;
      addressLine2: string;
      cisCustomerNumber: number;
      cisMadCode: string;
      city: string;
      countryCode: CountryCode;
      customerName1: string;
      customerName2: string;
      emailAddress: string;
      matchStatusCode: number;
      pacdNumber: number;
      phonNumber: number;
      phonrextensionNumber: string;
      postalCode: string;
      stateCode: string;
      zip4: string;
      zip6: string;
      zipCode: string;
    };
    shpRemarkTxt: string;
    shpRemarkTypeCd: string;
    totalPiecesCnt: number;
    totalWeightLbs: number;
  };
  bolCommodityDetails: [
    {
      classTxt: string;
      description: string;
      hazamatInd: boolean;
      nmfcTxt: string;
      pakageTxt: string;
      quantity: number;
      sequenceNumber: number;
      weight: number;
    }
  ];
}
