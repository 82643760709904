import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class CacheServiceBase<T = any> {
  protected readonly cacheMap = new Map<string, T>();

  clearCache(): void {
    this.cacheMap.clear();
  }

  protected abstract getSource(lookup: string | string[]): Observable<T>;

  protected get(lookup: string | string[] = 'storingKey'): Observable<T> {
    let key = lookup?.toString();
    if (Array.isArray(lookup)) {
      key = lookup.join('_');
    }
    const cachedValue = this.cacheMap.get(key);
    if (cachedValue) {
      return of(cachedValue);
    }
    return this.getSource(lookup).pipe(
      tap((values) => this.cacheMap.set(key, values)),
      share()
    );
  }
}
