import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaymentTerms } from '@ltlc/api';
import { FormControlsBaseComponent, OptionSelect } from '@ltlc/core';

@Component({
  selector: 'ltlcc-select-payment-terms',
  templateUrl: './select-payment-terms.component.html',
  styleUrls: ['./select-payment-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPaymentTermsComponent extends FormControlsBaseComponent {
  readonly paymentTerms: OptionSelect[] = [
    { label: this.translate.instant('paymentTerms.prepaid'), value: PaymentTerms.prepaid },
    { label: this.translate.instant('paymentTerms.collect'), value: PaymentTerms.collect },
  ];

  getDefaultLabel(): string {
    return this.translate.instant('rateQuote.terms');
  }
}
