import { Directive, Input } from '@angular/core';
import { IndentationMap } from '../account-list-light-tree.interface';

@Directive({
  selector: '[ltlccAccountListLightTreeLeftPadding]',
  host: {
    '[style.padding-left.px]': 'indentation',
  },
})
export class AccountListLightTreeLeftPaddingDirective {
  @Input('ltlccAccountListLightTreeLeftPadding') indentationMap: Map<string, IndentationMap>;
  @Input() id: string;

  get indentation(): number {
    if (!this.indentationMap || !this.id) {
      return 0;
    }
    const indent = this.indentationMap.get(this.id)?.padding ?? 0;
    return indent;
  }
}
