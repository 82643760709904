import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HazmatItemResponse } from '@ltlc/api';
import { ErrorHandlerService } from '@ltlc/core';
import * as merge from 'deepmerge';
import { Observable } from 'rxjs';
import { finalize, map, startWith, take } from 'rxjs/operators';
import { HazmatDropdownService } from './hazmat-dropdown.service';
import { HazmatApiResponseHelper } from './helpers/hazmat-api-response.helper';
import { HazmatItem } from './interfaces/hazmat.interface';

@Component({
  selector: 'ltlcc-hazmat-dropdown',
  templateUrl: './hazmat-dropdown.component.html',
  styleUrls: ['./hazmat-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HazmatDropdownComponent implements OnInit {
  @Input() isRequired: boolean;
  @Output() hazmatSelected: EventEmitter<HazmatItem> = new EventEmitter(null);

  filteredHazmatOptions$: Observable<HazmatItem[]>;
  hazmatControl = new FormControl();
  hazmatList: HazmatItem[];
  loading: boolean;

  constructor(
    private hazmatDropdownService: HazmatDropdownService,
    private errorHandlerService: ErrorHandlerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fillHazmatDropdown();
  }

  handleHazmatSelection(choice: MatAutocompleteSelectedEvent): void {
    const hazmatValue: HazmatItem = choice.option.value;
    if (hazmatValue) {
      this.hazmatSelected.emit(hazmatValue);
    }
  }

  displayHazmat(hazmat: HazmatItem): string {
    return hazmat?.display;
  }

  private fillHazmatDropdown(): void {
    this.hazmatDropdownService
      .getHazmatList()
      .pipe(
        take(1),
        this.errorHandlerService.snackbarOnError(),
        finalize(() => {
          this.loading = false;
          this.cd.markForCheck();
        })
      )
      .subscribe((hazmatItemList: HazmatItemResponse[]) => {
        this.hazmatList = HazmatApiResponseHelper.transformHazmatResponse(hazmatItemList);
        this.updateFilteredHazmatOptions();
      });
  }

  private updateFilteredHazmatOptions(): void {
    this.filteredHazmatOptions$ = this.hazmatControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this.filterHazmatOptions(value))
    );
  }

  private filterHazmatOptions(value: string): HazmatItem[] {
    const showFullList = value === '';
    const searchWord = typeof value === 'string' ? value.toLowerCase().trim() : '';
    const hazmatList = merge.all([this.hazmatList]) as HazmatItem[];

    if (showFullList) {
      return hazmatList;
    } else {
      const filteredList = hazmatList.filter((item) => {
        return (
          item.unnaCd.toLowerCase().indexOf(searchWord) >= 0 || item.description.toLowerCase().indexOf(searchWord) >= 0
        );
      });
      return filteredList;
    }
  }
}
