<mat-icon class="material-icons-outlined ltlcc-AccountRequestsSentDialog-checkIcon">check_circle</mat-icon>
<h1 mat-dialog-title>
  {{
    (requestsSent > 1 ? 'accountList.addAccount.requestsHaveBeenSent' : 'accountList.addAccount.requestHasBeenSent')
      | translate
  }}
</h1>
<div mat-dialog-content>
  <span>
    {{ 'accountList.addAccount.salesRepContact' | translate }}
  </span>
</div>
<div mat-dialog-actions>
  <button color="primary" mat-button mat-dialog-close>
    {{ 'defaults.close' | translate | titlecase }}
  </button>
</div>
