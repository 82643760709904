import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AnalyticsData } from './consts/analytics.const';

export abstract class XpoTrackingAnalyticsService {
  initialized: boolean;

  constructor(protected document: Document) {}

  initialize(): Observable<boolean> {
    if (this.initialized) {
      return of(false);
    }

    if (!this.trackingId()) {
      return of(false);
    }

    return this.insertScript().pipe(
      tap(() => {
        this.finalizeScriptInsertion();
        this.initialized = true;
      }),
      tap((success) => {
        if (success) {
          this.initialConfigurationSetup();
        }
      })
    );
  }

  protected appendToHead(element: HTMLElement): void {
    this.document.getElementsByTagName('head')[0].appendChild(element);
  }

  abstract trackEvent(eventName: string, username: string, eventValue?: Object): void;
  abstract trackPageView(pagePath: string, user: AnalyticsData, siteCode: string, area?: string): void;

  protected abstract insertScript(): Observable<boolean>;
  protected abstract finalizeScriptInsertion(): void;
  protected abstract initialConfigurationSetup(): void;
  protected abstract trackingId(): string;
}
