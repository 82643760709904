export enum AccessorialsCodes {
  // Delivery
  AFTER_BUSINESS_HOURS_DELIVERY = 'DAH',
  COLLECT_ON_DELIVERY = 'COD',
  CONSTRUCTION_UTILITY_DELIVERY = 'CSD',
  CONTAINER_STATION_DELIVERY = 'DCF',
  EXHIBITION_DELIVERY = 'PED',
  IN_BOND_FREIGHT = 'CIB',
  REMOVAL_OF_PALLET_DEBRIS = 'RPD',
  IN_BOND_TIR_CARNET = 'IBC',
  DESTINATION_LIMITED_ACCESS = 'DLA',
  INSIDE_DELIVERY = 'DID',
  LIFTGATE_DELIVERY = 'DLG',
  MINE_GOVT_AIRPORT_DELIVERY = 'DMS',
  NOTIFICATION_PRIOR_DELIVERY = 'DNC',
  PIERS_WHARVES_LOADING_DELIVERY = 'DPW',
  RESIDENTIAL_DELIVERY = 'RSD',
  SATURDAY_HOLIDAY_DELIVERY = 'DSH',
  SORTING_SEGREGATING_DELIVERY = 'SRT',
  STORAGE_AT_DESTINATION = 'DST',
  VOLUME_SHIPMENT_FUEL_SURCHARGE = 'TLF',
  // Pickup
  MINE_GOVT_AIRPORT_PICKUP = 'OMS',
  CONSTRUCTION_UTILITY_PICKUP = 'OCS',
  ORIGIN_LIMITED_ACCESS = 'OLA',
  CONTAINER_STATION_PICKUP = 'OCF',
  EXHIBITION_PICKUP = 'PEO',
  INSIDE_PICKUP = 'OIP',
  ORIGIN_LIFTGATE = 'OLG',
  PIERS_WHARVES_LOADING_PICKUP = 'OPW',
  RESIDENTIAL_PICKUP = 'RSO',
  SATURDAY_HOLIDAY_PICKUP = 'OSH',
  SORTING_SEGREGATING_PICKUP = 'OSS',
  ZHM_HAZMAT = 'ZHM',
  SINGLE_SHIPMENT = 'SSC',
  BLIND_SHIPMENT = 'BLS',
  // Misc
  EXCESS_LIABILITY_COVERAGE = 'EVC',
  EXCESSIVE_LENGTH = 'ELS',
  FUEL_SURCHARGE = 'FSC',
  HIGH_COST_DELIVERY_REGION_SRCHG = 'HCD',
  CUBIC_CAPACITY = 'XCC',
  // Premium
  PROTECT_FROM_FREEZING = 'XFZ',
  APPOINTMENT = 'APT',
  GUARANTEED = 'GUR',
  RAPID_REMOTE_SERVICE = 'RRS',
  GUARANTEED_BY_NOON = 'G12',
  BORDER_CROSSING_SERVICE = 'DRA',
  LINEALFEET = 'XLF',
  MBD = 'MBD',
  MBS = 'MBS',
  MABD = 'MABD',

  // Non-Services these are not real services to map but a condition for other services
  HAZMAT = 'HMT',
  HEAVY = 'HEAVY', // 20K+ this value will come from BUSINESS_CONSTRAINTS.ACCESSORIALS_HEAVY_WEIGHT_THRESHOLD
  SPOT_QUOTE_RATE = 'SQR',
  /*
 /this is not used for XPO REQUESTS just for know if it's a volumePricing Quote.
  DO NOT USE IT GHOST_ACCESSORIALS FOR ANYTHING, Except for CONDITIONS.
 */
  VOLUME_SHIPMENT_CAPPED_PRICE = 'VSCP',
  PAYMENT_TRANSACTION_FEE = 'PTF',

  //HAWAII
  INSIDE_PKUP_DLVRY_TRUCK_LEVEL = 'WHI',
  INSIDE_PKUP_DLVRY_NON_TRUCK_LEVEL = 'WHN',
  HAWAII_OCEAN_CHARGE = 'WHO',
}

export enum AccessorialType {
  ORIGIN = 'origin',
  DESTINATION = 'destination',
  BOTH = 'both',
  PREMIUM = 'premium',
}
