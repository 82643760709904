import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FooterLink, ShellActions, UserAccountMenu } from '../../interfaces';

@Component({
  selector: 'ltlcc-shell-user-account',
  templateUrl: './shell-user-account.component.html',
  styleUrls: ['./shell-user-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellUserAccountComponent {
  initials: string;
  links: FooterLink[];
  displayName: string;
  supportingData: string[];
  actions: ShellActions[];

  @Input()
  set configUser(configUser: UserAccountMenu) {
    this._configUser = configUser;
    this.initials = `${configUser?.firstName?.charAt(0) + configUser?.lastName?.charAt(0)}`.toUpperCase();
    this.displayName = `${configUser?.firstName ?? ''} ${configUser?.lastName ?? ''}`;
    this.links = configUser?.links ?? [];
    this.supportingData = configUser?.supportingData ?? [];
    this.actions = configUser?.actions ?? [];
  }
  get configUser(): UserAccountMenu {
    return this._configUser;
  }
  private _configUser: UserAccountMenu;
}
