import { FormArray } from '@angular/forms';
import * as deepMerge from 'deepmerge';
import * as deepEqual from 'fast-deep-equal/es6';

export class ArrayHelper {
  static removeDuplicates(arr: string[]): string[] {
    return [...new Set(arr)];
  }

  static isEqual(prev: any = {}, after: any = {}): boolean {
    return deepEqual(prev, after);
  }

  static deepMerge<T = any>(objectArray: Partial<T>[]): T {
    return <any>deepMerge.all(objectArray);
  }

  static splitCommaSeparatedStringIntoArray(array: string | string[]): string[] {
    if (Array.isArray(array)) {
      return array;
    }
    if (typeof array === 'string') {
      return array.split(/\s*[,\n]+\s*/).filter((item) => !!item);
    }
    return [];
  }

  static clearFormArray(formArray: FormArray): void {
    formArray.clear();
  }

  static flattenMatrix(array): any[] {
    return [].concat.apply([], array);
  }
}
