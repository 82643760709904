import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Directive, Inject, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroupTemplateService } from '../services/form-group-template.service';

@UntilDestroy()
@Directive({
  selector: '[ltlccFormGroupTemplate]',
  providers: [FormGroupTemplateService],
})
export class FormGroupTemplateDirective implements OnInit, OnDestroy {
  @Input('accountSelectors') accountSelectors: any[]; // TODO: merge the account picker into this lib to avoid circular dependency and use appropriate component name (AccountDetailsComponent)

  constructor(
    private readonly formGroupDirective: FormGroupDirective,
    private readonly viewRef: ViewContainerRef,
    private formGroupTemplateService: FormGroupTemplateService,
    @Inject(DOCUMENT) public document: Document,
    private cd: ChangeDetectorRef
  ) {}

  get form(): FormGroup {
    return this.formGroupDirective.form;
  }

  ngOnInit(): void {
    this.formGroupDirective.ngSubmit.pipe(untilDestroyed(this)).subscribe(() => {
      this.formGroupTemplateService.updateFormSubmitted(true);
      this.markErrors();
    });
  }

  ngOnDestroy(): void {
    this.formGroupTemplateService.updateFormSubmitted(false);
  }

  get formElement(): HTMLFormElement {
    return this.viewRef.element.nativeElement;
  }

  markErrors(): void {
    this.form.markAllAsTouched();
    this.formGroupTemplateService.expandInvalidAccountSelectors(this.accountSelectors);
    this.formGroupTemplateService.showErroredFieldsInGroup(this.form, this.formElement);
    this.cd.markForCheck();
  }
}
