import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalErrorHandlerDialogComponent } from './global-error-handler-dialog/global-error-handler-dialog.component';
import { GlobalErrorHandlerService } from './global-error-handler.service';

@NgModule({
  declarations: [GlobalErrorHandlerDialogComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule, MatLegacyDialogModule],
  exports: [GlobalErrorHandlerDialogComponent],
})
export class GlobalErrorHandlerDialogModule {
  static globalProviders = [{ provide: ErrorHandler, useClass: GlobalErrorHandlerService }];
}
