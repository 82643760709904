import { Component, ElementRef, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ltlcc-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoButtonComponent {
  @HostBinding('class') classes = 'ltlcc-InfoButton';

  constructor(private elementRef: ElementRef) {}

  clickParent(event: MouseEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.elementRef.nativeElement.click();
  }
}
