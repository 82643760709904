export enum CountryCode {
  UNITED_STATES = 'US',
  CANADA = 'CA', // sometimes CN but should be homologated to CA everywhere
  MEXICO = 'MX',
}

export enum LegacyCountryCode {
  LEGACY_CANADA = 'CN',
}

export enum CurrencyName {
  USD = 'USD',
  MXP = 'MXP',
  CAD = 'CAD',
}

// TODO: Feel free to extend according to ISO 639-1
// TODO: Check if Partner Master preferred language requires language preference in accordance with ISO 639-1 or by en, fr, etc. If, delete this enum
// https://www.localeplanet.com/icu/iso639.html
// export enum StandardLanguageCode {
//   en = 'en-US',
//   fr = 'fr-CA',
//   es = 'es-MX',
// }

// TODO: Feel free to extend according to ISO 639-1
// https://www.localeplanet.com/icu/iso639.html
export enum StandardLanguageCode {
  en = 'en',
  fr = 'fr',
  es = 'es',
}
