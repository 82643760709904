export type OrderArray = 'asc' | 'desc';

export class ArraySortHelper {
  static sortBy(key: string, cb: any) {
    if (!cb) {
      cb = () => 0;
    }
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb(a, b));
  }

  static sortByDesc(key: string, cb: any) {
    if (!cb) {
      cb = () => 0;
    }

    return (b, a) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb(b, a));
  }

  static orderByKeys(keys: string[], orders: OrderArray[]) {
    let cb: any = () => 0;
    keys.reverse();
    orders.reverse();

    for (const [i, key] of keys.entries()) {
      const order: OrderArray = orders[i];

      if (order === 'asc') {
        cb = this.sortBy(key, cb);
      } else if (order === 'desc') {
        cb = this.sortByDesc(key, cb);
      } else {
        throw new Error(`Unsupported order "${order}"`);
      }
    }
    return cb;
  }

  static orderBy(list: any[], keys: string[], orders: OrderArray[] = []): any[] {
    if (!Array.isArray(list)) {
      return [];
    }

    while (keys.length > orders.length) {
      orders.push('asc');
    }
    return list?.concat().sort(this.orderByKeys(keys, orders));
  }
}
