import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../alerts';

@Component({
  selector: 'ltlcc-copyable',
  template: `
    <ng-content></ng-content>
    <mat-icon
      [matTooltip]="'directives.copyToClipboard' | translate"
      matTooltipPosition="below"
      role="button"
      class="ltlcc-Copyable-icon"
      (click)="copy($event)"
      >content_copy</mat-icon
    >
  `,
  styleUrls: ['./copyable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CopyableComponent {
  @HostBinding('class') classes = 'ltlcc-Copyable';
  @Input() toCopy?: string;
  @Input() copiedText?: string;

  constructor(
    private alertService: AlertService,
    private translate: TranslateService,
    private el: ElementRef<HTMLElement>,
    private clipboard: Clipboard
  ) {}

  copy($event: MouseEvent): void {
    $event.stopPropagation();

    const element = this.el.nativeElement;
    const content = element.childNodes[0].textContent;

    this.toCopy = this.toCopy ? this.toCopy : content;
    this.copiedText = this.copiedText ?? this.translate.instant('directives.copied', { content: this.toCopy });

    this.alertService.showGeneralMessage({ body: this.copiedText, status: 'success' });
    this.clipboard.copy(this.toCopy);
  }
}
