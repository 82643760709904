import { Directive } from '@angular/core';

@Directive({
  selector: '[xpoIconSmall]',
  host: { class: 'ltlcc-XpoIcon--small' },
})
export class XpoIconSmall {}

@Directive({
  selector: '[xpoIconLarge]',
  host: { class: 'ltlcc-XpoIcon--large' },
})
export class XpoIconLarge {}

@Directive({
  selector: '[xpoIconInverted]',
  host: { class: 'ltlcc-XpoIcon--inverted' },
})
export class XpoIconInverted {}
