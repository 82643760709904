<mat-form-field appearance="outline" class="ltlcc-Search-matFormField">
  <input
    [formControl]="searchControl"
    [placeholder]="placeholder"
    type="text"
    matInput
    #input
    (keyup.enter)="searchWord.emit(searchControl.value)"
    autocomplete="off"
    (blur)="handleBlur($event)"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <button *ngIf="!hideClearButton" mat-icon-button matPrefix (click)="searchWord.emit(searchControl.value)">
    <mat-icon>search</mat-icon>
  </button>
  <!-- We want to show the serach icon as the suffix when the clear  button is not theres -->
  <button mat-icon-button matSuffix (click)="hideClearButton ? searchWord.emit(searchControl.value) : clear()">
    <mat-icon> {{ hideClearButton ? 'search' : 'cancel' }} </mat-icon>
  </button>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
</mat-form-field>
