import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagePickerFooterComponent } from './language-picker-footer.component';
import { LanguagePickerFooterPipe } from './language-picker-footer.pipe';

@NgModule({
  declarations: [LanguagePickerFooterComponent, LanguagePickerFooterPipe],
  imports: [CommonModule, MatIconModule, MatMenuModule, TranslateModule],
  exports: [LanguagePickerFooterComponent],
})
export class LanguagePickerFooterModule {}
