import { Directive, HostBinding, Input, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GridApi, GridColumnsChangedEvent, GridOptions } from 'ag-grid-community';
import * as deepmerge from 'deepmerge';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, delay, retryWhen, take } from 'rxjs/operators';
import { ErrorHandlerService } from '../../../services';
import { AgGridLoadingOverlayComponent } from '../ag-grid-loading-overlay/ag-grid-loading-overlay.component';
import {
  AgGridNoRowsOverlayComponent,
  AgGridNoRowsOverlayComponentParams,
} from '../ag-grid-no-rows-overlay/ag-grid-no-rows-overlay.component';
import { AgGridRowLoadingComponent } from '../ag-grid-row-loading/ag-grid-row-loading.component';
import { AgGridDataSource, AgGridDataSourceParamsSortExpression } from '../interfaces/ag-grid-data-source.model';

@UntilDestroy()
@Directive({ selector: '[ltlccAgGridOptions]' })
export class ConnectAgGridOptionsDirective implements OnDestroy {
  private readonly loadingColId = 'loader';

  private showError$ = new BehaviorSubject<boolean>(false);
  private rowDataSubscription: Subscription;
  private gridApi: GridApi;
  private cachedData: any[];
  private updateDataSourceOnGridLoad: boolean = false;
  private currentDataSource: AgGridDataSource;
  private getDataSubscription: Subscription;
  private loadingColDef: ColDef = {
    colId: this.loadingColId,
    headerName: '',
    maxWidth: 0,
    sortable: false,
    cellRendererFramework: AgGridRowLoadingComponent,
  };
  private readonly defaultGridOptions: GridOptions = {
    enableCellTextSelection: true,
    defaultColDef: { sortable: true, unSortIcon: true, resizable: true },
    loadingOverlayComponentFramework: AgGridLoadingOverlayComponent,
    noRowsOverlayComponentFramework: AgGridNoRowsOverlayComponent,
    noRowsOverlayComponentParams: <AgGridNoRowsOverlayComponentParams>{
      icon: 'insert_drive_file',
      title: 'No Results Found',
      getShowError$: () => this.showError$,
      retrySetData: () => {
        if (this.rowData$) {
          this.rowData$ = this.rowData$;
        } else {
          this.setDataSource();
        }
      },
    },
    animateRows: true,
    immutableData: true,
    columnDefs: [this.loadingColDef],
  };

  @Input('ltlccAgGridOptions')
  get gridOptions(): GridOptions {
    return this._gridOptions;
  }
  set gridOptions(v: GridOptions) {
    this._gridOptions = v = deepmerge.all([this.defaultGridOptions, v ?? {}]);

    this.hostGrid.gridOptions = this._gridOptions;
  }
  private _gridOptions: GridOptions;

  @Input('ltlccAgGridDataSource')
  set dataSource(getData: AgGridDataSource) {
    if (!getData) {
      return;
    }

    this.currentDataSource = getData;

    if (!this.gridApi) {
      this.updateDataSourceOnGridLoad = true;
      return;
    }

    this.setDataSource();
  }

  @Input('ltlccAgGridRowData')
  set rowData$(data: Observable<any[]>) {
    this._rowData$ = data;

    if (this.rowDataSubscription) {
      this.rowDataSubscription.unsubscribe();
      this.rowDataSubscription = null;
    }
    if (this.rowData$) {
      this.rowDataSubscription = this.rowData$
        .pipe(
          untilDestroyed(this),
          catchError((err) => {
            this.showError$.next(true);
            return of([]);
          })
        )
        .subscribe((data) => {
          if (this.gridApi) {
            this.gridApi.setRowData(data);
            this.gridApi.refreshCells({ force: true });
          } else {
            this.cachedData = data;
          }
        });
    }
  }
  get rowData$(): Observable<any[]> {
    return this._rowData$;
  }
  private _rowData$: Observable<any[]>;

  @HostBinding('class.ag-theme-material')
  addMaterialStyleClass = true;

  constructor(private hostGrid: AgGridAngular, private errorHandlerService: ErrorHandlerService) {
    this.hostGrid.gridReady.pipe(take(1), untilDestroyed(this)).subscribe((event) => {
      this.gridApi = event.api;
      this.handleColumnsChanging();
      this.handleCachedData();
    });
  }

  ngOnDestroy(): void {
    this.clearGetDataSourceSubscription();
  }

  private handleCachedData(): void {
    if (this.updateDataSourceOnGridLoad) {
      this.setDataSource();
      this.updateDataSourceOnGridLoad = false;
    }

    if (this.cachedData) {
      this.gridApi.setRowData(this.cachedData);
      this.gridApi.refreshCells({ force: true });
      this.cachedData = undefined;
    }
  }

  private generateSortExpressionFromSortModel(agGridSortModel: any[]): AgGridDataSourceParamsSortExpression[] {
    return (agGridSortModel || []).map((sortModelCol) => {
      return { column: sortModelCol.colId, direction: sortModelCol.sort };
    });
  }

  private setDataSource() {
    this.clearGetDataSourceSubscription();
    this.gridApi.hideOverlay();

    this.gridApi.setDatasource({
      getRows: (params) => {
        if (!this._gridOptions.cacheBlockSize) {
          console.error('ConnectAgGridOptionsDirective: please provide a cacheBlockSize');
        }

        const pageNumber = Math.max(params.startRow / this._gridOptions.cacheBlockSize + 1, 1);

        this.getDataSubscription = this.currentDataSource({
          startRow: params.startRow,
          endRow: params.endRow,
          filterModel: params.filterModel,
          sortModel: params.sortModel,
          pageNumber,
          sortExpression: this.generateSortExpressionFromSortModel(params.sortModel),
        })
          .pipe(
            untilDestroyed(this),
            // Retry twice with 1 second delay between calls, this is a patch to resolve some werid api issues with the `
            retryWhen((errors) => errors.pipe(delay(1000), take(2))),
            catchError((err) => {
              params.failCallback();

              // If the first page returns an error, show error message and let the user retry, else, if the user
              // gets an error while paging, let ag-grid handle it by calling the fail callback
              if (pageNumber === 1) {
                this.showError$.next(true);
                this.gridApi.showNoRowsOverlay();
              }
              return throwError(err);
            }),
            this.errorHandlerService.snackbarOnError(),
            take(1)
          )
          .subscribe((data) => {
            if (!data.rows?.length) {
              this.gridApi.showNoRowsOverlay();
            }
            params.successCallback(data.rows, data.resultCount ?? 0);
          });
      },
    });
  }

  private clearGetDataSourceSubscription(): void {
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
      this.getDataSubscription = undefined;
    }
  }

  private handleColumnsChanging(): void {
    const handler = (columnDefs: (ColDef | ColGroupDef)[]) => {
      if (!columnDefs.find((v: ColDef) => v.colId === this.loadingColId)) {
        columnDefs.unshift(this.defaultGridOptions.columnDefs[0]);
        this.gridOptions.api.setColumnDefs(columnDefs);
      }
    };

    handler(this.gridOptions.columnDefs);

    this.gridApi.addEventListener('gridColumnsChanged', (event: GridColumnsChangedEvent) => {
      handler(event.api.getColumnDefs());
    });
  }
}
