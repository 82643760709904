<div class="ltlc-AccountLightPicker">
  <mat-accordion class="ltlc-AccountLightPicker-accordion" displayMode="flat">
    <mat-expansion-panel
      [@bodyExpansion]="expansionState$ | async"
      hideToggle
      class="ltlc-AccountLightPicker-accordion-panel"
      [expanded]="expanded$ | async"
      (afterExpand)="panelExpand(true)"
      (afterCollapse)="panelExpand(false)"
      ltlcDisableToggle
    >
      <mat-expansion-panel-header
        collapsedHeight="auto"
        expandedHeight="auto"
        class="ltlc-AccountLightPicker-accordion-panel-header"
        (click)="handleClickHeader($event)"
      >
        <mat-panel-title
          class="ltlc-AccountLightPicker-accordion-panel-header-title"
          [class.showHide]="hintStatus === 'showHide'"
        >
          <ltlcc-account-list-tree-autocomplete-wrapper
            [dataCy]="dataCy + '-accountAutocomplete'"
            class="ltlc-AccountLightPicker-autocomplete"
            (selectedNode)="selectedNode($event)"
            [searchControl]="searchControl"
            [label]="label"
            [nodes]="nodes"
            [configList]="configList"
            [hint]="hint$ | async"
            [placeholder]="'accountListLight.placeholderEnterOptions' | translate"
            [required]="pickRequired$ | async"
          >
          </ltlcc-account-list-tree-autocomplete-wrapper>
        </mat-panel-title>
        <mat-panel-description class="ltlc-AccountLightPicker-accordion-panel-header-description">
          <ng-content></ng-content>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ltlcc-account-form
        *ngIf="expanded$ | async"
        matExpansionPanelContent
        [fieldConfig]="fieldConfig"
        [dataCy]="dataCy + '-accountDetailForm'"
      ></ltlcc-account-form>

      <div align="end" *ngIf="accountDetailsForm?.enabled">
        <button
          *ngIf="enableAddressUpdate"
          mat-button
          color="accent"
          (click)="updateAddress()"
          type="button"
          [disabled]="isLoading || !isDirty"
        >
          {{ 'accountList.updateAddress' | translate }}
        </button>

        <button
          *ngIf="enableAddressSave"
          mat-button
          color="accent"
          (click)="saveAsNewAddress()"
          type="button"
          [disabled]="isLoading || !isDirty"
        >
          {{ 'accountList.saveAsNewAddress' | translate }}
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
