<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }} {{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <mat-select
    multiple
    [required]="required"
    [formControl]="control"
    (openedChange)="openedChange($event)"
    (selectionChange)="selectAccessorialChange()"
    dataCy="select-accessorials-pur"
  >
    <mat-option
      *ngFor="let accessorial of accessorials"
      [value]="accessorial.value"
      [disabled]="accessorial.disabled"
      [attr.data-cy]="'SpecialService-' + accessorial.value"
    >
      {{ accessorial.label }}
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="hint"> {{ hint }}</mat-hint>
</mat-form-field>
