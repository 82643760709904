import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorMessageComponent } from './form-error-message.component';
import { FormErrorMessagePipe } from './form-error-message.pipe';

@NgModule({
  declarations: [FormErrorMessageComponent, FormErrorMessagePipe],
  imports: [CommonModule, TranslateModule, MatIconModule],
  exports: [FormErrorMessageComponent, FormErrorMessagePipe],
})
export class FormErrorMessageModule {}
