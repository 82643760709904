import { Provider } from '@angular/core';
import { of } from 'rxjs';
import { HaveblueService, SearchPickupRequestApiRequest } from '../haveblue';

export const MockHaveblueService: Provider = {
  provide: HaveblueService,
  useValue: {
    haveblueUrl: '',
    getShipmentStatusDetailHb: (proNumber: string) => of({}),
    getQuotes: (accountId: string | number) => of({}),
    searchShipments: (request) => of(null),
    getShipmentNotification: (proNumber: string) => of({}),
    getPickupRequests: (request: SearchPickupRequestApiRequest) => of({}),
  },
};
