export enum ActionEntityTypes {
  Unknown = 0,
  RateQuote = 1,
  PickupRequest = 3,
  ClaimRequest = 4,
  Billing = 5,
  Invoice = 6,
  Shipment = 100,
  User = 101,
  BillOfLading = 102,
  SmartFormsRateQuote = 1001,
}
