import { LOCALE_ID, NgModule } from '@angular/core';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';

@NgModule({
  exports: [RecaptchaModule],
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID],
    },
  ],
})
export class InitRecaptchaModule {}
