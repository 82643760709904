<mat-icon class="ltlc-ConfirmationDialog-dialogContent-icon" *ngIf="data.titleIcon">{{ data.titleIcon }}</mat-icon>

<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<div mat-dialog-content class="ltlc-ConfirmationDialog-dialogContent">
  <span [innerHTML]="data.body" class="ltlc-ConfirmationDialog-dialogContent-text"></span>
</div>

<div mat-dialog-actions>
  <button
    mat-flat-button
    [attr.data-cy]="'dialogButton-confirm'"
    [color]="data.actionColor"
    [disabled]="isLoading"
    (click)="confirm()"
  >
    <div class="ltlc-ConfirmationDialog-buttonContent">
      {{ data.actionText }}
      <div class="ltlc-ConfirmationDialog-spinnerWrapepr" *ngIf="isLoading">
        <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
      </div>
    </div>
  </button>
  <button *ngIf="!data.hideCancelButton" mat-flat-button mat-dialog-close dataCy="dialogButton-cancel">
    {{ data?.cancelText || ('defaults.cancel' | translate) }}
  </button>
</div>
