import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupName } from '@angular/forms';
import { OptionSelect } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Interval, set, startOfDay } from 'date-fns';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { TimeOptionsHelper } from '../../../helpers/time-options.helper';
import { DockingIntervalGroup } from './docking-interval.form';

@UntilDestroy()
@Component({
  selector: 'ltlcc-docking-interval',
  templateUrl: './docking-interval.component.html',
  styleUrls: ['./docking-interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupName }],
  host: {
    class: 'ltlc-Utils-grid ltlc-Utils-grid-templateColumn2',
  },
  encapsulation: ViewEncapsulation.None,
})
export class DockingIntervalComponent implements OnInit {
  //TODO: Consider this implementation with UI and BA's. What should be the logic and should it be a more generic time interval picker?
  readyTimeOptions: OptionSelect[] = TimeOptionsHelper.timeOptionsUS(
    {
      start: set(startOfDay(new Date()), { hours: 6 }),
      end: set(startOfDay(new Date()), { hours: 18 }),
    },
    15
  ); // TODO: Where is 6 AM defined as the start time? For BOL it starts at 1 PM

  dockTimeOptions: OptionSelect[] = [];
  dockingIntervalGroup: DockingIntervalGroup;

  @Input()
  set intervalOptions(interval: Interval) {
    this.readyTimeOptions = TimeOptionsHelper.timeOptionsUS(interval, 15);
  }

  constructor(@Optional() private formGroupName: FormGroupName) {}

  ngOnInit(): void {
    if (this.formGroupName?.control) {
      this.dockingIntervalGroup = this.formGroupName.control;
    } else {
      this.dockingIntervalGroup = new DockingIntervalGroup();
    }
    if (!this.dockingIntervalGroup.contains('open')) {
      this.dockingIntervalGroup.addControl('open', new FormControl());
    }
    if (!this.dockingIntervalGroup.contains('close')) {
      this.dockingIntervalGroup.addControl('close', new FormControl());
    }

    this.dockingIntervalGroup
      .get('open')
      .valueChanges.pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        startWith(this.dockingIntervalGroup.get('open').value)
      )
      .subscribe((readyTime) => {
        this.fillDockTimeOptions(readyTime);
      });
  }

  private fillDockTimeOptions(readyTime: string): void {
    if (typeof readyTime !== 'string') {
      return;
    }

    const endTime = this.readyTimeOptions[this.readyTimeOptions.length - 1].value;
    const [hours, minutes]: number[] = readyTime.split(':').map((v) => Number(v));
    const interval = {
      start: set(startOfDay(new Date()), { hours, minutes }),
      end: set(startOfDay(new Date()), { hours: Number(endTime.split(':')[0]) }),
    };
    const dockTimeOptions = TimeOptionsHelper.timeOptionsUS(interval, 15);
    this.dockTimeOptions = dockTimeOptions.slice(1); // Remove start option
  }
}
