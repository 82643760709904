import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DimensionUnit } from '@ltlc/api';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';
import { OptionSelect } from '../base/interfaces/form-controls.interface';

@Component({
  selector: 'ltlcc-select-dimension-unit',
  templateUrl: './select-dimension-unit.component.html',
  styleUrls: ['./select-dimension-unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDimensionUnitComponent extends FormControlsBaseComponent {
  dimensionUnits: OptionSelect[] = [
    {
      label: this.translate.instant('units.inches'),
      value: DimensionUnit.inch,
    },
    {
      label: this.translate.instant('units.feet'),
      value: DimensionUnit.feet,
    },
    {
      label: this.translate.instant('units.meters'),
      value: DimensionUnit.meters,
    },
  ];
}
