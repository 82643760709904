import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ListOrderByPipe } from './list-order-by.pipe';
import { ProNumberPipe } from './pro-number.pipe';
import { PurRolePipe } from './pur-role.pipe';
import { SpecialEquipmentPipe } from './special-equipment.pipe';

const pipes = [ProNumberPipe, SpecialEquipmentPipe, PurRolePipe, ListOrderByPipe];
@NgModule({
  declarations: [...pipes],
  imports: [CommonModule, TranslateModule],
  exports: [...pipes],
})
export class LTLConnectPipeModule {}
