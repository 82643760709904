// TODO: Remove from core
export enum US_STATES_CODES_LITERALS {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export interface IUSStatesOption {
  code: US_STATES_CODES_LITERALS;
  name: string;
}

export const US_STATES_OPTIONS: IUSStatesOption[] = [
  { code: US_STATES_CODES_LITERALS.AL, name: 'US_STATE_ALABAMA' },
  { code: US_STATES_CODES_LITERALS.AK, name: 'US_STATE_ALASKA' },
  { code: US_STATES_CODES_LITERALS.AZ, name: 'US_STATE_ARIZONA' },
  { code: US_STATES_CODES_LITERALS.AR, name: 'US_STATE_ARKANSAS' },
  { code: US_STATES_CODES_LITERALS.CA, name: 'US_STATE_CALIFORNIA' },
  { code: US_STATES_CODES_LITERALS.CO, name: 'US_STATE_COLORADO' },
  { code: US_STATES_CODES_LITERALS.CT, name: 'US_STATE_CONNECTICUT' },
  { code: US_STATES_CODES_LITERALS.DC, name: 'US_STATE_DISTRICT_OF_COLUMBIA' },
  { code: US_STATES_CODES_LITERALS.DE, name: 'US_STATE_DELAWARE' },
  { code: US_STATES_CODES_LITERALS.FL, name: 'US_STATE_FLORIDA' },
  { code: US_STATES_CODES_LITERALS.GA, name: 'US_STATE_GEORGIA' },
  { code: US_STATES_CODES_LITERALS.HI, name: 'US_STATE_HAWAII' },
  { code: US_STATES_CODES_LITERALS.ID, name: 'US_STATE_IDAHO' },
  { code: US_STATES_CODES_LITERALS.IL, name: 'US_STATE_ILLINOIS' },
  { code: US_STATES_CODES_LITERALS.IN, name: 'US_STATE_INDIANA' },
  { code: US_STATES_CODES_LITERALS.IA, name: 'US_STATE_IOWA' },
  { code: US_STATES_CODES_LITERALS.KS, name: 'US_STATE_KANSAS' },
  { code: US_STATES_CODES_LITERALS.KY, name: 'US_STATE_KENTUCKY' },
  { code: US_STATES_CODES_LITERALS.LA, name: 'US_STATE_LOUISIANA' },
  { code: US_STATES_CODES_LITERALS.ME, name: 'US_STATE_MAINE' },
  { code: US_STATES_CODES_LITERALS.MD, name: 'US_STATE_MARYLAND' },
  { code: US_STATES_CODES_LITERALS.MA, name: 'US_STATE_MASSACHUSETTS' },
  { code: US_STATES_CODES_LITERALS.MI, name: 'US_STATE_MICHIGAN' },
  { code: US_STATES_CODES_LITERALS.MN, name: 'US_STATE_MINNESOTA' },
  { code: US_STATES_CODES_LITERALS.MS, name: 'US_STATE_MISSISSIPPI' },
  { code: US_STATES_CODES_LITERALS.MO, name: 'US_STATE_MISSOURI' },
  { code: US_STATES_CODES_LITERALS.MT, name: 'US_STATE_MONTANA' },
  { code: US_STATES_CODES_LITERALS.NE, name: 'US_STATE_NEBRASKA' },
  { code: US_STATES_CODES_LITERALS.NV, name: 'US_STATE_NEVADA' },
  { code: US_STATES_CODES_LITERALS.NH, name: 'US_STATE_NEW_HAMPSHIRE' },
  { code: US_STATES_CODES_LITERALS.NJ, name: 'US_STATE_NEW_JERSEY' },
  { code: US_STATES_CODES_LITERALS.NM, name: 'US_STATE_NEW_MEXICO' },
  { code: US_STATES_CODES_LITERALS.NY, name: 'US_STATE_NEW_YORK' },
  { code: US_STATES_CODES_LITERALS.NC, name: 'US_STATE_NORTH_CAROLINA' },
  { code: US_STATES_CODES_LITERALS.ND, name: 'US_STATE_NORTH_DAKOTA' },
  { code: US_STATES_CODES_LITERALS.OH, name: 'US_STATE_OHIO' },
  { code: US_STATES_CODES_LITERALS.OK, name: 'US_STATE_OKLAHOMA' },
  { code: US_STATES_CODES_LITERALS.OR, name: 'US_STATE_OREGON' },
  { code: US_STATES_CODES_LITERALS.PA, name: 'US_STATE_PENNSYLVANIA' },
  { code: US_STATES_CODES_LITERALS.PR, name: 'US_STATE_PUERTO_RICO' },
  { code: US_STATES_CODES_LITERALS.RI, name: 'US_STATE_RHODE_ISLAND' },
  { code: US_STATES_CODES_LITERALS.SC, name: 'US_STATE_SOUTH_CAROLINA' },
  { code: US_STATES_CODES_LITERALS.SD, name: 'US_STATE_SOUTH _DAKOTA' },
  { code: US_STATES_CODES_LITERALS.TN, name: 'US_STATE_TENNESSEE' },
  { code: US_STATES_CODES_LITERALS.TX, name: 'US_STATE_TEXAS' },
  { code: US_STATES_CODES_LITERALS.UT, name: 'US_STATE_UTAH' },
  { code: US_STATES_CODES_LITERALS.VT, name: 'US_STATE_VERMONT' },
  { code: US_STATES_CODES_LITERALS.VA, name: 'US_STATE_VIRGINIA' },
  { code: US_STATES_CODES_LITERALS.WA, name: 'US_STATE_WASHINGTON' },
  { code: US_STATES_CODES_LITERALS.WV, name: 'US_STATE_WEST VIRGINIA' },
  { code: US_STATES_CODES_LITERALS.WI, name: 'US_STATE_WISCONSIN' },
  { code: US_STATES_CODES_LITERALS.WY, name: 'US_STATE_WYOMING' },
];
