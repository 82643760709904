export enum AccessLevels {
  FULL_ACCESS = 2,
  SHIPPING_AND_FINANCIAL = 3,
  SHIPPING_ONLY = 4,
  NO_ACCESS = 5,
}

export enum AccessLevelsUAT {
  FULL_ACCESS = 1,
  SHIPPING_AND_FINANCIAL = 2,
  SHIPPING_ONLY = 3,
  NO_ACCESS = 4,
}

// Note: these values are from api: user-management/getAllAccessLevels
export enum AccessRoleName {
  admin = 'Admin',
  shipAndFin = 'ShipAndFin',
  shipping = 'Shipping',
  noAccess = 'NoAccess',
}

// Note: these values are from api: /user-management/backend/partner/getFeature/{text}
export enum BetaFeatureName {
  CUSERPILOT = 'CUSERPILOT',
  BETATESTER = 'BETATESTER',
}

// Note: these values are from api: /user-management/backend/partner/getFeature/{text}
export enum BetaPermissionName {
  CUSERPILOT = 'CUSERPILOT',
  RESEARCH = 'RESEARCH',
  BETATESTER = 'BETATESTER',
  BOTH = 'BOTH',
  NONE = 'NONE',
}
