import { AddressBookData } from '@ltlc/api';
import { AccountForm } from '@ltlc/ltl-core';
import { AccountDetailsForm } from '../interfaces';

export class AddressBookHelper {
  static parseAddress(addressFormData: AccountDetailsForm): AddressBookData {
    return {
      addressBookEntry: {
        companyName: addressFormData.companyName,
        companyCountryCd: addressFormData.countryCode,
        companyAddressLine1: addressFormData.addressLines?.addressLine1 ?? null,
        companyCityName: addressFormData.cityName,
        companyStateCd: addressFormData.stateCode,
        companyPostalCd: addressFormData.postalCode,
        companyPhoneNumber: addressFormData.phoneNumber,
        companyEmailAddress: addressFormData.email,
        contactName: addressFormData.contactFullName,
        contactPhoneNumber: addressFormData.contactPhoneNumber,
        contactEmailAddress: addressFormData.contactEmail,
        dockOpenTime: addressFormData.dockingInterval?.open,
        dockCloseTime: addressFormData.dockingInterval?.close,
        locationTypeCd: null,
        recordVersionNbr: 1,
      },
    };
  }

  static parseAddressForm(addressFormData: AccountForm): AddressBookData {
    return {
      addressBookEntry: {
        companyName: addressFormData.companyName,
        companyCountryCd: addressFormData.countryCode,
        companyAddressLine1: addressFormData.addressLine1 ?? null,
        companyCityName: addressFormData.cityName,
        companyStateCd: addressFormData.stateCode,
        companyPostalCd: addressFormData.postalCode,
        companyPhoneNumber: addressFormData.phoneNumber,
        companyEmailAddress: addressFormData.email,
        contactName: addressFormData.contactFullName,
        contactPhoneNumber: addressFormData.contactPhoneNumber,
        contactEmailAddress: addressFormData.contactEmail,
        dockOpenTime: addressFormData.dockingInterval?.open,
        dockCloseTime: addressFormData.dockingInterval?.close,
        locationTypeCd: null,
        recordVersionNbr: 1,
      },
    };
  }
}
