import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { FormControlsModule } from '../form-controls';
import { XpoIconModule } from '../xpo-icon';
import { AgGridDatePickerFilterComponent } from './ag-grid-date-picker-filter/ag-grid-date-picker-filter.component';
import { AgGridLoadingOverlayComponent } from './ag-grid-loading-overlay/ag-grid-loading-overlay.component';
import { AgGridNoRowsOverlayComponent } from './ag-grid-no-rows-overlay/ag-grid-no-rows-overlay.component';
import { ConnectAgGridOptionsDirective } from './ag-grid-options/ag-grid-options.directive';
import { AgGridQuickSearchFilterComponent } from './ag-grid-quick-search-filter/ag-grid-quick-search-filter.component';
import { AgGridRenderersModule } from './ag-grid-renderers';
import { AgGridRowLoadingComponent } from './ag-grid-row-loading/ag-grid-row-loading.component';
import { AgGridSelectFilterComponent } from './ag-grid-select-filter/ag-grid-select-filter.component';
import { AgGridToolbarComponent, AgGridToolbarFiltersDirective } from './ag-grid-toolbar/ag-grid-toolbar.component';
import { ArrayValueCellRendererComponent } from './array-value-cell-renderer/array-value-cell-renderer.component';
import { CopyableCellRendererModule } from './copyable-cell-renderer/copyable-cell-renderer.module';

@NgModule({
  declarations: [
    AgGridQuickSearchFilterComponent,
    ArrayValueCellRendererComponent,
    ConnectAgGridOptionsDirective,
    AgGridLoadingOverlayComponent,
    AgGridNoRowsOverlayComponent,
    AgGridSelectFilterComponent,
    AgGridDatePickerFilterComponent,
    AgGridToolbarComponent,
    AgGridToolbarFiltersDirective,
    AgGridRowLoadingComponent,
  ],
  imports: [
    CommonModule,
    FormControlsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatProgressSpinnerModule,
    XpoIconModule,
    TranslateModule,
    MatToolbarModule,
    AgGridRenderersModule,
    CopyableCellRendererModule,
  ],
  exports: [
    AgGridQuickSearchFilterComponent,
    ArrayValueCellRendererComponent,
    ConnectAgGridOptionsDirective,
    AgGridNoRowsOverlayComponent,
    AgGridSelectFilterComponent,
    AgGridDatePickerFilterComponent,
    AgGridToolbarComponent,
    AgGridToolbarFiltersDirective,
    AgGridModule,
  ],
})
export class ConnectAgGridModule {}
