<mat-drawer-container [hasBackdrop]="true" class="ltlcc-Shell-container">
  <mat-drawer #drawer mode="over">
    <div class="ltlcc-Shell-drawer">
      <ltlcc-shell-routes *ngIf="routes" [routes]="routes"></ltlcc-shell-routes>
      <ng-content select="[ltlccShellDrawerActions]"></ng-content>
    </div>
  </mat-drawer>
  <mat-drawer-content class="ltlcc-Shell-drawerContent">
    <div>
      <ng-content select="[ltlccShellBanner]"></ng-content>

      <mat-toolbar class="ltlcc-Shell-toolbar">
        <div class="ltlcc-Shell-toolbar-inner">
          <button class="ltlcc-Shell-toolbar-inner-menuButton" mat-icon-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
          </button>

          <ng-content select="[ltlccShellLogo]"></ng-content>

          <ltlcc-shell-routes
            class="ltlcc-Shell-toolbar-inner-routes"
            *ngIf="routes"
            [routes]="routes"
            [attr.data-cy]="'toolbar-inner-menu'"
          ></ltlcc-shell-routes>

          <div class="ltlcc-Shell-toolbar-inner-actions">
            <ng-content select="[ltlccShellActions]"></ng-content>
          </div>
          <ng-container *ngIf="configUser">
            <mat-divider [vertical]="true"></mat-divider>
            <ltlcc-shell-user-account
              class="ltlcc-Shell-toolbar-inner-user"
              [configUser]="configUser"
            ></ltlcc-shell-user-account>
          </ng-container>
        </div>
      </mat-toolbar>
    </div>

    <ng-content></ng-content>

    <ltlcc-shell-footer
      class="ltlcc-Shell-footer"
      [appVersion]="appVersion"
      [primaryLinks]="primaryLinks"
      [secondaryLinks]="secondaryLinks"
      [showLanguagePicker]="showLanguagePicker"
    >
    </ltlcc-shell-footer>
  </mat-drawer-content>
</mat-drawer-container>
