import { Injectable } from '@angular/core';
import { DocumentTypes } from '@ltlc/api';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { DocumentsNode } from './imaged-document-tree.interface';
import { ProNumberHelper } from '../../helpers';

@Injectable({
  providedIn: 'root',
})
export class ImagedDocumentTreeService {
  constructor(private translateService: TranslateService) {}

  emitDocuments(documents: DocumentTypes[]): Observable<DocumentsNode[]> {
    return of(this.buildFileTree(documents));
  }

  buildFileTree(documents: DocumentTypes[]): DocumentsNode[] {
    const groupedDocuments: { [proNumber: string]: DocumentTypes[] } = {};
    documents.forEach((d) => {
      if (groupedDocuments[d.proNumber]) {
        groupedDocuments[d.proNumber].push(d);
      } else {
        groupedDocuments[d.proNumber] = [d];
      }
    });

    return [
      {
        name: this.translateService.instant('shipments.shipmentDetails.SHIPMENT_DOCUMENTS.TITLE'),
        children: Object.entries(groupedDocuments).map(([proNumber, documents]) => {
          return {
            name: `PRO# ${ProNumberHelper.formatProNumber(proNumber)}`,
            children: documents.map((d) => ({
              name: d.imageType,
              proNumber: d.proNumber,
              uploadedOn: d.scanDate,
              corpCode: d.corpCode,
              docClass: d.docClass,
              timeStamp: d.timeStamp,
            })),
          };
        }),
      },
    ];
  }
}
