import { Injectable } from '@angular/core';
import { RatingApiService } from '@ltlc/api';
import { ErrorHandlerService } from '@ltlc/core';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MexicanCitiesService {
  readonly mexicanCities$ = this.ratingApiService
    .getMexicanCities()
    .pipe(this.errorHandlerService.snackbarOnError(), shareReplay({ bufferSize: 1, refCount: true }));

  constructor(private ratingApiService: RatingApiService, private errorHandlerService: ErrorHandlerService) {}
}
