// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { environmentBase } from './enviroments-base';

const legacyLegacyBaseUrl = 'https://tcts3w.ltl.xpo.com';
const legacyBaseUrl = 'https://tcts3-app.ltl.xpo.com';

export const environment = {
  ...environmentBase(legacyBaseUrl, legacyLegacyBaseUrl),
  production: false,
  bypassAuth: false,
  firebase: {
    apiKey: 'AIzaSyBIo-Gnt7bOdJLouRxWEeDzmBkI-h90JjY',
    authDomain: 'xpo-ltl-dev-cf3b6.firebaseapp.com',
    databaseURL: 'https://xpo-ltl-dev-cf3b6.firebaseio.com',
    projectId: 'xpo-ltl-dev',
    storageBucket: 'xpo-ltl-dev.appspot.com',
    messagingSenderId: '339239167082',
    appId: '1:339239167082:web:fba5949733f56a2bf29613',
  },
  billingFeedbackEmail: 'ancel.hernandez@xpo.com',
  hideBetaRoutes: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
