import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ErrorMessage } from '@ltlc/api';
import { TranslateService } from '@ngx-translate/core';
import { AlertsComponent } from '../alerts.component';
import { AlertParams } from '../interfaces/alerts.interface';
import { XpoSnackBarStatus } from '../types/xpo-snack-bar-status.type';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  readonly DEFAULT_DURATION = 5e3;
  readonly LONGER_DURATION = 10e3;

  private readonly defaultSnackbarPosition: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'top',
    panelClass: 'ltlcc-Alert-SnackBar',
  };

  constructor(private translate: TranslateService, private snackbar: MatSnackBar) {}

  static deriveErrorMessage(
    error: HttpErrorResponse & ErrorMessage[],
    translate: TranslateService
  ): { detailedMessage: string; message: string } {
    const errorData = error?.error?.error || null;
    let message = translate.instant('alertMessages.errorTitle'),
      detailedMessage = translate.instant('alertMessages.errorBody');

    if (error?.status && errorData?.message) {
      detailedMessage = errorData.message;
      switch (error.status) {
        case 400:
          detailedMessage = detailedMessage.split('|').join('\n');
          break;
        default:
          break;
      }
    }

    if (errorData?.moreInfo?.length) {
      detailedMessage = '';

      errorData.moreInfo.forEach((err, index) => {
        // We want to avoid showing java api errors, if the message has a java like error message, place generic message
        const m =
          !err.message || err.message?.includes('com.xpo.ltl')
            ? translate.instant('alertMessages.errorBody')
            : err.message;

        detailedMessage += `Error ${index + 1} - ${m}${index < errorData?.moreInfo.length - 1 ? '\n' : ''}`;
      });

      message = translate.instant('alertMessages.multipleError');
      detailedMessage = detailedMessage ?? translate.instant('alertMessages.errorBody');
    }

    if (Array.isArray(error)) {
      detailedMessage = '';
      error.forEach((err, index) => {
        detailedMessage += `Error ${index + 1} - ${err.message ?? err.detailedErrors?.join('\n')}${
          index < error.length - 1 ? '\n' : ''
        }`;
      });
      message = error.length > 1 ? translate.instant('alertMessages.multipleError') : message;
      detailedMessage = detailedMessage ?? translate.instant('alertMessages.errorBody');
    }

    return { message, detailedMessage };
  }

  showApiError({ body, title, error, duration = this.LONGER_DURATION }: AlertParams): void {
    if (!body || !title) {
      const { message, detailedMessage } = AlertService.deriveErrorMessage(
        <HttpErrorResponse & ErrorMessage[]>error,
        this.translate
      );
      body = body ?? detailedMessage;
      title = title ?? message;
    }

    this.snackbar.openFromComponent(AlertsComponent, {
      data: {
        message: title,
        detailedMessage: body,
        status: 'error',
      },
      ...this.defaultSnackbarPosition,
      duration,
    });
  }

  showApiSuccess({ body, title, duration = this.DEFAULT_DURATION }: AlertParams) {
    this.snackbar.openFromComponent(AlertsComponent, {
      data: {
        message: title ?? this.translate.instant('alertMessages.successTitle'),
        detailedMessage: body,
        status: 'success',
      },
      duration,
      ...this.defaultSnackbarPosition,
    });
  }

  showApiWarning({ body, title, duration = this.DEFAULT_DURATION }: AlertParams) {
    this.snackbar.openFromComponent(AlertsComponent, {
      data: {
        message: title ?? this.translate.instant('alertMessages.warningTitle'),
        detailedMessage: body,
        status: 'warn',
      },
      duration,
      ...this.defaultSnackbarPosition,
    });
  }

  showGeneralMessage({
    body,
    title,
    duration = this.DEFAULT_DURATION,
    status = 'info' as XpoSnackBarStatus,
  }: AlertParams) {
    this.snackbar.openFromComponent(AlertsComponent, {
      data: {
        message: title,
        detailedMessage: body,
        status,
      },
      duration,
      ...this.defaultSnackbarPosition,
    });
  }
}
