import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CommodityItemApiResponse, UserMaintenanceService } from '@ltlc/api';
import { ErrorHandlerService, FormGroupTemplateService } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import * as merge from 'deepmerge';
import { Observable, throwError } from 'rxjs';
import { catchError, map, startWith, take } from 'rxjs/operators';
import { ExtraCommodities } from '../../enums/commodity.enum';
import { Commodity, CommodityList } from '../../interfaces/commodity.interface';
import { LtlConnectCommodityService } from '../../services/commodity.service';
import { CommodityApiResponseHelper } from './helpers/commodity-api-response.helper';

@UntilDestroy()
@Component({
  selector: 'ltlcc-commodity-dropdown',
  templateUrl: './commodity-dropdown.component.html',
  styleUrls: ['./commodity-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommodityDropdownComponent implements OnInit {
  commoditiesList: CommodityList[];
  filteredCommodities: Observable<CommodityList[]>;
  commodityControl = new FormControl();
  extraCommodities = ExtraCommodities;
  loading: boolean;
  hasError = false;

  @Input() commoditiesLength: number;
  @Output() commoditySelected: EventEmitter<Commodity | ExtraCommodities> = new EventEmitter(null);
  @Output() userCommoditiesChange = new EventEmitter<CommodityList[]>();

  constructor(
    private userMaintenanceService: UserMaintenanceService,
    private commodityService: LtlConnectCommodityService,
    private translate: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    private cd: ChangeDetectorRef,
    @Optional() private formGroupTemplateService?: FormGroupTemplateService
  ) {}

  ngOnInit(): void {
    this.updateCommodityList();

    this.listenSubmittedForm();
    // TODO: Refactor component to formbase directive so that this is not necessary and and mat-error to template
  }

  handleCommoditySelection(choice: MatAutocompleteSelectedEvent): void {
    const commodityValue: CommodityList | ExtraCommodities = choice.option.value;
    if (commodityValue) {
      switch (commodityValue) {
        case ExtraCommodities.NEW:
          this.commoditySelected.emit(ExtraCommodities.NEW);
          break;
        default:
          this.getCommodityById(commodityValue.commodityId);
          break;
      }
      this.commodityControl.setValue('');
    }
  }

  updateCommodityList(): void {
    this.handleStartApiCall();
    this.commodityService.commodityList$
      .pipe(
        this.errorHandlerService.snackbarOnError(),
        untilDestroyed(this),
        catchError((err) => {
          this.handleErrorApiCall();
          this.cd.markForCheck();

          return throwError(err);
        })
      )
      .subscribe((commoditiesResponse: CommodityItemApiResponse[]) => {
        this.handleEndApiCall();
        this.commoditiesList = CommodityApiResponseHelper.transformCommodityListItems(
          commoditiesResponse,
          this.translate
        );
        this.userCommoditiesChange.emit(this.commoditiesList);
        this.updateFilteredCommodityOptions();
        this.cd.markForCheck();
      });
  }

  private listenSubmittedForm(): void {
    this.formGroupTemplateService?.formSubmitted$.pipe(untilDestroyed(this)).subscribe(() => {
      this.commodityControl.markAsTouched();
      this.cd.markForCheck();
    });
  }

  private updateFilteredCommodityOptions(): void {
    this.filteredCommodities = this.commodityControl.valueChanges.pipe(
      startWith(''),
      map((input: string) => this.filterCommodityList(input))
    );
  }

  private filterCommodityList(value: string): CommodityList[] {
    const commodityList = merge.all([this.commoditiesList]) as CommodityList[];
    const searchWord = typeof value === 'string' ? value.toLowerCase().trim() : '';
    const filteredList = commodityList.filter((item) => {
      const index = item.description.toLowerCase().indexOf(searchWord);
      return index >= 0;
    });
    return filteredList;
  }

  private handleErrorApiCall(): void {
    this.loading = false;
    this.hasError = true;
    this.commodityControl.enable();
  }

  private handleStartApiCall(): void {
    this.loading = true;
    this.hasError = false;
    this.commodityControl.disable();
  }

  private handleEndApiCall(): void {
    this.loading = false;
    this.commodityControl.enable();
  }

  private getCommodityById(commodityId: number): void {
    this.handleStartApiCall();
    this.userMaintenanceService
      .getCommodityById(commodityId)
      .pipe(take(1))
      .subscribe((userCommodity: CommodityItemApiResponse) => {
        const commodityItem = CommodityApiResponseHelper.transformCommodityResponse(userCommodity);
        if (commodityItem) {
          this.commoditySelected.emit(commodityItem);
        }
        this.handleEndApiCall();
      });
  }
}
