import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { FormControlsBaseComponent, FormGroupTemplateService, OptionSelect } from '@ltlc/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LtlConnectApplicationRoutesData, LTL_CONNECT_APPLICATION_ROUTES } from '../../../consts/tokens';
import { commodityClassOptions } from '../../../enums';

@UntilDestroy()
@Component({
  selector: 'ltlcc-form-select-freight-class',
  templateUrl: './select-freight-class.component.html',
  styleUrls: ['./select-freight-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFreightClassComponent extends FormControlsBaseComponent implements OnInit {
  @Input() hint = this.translate.instant('rateQuote.calculateFreightClass');
  classes: OptionSelect[] = commodityClassOptions.map((value: number) => {
    return { value, label: value.toString() };
  });

  commodityClassRoute: string;

  constructor(
    _translate: TranslateService,
    _cd: ChangeDetectorRef,
    @Optional() formGroupTemplateService?: FormGroupTemplateService,
    @Optional() @Self() _parentControl?: NgControl,
    @Optional() @Inject(LTL_CONNECT_APPLICATION_ROUTES) public routes?: LtlConnectApplicationRoutesData
  ) {
    super(_translate, _cd, _parentControl, formGroupTemplateService);
    this.commodityClassRoute = routes?.getCommodityClassCalculator();
  }

  formatValueBeforeUpdate(value: string | number): number {
    // Ensuring nmfc class is type number always
    return typeof value === 'string' ? +value : value;
  }

  getDefaultLabel(): string {
    return this.translate.instant('form.labels.freightClass');
  }

  get freightControl(): FormControl {
    return <FormControl>this.parentControl?.control ?? <FormControl>this.control;
  }
}
