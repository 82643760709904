export enum CodPaymentMethods {
  CustomerCheck = 'CustomerCheck',
  CashiersCheck = 'CashiersCheck',
  CertifiedCheck = 'CertifiedCheck',
  MoneyOrder = 'MoneyOrder',
}

export enum CodPaymentMethodsConvert {
  CertifiedFund = CodPaymentMethods.CertifiedCheck,
  CompanyCheck = CodPaymentMethods.CashiersCheck,
  CustomerCheck = CodPaymentMethods.CustomerCheck,
  MoneyOrder = CodPaymentMethods.MoneyOrder,
}
