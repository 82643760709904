import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { WebConfigService } from '../../services';
import { HeaderBanner } from './banner.interface';

const HeaderBannerData = 'headerBannerData';
@Component({
  selector: 'ltlcc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-Banner' },
})
export class BannerComponent implements AfterViewInit {
  @HostBinding('class.ltlcc-Banner--hidden')
  hideBanner = false;

  @ViewChild('banner', { static: true, read: ElementRef }) bannerParent: ElementRef;

  constructor(private cdr: ChangeDetectorRef, private config: WebConfigService, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.setBannerData();
  }
  get weatherBannerUrl(): string {
    return `https://storage.googleapis.com/${
      this.config.isProd ? 'xpo-ltl-prd-web-public' : 'xpo-ltl-web-public'
    }/www/weather-banner-content/banner-data${this.config.isProd ? '' : '-dev'}.js?v=${Date.now()}`;
  }

  private setBannerData(): void {
    const banner: HTMLScriptElement = this.renderer.createElement('script');
    this.renderer.setAttribute(banner, 'type', 'text/javascript');
    this.renderer.setProperty(banner, 'async', true);
    this.renderer.setProperty(banner, 'defer', true);
    this.renderer.setAttribute(banner, 'src', this.weatherBannerUrl);
    banner.onload = () => {
      if (window.hasOwnProperty(HeaderBannerData)) {
        const { showBanner, html } = <HeaderBanner>window[HeaderBannerData];
        if (showBanner && this.bannerParent?.nativeElement) {
          const span: HTMLSpanElement = this.renderer.createElement('SPAN');

          this.renderer.setProperty(span, 'innerHTML', html);
          this.renderer.appendChild(this.bannerParent.nativeElement, span);
        }

        this.cdr.markForCheck();
      }
    };
    const body: HTMLBodyElement = document.querySelector('body');
    this.renderer.appendChild(body, banner);
  }

  removeBanner(): void {
    this.hideBanner = true;
  }
}
