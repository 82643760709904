<mat-form-field appearance="outline" [floatLabel]="floatLabel">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select [required]="required" [formControl]="selectControl" [multiple]="multiple" [placeholder]="placeholder">
    <mat-option *ngFor="let option of options" [disabled]="option.disabled" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-icon matPrefix *ngIf="inputPrefix">{{ inputPrefix }}</mat-icon>
  <mat-icon matSuffix *ngIf="inputSuffix">{{ inputSuffix }}</mat-icon>
</mat-form-field>
