import { Provider } from '@angular/core';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { User } from 'oidc-client';
import { Observable, of } from 'rxjs';

export const MockXpoAuthenticationServiceProvider: Provider = {
  provide: XpoAuthenticationService,
  useValue: <XpoAuthenticationService>{
    getUser(): User {
      return {
        access_token: '',
        expired: false,
        expires_at: 0,
        expires_in: 0,
        id_token: '',
        scope: '',
        scopes: [],
        state: '',
        token_type: '',
        toStorageString: () => null,
        profile: { aud: '', exp: 0, iat: 0, iss: '', sub: '' },
      };
    },
    getUser$(): Observable<User> {
      return of(this.getUser());
    },
    setUser(u: User): void {
      // noop
    },
    isLoggedIn(): boolean {
      return true;
    },
    isLoggedIn$(): Observable<boolean> {
      return of(true);
    },
    getAuthorizationHeaderValue(): string {
      return '';
    },
    startSignIn$(redirectUrl?: string): Observable<void> {
      return of();
    },
    completeSignIn$(): Observable<User> {
      return this.getUser$();
    },
    signOut$(): Observable<void> {
      return of();
    },
    completeSilentRefresh$(): Observable<User> {
      return this.getUser$();
    },
    startSilentRefresh$(): Observable<User> {
      return this.getUser$();
    },
  },
};
