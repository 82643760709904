import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoIconInverted, XpoIconLarge, XpoIconSmall } from './icon.directive';
import { XpoIconComponent } from './xpo-icon.component';

@NgModule({
  declarations: [XpoIconComponent, XpoIconSmall, XpoIconLarge, XpoIconInverted],
  imports: [CommonModule],
  exports: [XpoIconComponent, XpoIconSmall, XpoIconLarge, XpoIconInverted],
})
export class XpoIconModule {}
