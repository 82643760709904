import { SpecialService } from '../../rating/enums/special-service.enum';

export enum RequestFormType {
  Unknown = 0,
  RateQuote = 1,
  PickupRequest = 3,
  Claims = 4,
  Shipment = 100,
  Rebuttal = 16,
  Dispute = 23,
  SmartFormsRateQuote = 101,
  SmartFormsPickupRequest = 111,
  SmartFormsBol = 121,
  SmartFormsSpotQuote = 131,
}

// TODO: Are these title-cased or lowerCase?
export enum ResponseFormType {
  Unknown = 'unknown',
  RateQuote = 'RateQuote',
  Shipment = 'shipment',
  PickupRequest = 'PickupRequest',
  Claims = 'claim',
  ClaimRequest = 'claimrequest',
  SmartFormsRateQuote = 'SmartFormsRateQuote',
}

// TODO: Should we add SPOT and VOLUME?
export const QuoteTypeMap = new Map<SpecialService | string, number>([
  ['UNKNOWN', 0],
  [SpecialService.GUARANTEED, 1],
  [SpecialService.RAPID_REMOTE_SERVICE, 2],
  [SpecialService.GUARANTEED_BY_NOON, 3],
  [SpecialService.STANDARD, 4],
  [SpecialService.VOLUME_PRICING, 5],
  [SpecialService.SPOTQUOTE, 6],
]);
