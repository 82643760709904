<cdk-virtual-scroll-viewport
  [minBufferPx]="dims.heightRow * dims.shownRows"
  [maxBufferPx]="dims.heightRow * (dims.shownRows * 1.5)"
  [itemSize]="dims.heightRow"
  class="ltlcc-AccountListLightTree-viewport"
  [ngClass]="{
    'ltlcc-AccountListLightTree-viewport--border': config?.enableBorder
  }"
  [style.height.px]="listHeight"
  [attr.data-cy]="localDataCy"
>
  <ng-container *cdkVirtualFor="let node of dataSource; let i = index">
    <ng-container
      *ngIf="node.data.listId === TreeListOption.loader; then renderLoaderNode; else renderNode"
    ></ng-container>
    <ng-template #renderLoaderNode>
      <ng-container *ngTemplateOutlet="loadingAccountNode"></ng-container>
    </ng-template>
    <ng-template #renderNode>
      <ng-container *ngTemplateOutlet="treeNode; context: { node: node, index: i }"></ng-container>
    </ng-template>
  </ng-container>
</cdk-virtual-scroll-viewport>

<ng-template #treeNode let-node="node" let-index="index">
  <div
    (click)="toggleSelection(node, $event)"
    class="ltlcc-AccountListLightTree-viewport-tree-node-container mat-option"
    [ltlccAccountListLightTreeLeftPadding]="indentationMap"
    [attr.id]="node.data.listId"
    [attr.data-index]="index"
  >
    <div
      *ngIf="node.level > 0; else rootLevelSpace"
      style="height: 5px"
      [style.width.px]="
        dims.gap +
        dims.indentation * node.level +
        (node.expandable ||
        (config?.enableMultipleSelection &&
          ((node.expandable && config?.enableCorporateSelection) || !node.expandable)) ||
        (config?.enableSingleSelection &&
          ((node.expandable && config?.enableCorporateSelection) || !node.expandable)) ||
        node.data.matIconName
          ? 0
          : dims.indentation + dims.gap)
      "
    ></div>
    <ng-template #rootLevelSpace>
      <div style="height: 5px" [style.width.px]="dims.gap"></div>
    </ng-template>

    <div *ngIf="node.expandable" class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron">
      <button
        type="button"
        class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron-button"
        mat-icon-button
        aria-label="Expand button"
      >
        <mat-icon
          class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron-button-icon"
          [@indicatorRotate]="getExpansionState(node)"
          >expand_more</mat-icon
        >
      </button>
    </div>
    <div
      *ngIf="
        config?.enableMultipleSelection && ((node.expandable && config?.enableCorporateSelection) || !node.expandable)
      "
      class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron ltlcc-AccountListLightTree-viewport-tree-node-container-chevron--alignTopText"
    >
      <mat-checkbox
        (click)="selectItem($event, node)"
        class="ltlcc-AccountListLightTree-viewport-tree-node-container-checkbox"
        [checked]="node.data.listId | ltlccIsOptionInList : selectedIds"
      ></mat-checkbox>
    </div>
    <div
      *ngIf="
        config?.enableSingleSelection && ((node.expandable && config?.enableCorporateSelection) || !node.expandable)
      "
      class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron"
    >
      <mat-radio-button
        (click)="selectItem($event, node)"
        class="ltlcc-AccountListLightTree-viewport-tree-node-container-radio"
        [checked]="node.data.listId | ltlccIsOptionInList : selectedIds"
        [attr.data-cy]="localDataCy + '-radio-' + node.data.listId"
      ></mat-radio-button>
    </div>
    <div *ngIf="node.data.matIconName" class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron">
      <button
        type="button"
        mat-icon-button
        class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron-button"
      >
        <mat-icon class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron-button-icon">{{
          node.data.matIconName
        }}</mat-icon>
      </button>
    </div>

    <div class="ltlcc-AccountListLightTree-viewport-tree-node-container-main">
      <div
        class="ltlcc-AccountListLightTree-viewport-tree-node-container-main-textContainer"
        [ngClass]="{
          'ltlcc-AccountListLightTree-viewport-tree-node-container-main-textContainer--disabled':
            !config?.enableCorporateSelection &&
            node.data.addressForm?.locationType === AccountType.corporate &&
            !node.expandable
        }"
      >
        <div>
          <span
            class="ltlcc-AccountListLightTree-viewport-tree-node-container-main-textContainer-text"
            *ngIf="!!node.data.name"
            [innerHTML]="node.data.name | htmlSearchText : searchWord : true"
          ></span>
          <span *ngIf="node.data.disabled" class="disabled">
            {{ '- ' + ('accountList.deleteAccount.accountDeleteRequestSent' | translate) }}
          </span>
        </div>
        <span
          class="ltlcc-AccountListLightTree-viewport-tree-node-container-main-textContainer-text"
          *ngIf="!!node.data.address"
          [innerHTML]="node.data.address | htmlSearchText : searchWord"
        ></span>
      </div>
    </div>

    <div class="ltlc-Utils-spacer"></div>
    <div class="ltlcc-AccountListLightTree-viewport-tree-node-container-type">
      {{ node.data.type }}
    </div>
    <div class="ltlcc-AccountListLightTree-viewport-tree-node-container-delete">
      <ltlcc-account-actions
        [node]="node"
        [enableDelete]="config?.enableDelete"
        (deleteAccount)="deleteAccount($event)"
      ></ltlcc-account-actions>
    </div>
    <i
      class="ltlcc-AccountListLightTree-viewport-tree-node-container-badge"
      [innerHTML]="node.data.searchFields | htmlSearchOptions : searchWord"
    >
    </i>
  </div>
</ng-template>

<ng-template #loadingAccountNode>
  <div
    class="ltlcc-AccountListLightTree-viewport-tree-node-container mat-option"
    [style.padding-left.px]="dims.paddingIndent"
  >
    <div class="ltlcc-AccountListLightTree-viewport-tree-node-container-chevron">
      <button type="button" disabled mat-icon-button aria-label="Expand button">
        <mat-icon>expand_more</mat-icon>
      </button>
    </div>
    <div class="ltlcc-AccountListLightTree-viewport-tree-node-container-main">
      <div class="ltlcc-AccountListLightTree-viewport-tree-node-container-main-textContainer">
        <span class="ltlc-skeleton-animated" style="width: 200px"></span>
        <span class="ltlc-skeleton-animated" style="width: 350px"></span>
      </div>
    </div>
    <div class="ltlc-Utils-spacer"></div>
    <div class="ltlcc-AccountListLightTree-viewport-tree-node-container-type">
      <span class="ltlc-skeleton-animated" style="width: 100px"></span>
    </div>
  </div>
</ng-template>
