export enum RateQuoteValidatorLimitationsEnum {
  MIN_WIDTH_IN = 1,
  MAX_WIDTH_IN = 94.5,
  MIN_LENGTH_IN = 1,
  MAX_LENGTH_IN = 320,
  MIN_HEIGHT_IN = 1,
  MAX_HEIGHT_IN = 98,
  MIN_WIDTH_CM = 1,
  MAX_WIDTH_CM = 240,
  MIN_LENGTH_CM = 1,
  MAX_LENGTH_CM = 838,
  MIN_HEIGHT_CM = 1,
  MAX_HEIGHT_CM = 259,
  MIN_QUANTITY = 1,
  MAX_QUANTITY = 9999,
  MIN_WEIGHT_LBS = 1,
  MAX_WEIGHT_LBS = 99999,
  MIN_WEIGHT_KG = 1,
  MAX_WEIGHT_KG = 11339809,
  MAX_WEIGHT_LBS_GUARANTEED = 20000,
  MAX_WEIGHT_KG_GUARANTEED = 9071.84,
  MIN_EVC = 1,
  MAX_EVC = 999999,
}
