import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AChipModule } from '../../directives/a-chip/a-chip.module';
import { LanguagePickerFooterModule } from '../translate-handler/language-picker-footer/language-picker-footer.module';
import { XpoIconModule } from '../xpo-icon/xpo-icon.module';
import { ShellFooterComponent, ShellRoutesComponent } from './components';
import { CopyrightComponent } from './components/shell-footer/copyright/copyright.component';
import { FooterLinksComponent } from './components/shell-footer/footer-links/footer-links.component';
import { ShellUserAccountComponent } from './components/shell-user-account/shell-user-account.component';
import { ShellComponent } from './shell.component';
@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatTabsModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    XpoIconModule,
    MatChipsModule,
    ScrollingModule,
    LanguagePickerFooterModule,
    AChipModule,
  ],
  declarations: [
    ShellComponent,
    ShellRoutesComponent,
    ShellFooterComponent,
    CopyrightComponent,
    FooterLinksComponent,
    ShellUserAccountComponent,
  ],
  exports: [ShellComponent],
})
export class ShellModule {}
