<mat-form-field appearance="outline">
  <mat-label>{{
    (commoditiesLength === 0 ? 'commodity.commodity' : 'commodity.additionalCommodity') | translate
  }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="commodityControl"
    [matAutocomplete]="autoCommodity"
    [required]="!commoditiesLength"
    [placeholder]="'createRateQuote.typeSelect' | translate"
    class="ltlcc-CommodityDropdown-formField-input"
    [attr.data-cy]="'input-commodity'"
  />
  <mat-icon *ngIf="!loading" matSuffix>arrow_drop_down</mat-icon>
  <mat-spinner *ngIf="loading" matSuffix diameter="20"></mat-spinner>
  <mat-autocomplete autoActiveFirstOption #autoCommodity (optionSelected)="handleCommoditySelection($event)">
    <mat-option
      class="ltlcc-CommodityDropdown-actionOption"
      [value]="extraCommodities.NEW"
      [attr.data-cy]="'commodityDropdown-newCommodity'"
      ><mat-icon>add</mat-icon> {{ 'commodity.newCommodity' | translate }}</mat-option
    >
    <mat-option *ngFor="let commodity of filteredCommodities | async | commodityListOrderBy" [value]="commodity">
      <span [title]="commodity.description">
        {{ commodity.description }}
      </span>
    </mat-option>
    <mat-option *ngIf="hasError" (click)="updateCommodityList()">
      {{ 'commodity.errorOccurred' | translate }}
      <span class="ltlcc-CommodityDropdown-actionOption">{{ 'commodity.tryAgain' | translate }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="commodityControl.touched && commodityControl.invalid">
    {{ 'form.errors.required' | translate }}
  </mat-error>
</mat-form-field>
