import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';
import { Observable } from 'rxjs';

export interface AgGridNoRowsOverlayComponentParams extends Partial<INoRowsOverlayParams> {
  icon?: string;
  fontSet?: string;
  svg?: string;
  title?: string;
  subtitle?: string;
  actionRoute?: string;
  queryParams?: Params;
  actionTitle?: string;
  action?: () => void;
  getShowError$?: () => Observable<boolean>;
  retrySetData?: () => void;
}

@UntilDestroy()
@Component({
  selector: 'ltlcc-ag-grid-no-rows-overlay',
  templateUrl: './ag-grid-no-rows-overlay.component.html',
  styleUrls: ['./ag-grid-no-rows-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-AgGridNoRowsOverlay' },
})
export class AgGridNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  params: AgGridNoRowsOverlayComponentParams;
  showError: boolean;

  @Input()
  set data(v: AgGridNoRowsOverlayComponentParams) {
    this.agInit(v);
  }

  agInit(p: AgGridNoRowsOverlayComponentParams): void {
    this.params = p;
    this.params
      .getShowError$()
      .pipe(untilDestroyed(this))
      .subscribe((v) => {
        this.showError = v;
        this.cd.markForCheck();
      });
  }

  constructor(private cd: ChangeDetectorRef) {}

  retrySetData(): void {
    this.params.api.showLoadingOverlay();
    this.params.retrySetData?.();
  }
}
