export enum AccountType {
  corporate = 'C',
  billTo = 'B',
  pnd = 'P',
}

export enum LocationTypePM {
  pickupDelivery = 'PKUP',
  billTo = 'BILL_TO',
  corporate = 'CORP',
}
