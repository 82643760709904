import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CUST_NOTIFICATION_PROXY_API_URI } from '../tokens';
import { ShipmentSummaryRequest } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReportsServiceApi {
  constructor(@Inject(CUST_NOTIFICATION_PROXY_API_URI) private baseApiUri: string, private httpClient: HttpClient) {}

  // NOTE: use local for testing -- remove once https://github.xpo.com/OneXPO/ltl-dotnet-web-customernotification-backend/pull/127 is deployed
  get customerNotificationBackendUri(): string {
    return `${this.baseApiUri}customer-notification/backend/Reports/`;
  }

  downloadShipmentSummaryReport(request: ShipmentSummaryRequest): Observable<Blob> {
    const url = `${this.customerNotificationBackendUri}downloadShipmentSummary`;
    return this.httpClient.post(url, request, {
      responseType: 'blob',
    });
  }
}
