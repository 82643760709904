// import { LazyElementsModule } from '@angular-extensions/elements'; //TODO: Consider for lazy loading the pdf viewer
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { Base64ToPdfViewerComponent } from './base64-to-pdf-viewer.component';

@NgModule({
  declarations: [Base64ToPdfViewerComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule, MatButtonModule, TranslateModule],
  exports: [Base64ToPdfViewerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class Base64ToPdfModule {}
