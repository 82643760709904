import { Injectable } from '@angular/core';
import { ExternalUserData } from '@ltlc/api';
import { LtlConnectUserService } from '@ltlc/ltl-core';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * LoggedInUser service that returns a mocked user
 */
@Injectable()
export class XpoLtlLtlConnectUserServiceAlwaysLoggedIn extends LtlConnectUserService {
  getWebUserData$(): Observable<ExternalUserData> {
    return of({
      userId: 'extwebqa',
      profileInstId: '897604630330000',
      telephone: '1-111-2223333',
      givenName: 'ExternalWeb',
      lastName: 'UIAutomation',
      streetAddress: '1621 NW 21st',
      emailAddress: 'saravanan.thillaivanarajan@xpo.com',
      roles: ['WEB-DEV/IDMDAdmin', ' Internal/everyone', ' WEB-DEV/IDMDCustomerSecurityAdmin'],
    } as ExternalUserData).pipe(shareReplay());
  }
}
