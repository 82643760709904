import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationDialogModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { AccountFormModule } from '../account-form/account-form.module';
import { AccountListTreeAutocompleteWrapperModule } from '../account-list-light/account-list-tree-autocomplete-wrapper/account-list-tree-autocomplete-wrapper.module';
import { ConnectExpansionModule } from '../expansion/expansion.module';
import { AccountLightPickerComponent } from './account-light-picker.component';
@NgModule({
  declarations: [AccountLightPickerComponent],
  imports: [
    CommonModule,
    ConnectExpansionModule,
    ReactiveFormsModule,
    AccountFormModule,
    AccountListTreeAutocompleteWrapperModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    ConfirmationDialogModule,
    MatLegacyDialogModule,
  ],
  exports: [AccountLightPickerComponent],
})
export class AccountLightPickerModule {}
