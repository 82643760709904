<button
  *ngIf="node?.data.listId === TreeListOption.reload && node?.data.rawData?.reload"
  mat-flat-button
  color="primary"
  (click)="reload()"
  [attr.data-cy]="'accountActions-reload'"
>
  <mat-icon>refresh</mat-icon> {{ 'defaults.reload' | translate | titlecase }}
</button>
<button
  *ngIf="enableDelete"
  type="button"
  mat-icon-button
  aria-label="Delete button"
  class="ltlcc-AccountActions-delete-button"
  [disabled]="node.data.disabled"
  (click)="emitDeleteAccount($event, node)"
>
  <mat-icon>delete</mat-icon>
</button>
