import { animate, AnimationTriggerMetadata, sequence, state, style, transition, trigger } from '@angular/animations';

export class AnimationsHelper {
  static bounce: AnimationTriggerMetadata = trigger('bounce', [
    state('active', style({ transform: 'translateY(0)' })),
    transition('* => active', [
      sequence([
        style({ transform: 'translateY(0)' }),
        animate('400ms cubic-bezier(0,0,0,1)', style({ transform: 'translateY(-8px)' })),
        animate('300ms cubic-bezier(1,0,1,1)', style({ transform: 'translateY(0)' })),
        animate('200ms cubic-bezier(0,0,0,1)', style({ transform: 'translateY(-5px)' })),
        animate('150ms cubic-bezier(1,0,1,1)', style({ transform: 'translateY(0)' })),
        animate('100ms cubic-bezier(0,0,0,1)', style({ transform: 'translateY(-2px)' })),
        animate('80ms cubic-bezier(1,0,1,1)', style({ transform: 'translateY(0)' })),
      ]),
    ]),
  ]);
}
