<mat-form-field class="ltlcc-HazmatDropdown-field" appearance="outline">
  <mat-label>{{ 'hazmat.unNaDescription' | translate }}</mat-label>
  <input
    type="text"
    matInput
    [placeholder]="'createRateQuote.typeSelect' | translate"
    [formControl]="hazmatControl"
    [matAutocomplete]="hazmatAutocomplete"
    [required]="isRequired"
  />
  <mat-icon matSuffix *ngIf="!loading">arrow_drop_down</mat-icon>
  <mat-spinner matSuffix *ngIf="loading" diameter="20"></mat-spinner>
  <mat-autocomplete
    #hazmatAutocomplete
    autoActiveFirstOption
    [displayWith]="displayHazmat"
    (optionSelected)="handleHazmatSelection($event)"
  >
    <mat-option *ngFor="let hazmat of filteredHazmatOptions$ | async" [value]="hazmat">
      {{ hazmat.display }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
