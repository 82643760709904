export const billOfLadingsResponse = {
  code: '200',
  transactionTimestamp: 1664497643573,
  data: {
    billsOfLading: [
      {
        statusCd: 'ACT',
        originName: 'MENSA Enterprise Services',
        originCityName: 'Portland',
        originPostalCd: '97229',
        originStateCd: 'OR',
        destinationName: 'Testing in Savier',
        destinationCityName: 'Beaverton',
        destinationPostalCd: '97006',
        destinationStateCd: 'OR',
        pickupDate: '2015-10-29',
        creationDate: '2015-06-15',
        bolId: 3927442857786,
        referenceNumbers: [],
      },
      {
        statusCd: 'CAN',
        originName: 'MENSA Enterprise Services',
        originCityName: 'Portland',
        originPostalCd: '97229',
        originStateCd: 'OR',
        destinationName: 'Testing in Savier',
        destinationCityName: 'Beaverton',
        destinationPostalCd: '97006',
        destinationStateCd: 'OR',
        pickupDate: '2015-10-30',
        creationDate: '2015-10-30',
        bolId: 4045440660828,
        referenceNumbers: [],
      },
      {
        statusCd: 'ACT',
        originName: 'Daryl Flood Logistics Inc',
        originCityName: 'El Paso',
        originPostalCd: '79906',
        originStateCd: 'TX',
        destinationName: 'Electrolux Dallas Rdc',
        destinationCityName: 'Dallas',
        destinationPostalCd: '75211',
        destinationStateCd: 'TX',
        pickupDate: '2015-11-05',
        creationDate: '2015-11-05',
        bolId: 4050842900794,
        proNumber: '255565542',
        referenceNumbers: [],
      },
      {
        statusCd: 'ACT',
        originName: 'LOWE',
        originCityName: 'Edmond',
        originPostalCd: '73034',
        originStateCd: 'OK',
        destinationName: 'Conway Inc.',
        destinationCityName: 'Portland',
        destinationPostalCd: '97209',
        destinationStateCd: 'OR',
        pickupDate: '2016-01-14',
        creationDate: '2016-01-14',
        bolId: 4111310562190,
        proNumber: '656904463',
        referenceNumbers: [],
      },
      {
        statusCd: 'ACT',
        originName: 'MAV-TECH INC',
        originCityName: 'CORPUS CHRISTI',
        originPostalCd: '78409-2805',
        originStateCd: 'TX',
        destinationName: 'MATT RICHARD',
        destinationCityName: 'CORPUS CHRISTI',
        destinationPostalCd: '78409-2805',
        destinationStateCd: 'TX',
        pickupDate: '2016-03-11',
        creationDate: '2016-03-11',
        bolId: 4160347959928,
        referenceNumbers: [],
      },
    ],
  },
};
