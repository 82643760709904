import { AccessorialPurCodes } from '../enums';

export const SpecialShipmentOptions = [
  { code: AccessorialPurCodes.guaranteedService, name: 'GUARANTEED' },
  { code: AccessorialPurCodes.hazmat, name: 'ZHM_HAZMAT' },
  { code: AccessorialPurCodes.freezble, name: 'PROTECT_FROM_FREEZING' },
  { code: AccessorialPurCodes.weekendHolidayDelivery, name: 'WEEKEND_HOLIDAY_DELIVERY' },
  { code: AccessorialPurCodes.guaranteedByNoon, name: 'GUARANTEED_BY_NOON' },
  { code: AccessorialPurCodes.appointment, name: 'APPOINTMENT' },
  // TODO: see if this is needed
  { code: AccessorialPurCodes.apptInd, name: 'APPOINTMENT' },
  { code: AccessorialPurCodes.prem, name: 'MABD' },
];
