export enum Roles {
  SHIPPER = 'shipper',
  CONSIGNEE = 'consignee',
  BILL_TO = 'billTo',
  BILL_2_PARTY = 'bill2Party',
}

export enum PurRoles {
  SHIPPER = 'S',
  CONSIGNEE = 'C',
  THIRD_PARTY = '3',
}

export enum WebformRequesterType {
  shipper = 'Shipper',
  consignee = 'Consignee',
  billTo = 'ThirdParty',
}
