import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOTNET_API_URI } from '../tokens';
import { OcaFormExternal } from './interfaces/hrc-interface.interface';

@Injectable({ providedIn: 'root' })
export class HrcInterfaceService {
  constructor(private http: HttpClient, @Inject(DOTNET_API_URI) private baseApiUri: string) {}

  get baseUrl(): string {
    return `${this.baseApiUri}ltl-web-hrc-api/backend/`;
  }

  //TODO: Define Interface
  submitForm(request: OcaFormExternal): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}online-credit-application`, request);
  }
}
