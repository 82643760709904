import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { DefaultAuthEventHandlerProvider, XpoAuthModule } from '@xpo/ngx-auth';
import { AuthInterceptor } from './auth.interceptor';
import { XpoLtlAuthenticationService } from './authentication';
import { LtlAuthRoutingModule } from './ltl-auth-routing.module';

@NgModule({
  imports: [CommonModule, XpoAuthModule, LtlAuthRoutingModule],
  exports: [XpoAuthModule],
})
export class LtlAuthModule {
  static forRoot(
    xpoAuthConfigProvider: Provider,
    // providing way to override/extend the auth interceptor. useful if there is logic involved to when to attach ltl
    // token and when not to
    authInterceptor: Provider = { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ): ModuleWithProviders<LtlAuthModule> {
    return {
      ngModule: LtlAuthModule,
      providers: [xpoAuthConfigProvider, DefaultAuthEventHandlerProvider, XpoLtlAuthenticationService, authInterceptor],
    };
  }
}
