import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormControlsModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '../../loader';
import { AccountInformationModalModule } from '../account-information-modal/account-information-modal.module';
import { AccountListLightTreeModule } from '../account-list-light-tree/account-list-light-tree.module';
import { ToolbarSelectModule } from '../toolbar-select/toolbar-select.module';
import { AccountListTreeToolbarWrapperComponent } from './account-list-tree-toolbar-wrapper.component';

@NgModule({
  declarations: [AccountListTreeToolbarWrapperComponent],
  imports: [
    CommonModule,
    AccountListLightTreeModule,
    ReactiveFormsModule,
    FormControlsModule,
    MatToolbarModule,
    MatIconModule,
    AccountInformationModalModule,
    MatLegacyDialogModule,
    LoaderModule,
    ToolbarSelectModule,
    TranslateModule,
  ],
  exports: [AccountListTreeToolbarWrapperComponent],
})
export class AccountListTreeToolbarWrapperModule {}
