import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CountryStateOption } from '../rating';
import {
  AccessorialsShipmentInfoResponse,
  CreateSpotQuotePayload,
  CreateSpotQuoteResponseData,
  RateQuotePDFDocument,
  RatingResponseData,
  RQDetailPDFRequest,
} from '../rating/interfaces/rating.interface';
import { RatingApiService } from '../rating/rating.service';
import { mockRateQuote } from './data/rating.data';

export const MockRatingService: Provider = {
  provide: RatingApiService,
  useValue: <RatingApiService>{
    ratingURI: '',
    createRateQuote: (rateQuote: any): Observable<RatingResponseData> => of(<RatingResponseData>{}),
    getRateQuotes: (confirmationNumber: string): Observable<RatingResponseData> =>
      of(<RatingResponseData>mockRateQuote.data),
    getSpotQuoteDetail: (spotQuoteNumber: string): Observable<CreateSpotQuoteResponseData> =>
      of(<CreateSpotQuoteResponseData>{}),
    getRQDetailPDF: (printRequest: RQDetailPDFRequest): Observable<RateQuotePDFDocument> =>
      of(<RateQuotePDFDocument>{}),
    getSfrStatus: (accountId: number | string): Observable<boolean> => of(true),
    createSpotQuote: (spotQuoteBody: CreateSpotQuotePayload): Observable<CreateSpotQuoteResponseData> =>
      of(<CreateSpotQuoteResponseData>{}),
    getPricingAccessorials: (madCode: string): Observable<AccessorialsShipmentInfoResponse[]> => of([]),
    getMexicanCities: (): Observable<CountryStateOption[]> => of([]),
  },
};

export const MockRatingApiService = MockRatingService;
