import { Injectable } from '@angular/core';
import { CANADA_STATES_OPTIONS, CountryCode, US_STATES_OPTIONS } from '@ltlc/api';
import { Observable, of } from 'rxjs';
import { LegacyOptionSelect } from '../../../interfaces';
import { MexicanCitiesService } from '../../../services/mexican-cities.service';

@Injectable({
  providedIn: 'root',
})
export class DropdownLocationOptionsService {
  constructor(private mexicanCitiesService: MexicanCitiesService) {}

  regions(countryCode: CountryCode) {
    let arr: Observable<LegacyOptionSelect[]>;

    if (!countryCode) {
      return arr;
    }

    switch (countryCode) {
      case CountryCode.UNITED_STATES:
        arr = of(
          US_STATES_OPTIONS.map(({ name, code }) => {
            return { name: `STATES.US.${name}`, code };
          })
        );
        break;
      case CountryCode.CANADA:
        arr = of(
          CANADA_STATES_OPTIONS.map(({ name, code }) => {
            return { name: `STATES.CA.${name}`, code };
          })
        );
        break;
      case CountryCode.MEXICO:
        arr = this.mexicanCitiesService.mexicanCities$;
        break;
    }

    return arr;
  }
}
