import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

// TODO: Replace with the text input and remove this component
@Component({
  selector: 'ltlcc-form-company-name',
  templateUrl: './company-name.component.html',
  styleUrls: ['./company-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyNameComponent extends FormControlsBaseComponent {
  getDefaultLabel(): string {
    return this.translate.instant('form.labels.companyName');
  }

  get companyControl(): FormControl {
    return <FormControl>this.parentControl?.control ?? <FormControl>this.control;
  }
}
