import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DocumentsFlatNode, DocumentsNode } from './imaged-document-tree.interface';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ltlcc-imaged-document-tree',
  templateUrl: './imaged-document-tree.component.html',
  styleUrls: ['./imaged-document-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-ImagedDocumentTree' },
})
export class ImagedDocumentTreeComponent {
  readonly prefixShipmentDocument = 'shipments.shipmentDetails.SHIPMENT_DOCUMENTS';
  dataSource?: MatTreeFlatDataSource<DocumentsNode, DocumentsFlatNode>;
  checklistSelection = new SelectionModel<DocumentsFlatNode>(true);
  flatNodeMap = new Map<DocumentsFlatNode, DocumentsNode>();
  nestedNodeMap = new Map<DocumentsNode, DocumentsFlatNode>();
  treeControl?: FlatTreeControl<DocumentsFlatNode>;
  @Input()
  set enableLeafItemLabel(enable: BooleanInput) {
    this._enableLeafItemLabel = coerceBooleanProperty(enable);
  }
  get enableLeafItemLabel(): boolean {
    return this._enableLeafItemLabel;
  }
  private _enableLeafItemLabel = false;

  @Input() set documentsNode(documentsNode: DocumentsNode[]) {
    this.dataSource.data = documentsNode;
    this.treeControl.expandAll();
  }
  @Output() emitNode = new EventEmitter<DocumentsFlatNode>();

  private treeFlattener?: MatTreeFlattener<DocumentsNode, DocumentsFlatNode>;

  constructor() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<DocumentsFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  hasChild = (_: number, node: DocumentsFlatNode) => node.expandable;

  descendantsAllSelected(node: DocumentsFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  descendantsPartiallySelected(node: DocumentsFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: DocumentsFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  todoLeafItemSelectionToggle(node: DocumentsFlatNode, isLabel = false): void {
    if (isLabel && !this.enableLeafItemLabel) {
      this.emitNode.emit(node);
      return;
    }
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: DocumentsFlatNode): void {
    let parent: DocumentsFlatNode | null = this.getParentNode(node);

    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  private getLevel = (node: DocumentsFlatNode) => node.level;
  private isExpandable = (node: DocumentsFlatNode) => node.expandable;
  private getChildren = (node: DocumentsNode): DocumentsNode[] => node.children;

  private transformer = (node: DocumentsNode, level: number): DocumentsFlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name ? existingNode : new DocumentsFlatNode();
    flatNode.name = node.name;
    flatNode.uploadedOn = node.uploadedOn;
    flatNode.level = level;
    flatNode.proNumber = node.proNumber;
    flatNode.expandable = !!node.children?.length;
    flatNode.length = node.children?.length;
    flatNode.corpCode = node.corpCode;
    flatNode.docClass = node.docClass;
    flatNode.timeStamp = node.timeStamp;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  private checkRootNodeSelection(node: DocumentsFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  private getParentNode(node: DocumentsFlatNode): DocumentsFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
