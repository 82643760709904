import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { AddressLinesComponent } from './address-lines/address-lines.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { FormErrorMessageModule, RemoveCharactersDirective } from './base';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CompanyNameComponent } from './company-name/company-name.component';
import { CurrencyComponent } from './currency/currency.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { EmailComponent } from './email/email.component';
import { NumberComponent } from './number/number.component';
import { PasswordComponent } from './password/password.component';
import { PhoneExtensionComponent } from './phone-extension/phone-extension.component';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { SearchComponent } from './search/search.component';
import { SelectCountryComponent } from './select-country/select-country.component';
import { SelectDimensionUnitComponent } from './select-dimension-unit/select-dimension-unit.component';
import { SelectWeightUnitComponent } from './select-weight-unit/select-weight-unit.component';
import { SelectComponent } from './select/select.component';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TimeComponent } from './time/time.component';

const formComponents = [
  TextComponent,
  CompanyNameComponent,
  PhoneNumberComponent,
  NumberComponent,
  EmailComponent,
  PhoneExtensionComponent,
  SelectCountryComponent,
  SelectDimensionUnitComponent,
  SelectWeightUnitComponent,
  SelectComponent,
  DatePickerComponent,
  PasswordComponent,
  SearchComponent,
  AddressLinesComponent,
  TextareaComponent,
  DateRangePickerComponent,
  AutoCompleteComponent,
  TimeComponent,
  CheckboxComponent,
  CurrencyComponent,
  RemoveCharactersDirective,
];

@NgModule({
  declarations: formComponents,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    TranslateModule,
    MatAutocompleteModule,
    NgxMaskModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FormErrorMessageModule,
  ],
  exports: formComponents,
})
export class FormControlsModule {}
