import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ltlcc-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyrightComponent {
  readonly currentYear: number = new Date().getFullYear();

  @Input() appVersion: string;
}
