import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Injector,
  OnInit,
  Type,
} from '@angular/core';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MonoTypeOperatorFunction, Observable, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { DrawerLauncherService } from './drawer-launcher.service';
import { DRAWER_DATA } from './drawer.token';
import { LauncherLaunchData } from './launcher-data';

// TODO: Missing spec file
@UntilDestroy()
@Component({
  selector: 'ltlcc-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent implements OnInit {
  @HostBinding('class')
  panelClass: string;

  launchedComponent: Type<any>;
  launchedComponentDataInjector: Injector;
  launchedData: LauncherLaunchData;
  private closeSubscription: Subscription;

  constructor(
    private launcherService: DrawerLauncherService,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    private matDrawerContainer: MatDrawerContainer
  ) {}

  protected handleOpen(): void {
    this.matDrawerContainer.open();
  }

  protected handleClose(): void {
    this.matDrawerContainer.close();
  }

  ngOnInit(): void {
    this.launcherService.launchComponent$.pipe(untilDestroyed(this)).subscribe((x) => {
      if (x) {
        this.launchedData = x;
        this.panelClass = this.launchedData?.panelClass ?? '';
        this.launchedComponent = x.component;
        this.launchedComponentDataInjector = Injector.create({
          providers: [
            { provide: DRAWER_DATA, useValue: x.data },
            // used to provide the launched component to a reference to the host component. they can call
            // launcher.close() from the component that was launched. (similar to MatLegacyDialogRef)
            { provide: DrawerComponent, useValue: this },
          ],
          parent: x.injector ?? this.injector,
        });
        this.startCloseRequestSubscription(x.destroyDrawer$);
        this.open();
      } else {
        this.close();
      }
      this.cd.markForCheck();
    });

    this.matDrawerContainer.backdropClick.pipe(untilDestroyed(this)).subscribe(() => this.close());
  }

  open(): void {
    // Wanted to have a similar api to the close function, for now this function just calls handleOpen
    this.handleOpen();
  }

  close(results?: any): void {
    this.launchedData?.afterClosed?.(results);
    this.destroyCloseSubscription();
    this.launchedComponent = undefined;
    this.launchedComponentDataInjector = undefined;
    this.launchedData = undefined;

    this.handleClose();
  }

  /**
   * Function available for components that inject this component to attach to their api calls and close the drawer if
   * the api returns an error
   */
  closeDrawerOnError<T>(): MonoTypeOperatorFunction<T> {
    return catchError((error) => {
      this.close();

      return throwError(error);
    });
  }

  private startCloseRequestSubscription(closeRequest$: Observable<any> | null): void {
    this.destroyCloseSubscription();

    if (closeRequest$) {
      this.closeSubscription = closeRequest$.pipe(take(1), untilDestroyed(this)).subscribe((x) => this.close());
    }
  }

  private destroyCloseSubscription(): void {
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
  }
}
