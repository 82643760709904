import { Injectable } from '@angular/core';
import { AddressBook, GeneralInfoWebAccountInfo, MembershipService, UserMaintenanceService } from '@ltlc/api';
import { ErrorHandlerService } from '@ltlc/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, retry, shareReplay, take } from 'rxjs/operators';
import { AccountAddressLocationType } from '../modules/account-list-light/enums/account-list-light.enum';

@Injectable({ providedIn: 'root' })
export class AddressBookListService {
  readonly usersAddress$: Observable<AddressBook>;
  readonly addressListWithUserAddress$: Observable<AddressBook[]>;

  private readonly addressListSubject$ = new BehaviorSubject<AddressBook[]>([]);
  readonly addressList$ = this.addressListSubject$.asObservable();

  constructor(
    private userMaintenanceService: UserMaintenanceService,
    private membershipService: MembershipService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.usersAddress$ = this.membershipService.getGeneralInformation().pipe(
      this.errorHandlerService.returnValue(null),
      map((user) => {
        return user ? this.mapUserToAddressBook(user) : null;
      })
    );
    this.addressListWithUserAddress$ = combineLatest([this.usersAddress$, this.addressList$]).pipe(
      map(([user, addressList]) => (user ? [user].concat(addressList) : addressList)),
      shareReplay()
    );

    this.updateAddresses();
  }

  updateAddresses(addressBookEntry?: AddressBook): void {
    if (addressBookEntry) {
      // Optimistically loaded
      const addressList = this.addressListSubject$.getValue();
      addressList.push(addressBookEntry);
      this.addressListSubject$.next(addressList);
    }

    this.userMaintenanceService
      .getAddressBookEntries()
      .pipe(retry(1), take(1))
      .subscribe((addresses: AddressBook[]) => this.addressListSubject$.next(addresses));
  }

  private mapUserToAddressBook(webAcctInfo: GeneralInfoWebAccountInfo): AddressBook {
    return {
      companyName: webAcctInfo.companyName,
      companyAddressLine1: webAcctInfo.address.addressLine1,
      companyCountryCd: webAcctInfo.address?.countryCd,
      companyStateCd: webAcctInfo.address.stateCd,
      companyCityName: webAcctInfo.address.cityName,
      companyPostalCd: webAcctInfo.address.postalCd,
      contactName: `${webAcctInfo.firstName} ${webAcctInfo.lastName}`,
      contactPhoneNumber: webAcctInfo.telephone.phoneNbr,
      companyPhoneNumber: webAcctInfo.telephone.phoneNbr,
      contactEmailAddress: webAcctInfo.emailAddress.emailAddr,
      companyEmailAddress: webAcctInfo.emailAddress.emailAddr,
      dockCloseTime: '',
      dockOpenTime: '',
      locationTypeCd: AccountAddressLocationType.userAddress,
    };
  }
}
