/*
  This interface is defined apart because it does not share
  common interfaces with the others
*/

import { CodPaymentMethods, CountryCode, PurRoles, ServiceTypeLiteral } from '../../api-shared';
import { AccessorialsCodes } from '../../rating/enums/accessorials.enum';
import { BolResponseStatus } from '../enums/bill-of-lading.enum';
import {
  BolInstancePackageCodeToPackageType,
  PaymentBolTermInstance,
} from '../enums/bol-accessorial-details-instance.enum';
import { ProNumberStrategy } from '../enums/pro-number-strategy.enum';

export interface BolInstanceCommodityDetail {
  classTxt: string;
  description: string;
  hazamatInd: false;
  nmfcTxt: string;
  pakageTxt: string | keyof BolInstancePackageCodeToPackageType;
  quantity: number;
  sequenceNumber: number;
  weight: number;
}

export interface InquirerInstance {
  addressLine1: string;
  addressLine2: string;
  cisCustomerNumber: number;
  cisMadCode?: string;
  city: string;
  countryCode: CountryCode | string;
  customerName1: string;
  customerName2: string;
  emailAddress: string;
  matchStatusCode?: string;
  pacdNumber: string;
  phonNumber: string;
  phonrextensionNumber: string;
  postalCode: string;
  stateCode: string;
  zip4: string;
  zip6: string;
  zipCode: string;
}

export interface BolCodCustomerInstance {
  codAmount: number;
  codChargeTypeCode?: keyof PaymentBolTermInstance;
  codCompanyName: string;
  codCustomer: InquirerInstance;
  codRemitToName: string;
  codSequenceNumber: number;
  paymentTypeCode?: CodPaymentMethods;
}
export interface BolDocInstance {
  acceptedWithExceptionInd: boolean;
  archiveInd: boolean;
  bolStatusCd: BolResponseStatus;
  chargeCd: keyof PaymentBolTermInstance;
  cisCustIdentifier: number;
  createDt: number;
  createTmst: number;
  custSuppliedProNbrTxt: string;
  deferredInd: boolean;
  destTrmnlSic: string;
  documentName: string;
  documentTypeCd: string;
  emrCntctName: string;
  emrCntctPhone: string;
  freezableInd: boolean;
  guaranteedInd: boolean;
  hazmatInd: boolean;
  holidayInd: boolean;
  lstUpdtTmst: number;
  lstUsedDt: number;
  origTrmnlSic: string;
  proNumberTxt: string;
  proTypeCd: ProNumberStrategy;
  refNumberTxt: string;
  shpInstanceId: number;
  shpmntRemarksTxt: string;
  sourcecd: string;
  templateSrcCd: string;
  testModeInd: boolean;
  versionNbr: number;
  svcTypCd: ServiceTypeLiteral;
}
export interface bBolRequestorInstance {
  countryCd: CountryCode;
  declPerLb: string;
  declTot: string;
  ediSenderId: string;
  ediSenderShpId: string;
  exLiaInit: string;
  pccdPhone: string;
  requestorAreaCd: string;
  requestorCompanyName: string;
  requestorEmail: string;
  requestorName: string;
  requestorPhone: string;
  role: PurRoles;
  userId: string;
  userIdNumberTxt: string;
  userTypeCd: string;
}
export interface bolpickLstInstance {
  consZip6: string;
  consigneeAddressTxt: string;
  consigneeName1: string;
  destAreaCd: string;
  destTrmnlSic: string;
  excludeInd: boolean;
  lstUpdtTmst: number;
  origAreacd: string;
  origTrmnlSic: string;
  pkupDt: number;
  proNumberTxt: string;
  reserveInd: boolean;
  shipperCisCustNumber: number;
  shipperMadCd?: string;
  shipperName1: string;
  sourcecd: string;
  suppRefNbrtxt: string;
}
export interface BolPrimaryDetails {
  billTo: InquirerInstance;
  bolCodCustomer: BolCodCustomerInstance;
  bolDoc: BolDocInstance;
  bolInstanceId: number;
  bolRequestor: bBolRequestorInstance;
  bolpickLst: bolpickLstInstance;
  consignee: InquirerInstance;
  custProfInstId: number;
  hazmatRemarkTxt: string;
  hazmatRemarkTypeCd: string;
  shipper: InquirerInstance;
  shpRemarkTxt: string;
  shpRemarkTypeCd: string;
  totalPiecesCnt: number;
  totalWeightLbs: number;
}

export interface BolAccessorialDetailsInstance {
  accsrlcd: AccessorialsCodes;
  chargeCd: keyof PaymentBolTermInstance;
  sequenceNumber: number;
}

export interface BolPickupRequestDetailsInstance {
  areaCd: string;
  callSeqNumber: number;
  contactCmpnyTxt: string;
  contactName: string;
  contactPhntxt: string;
  dockCloseTme: number;
  pkupDt: number;
  pkupTme: number;
  pkupTrmnlSic: string;
  sequenceNumber: number;
}

export interface BolSupplementalRefDetails {
  refCd: string;
  refDescripton: string;
  refNumberTxt: string;
  refSequenceNumber: number;
  sourceCd: string;
  typeCd: string; // ServiceTypeService??
}
export interface BillOfLadingInstanceData {
  bolAccessorialDetails: BolAccessorialDetailsInstance[];
  bolCommodityDetails: BolInstanceCommodityDetail[];
  bolPickupRequestDetails?: BolPickupRequestDetailsInstance[];
  bolPrimaryDetails: BolPrimaryDetails;
  bolSupplementalRefDetails?: BolSupplementalRefDetails[];
}
