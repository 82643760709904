import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConnectDialogModule, FormControlsModule, FormGroupTemplateModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { LtlConnectFormControlModule } from '../form-controls/form-controls.module';
import { LoaderModule } from '../loader/loader.module';
import { AccountRequestsSentDialogComponent } from './account-requests-sent-dialog/account-requests-sent-dialog.component';
import { AddAccountModalComponent } from './add-account-modal.component';

@NgModule({
  declarations: [AddAccountModalComponent, AccountRequestsSentDialogComponent],
  imports: [
    CommonModule,
    ConnectDialogModule,
    LtlConnectFormControlModule,
    FormControlsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatLegacyDialogModule,
    MatButtonModule,
    FormGroupTemplateModule,
    LoaderModule,
    MatSnackBarModule,
    MatIconModule,
  ],
  exports: [AddAccountModalComponent],
})
export class AddAccountModalModule {}
