import { Pipe, PipeTransform } from '@angular/core';
import { StandardLanguageCode } from '@ltlc/api';
import { TranslateHelper } from '../translate-helper';

@Pipe({
  name: 'languagePickerFooter',
})
export class LanguagePickerFooterPipe implements PipeTransform {
  transform(language: StandardLanguageCode): unknown {
    return TranslateHelper.languageList.find((v) => v.value === language)?.label ?? '';
  }
}
