export enum CANADA_STATES_LITERALS {
  AB = 'AB',
  NB = 'NB',
  NS = 'NS',
  NW = 'NW',
  PE = 'PE',
  SK = 'SK',
  BC = 'BC',
  MB = 'MB',
  NL = 'NL',
  NU = 'NU',
  ON = 'ON',
  QC = 'QC',
  YU = 'YU',
}
export interface ICanadaStatesOption {
  code: CANADA_STATES_LITERALS;
  name: string;
}

export const CANADA_STATES_OPTIONS: ICanadaStatesOption[] = [
  { code: CANADA_STATES_LITERALS.AB, name: 'CA_STATE_ALBERTA' },
  { code: CANADA_STATES_LITERALS.NB, name: 'CA_STATE_NEW_BRUNSWICK' },
  { code: CANADA_STATES_LITERALS.NS, name: 'CA_STATE_NOVA_SCOTIA' },
  { code: CANADA_STATES_LITERALS.NW, name: 'CA_STATE_NORTH_WEST_TERR.' },
  { code: CANADA_STATES_LITERALS.PE, name: 'CA_STATE_PRINCE_EDWARD_IS.' },
  { code: CANADA_STATES_LITERALS.SK, name: 'CA_STATE_SASKATCHEWEN' },
  { code: CANADA_STATES_LITERALS.BC, name: 'CA_STATE_BRITISH_COLUMBIA' },
  { code: CANADA_STATES_LITERALS.MB, name: 'CA_STATE_MANITOBA' },
  { code: CANADA_STATES_LITERALS.NL, name: 'CA_STATE_NEWFOUNDLAND/LABRADOR' },
  { code: CANADA_STATES_LITERALS.NU, name: 'CA_STATE_NUNAVUT' },
  { code: CANADA_STATES_LITERALS.ON, name: 'CA_STATE_ONTARIO' },
  { code: CANADA_STATES_LITERALS.QC, name: 'CA_STATE_QUEBEC' },
  { code: CANADA_STATES_LITERALS.YU, name: 'CA_STATE_YUKON' },
];
