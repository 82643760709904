import { Provider } from '@angular/core';
import {
  AccountFinancialInfoResponseData,
  AccountType,
  AddressBook,
  AddressBookApiResponse,
  AddressBookData,
  FlatAccountData,
  InquirerInfo as ShipperCustomerProfileAddress,
  PreferredAccount,
} from '@ltlc/api';
import { Observable, of } from 'rxjs';
import { AccountListLightTreeNode } from '../interfaces';
import { AccountDataSourcesService } from '../services/account-data-sources.service';

export const MockAccountDataSourcesService: Provider = {
  provide: AccountDataSourcesService,
  useValue: <AccountDataSourcesService>{
    newAddress: (): Observable<AccountListLightTreeNode> => of(<AccountListLightTreeNode>{}),
    saveAsNewAddress: (addressBook: AddressBookData): Observable<AddressBook> => of(<AddressBook>{}),
    updateAddress: (addressBook: AddressBookData, sequenceNbr: number): Observable<AddressBookApiResponse> =>
      of(<AddressBookApiResponse>{}),
    shipperAccounts: (): Observable<ShipperCustomerProfileAddress[] | null> => of([]),
    flatAccounts: (accountIds: string[]): Observable<FlatAccountData[] | null> => of([]),
    nestedShipperAccounts: (): Observable<AccountListLightTreeNode[] | null> => of([]),
    preferredAccounts: (): Observable<PreferredAccount[] | null> => of([]),
    nestedAssociatedAccounts: (): Observable<AccountListLightTreeNode[] | null> => of([]),
    userDefaultAddress: (): Observable<AccountListLightTreeNode> => of(<AccountListLightTreeNode>{}),
    financialInfo: (idAccount: string): Observable<AccountFinancialInfoResponseData | null> =>
      of(<AccountFinancialInfoResponseData>{}),
    addressBook: (): Observable<AddressBook[] | null> => of([]),
    addressBookWithUserInfo: (): Observable<AccountListLightTreeNode[] | null> => of([]),
    clearDataSourcesCache: (): void => {},
    combinedNestedAssociatedAndShipperAccounts: (
      locationTypeExceptions?: AccountType[]
    ): Observable<AccountListLightTreeNode[] | null> => of([]),
  },
};
