import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CommonBaseResponse } from '../api-shared/interfaces/api-shared.interface';
import { API_URI } from '../tokens';
import { AccountListType } from './enums';
import {
  AccountDeletePreferredAccountParams,
  AccountFinancialInfoResponse,
  AccountFinancialInfoResponseData,
  AccountSearchFilter,
  AccountSetPreferredAccount,
  AccountSetPreferredAccountData,
  AccountsSearchProNumberResult,
  AccountsSearchResult,
  AssociatedAccounts,
  ContactMeBody,
  ContactMeResponse,
  GeneralInfoApiResponse,
  GeneralInfoDataApiResponse,
  GeneralInfoWebAccountInfo,
  PreferredAccount,
  PreferredAccountResponse,
  ResetPasswordApiRequest,
  ResetPasswordApiResponse,
  ResetPasswordApiResponseData,
  ShipmentAccessApiResponse,
  ShipmentAccessApiResponseData,
  UserAccount,
} from './interfaces/membership.interface';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get membershipURI(): string {
    return `${this.apiUri}/membership/1.0/`;
  }

  setPreferredAccount(
    userProfileId: string,
    accountId: string,
    paramsDelete: AccountDeletePreferredAccountParams
  ): Observable<AccountSetPreferredAccountData> {
    const url = `${this.membershipURI}preferred-account`;
    return this.httpClient
      .post<AccountSetPreferredAccount>(url, {
        userProfileId,
        cisCustNbr: accountId,
        typeCd: paramsDelete.accountListType,
        applId: [paramsDelete.applicationName],
      })
      .pipe(map((result: AccountSetPreferredAccount) => result.data));
  }

  deletePreferredAccount(
    userProfileId: string,
    accountId: string,
    paramsDelete: AccountDeletePreferredAccountParams
  ): Observable<CommonBaseResponse> {
    const url = `${this.membershipURI}${userProfileId}/preferred-accounts/${accountId}`;

    const params = {
      applId: paramsDelete.applicationName,
      cisCustNbr: accountId,
      userProfileId,
      typeCd: paramsDelete.accountListType || AccountListType.P, //LMF-1232
      format: 'JSON',
    };

    return this.httpClient.delete<CommonBaseResponse>(url, { params });
  }

  associatedAccounts(profileInstanceId: string): Observable<UserAccount[]> {
    const url = `${this.membershipURI}user-profiles/${profileInstanceId}/associated-accounts`;
    return this.httpClient.get<AssociatedAccounts>(url).pipe(
      map((associatedAccounts: AssociatedAccounts) => {
        return associatedAccounts.data.userAccounts;
      })
    );
  }

  searchUserProNumber(proNumber: string): Observable<UserAccount[]> {
    const url = `${this.membershipURI}user-accounts/match-by-pro/${proNumber}`;
    return this.httpClient
      .get<AccountsSearchProNumberResult>(url)
      .pipe(map((result: AccountsSearchProNumberResult) => result.data.userAccounts));
  }

  searchUserAccounts(searchFilter: AccountSearchFilter): Observable<UserAccount | null> {
    const url = `${this.membershipURI}user-accounts/match-by-name`;
    return this.httpClient
      .post<AccountsSearchResult>(url, searchFilter)
      .pipe(map((result: AccountsSearchResult) => result.data.userAccount ?? null));
  }

  contactMe(contactMeBody: ContactMeBody): Observable<string> {
    const url = `${this.membershipURI}user-accounts/contact-me`;
    return this.httpClient.post<ContactMeResponse>(url, contactMeBody).pipe(map((result) => result.data.caseNumber));
  }

  preferredAccounts(profileInstanceId: string): Observable<PreferredAccount[]> {
    const url = `${this.membershipURI}user-profiles/${profileInstanceId}/accounts`;
    return this.httpClient.get<PreferredAccountResponse>(url).pipe(
      shareReplay(),
      map((result: PreferredAccountResponse) => {
        return result.data.userAccount;
      })
    );
  }

  getGeneralInformation(): Observable<GeneralInfoWebAccountInfo> {
    const url = `${this.membershipURI}user-profiles/acct-info`;
    return this.httpClient.get<GeneralInfoApiResponse>(url).pipe(
      map((response: GeneralInfoApiResponse) => {
        return response.data.webAcctInfo;
      })
    );
  }

  getShipmentAccess(proNumber: string): Observable<ShipmentAccessApiResponseData> {
    const url = `${this.membershipURI}user-accounts/shipment-access/${proNumber}`;

    return this.httpClient.get<ShipmentAccessApiResponse>(url).pipe(map((result) => result.data));
  }

  financialInfoAccounts(accountId: string | number): Observable<AccountFinancialInfoResponseData> {
    const url = `${this.membershipURI}user-accounts/${accountId}/financial-info`;
    return this.httpClient.get<AccountFinancialInfoResponse>(url).pipe(
      map((result: AccountFinancialInfoResponse) => result.data),
      shareReplay()
    );
  }

  saveGeneralInformation(generalInfoUser: GeneralInfoDataApiResponse): Observable<CommonBaseResponse> {
    const url = `${this.membershipURI}/user-profiles/acct-info`;
    return this.httpClient.put<CommonBaseResponse>(url, generalInfoUser);
  }

  changePassword(resetRequest: ResetPasswordApiRequest): Observable<ResetPasswordApiResponseData> {
    const url = `${this.membershipURI}user-profiles/change-password`;

    return this.httpClient.post<ResetPasswordApiResponse>(url, resetRequest).pipe(
      map((response) => {
        return response.data;
      })
    );
  }
}
