<div *ngIf="showError; else noRows">
  <h2>An Error Has Occurred.</h2>
  <button mat-flat-button color="primary" (click)="retrySetData()">Click Here To Retry</button>
</div>
<ng-template #noRows>
  <mat-icon *ngIf="params.icon && !params.svg" [fontSet]="params.fontSet" class="ltlcc-AgGridNoRowsOverlay-icon">{{
    params.icon
  }}</mat-icon>
  <ltlcc-icon *ngIf="params.svg" class="ltlcc-AgGridNoRowsOverlay-icon" [iconName]="params.svg"></ltlcc-icon>
  <h1 class="ltlcc-AgGridNoRowsOverlay-title">{{ params.title }}</h1>
  <p class="ltlcc-AgGridNoRowsOverlay-subtitle" *ngIf="params.subtitle">{{ params.subtitle }}</p>
  <a
    *ngIf="params.actionRoute"
    class="ltlcc-AgGridNoRowsOverlay-action"
    mat-flat-button
    color="primary"
    [routerLink]="params.actionRoute"
    [queryParams]="params.queryParams"
  >
    {{ params.actionTitle }}
  </a>

  <button
    *ngIf="params.action"
    class="ltlcc-AgGridNoRowsOverlay-action"
    mat-flat-button
    color="primary"
    (click)="params.action()"
  >
    {{ params.actionTitle }}
  </button>
</ng-template>
