import { Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LtlConnectUserService } from '../services';

export const MockLtlConnectUserService: Provider = {
  provide: LtlConnectUserService,
  useValue: {
    getPartnerByUser$: of(null),
    isAdminUser$: of(true),
    webUser$: of(null),
    isPartnerManagerUser: true,
    refreshUser: () => {},
    getXpoAuthUserData$: () => of(null),
    getProfileInstId$: (): Observable<string> => of('userProfileInstId'),
    isLoggedIn: () => true,
    isTestUser: () => true,
    isBetaUser: () => true,
    signOut: () => {},
  },
};
