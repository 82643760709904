import { InvoiceInfo } from '../../invoice';

export interface SavedCardItem {
  creditCardTokenId: number;
  cardHolderPartnerCodeId: number;
  cardIssuer: string;
  creditCardToken: string;
  cardExpirationYear: number;
  cardExpirationMonth: number;
  isDeleted: boolean;
  isActive: boolean;
  cardHolderName: string;
  postalCode: string;
  emailAddress: string;
  phoneNumber: string;
  maskedCardNumber: string;
  action?: string;
}

export class UpdateSavedCardRequest {
  creditCardTokenId: number;
  paymentKey: string;
  sessionKey: string;
  cardHolderName: string;
  phoneNumber: string;
  emailAddress: string;
  attemptNumber: number;
  attributes: SavedCardAttribute[];
}

export interface PaymentError {
  responseCode: number;
  responseMessage: string;
  authorizedDateTime: string;
  amount: number;
  creditCardTransactionId: number;
  paymentReceiptId: number;
  responseMessageTitle: string;
  requestId: string;
  attemptNumber: number;
  isTransactionAvailable: boolean;
  transactionId: string;
}

export class SavedCardAttribute {
  key: string;
  value: string;
}
export interface PaymentConfirmation {
  invoiceList: InvoiceInfo[];
  partnerDetails: PartnerDetails;
  paymentDetails: PaymentDetails;
}

export class PaymentDetails {
  transactionId: string;
  receiptNumber: string;
  totalAmount: number;
  totalOrderAmount: number;
  feeAmount: number;
  currencyCode: string;
  cardHolderName: string;
  phoneNumber: string;
  cardType: string;
  cardNumber: string;
  receiptDate: string;
}

export class PartnerDetails {
  partnerCode: string;
  partnerName: string;
}

export interface MultiInvoiceTransactionDetailsRequest {
  referenceType: string;
  referenceNumbers: string[];
}
export interface PartialPaymentReasonCodeItem {
  partialPaymentReasonCodeId: number;
  partialPaymentReasonCode: string;
  description: string;
}

export interface TransactionFeeResponse {
  transactionFee: number;
  totalAmount: number;
}

export interface CreditCardTokenResponse {
  creditCardTokenId: number;
  cardHolderPartnerCodeId: number;
  cardIssuer: string;
  creditCardToken: string;
  cardExpirationYear: number;
  cardExpirationMonth: number;
  isDeleted: boolean;
  isActive: boolean;
  cardHolderName: string;
  postalCode: string;
  emailAddress: string;
  phoneNumber: string;
  maskedCardNumber: string;
}

export interface PaymentResponse {
  responseCode: number;
  responseMessage: string;
  authorizationCode: string;
  authorizedDateTime: string;
  maskedCardNumber: string;
  cardIssuer: string;
  amount: number;
  transactionId: string;
  creditCardTransactionId: number;
  receiptId: number;
  receiptNumber: string;
  responseMessageTitle: string;
  requestId: string;
  attemptNumber: number;
  isTransactionAvailable: boolean;
  isDisablePayButton: boolean;
  isOracleRequestSuccessful: boolean;
  failMessage: string;
  paymentReceiptId: number;
}

export interface MultiInvoiceTransactionRequest {
  invoiceList: InvoiceInfo[];
  currencyCode: string;
  partnerDetails: PartnerDetails;
  partnerCodes?: string[];
}

export interface MultiInvoicePaymentInformation {
  paymentType: string;
  paymentKey: string;
  attributes: SavedCardAttribute;
  sessionKey: string;
  saveCard: boolean;
  referenceNumber: string;
  referenceType: string;
  totalAmount: number;
  totalOrderAmount: number;
  feeAmount: number;
  currencyCode: string;
  tokenInformation: CreditCardTokenDetails;
  creditCardHolder: CreditCardHolder;
  attemptNumber: number;
  isPartialPayment: boolean;
  invoices: InvoicePaymentInformation[];
  partnerCodes: string[];
}
export interface CreditCardHolder {
  cardholderId: number;
  cardHolderPartnerCodeId: number;
  cardHolderName: string;
  partnerIdentifierCode: string;
  systemId: number;
  partnerTypeId: number;
  postalCode: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface CreditCardTokenDetails {
  expirationMonth: string;
  expirationYear: string;
  token: string;
  maskedCardNumber: string;
  cardIssuer: string;
}

export interface InvoicePaymentInformation {
  invoiceNumber: string;
  orderNumber: string;
  dueDate: string;
  mode: string;
  amount: number;
  currencyCode: string;
  sourceApplicationCode: string;
  sourceApplicationKey: string;
  customerCode: string;
  billingInvoiceCode: string;
  isPartialPayment?: boolean;
  partialPaymentDetails?: PartialPaymentDetails;
}

export interface PartialPaymentDetails {
  invoiceNumber: string;
  partialAmountPaid: number;
  partialPaymentReasonCode: string;
  partialPaymentReasonComment: string;
}

export interface CreditCardTransactionFeeRequest {
  orderAmount: number;
  customerNumber: string;
  paymentMethodCode: string;
}

export interface CreditCardTransactionFeeResponse {
  result: number;
  id: number;
  status: string;
  isCanceled: boolean;
  isCompleted: boolean;
  isCompletedSuccessfully: boolean;
  creationOptions: string;
  isFaulted: boolean;
}
