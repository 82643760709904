import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, Optional } from '@angular/core';
import { FormControl, NgControl, ValidationErrors } from '@angular/forms';
import { MatFormField, MAT_FORM_FIELD } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, of } from 'rxjs';
import { OptionSelect } from '../interfaces';

@UntilDestroy()
@Component({
  selector: 'ltlcc-form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorMessageComponent {
  errors: ValidationErrors | null;
  errorMessageList: OptionSelect | OptionSelect[] | undefined;

  @Input() customError?: OptionSelect | OptionSelect[] | undefined;
  @Input() control?: FormControl | undefined;

  constructor(
    private cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_FORM_FIELD) public formField?: MatFormField,
    @Optional() private parentControl?: NgControl
  ) {}

  ngOnInit(): void {
    this.errorMessageList = this.getErrorMessageList();

    merge(
      this.control?.statusChanges ?? of(null),
      this.parentControl?.control?.statusChanges ?? of(null),
      this.formField?._control?.ngControl?.statusChanges ?? of(null)
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.errors = this.getErrors();
        this.cd.markForCheck();
      });
  }

  getErrors(): ValidationErrors | null {
    const errors = {
      ...this.control?.errors,
      ...this.parentControl?.control?.errors,
      ...this.formField?._control?.ngControl?.control?.errors,
    };

    if (Object.keys(errors).length === 0) {
      return null;
    }
    return errors;
  }

  getErrorMessageList(): OptionSelect | OptionSelect[] | undefined {
    return this.customError ?? this.parentControl?.valueAccessor?.['customError'];
  }
}
