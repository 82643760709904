export enum LocationAccountFields {
  accountInstId = 'accountInstId',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  cityName = 'cityName',
  stateCd = 'stateCd',
  postalCd = 'postalCd',
  countryCd = 'countryCd',
  email = 'email',
  phone = 'phone',
  extension = 'extension',
  contactName = 'contactName',
  contactPhone = 'contactPhone',
  contactEmail = 'contactEmail',
  companyCode = 'companyCode',
  locationType = 'locationType',
  dockOpenTime = 'dockOpenTime',
  dockCloseTime = 'dockCloseTime',
  comments = 'comments',
}

export enum ExtraAccounts {
  NEW_ACCOUNT = '-1',
  BACK_TO_ROOT = '',
  USE_MY_INFO = '-2',
  SAME_AS_PICKUP = '-3',
  SAME_AS_DELIVERY = '-4',
  SAME_AS_REQUESTER = '-5',
}

export enum ParticipantTypes {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
  BILL_TO = 'billTo',
}

export enum PickupDeliveryLocationType {
  ORIGIN = 'ORIGIN',
  DESTINATION = 'DESTINATION',
  PREMIUM = 'PREMIUM',
}
