import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { CountryCode } from '@ltlc/api';
import { ArraySortHelper, FormControlsBaseComponent, FormGroupTemplateService, OptionSelect } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DropdownLocationOptionsService } from './location-dropdowns.service';

@UntilDestroy()
@Component({
  selector: 'ltlcc-select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectStateComponent extends FormControlsBaseComponent implements OnInit {
  _countryCode: CountryCode;
  isMexico: boolean = false;

  @Input() set countryCode(countryCode: CountryCode) {
    this._countryCode = countryCode;

    if (countryCode) {
      this.regions$ = this.dropDownLocationService.regions(countryCode).pipe(
        map((options) => {
          return options.map((option) => {
            return {
              label:
                countryCode !== CountryCode.MEXICO
                  ? this.translate.instant(option.name)
                  : this.titlecasePipe.transform(option.name),
              value: countryCode === CountryCode.MEXICO ? option.code.toLowerCase() : option.code,
            };
          });
        }),
        map((regions) => ArraySortHelper.orderBy(regions, ['label']))
      );
    }

    this.isMexico = this._countryCode === CountryCode.MEXICO;
  }
  regions$: Observable<OptionSelect[]>;

  @Output() stateChange = new EventEmitter<OptionSelect>();

  constructor(
    private dropDownLocationService: DropdownLocationOptionsService,
    private titlecasePipe: TitleCasePipe,
    cd: ChangeDetectorRef,
    translate: TranslateService,
    parentControl: NgControl,
    formGroupTemplateService?: FormGroupTemplateService
  ) {
    super(translate, cd, parentControl, formGroupTemplateService);
  }
  ngOnInit(): void {
    super.ngOnInit();

    this.control.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((value) => {
      this.stateChange.emit(value);
    });
  }

  getDefaultLabel(): string {
    return this.translate.instant('form.labels.stateCode');
  }
}
