import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { CountryCode } from '@ltlc/api';
import { GoogleMapsService, OptionSelect, TextComponent } from '@ltlc/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Optional } from 'ag-grid-community';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { AccountFormConfig } from '../account-list-light/interfaces/account-list-light.interface';
import { AddressForm } from './address-form.interface';

@UntilDestroy()
@Component({
  selector: 'ltlcc-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-AddressForm' },
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupName }],
})
export class AddressFormComponent implements OnInit {
  addressOptions$: Observable<OptionSelect[]> | undefined;
  addressLineSearchControl = new FormControl();
  private _biasLatLng = new BehaviorSubject<{ lat: number; lng: number }>(null);
  private _biasLatLng$ = this._biasLatLng.asObservable();
  private _lastPredictions: google.maps.places.AutocompletePrediction[] = [];
  private _assigningPlace = false;

  @Input() fieldConfig?: AccountFormConfig;
  @Input() dataCy?: string;
  @Input() nonMutableFields?: Array<keyof AddressForm>;

  @ViewChild('addressLine2', { static: false }) addressLine2Component: TextComponent;

  constructor(
    private googleMapsService: GoogleMapsService,
    private cd: ChangeDetectorRef,
    @Optional() private formGroupName?: FormGroupName
  ) {}

  ngOnInit(): void {
    this.initializeBias();
    this.listenAddress();
  }

  get addressLine1Required(): boolean {
    const req = !!this.addressFormGroup?.get('addressLine1')?.hasValidator(Validators.required);
    return req;
  }

  get addressFormGroup(): FormGroup | undefined {
    return this.formGroupName?.control as FormGroup;
  }

  get countryCodeControl(): FormControl | undefined {
    return <FormControl>this.addressFormGroup?.get('countryCode');
  }

  get countryCode(): CountryCode | undefined {
    return this.countryCodeControl?.value;
  }

  get addressLine1Control(): FormControl | undefined {
    return <FormControl>this.addressFormGroup?.get('addressLine1');
  }

  private initializeBias() {
    if (this.nonMutableFields?.length) {
      const address: AddressForm = this.addressFormGroup.getRawValue();

      const request: google.maps.GeocoderRequest = {
        componentRestrictions: {
          administrativeArea: address.stateCode || null,
          locality: address.cityName || null,
          postalCode: address.postalCode || null,
          route: address.addressLine1 || null,
        },
      };

      this.googleMapsService.mapsLoaded$.pipe(take(1)).subscribe(() => {
        this.googleMapsService
          .geocode(request)
          .pipe(take(1))
          .subscribe((res: google.maps.GeocoderResult[]) => {
            this._biasLatLng.next({
              lat: res?.[0].geometry.location.lat() ?? null,
              lng: res?.[0].geometry.location.lng() ?? null,
            });
          });
      });
    }
  }

  private listenAddress(): void {
    if (!this.addressFormGroup) {
      console.error('AddressFormComponent: Form not found');
      return;
    }

    if (this.addressLine1Control) {
      this.addressLineSearchControl.setValidators(this.addressLine1Control.validator);
      this.addressLineSearchControl.updateValueAndValidity();

      // if the address line control changes values, update the address search contol to have that value
      this.addressLine1Control.valueChanges
        .pipe(startWith(this.addressLine1Control.value), distinctUntilChanged())
        .subscribe((addressLine1) => {
          if (addressLine1 !== this.addressLineSearchControl.value) {
            this.addressLineSearchControl.setValue(addressLine1, { emitEvent: false });
          }
        });

      // if the address search control has an updated value, set it to the value of the addressLine1 this prevents
      // the bug where addressLine1 is empty if the user does not select a value from the google places api
      this.addressLineSearchControl.valueChanges
        .pipe(startWith(this.addressLineSearchControl.value), distinctUntilChanged())
        .subscribe((searchValue) => {
          if (searchValue !== this.addressLine1Control.value) {
            this.addressLine1Control.setValue(searchValue);
          }
        });

      // ensuring the disabled state of the parent is attached to the child.
      this.addressLine1Control.statusChanges
        .pipe(
          startWith(this.addressLine1Control.status),
          map((status) => status === 'DISABLED'),
          distinctUntilChanged()
        )
        .subscribe((isDisabled) => {
          if (isDisabled) {
            if (this.addressLineSearchControl.enabled) {
              this.addressLineSearchControl.disable();
            }
          } else if (this.addressLineSearchControl.disabled) {
            this.addressLineSearchControl.enable();
          }
        });
    }

    this.addressOptions$ = this.googleMapsService.mapsLoaded$
      .pipe(
        switchMap(() =>
          combineLatest([
            this.addressLineSearchControl.valueChanges.pipe(
              startWith(this.addressLineSearchControl.value),
              debounceTime(500)
            ),
            this._biasLatLng$,
          ])
        )
      )
      .pipe(
        filter(() => !this._assigningPlace),
        distinctUntilChanged(),
        switchMap(([searchAddress, bias]) => {
          if (!searchAddress) {
            return of([]);
          }
          const placeSelected = this.findPlaceChoice(searchAddress);
          if (placeSelected) {
            this.assignPlace(placeSelected);
            return of(this.predictionOption());
          }
          return this.googleMapsService.searchAddress(searchAddress, this.countryCode, bias).pipe(
            map((predictions) => {
              this._lastPredictions = predictions || [];
              if (!predictions) {
                return [];
              }

              return this.predictionOption();
            })
          );
        })
      );
  }

  private predictionOption(): OptionSelect[] {
    return this._lastPredictions.map((prediction) => {
      return <OptionSelect>{
        label: prediction.description,
        displayLabel: prediction.description.split(',')[0],
        value: prediction.place_id,
      };
    });
  }

  private assignPlace(selectedPlace: google.maps.places.AutocompletePrediction): void {
    const placeId = selectedPlace.place_id;
    if (!placeId) {
      return;
    }
    this._assigningPlace = true;
    this.googleMapsService
      .geocode({ placeId })
      .pipe(take(1))
      .subscribe((places) => {
        if (Array.isArray(places)) {
          const place = places[0];
          const addressForm = this.placeToAddress(place, selectedPlace.description);
          this.addressFormGroup.patchValue(addressForm, { emitEvent: false });
          this.addressFormGroup.updateValueAndValidity();
          this.addressLine2Component?.matInput?.focus();
          this._assigningPlace = false;
          this.cd.markForCheck();
        }
      });
  }

  private placeToAddress(place: google.maps.places.PlaceResult, description: string): AddressForm {
    const address: AddressForm = {};

    if (!place) {
      return address;
    }

    // We could use the street number and route in the address_components, but there have been flakiness
    // (Ex 1799 Hwy 50 E -> Hwy 50 E), this avoids this flakeyness.
    address.addressLine1 = `${description.split(',')[0] ?? ''}`.trim();

    for (const { types, long_name, short_name } of place.address_components) {
      if (
        (types.includes('locality') || types.includes('sublocality_level_1')) &&
        !this.nonMutableFields?.includes('cityName')
      ) {
        address.cityName = long_name;
      }
      if (types.includes('administrative_area_level_1') && !this.nonMutableFields?.includes('stateCode')) {
        address.stateCode = short_name;
      }
      if (types.includes('country') && !this.nonMutableFields?.includes('countryCode')) {
        address.countryCode = short_name;
      }
      if (types.includes('postal_code') && !this.nonMutableFields?.includes('postalCode')) {
        address.postalCode = long_name;
      }
    }
    return address;
  }

  private findPlaceChoice(placeId: string): google.maps.places.AutocompletePrediction | undefined {
    return this._lastPredictions.find((p) => p.place_id === placeId);
  }
}
