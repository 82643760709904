<div class="ltlcc-Alert-icon">
  <mat-icon>{{ alertIcon }}</mat-icon>
</div>

<div class="ltlcc-Alert-content">
  <span *ngIf="title" class="ltlcc-Alert-title">{{ title }}</span>
  <!--
    do not use innerHTML for the description text, this is used in api error messages and its a vulnerability if
    the api sends down api errors with html in it
  -->
  <span class="ltlcc-Alert-description" [innerHTML]="description"></span>
  <ng-content></ng-content>
</div>

<div *ngIf="snackBarRef" class="ltlcc-Alert-closeButton">
  <mat-icon (click)="close()">close</mat-icon>
</div>
