<div class="AccountInformationModal">
  <div class="AccountInformationModal-title">
    <h1 mat-dialog-title>
      {{ translatePrefix + 'accountInformation' | translate }}
    </h1>

    <mat-icon [mat-dialog-close]="data">close</mat-icon>
  </div>

  <mat-divider class="AccountInformationModal-divider"></mat-divider>

  <div mat-dialog-content class="AccountInformationModal-content">
    <span>
      <b>{{ translatePrefix + 'note' | translate }}:</b>
      {{ translatePrefix + 'note1' | translate }}
      <a [href]="mailToUpdate">{{ accountEmail }}</a>
    </span>
    <div>
      <mat-checkbox class="checkbox" [formControl]="formAccountInfo.get('preferredAccount')">
        {{ translatePrefix + 'preferredAccounts' | translate }}
      </mat-checkbox>
    </div>

    <div class="AccountInformationModal-content-wrapper">
      <div class="AccountInformationModal-content-wrapper-column">
        <div class="AccountInformationModal-content-wrapper-column-column">
          <span>
            <b> {{ data.account.name }} </b>
          </span>
          <span> {{ data.account.addressLine1 }} </span>
          <span>
            {{ data.account.cityName }} {{ data.account.stateCode }} {{ data.account.countryCode }}
            {{ data.account.postalCode }}
          </span>
        </div>
        <div>
          <span>
            <b> {{ translatePrefix + 'xpoLogisticsContacts' | translate }} </b>
          </span>
        </div>
        <div>
          <span>
            <b> {{ translatePrefix + 'nationalSales' | translate }}: </b>
          </span>
          <a *ngIf="formatted.nationalSales.mailto" [href]="formatted.nationalSales.mailto">
            {{ formatted.nationalSales.fullName | titlecase }}
          </a>
        </div>
        <div>
          <span>
            <b>{{ translatePrefix + 'invoicingContact' | translate }}:</b>
          </span>
          <span> {{ formatted.invoicing.fullName | titlecase }} {{ formatted.invoicing.phoneNumber }} </span>
        </div>
      </div>
      <div class="AccountInformationModal-content-wrapper-column">
        <div>
          <span>
            <b> {{ translatePrefix + 'accountCode' | translate }}: </b>
          </span>
          <span> {{ data.account.madCode }} </span>
        </div>
        <div>
          <span>
            <b> {{ translatePrefix + 'accountType' | translate }}:</b>
          </span>
          <span> {{ data.account.accountId }} </span>
        </div>
        <div>
          <span>
            <b>{{ translatePrefix + 'accountClass' | translate }}: </b>
          </span>
          <span> {{ formatted.accountClass }} </span>
        </div>
        <div>
          <span>
            <b> {{ translatePrefix + 'creditStatus' | translate }}: </b>
          </span>
          <span>
            {{ formatted.creditStatusCode | titlecase }}
          </span>
        </div>
        <div>
          <a
            *ngIf="!isCorporateAccount"
            [routerLink]="historyRoute"
            [queryParams]="historyQueryParams"
            [mat-dialog-close]="true"
          >
            {{ translatePrefix + 'viewHistory' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
