import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CountryCode } from '@ltlc/api';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';
import { OptionSelect } from '../base/interfaces/form-controls.interface';

@Component({
  selector: 'ltlcc-form-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCountryComponent extends FormControlsBaseComponent {
  private readonly countriesOptions: OptionSelect[] = Object.values(CountryCode).map((code: CountryCode) => {
    return { value: code, label: code };
  });
  _countries: OptionSelect[] = this.countriesOptions;

  @Input() set countries(countries: OptionSelect[]) {
    this._countries = Array.isArray(countries) ? countries : this.countriesOptions;
  }

  @Input()
  get hiddenCountryCodes(): CountryCode[] {
    return this._hiddenCountryCodes;
  }
  set hiddenCountryCodes(codes: CountryCode[]) {
    this._hiddenCountryCodes = codes;
    this._countries = this.countriesOptions.filter((option) => !this.hiddenCountryCodes.includes(option.value));
  }
  private _hiddenCountryCodes: CountryCode[] = [];

  getDefaultLabel(): string {
    return this.translate.instant('form.labels.country');
  }
}
