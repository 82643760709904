<ng-container *ngIf="isMexico; else defaultSelect">
  <ng-container *ngIf="regions$ | async as regions; else mexicoCitiesLoading">
    <ng-container *ngTemplateOutlet="mexicoCitiesAutocomplete; context: { regions }"></ng-container>
  </ng-container>
  <ng-template #mexicoCitiesLoading>
    <ng-container *ngTemplateOutlet="mexicoCitiesAutocomplete"></ng-container>
  </ng-template>
  <ng-template #mexicoCitiesAutocomplete let-regions="regions">
    <ltlcc-auto-complete
      [formControl]="control"
      [required]="required"
      [options]="regions"
      [label]="label"
      [attr.data-cy]="dataCy"
    >
      <mat-hint [attr.data-cy]="'state-select-hint'">
        {{ 'rateQuote.mxHint1' | translate }}
        <a href="mailto:LTL.Mexico@xpo.com">{{ 'form.labels.email' | translate }}</a
        >{{ 'rateQuote.mxHint2' | translate }}
      </mat-hint>
    </ltlcc-auto-complete>
  </ng-template>
</ng-container>

<ng-template #defaultSelect>
  <mat-form-field appearance="outline">
    <mat-label *ngIf="label"
      >{{ label }} {{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
    >
    <mat-select [formControl]="control" [required]="required" [attr.data-cy]="dataCy">
      <mat-option *ngFor="let region of regions$ | async" [value]="region.value">
        {{ region.label }}
      </mat-option>
    </mat-select>
    <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  </mat-form-field>
</ng-template>
