export enum DimensionUnit {
  cm = 'CM',
  feet = 'Feet',
  inch = 'INCH',
  meters = 'Meters',
}

export enum WeightUnit {
  lbs = 'LBS',
  kg = 'KG',
}
