import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsOptionInListPipe, ltlccIsListInOptionPipe } from './option-in-list.pipe';

@NgModule({
  declarations: [IsOptionInListPipe, ltlccIsListInOptionPipe],
  imports: [CommonModule],
  exports: [IsOptionInListPipe, ltlccIsListInOptionPipe],
})
export class IsOptionInListPipeModule {}
