import { Provider } from '@angular/core';
import { of } from 'rxjs';
import { WebConfigService } from './web-config.service';

export const configManagerStub = {
  production: false,
  COOKIE_DOMAIN: 'localhost',
  IS_DEBUG_ENABLED: true,
  region: 'TCTSI',
};

/**
 * A test version of the ConfigManagerService
 * @example
 * { provide: ConfigManagerService, useFactory: MockConfigManagerService}
 */
export const MockConfigManagerService: Provider = {
  provide: WebConfigService,
  useValue: {
    getSettings: () => {},
    getSetting: (propertyName: string) => {
      return configManagerStub[propertyName];
    },
    init: () => of({}),
    parseArray: (settings: any) => {},
  },
};
