import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { XpoIcon } from './xpo-icon.enum';

@Component({
  selector: 'ltlcc-icon',
  template: '',
  styleUrls: ['./xpo-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoIconComponent {
  private readonly hostClass = 'ltlcc-XpoIcon';

  @HostBinding('class') classes: string = this.hostClass;

  @Input() set iconName(iconName: XpoIcon) {
    this.classes = `${this.hostClass} ${iconName}`;
  }
}
