<ltlcc-select
  formControlName="open"
  [label]="'accountDetails.dockingInterval.open' | translate"
  [options]="readyTimeOptions"
  [attr.data-cy]="'dockingInterval-open'"
></ltlcc-select>

<ltlcc-select
  formControlName="close"
  [label]="'accountDetails.dockingInterval.close' | translate"
  [options]="dockTimeOptions"
  [attr.data-cy]="'dockingInterval-close'"
></ltlcc-select>
