import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URI } from '../tokens';
import {
  CreateDailyShipmentSubscription,
  DailyShipmentSubscription,
  ShipmentDigestDeleteResponse,
  ShipmentDigestSubscriptionListResponse,
  ShipmentDigestUpsertResponse,
} from './interfaces/shipment-evt-activity.interface';

@Injectable({
  providedIn: 'root',
})
export class ShipmentEvtActivityService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get externalBaseUrlShipmentdigest(): string {
    return `${this.apiUri}/shipmentevtactivity/1.0/`;
  }

  getShipmentDigestSubscriptions(): Observable<DailyShipmentSubscription[]> {
    const url = `${this.externalBaseUrlShipmentdigest}shipment-digest-subscriptions`;
    return this.httpClient
      .get<ShipmentDigestSubscriptionListResponse>(url)
      .pipe(map((result: ShipmentDigestSubscriptionListResponse) => result.data.shipmentDigestSubscriptions));
  }

  createShipmentDigestSubscription(body: CreateDailyShipmentSubscription): Observable<DailyShipmentSubscription> {
    const url = `${this.externalBaseUrlShipmentdigest}shipment-digest-subscriptions`;
    return this.httpClient
      .post<ShipmentDigestUpsertResponse>(url, body)
      .pipe(map((result: ShipmentDigestUpsertResponse) => result.data.shipmentDigestSubscription));
  }

  updateShipmentDigestSubscription(
    body: CreateDailyShipmentSubscription,
    idShipmentDigest: string
  ): Observable<DailyShipmentSubscription> {
    const url = `${this.externalBaseUrlShipmentdigest}shipment-digest-subscriptions/${idShipmentDigest}`;
    return this.httpClient
      .put<ShipmentDigestUpsertResponse>(url, body)
      .pipe(map((result: ShipmentDigestUpsertResponse) => result.data.shipmentDigestSubscription));
  }

  deleteShipmentDigestSubscription(idShipmentDigest: string): Observable<ShipmentDigestDeleteResponse> {
    const url = `${this.externalBaseUrlShipmentdigest}shipment-digest-subscriptions/${idShipmentDigest}`;
    return this.httpClient.delete<ShipmentDigestDeleteResponse>(url);
  }
}
