import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { API_URI } from '../tokens';
import { EmailMessage } from './interfaces/email-message.interface';

@Injectable({ providedIn: 'root' })
export class InfrastructureService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get infrastructureURI(): string {
    return `${this.apiUri}/infrastructure/1.0/`;
  }

  sendEmail(emailMessage: EmailMessage): Observable<boolean> {
    const url = `${this.infrastructureURI}messages/email`;
    return this.httpClient.post(url, { emailMessage }).pipe(mapTo(true));
  }
}
