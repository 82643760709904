<h1 mat-dialog-title>
  {{ translatePrefix + 'commodityInformation' | translate }}
</h1>
<div mat-dialog-content>
  <p>
    <strong> {{ translatePrefix + 'dimensionsTitle' | translate }}: </strong>
    {{ translatePrefix + 'dimensionsDescription1' | translate }}
  </p>

  <p>
    <strong> {{ translatePrefix + 'excessiveLengthTitle' | translate }} - </strong>
    {{ translatePrefix + 'excessiveLengthDescription' | translate }}
  </p>

  <p>
    <strong> {{ translatePrefix + 'unitsTitle' | translate }} - </strong>
    {{ translatePrefix + 'unitsDescription' | translate }}
  </p>

  <p>
    <strong> {{ translatePrefix + 'palletsTitle' | translate }} - </strong>
    {{ translatePrefix + 'palletsDescription' | translate }}
  </p>

  <p>
    <strong> {{ translatePrefix + 'excessValueCoverageTitle' | translate }} - </strong>
    {{ translatePrefix + 'excessValueCoverageDescription' | translate }}
    <a *ngIf="tariffLibraryLink" rel="dns-prefetch" target="_blank" [href]="tariffLibraryLink">
      {{ translatePrefix + 'TariffLibrary' | translate }}
    </a>
  </p>
</div>

<mat-dialog-actions>
  <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>
    {{ 'defaults.close' | translate }}
  </button>
</mat-dialog-actions>
