export enum PackageType {
  CTN = 'CTN',
  PLT = 'PLT',
  PCS = 'PCS',
  SKD = 'SKD',
  BAG = 'BAG',
  BAL = 'BAL',
  BBL = 'BBL',
  BDL = 'BDL',
  BIN = 'BIN',
  BOX = 'BOX',
  BSK = 'BSK',
  BTL = 'BTL',
  BXT = 'BXT',
  CAB = 'CAB',
  CAG = 'CAG',
  CAN = 'CAN',
  CAS = 'CAS',
  CBY = 'CBY',
  CHS = 'CHS',
  CNT = 'CNT',
  COL = 'COL',
  CRT = 'CRT',
  CYL = 'CYL',
  DRM = 'DRM',
  ENV = 'ENV',
  FRM = 'FRM',
  IBC = 'IBC',
  JAR = 'JAR',
  JER = 'JER',
  JUG = 'JUG',
  KEG = 'KEG',
  KIT = 'KIT',
  LSE = 'LSE',
  MSC = 'MSC',
  PAL = 'PAL',
  PKG = 'PKG',
  PLF = 'PLF',
  RCK = 'RCK',
  REL = 'REL',
  ROL = 'ROL',
  SAK = 'SAK',
  SLV = 'SLV',
  SPL = 'SPL',
  TBE = 'TBE',
  TBN = 'TBN',
  TNK = 'TNK',
  TRY = 'TRY',
  TUB = 'TUB',
}
