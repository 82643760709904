import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';
import { FocusHelper } from '../base/helpers/focus.helper';
import { OptionSelect } from '../base/interfaces/form-controls.interface';

@Component({
  selector: 'ltlcc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends FormControlsBaseComponent implements AfterViewInit {
  @Input() options: OptionSelect[] = [];
  @Input() inputSuffix: string;
  @Input() inputPrefix: string;
  @Input() autofocus: boolean;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }
  private _multiple = false;

  @ViewChild(MatSelect, { static: true, read: ElementRef }) selectEl: ElementRef;

  ngAfterViewInit(): void {
    if (this.selectEl && this.autofocus) {
      setTimeout(() => {
        FocusHelper.focusElement(this.selectEl.nativeElement);
      }, 0);
    }
  }

  get selectControl(): FormControl {
    return <FormControl>this.parentControl?.control ?? <FormControl>this.control;
  }
}
