import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { AnimationsHelper } from '../../helpers/animations-helper';
import { FileHelper } from './file.helper';
@UntilDestroy()
@Component({
  selector: 'ltlcc-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-FileDrop' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [AnimationsHelper.bounce],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FileDropComponent implements OnInit {
  dragover = false;
  @Input() filesControlName: string;
  @Input() multiple = true;
  //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
  @Input() accept: string;

  constructor(private mainForm: FormGroupDirective, private cd: ChangeDetectorRef) {}

  get files(): FormArray {
    return <FormArray>this.mainForm.form.get(this.filesControlName);
  }

  ngOnInit(): void {
    this.files.statusChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(() => this.cd.markForCheck());
  }

  remove(index: number) {
    this.files.removeAt(index);
  }

  selectFiles(event): void {
    const files: FileList = event.target.files;

    FileHelper.addFiles(files, this.files, this.accept, this.multiple);
  }

  previewFile(file): void {
    const url = URL.createObjectURL(file);
    window.open(url);
  }
}
