import { AccountType, CountryCode } from '@ltlc/api';
import { ExtraAccounts, LocationAccountFields } from '../enums';

export interface LocationAccount {
  [LocationAccountFields.accountInstId]?: string | ExtraAccounts;
  [LocationAccountFields.companyCode]?: string;
  [LocationAccountFields.addressLine1]?: string;
  [LocationAccountFields.addressLine2]?: string;
  [LocationAccountFields.cityName]?: string;
  [LocationAccountFields.stateCd]?: string;
  [LocationAccountFields.postalCd]?: string;
  [LocationAccountFields.countryCd]?: CountryCode;
  [LocationAccountFields.email]?: string;
  [LocationAccountFields.phone]?: string;
  [LocationAccountFields.extension]?: string;
  [LocationAccountFields.locationType]?: AccountType;
  [LocationAccountFields.contactName]?: string;
  [LocationAccountFields.contactPhone]?: string;
  [LocationAccountFields.contactEmail]?: string;
  [LocationAccountFields.dockOpenTime]?: string;
  [LocationAccountFields.dockCloseTime]?: string;
  [LocationAccountFields.comments]?: string;
}
