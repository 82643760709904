<mat-form-field appearance="outline">
  <mat-label *ngIf="label">
    {{ label }}
    {{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}
  </mat-label>
  <input
    [formControl]="numberControl"
    [required]="required"
    type="number"
    matInput
    [attr.data-cy]="dataCy"
    [placeholder]="placeholder"
    autocomplete="off"
    [attr.max]="max"
    [attr.min]="min"
    (blur)="handleBlur($event)"
  />
  <mat-icon matPrefix *ngIf="'$' === inputPrefix">attach_money</mat-icon>
  <span matPrefix *ngIf="inputPrefix && '$' !== inputPrefix">{{ inputPrefix }} &nbsp;</span>
  <span matSuffix *ngIf="inputSuffix">{{ inputSuffix }}</span>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
