<button class="ltlcc-ShellUserAccount-menu" mat-icon-button [matMenuTriggerFor]="menu">
  {{ initials }}
</button>

<mat-menu #menu="matMenu" class="ltlcc-ShellUserAccount-popover">
  <div (click)="$event.stopPropagation()" class="ltlcc-ShellUserAccount-popover-detail">
    <span class="ltlcc-ShellUserAccount-popover-detail-displayName">{{ displayName | titlecase }}</span>
    <span class="ltlcc-ShellUserAccount-popover-detail-supportingData" *ngFor="let data of supportingData">{{
      data
    }}</span>
  </div>
  <div class="ltlcc-ShellUserAccount-popover-links">
    <ng-container *ngFor="let link of links">
      <a *ngIf="link.isExternalUrl; else useRouterLink" ref="dns-prefetch" [href]="link.url" target="_blank">
        <span>{{ link.name }}</span>
      </a>
      <ng-template #useRouterLink>
        <a [routerLink]="link.url" class="ltlc-Utils-new">
          {{ link.name }}
          <mat-chip-list>
            <mat-chip *ngIf="link.isNew" ltlccAChip>
              {{ 'defaults.new' | translate | titlecase }}
            </mat-chip>
          </mat-chip-list></a
        >
      </ng-template>
    </ng-container>
  </div>
  <ng-container *ngIf="configUser?.processSignOut || actions?.length">
    <mat-divider></mat-divider>
    <button *ngFor="let action of actions" mat-menu-item (click)="action.handleAction()">
      {{ action.name }}
    </button>
    <!-- TODO: Translate -->
    <button *ngIf="configUser?.processSignOut" mat-menu-item (click)="configUser.processSignOut()">
      {{ 'defaults.signOut' | translate }} <mat-icon>output</mat-icon>
    </button>
  </ng-container>
</mat-menu>
