import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormControlsModule, FormErrorMessageModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { LtlConnectFormControlModule } from '../form-controls';
import { AddressFormComponent } from './address-form.component';

@NgModule({
  declarations: [AddressFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormControlsModule,
    LtlConnectFormControlModule,
    TranslateModule,
    FormErrorMessageModule,
  ],
  exports: [AddressFormComponent],
})
export class AddressFormModule {}
