import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { XpoAuthenticationGuard, XpoAuthenticationService, XpoAuthUser } from '@xpo/ngx-auth';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap, timeoutWith } from 'rxjs/operators';
import { XpoLtlAuthenticationService } from '../authentication';

@Injectable({ providedIn: 'root' })
export class NgxAuthTokenGuard implements CanLoad, CanActivate {
  private _ltlAuthRan = false;

  get ltlAuthRan(): boolean {
    return this._ltlAuthRan;
  }

  constructor(
    private xpoLtlAuthenticationService: XpoLtlAuthenticationService,
    private loggedInUserService: XpoAuthenticationService,
    private xpoAuthenticationGuard: XpoAuthenticationGuard,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(this.xpoAuthenticationGuard.canActivate(route, state)).pipe(
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          return of(false);
        }

        return this.isAllowed();
      })
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const routeToUse = { path: this.getPathFromCurrentNavigation() };
    return of(this.xpoAuthenticationGuard.canLoad(routeToUse, segments)).pipe(
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          return of(false);
        }

        return this.isAllowed();
      })
    );
  }

  private isAllowed(): Observable<boolean> {
    return this.xpoLtlAuthenticationService.isAuthorized().pipe(
      switchMap((isAuthorized: boolean) => {
        if (isAuthorized) {
          return of(true);
        }
        return this.xpoLtlAuthenticationService.initAuthSetup$().pipe(
          // wait 10 seconds, then quit, remove in future if users are not getting this error
          timeoutWith(10 * 1000, of('').pipe(tap(() => console.error('ERROR: timeout on firebase init')))),
          take(1),
          switchMap(() => {
            this._ltlAuthRan = true;

            return this.loggedInUserService.getUser$().pipe(
              take(1),
              map((user: XpoAuthUser) => {
                return !!user?.access_token;
              })
            );
          }),
          catchError((err) => {
            console.error('Error occurred with logging in');
            console.error(err);
            this._ltlAuthRan = true;
            return of(false);
          })
        );
      })
    );
  }

  private getPathFromCurrentNavigation(): string {
    // TODO: move this to the canLoad function in the XpoAuthenticationGuard, the route on the canLoad guard does
    // not bring the entire path, just the path that the guard is on.
    // https://stackoverflow.com/questions/44538256/angular2-how-to-get-full-path-on-canload-guard-while-maintaining-redirect-url
    const extractedUrl = this.router.getCurrentNavigation().extractedUrl;
    let path = extractedUrl.root.children['primary']?.segments.map((v) => v.path).join('/') ?? '/';
    if (extractedUrl.queryParamMap.keys.length) {
      path += '?';
      extractedUrl.queryParamMap.keys.forEach((key, indexKeyParam) => {
        const queryParamsValue: string | string[] = extractedUrl.queryParams[key];
        if (Array.isArray(queryParamsValue)) {
          queryParamsValue.forEach((value, indexQueryParam) => {
            path += `${key}=${value}${indexQueryParam !== queryParamsValue.length - 1 ? '&' : ''}`;
          });
        } else {
          path += `${key}=${queryParamsValue}${
            indexKeyParam !== extractedUrl.queryParamMap.keys.length - 1 ? '&' : ''
          }`;
        }
      });
    }

    return path;
  }
}
