import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ConfirmationDialogData } from './confirmation-dialog.interface';

@Component({
  selector: 'ltlcc-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  host: { class: 'ltlc-ConfirmationDialog' },
})
export class ConfirmationDialogComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: ConfirmationDialogData,
    private cd: ChangeDetectorRef,
    private errorService: ErrorHandlerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (!this.data) {
      this.dialogRef.close(false);
      console.error('no data defined!');
    } else {
      this.data.titleIcon = this.data.titleIcon || 'info_outline';
      this.data.actionText = this.data.actionText || this.translate.instant('defaults.close');
      this.data.hideCancelButton =
        this.data.actionText === this.translate.instant('defaults.close') ? true : this.data.hideCancelButton;
    }
  }

  confirm(): void {
    if (this.data.actionText === this.translate.instant('defaults.close')) {
      this.dialogRef.close(false);
      return;
    }
    if (this.data.handleConfirmation && this.data.actionText !== this.translate.instant('defaults.close')) {
      this.isLoading = true;
      this.data
        .handleConfirmation()
        .pipe(
          take(1),
          catchError((error) => {
            this.dialogRef.close(false);
            return throwError(error);
          }),
          this.errorService.snackbarOnError(),
          finalize(() => {
            this.isLoading = false;
            this.cd.markForCheck();
          })
        )
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    } else {
      this.dialogRef.close(true);
    }
  }
}
