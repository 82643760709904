<div class="ltlc-EmailList">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'shipments.shipmentDetails.NOTIFICATIONS.ENTER_EMAIL_ADDRESS' | translate }}</mat-label>
    <mat-chip-list #emailChipList class="ltlc-EmailList-emailInput-input">
      <mat-chip
        *ngFor="let email of emails"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(email)"
      >
        {{ email }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        [formControl]="emailForm.get('emailList')"
        [matChipInputFor]="emailChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
      <button mat-icon-button>
        <mat-icon (click)="removeAll()">delete</mat-icon>
      </button>
    </mat-chip-list>
    <mat-error *ngIf="emailChipList.errorState">{{
      'shipments.shipmentDetails.NOTIFICATIONS.MAX_EMAIL_MESSAGE' | translate : { maxEmail: max }
    }}</mat-error>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</div>
