import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WebConfigService } from '@ltlc/core';
import { Observable, Observer } from 'rxjs';
import { AnalyticsData } from './consts';
import { XpoTrackingAnalyticsService } from './tracking-analytics-base.service';

declare var gtag: Function;

@Injectable({ providedIn: 'root' })
export class XpoGtagAnalyticsService extends XpoTrackingAnalyticsService {
  constructor(@Inject(DOCUMENT) document: Document, private config: WebConfigService) {
    super(document);
  }

  trackEvent(eventName: string, username: string, eventValue?: Object): void {
    gtag('event', eventName, { user_id: username, ...(eventValue ?? {}) });
  }

  trackPageView(url: string, user?: AnalyticsData): void {
    if (typeof gtag !== 'undefined') {
      gtag('config', this.trackingId(), { page_path: url, client_id: user?.userId, user_id: user?.emailAddress });
    }
  }

  protected insertScript(): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      const s = this.document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://www.googletagmanager.com/gtag/js?id=${this.trackingId()}`;
      s.text = '';
      s.async = true;

      s.onload = () => {
        observer.next(true);
        observer.complete();
      };

      s.onerror = (error: any) => {
        observer.error('Couldnt load script' + s.src);
      };

      this.appendToHead(s);
    });
  }
  protected finalizeScriptInsertion(): void {
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${this.trackingId()}');
    `;
    this.appendToHead(s);
    console.log('GTAG initialized');
  }

  protected initialConfigurationSetup(): void {
    if (typeof gtag !== 'undefined') {
      const appName = this.config.getSetting('appName');
      const region = this.config.getSetting('region');
      gtag('config', this.trackingId(), {
        app_name: appName,
        custom_map: { dimension1: 'environment' },
      });
      gtag('set', { environment: region });
    }
  }

  protected trackingId(): string {
    return this.config.analyticsTrackingId;
  }
}
