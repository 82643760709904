<mat-form-field [appearance]="appearance">
  <mat-label *ngIf="label"
    >{{ label }}{{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <input
    type="text"
    matInput
    [formControl]="textControl"
    [required]="required"
    [attr.data-cy]="dataCy"
    [name]="id ? id : null"
    [id]="id ? id : null"
    [autocomplete]="autocomplete ? autocomplete : 'off'"
    [placeholder]="placeholder"
    [maxlength]="maxLength"
    [ltlccRemoveCharacters]="removeCharactersRegex"
    [readonly]="readonly"
    (blur)="handleBlur($event)"
  />
  <span matPrefix *ngIf="inputPrefix">{{ inputPrefix }}&nbsp;</span>
  <span matSuffix *ngIf="inputSuffix">{{ inputSuffix }}</span>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
