import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConnectDialogCloseButtonComponent } from './dialog-close-button/dialog-close-button.component';

@NgModule({
  declarations: [ConnectDialogCloseButtonComponent],
  imports: [CommonModule, MatButtonModule, MatLegacyDialogModule, MatIconModule],
  exports: [MatLegacyDialogModule, ConnectDialogCloseButtonComponent],
})
export class ConnectDialogModule {}
