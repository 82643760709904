<div class="ltlcc-DrawerWrapper-header" fxLayout="row" fxLayoutAlign="space-between">
  <div class="ltlcc-DrawerWrapper-title">
    <ng-content select="[ltlccDrawerTitle]"></ng-content>
  </div>
  <div class="ltlcc-DrawerWrapper-actions">
    <ng-content select="[ltlccDrawerActions]"></ng-content>
    <button
      *ngIf="parentDrawerComponent"
      mat-icon-button
      (click)="close()"
      [attr.data-cy]="'parentDrawerComponent-close'"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="ltlcc-DrawerWrapper-content">
  <ng-content></ng-content>
</div>
