import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormControlsModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { IsOptionInListPipeModule } from '../../pipes/option-in-list/option-in-list-pipe.module';
import { LtlConnectFormControlModule } from '../form-controls';
import { AccountDetailsFormComponent } from './account-details-form.component';

@NgModule({
  declarations: [AccountDetailsFormComponent],
  imports: [
    CommonModule,
    IsOptionInListPipeModule,
    FormControlsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatExpansionModule,
    LtlConnectFormControlModule,
  ],
  exports: [AccountDetailsFormComponent],
})
export class AccountDetailsFormModule {}
