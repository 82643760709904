import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

export interface ArrayValueCellRendererParams extends ICellRendererParams {
  hideExpand?: (node: RowNode) => boolean;
}

@Component({
  selector: 'ltlcc-array-value-cell-renderer',
  templateUrl: './array-value-cell-renderer.component.html',
  styleUrls: ['./array-value-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrayValueCellRendererComponent implements ICellRendererAngularComp {
  arrayValue: any[] = [];
  showMore = false;
  hideExpand = false;

  refresh(params: ArrayValueCellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return true;
  }

  agInit(params: ArrayValueCellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  handleToggleClick(event: Event): void {
    event.stopPropagation();
    this.showMore = !this.showMore;
  }

  private mapParamsToTemplateValues(params: ArrayValueCellRendererParams): void {
    this.arrayValue = params.value ?? [];
    this.hideExpand = params.hideExpand ? params.hideExpand(params.node) : false;
  }
}
