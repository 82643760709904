import { Observable, of } from 'rxjs';
import {
  CalculateShipmentDensityResp,
  CalculateShipmentDensityRqst,
  CalculateTransitTimeRequest,
  CalculatorApiRequest,
  CurrencyExchangeApiResponse,
  ExchangeListDataApiResponse,
  GeoCityStateCountryResponseData,
} from '../shippingtools';
import { ShippingtoolsService } from './../shippingtools/shippingtools.service';

export const MockShippingToolsService = {
  provide: ShippingtoolsService,
  useValue: <ShippingtoolsService>{
    shippingtoolURI: '',
    getServiceCenterInfo: (postalCd: string) => of({}),
    calculateTransitTime: (calcTransitTimeRequest: CalculateTransitTimeRequest) => of({}),
    postalCodeIsAddressNeeded: (val) => of(false),
    geoCityStateCountry: (postalCd: string): Observable<GeoCityStateCountryResponseData[]> => of([]),
    getExchange: (calculation: CalculatorApiRequest): Observable<CurrencyExchangeApiResponse> =>
      of(<CurrencyExchangeApiResponse>{}),
    getExchangeList: (period: string): Observable<ExchangeListDataApiResponse> => of(<ExchangeListDataApiResponse>{}),
    calculateDensity: (transformedRequest: CalculateShipmentDensityRqst): Observable<CalculateShipmentDensityResp> =>
      of(<CalculateShipmentDensityResp>{}),
    isProNumberLegacy: (proNumber: string): Observable<boolean> => of(true),
  },
};
