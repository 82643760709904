import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormGroup, FormGroupName } from '@angular/forms';
import { CountryCode } from '@ltlc/api';
import { OptionSelect } from '@ltlc/core';
import {
  AccountFieldFormConfig,
  AccountFormConfig,
} from '../account-list-light/interfaces/account-list-light.interface';
import { AccountFormHelper } from './account-form.helper';
import { AccountForm } from './account-form.interface';

@Component({
  selector: 'ltlcc-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupName }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'ltlcc-AccountForm' },
})
export class AccountFormComponent implements OnInit, AfterViewInit {
  fullWidthClass = AccountFormHelper.fullWidthClass;
  fieldConfigs = new Map<keyof AccountForm, AccountFieldFormConfig>();
  countries: OptionSelect[];

  @Input() dataCy?: string;
  @Input() fieldConfig: AccountFormConfig;
  @ViewChild('grid') grid: ElementRef;

  constructor(@Optional() private formGroupName: FormGroupName) {}

  ngOnInit(): void {
    if (this.fieldConfig?.fields) {
      Object.entries(this.fieldConfig.fields).forEach(([key, value]) => {
        this.fieldConfigs.set(<keyof AccountForm>key, value);
      });
      if (Array.isArray(this.fieldConfig.fields.countryCode?.options)) {
        this.countries = this.fieldConfig.fields.countryCode.options;
      }
    }
  }
  ngAfterViewInit(): void {
    AccountFormHelper.applyWidths(this.grid?.nativeElement.children);
  }

  get addressGroupName(): string {
    return this.formGroupName?.name?.toString() || '';
  }

  get accountDetailsForm(): FormGroup | null {
    return this.formGroupName?.control as FormGroup;
  }

  get countryCode(): CountryCode | null {
    return this.accountDetailsForm.get('countryCode')?.value ?? null;
  }
}
