import { AccessorialRqResponse, CountryCode, LegacyCountryCode } from '@ltlc/api';
import { Observable, of } from 'rxjs';

export class AccessorialListHelper {
  static getAccessorialList(
    origCountry: CountryCode | string | null,
    destCountry: CountryCode | string | null
  ): Observable<AccessorialRqResponse[]> {
    if (
      [CountryCode.UNITED_STATES, CountryCode.CANADA, LegacyCountryCode.LEGACY_CANADA].includes(
        <CountryCode>origCountry
      ) &&
      [CountryCode.UNITED_STATES, CountryCode.CANADA, LegacyCountryCode.LEGACY_CANADA].includes(
        <CountryCode>destCountry
      )
    ) {
      return of(AccessorialListHelper.accessorialListUSCA);
    }
    if (
      [CountryCode.MEXICO, CountryCode.CANADA, LegacyCountryCode.LEGACY_CANADA].includes(<CountryCode>origCountry) &&
      [CountryCode.MEXICO, CountryCode.CANADA, LegacyCountryCode.LEGACY_CANADA].includes(<CountryCode>destCountry)
    ) {
      return of(AccessorialListHelper.accessorialListMXCA);
    }
    if (
      [CountryCode.MEXICO, CountryCode.UNITED_STATES].includes(<CountryCode>origCountry) &&
      [CountryCode.MEXICO, CountryCode.UNITED_STATES].includes(<CountryCode>destCountry)
    ) {
      return of(AccessorialListHelper.accessorialListMXUS);
    }
    return of([]);
  }

  /**
   * This list is for combinations:
   * - US/US
   * - US/CA
   * - CA/US
   * - CA/CA
   */
  static get accessorialListUSCA(): AccessorialRqResponse[] {
    return [
      {
        accessorialCd: 'APT',
        accessorialDesc: 'APT APPOINTMENT REQUIRED',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'BLS',
        accessorialDesc: 'BLIND SHIPMENT',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'IBC',
        accessorialDesc: 'IBC IN BOND TIR CARNET',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OLG',
        accessorialDesc: 'OLG ORIG LIFTGATE SERVICE',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DPW',
        accessorialDesc: 'DPW DEST PIER/WHARF LOAD/UNLOAD',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'ELS',
        accessorialDesc: 'ELS EXCESSIVE LENGTH SHIPMENT',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'TDC',
        accessorialDesc: 'TDC TIME DATE CRITICAL',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'CSD',
        accessorialDesc: 'CSD CONSTR/UTILITY SITE DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'SRT',
        accessorialDesc: 'SRT DEST PALLETIZE, SORT, SEGREGATE',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'WHI',
        accessorialDesc: 'INSIDE PKUP/DLVRY TRUCK LEVEL',
        accessorialType: 'Hawaii',
      },
      {
        accessorialCd: 'DSH',
        accessorialDesc: 'DSH DEST SAT, SUN, HOLIDAY DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'CIB',
        accessorialDesc: 'CIB CUSTOMS INBOND U.S.',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'WHN',
        accessorialDesc: 'INSIDE PKUP/DLVRY NON-TRUCK LEVEL',
        accessorialType: 'Hawaii',
      },
      {
        accessorialCd: 'DID',
        accessorialDesc: 'DID DEST INSIDE DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'ZHM',
        accessorialDesc: 'ZHM HAZMAT',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'RSD',
        accessorialDesc: 'RSD DEST RESIDENTIAL DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DLA',
        accessorialDesc: 'DLA DEST DESTINATION LIMITED ACCESS',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'SSC',
        accessorialDesc: 'SSC SINGLE SHIPMENT',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'GUR',
        accessorialDesc: 'GUR XPO LOGISTICS GUARANTEED!',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'OSH',
        accessorialDesc: 'OSH ORIG SAT, SUN, HOLIDAY PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DCF',
        accessorialDesc: 'DCF DEST CONTAINER FREIGHT STATION',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'G12',
        accessorialDesc: 'G12 XPO LOGISTICS GUARANTEED BEFORE NOON!',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'DMS',
        accessorialDesc: 'DMS GOVT AIRPORT MINE GAS OIL DLVRY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DAH',
        accessorialDesc: 'DAH AFTER BUSINESS HOURS DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'RPD',
        accessorialDesc: 'RPD REMOVAL OF PALLET/DEBRIS ',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'XFZ',
        accessorialDesc: 'Freezable Protection Service',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'RSO',
        accessorialDesc: 'RSO ORIG RESIDENTIAL PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OLA',
        accessorialDesc: 'OLA ORIG ORIGIN LIMITED ACCESS',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OSS',
        accessorialDesc: 'OSS ORIG PALLETIZE, SORT, SEGREGATE',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'PED',
        accessorialDesc: 'PED DEST EXHIBITION SITES DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'EVC',
        accessorialDesc: 'ELS EXCESSIVE VALUE SHIPMENT',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'OCF',
        accessorialDesc: 'OCF ORIG CONTAINER FREIGHT STATION',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OMS',
        accessorialDesc: 'OMS GOVT AIRPORT MINE GAS OIL PCKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OIP',
        accessorialDesc: 'OIP ORIG INSIDE PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DNC',
        accessorialDesc: 'DNC DEST NOTIFICATION',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'PEO',
        accessorialDesc: 'PEO ORIG EXHIBITION SITES PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DLG',
        accessorialDesc: 'DLG DEST LIFTGATE SERVICE',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OCS',
        accessorialDesc: 'OCS CONSTR/UTILITY SITE PICKUP',
        accessorialType: 'Origin',
      },
    ];
  }

  /**
   * This list is for combinations:
   * - MX/CA
   * - CA/MX
   */
  static get accessorialListMXCA(): AccessorialRqResponse[] {
    return [
      {
        accessorialCd: 'APT',
        accessorialDesc: 'APT APPOINTMENT REQUIRED',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'BLS',
        accessorialDesc: 'BLIND SHIPMENT',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'IBC',
        accessorialDesc: 'IBC IN BOND TIR CARNET',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OLG',
        accessorialDesc: 'OLG ORIG LIFTGATE SERVICE',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DPW',
        accessorialDesc: 'DPW DEST PIER/WHARF LOAD/UNLOAD',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'ELS',
        accessorialDesc: 'ELS EXCESSIVE LENGTH SHIPMENT',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'TDC',
        accessorialDesc: 'TDC TIME DATE CRITICAL',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'CSD',
        accessorialDesc: 'CSD CONSTR/UTILITY SITE DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'SRT',
        accessorialDesc: 'SRT DEST PALLETIZE, SORT, SEGREGATE',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DSH',
        accessorialDesc: 'DSH DEST SAT, SUN, HOLIDAY DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'CIB',
        accessorialDesc: 'CIB CUSTOMS INBOND U.S.',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DID',
        accessorialDesc: 'DID DEST INSIDE DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'ZHM',
        accessorialDesc: 'ZHM HAZMAT',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'RSD',
        accessorialDesc: 'RSD DEST RESIDENTIAL DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DLA',
        accessorialDesc: 'DLA DEST DESTINATION LIMITED ACCESS',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'SSC',
        accessorialDesc: 'SSC SINGLE SHIPMENT',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'GUR',
        accessorialDesc: 'GUR XPO LOGISTICS GUARANTEED!',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'OSH',
        accessorialDesc: 'OSH ORIG SAT, SUN, HOLIDAY PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DCF',
        accessorialDesc: 'DCF DEST CONTAINER FREIGHT STATION',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'G12',
        accessorialDesc: 'G12 XPO LOGISTICS GUARANTEED BEFORE NOON!',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'DMS',
        accessorialDesc: 'DMS GOVT AIRPORT MINE GAS OIL DLVRY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DAH',
        accessorialDesc: 'DAH AFTER BUSINESS HOURS DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'RPD',
        accessorialDesc: 'RPD REMOVAL OF PALLET/DEBRIS',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'XFZ',
        accessorialDesc: 'Freezable Protection Service',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'RSO',
        accessorialDesc: 'RSO ORIG RESIDENTIAL PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OLA',
        accessorialDesc: 'OLA ORIG ORIGIN LIMITED ACCESS',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OSS',
        accessorialDesc: 'OSS ORIG PALLETIZE, SORT, SEGREGATE',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'PED',
        accessorialDesc: 'PED DEST EXHIBITION SITES DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'EVC',
        accessorialDesc: 'ELS EXCESSIVE VALUE SHIPMENT',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'OCF',
        accessorialDesc: 'OCF ORIG CONTAINER FREIGHT STATION',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DRA',
        accessorialDesc: 'DRA BORDER CROSSING SERVICE',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'OMS',
        accessorialDesc: 'OMS GOVT AIRPORT MINE GAS OIL PCKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OIP',
        accessorialDesc: 'OIP ORIG INSIDE PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DNC',
        accessorialDesc: 'DNC DEST NOTIFICATION',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'PEO',
        accessorialDesc: 'PEO ORIG EXHIBITION SITES PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DLG',
        accessorialDesc: 'DLG DEST LIFTGATE SERVICE',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OCS',
        accessorialDesc: 'OCS CONSTR/UTILITY SITE PICKUP',
        accessorialType: 'Origin',
      },
    ];
  }

  /**
   * This list is for combinations:
   * - MX/US
   * - US/MX
   */
  static get accessorialListMXUS(): AccessorialRqResponse[] {
    return [
      {
        accessorialCd: 'BLS',
        accessorialDesc: 'BLIND SHIPMENT',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OCF',
        accessorialDesc: 'DCF DEST CONTAINER FREIGHT STATION',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DCF',
        accessorialDesc: 'DCF DEST CONTAINER FREIGHT STATION',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OSH',
        accessorialDesc: 'DSH DEST SAT, SUN, HOLIDAY DELIVERY',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DSH',
        accessorialDesc: 'DSH DEST SAT, SUN, HOLIDAY DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OCS',
        accessorialDesc: 'OCS CONSTR/UTILITY SITE PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OPW',
        accessorialDesc: 'OPW ORIG PIER/WHARF LOAD/UNLOAD',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DAH',
        accessorialDesc: 'DAH AFTER BUSINESS HOURS DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'RPD',
        accessorialDesc: 'RPD REMOVAL OF PALLET/DEBRIS',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'APT',
        accessorialDesc: 'APT APPOINTMENT REQUIRED',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DMS',
        accessorialDesc: 'DMS GOVT AIRPORT MINE GAS OIL DLVRY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DPW',
        accessorialDesc: 'DPW DEST PIER/WHARF LOAD/UNLOAD',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'SSC',
        accessorialDesc: 'SSC SINGLE SHIPMENT',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'RSD',
        accessorialDesc: 'RSD DEST RESIDENTIAL DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DLA',
        accessorialDesc: 'RSD DEST DESTINATION LIMITED ACCESS',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OLG',
        accessorialDesc: 'OLG ORIG LIFTGATE SERVICE',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'XFZ',
        accessorialDesc: 'Freezable Protection Service',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'OLA',
        accessorialDesc: 'OLA ORIG ORIGIN LIMITED ACCESS',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'OSS',
        accessorialDesc: 'OSS ORIG PALLETIZE, SORT, SEGREGATE',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'DRA',
        accessorialDesc: 'DRA BORDER CROSSING SERVICE',
        accessorialType: 'Both',
      },
      {
        accessorialCd: 'CSD',
        accessorialDesc: 'CSD CONSTR/UTILITY SITE DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OMS',
        accessorialDesc: 'OMS GOVT AIRPORT MINE GAS OIL PCKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'SRT',
        accessorialDesc: 'SRT DEST PALLETIZE, SORT, SEGREGATE',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'OIP',
        accessorialDesc: 'OIP ORIG INSIDE PICKUP',
        accessorialType: 'Origin',
      },
      {
        accessorialCd: 'WHI',
        accessorialDesc: 'INSIDE PKUP/DLVRY TRUCK LEVEL',
        accessorialType: 'Hawaii',
      },
      {
        accessorialCd: 'DNC',
        accessorialDesc: 'DNC DEST NOTIFICATION',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DSH',
        accessorialDesc: 'DSH DEST SAT, SUN, HOLIDAY DELIVERY',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'WHN',
        accessorialDesc: 'INSIDE PKUP/DLVRY NON-TRUCK LEVEL',
        accessorialType: 'Hawaii',
      },
      {
        accessorialCd: 'DLG',
        accessorialDesc: 'DLG DEST LIFTGATE SERVICE',
        accessorialType: 'Destination',
      },
      {
        accessorialCd: 'DID',
        accessorialDesc: 'DID DEST INSIDE DELIVERY',
        accessorialType: 'Destination',
      },
    ];
  }
}
