import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlsModule } from '@ltlc/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProNumberSearchComponent } from './pro-number-search.component';

@NgModule({
  declarations: [ProNumberSearchComponent],
  imports: [CommonModule, FormControlsModule, TranslateModule, ReactiveFormsModule],
  exports: [ProNumberSearchComponent],
})
export class ProNumberSearchModule {}
