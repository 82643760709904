import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CopyableCellRendererConfig } from './copyable-cell-renderer.interface';

@Component({
  selector: 'ltlcc-copyable-cell-renderer',
  template: `<ltlcc-copyable class="ltlcc-CopyableCellRenderer" [copiedText]="copiedText" [toCopy]="content">{{
    content
  }}</ltlcc-copyable>`,
  styleUrls: ['./copyable-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CopyableCellRendererComponent implements ICellRendererAngularComp {
  @HostBinding('class.ltlcc-CopyableCellRenderer--linkLike')
  enableBlueText = true;

  content: string;
  copiedText?: string;

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  private mapParamsToTemplateValues(params: ICellRendererParams & CopyableCellRendererConfig): void {
    this.content = params.value;
    this.copiedText = params.copiedText;
    this.enableBlueText = !params.suppressBlueText;
  }
}
