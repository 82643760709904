import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';

//TODO: Check if we should just parse in parentControl.control in all controlValueAccessor components like this one
@Component({
  selector: 'ltlcc-form-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent extends FormControlsBaseComponent implements AfterViewInit {
  @Input() inputSuffix: string;
  @Input() inputPrefix: string;
  @Input() autofocus: boolean;
  @Input() maxLength: number;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() readonly = false;

  @ViewChild(MatInput) matInput: MatInput;

  ngAfterViewInit(): void {
    if (this.matInput && coerceBooleanProperty(this.autofocus)) {
      this.matInput.focus();
    }
  }

  get textControl(): FormControl {
    return <FormControl>this.parentControl?.control ?? <FormControl>this.control;
  }
}
