import { Provider } from '@angular/core';
import { of } from 'rxjs';
import { AlertsApiService } from '../alerts-api/alerts-api.service';

export const MockAlertsApiService: Provider = {
  provide: AlertsApiService,
  useValue: {
    getShipmentsAlerts: () => of(null),
    subscribeToGroup: () => of(null),
    unsubscribeFromGroup: () => of(null),
    getSubscriptions: () => of(null),
    upsertSubscription: () => of(null),
    getSubscriptionsFromElastic: () => of(null),
    unsubscribeFromSubscription: () => of(null),
    getEventItems: () => of(null),
  },
};
