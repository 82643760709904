import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { XpoLtlAuthenticationService } from '@ltlc/auth';
import { LtlConnectUserService } from '@ltlc/ltl-core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NotAcceptableInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        try {
          const userService = this.injector.get(LtlConnectUserService);

          if (err.status === 406 && userService.isLoggedIn()) {
            const ltlAuthenticationService = this.injector.get(XpoLtlAuthenticationService);

            return ltlAuthenticationService.revokeToken().pipe(
              switchMap(() => ltlAuthenticationService.getAccessToken()),
              switchMap(() => next.handle(req))
            );
          }
        } catch {
          return throwError(err);
        }

        return throwError(err);
      })
    );
  }
}
