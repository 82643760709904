<ng-container *ngIf="base64 && !error">
  <div *ngFor="let n of pageCountArray(numPages) | async; let i = index">
    <pdfjs-viewer
      class="ltlc-Base64ToPdf-viewer"
      [page]="i + currentPage"
      [url]="base64Url"
      (pdfError)="setErrorViewer($event)"
      (rendered)="rendered($event?.detail)"
    ></pdfjs-viewer>
  </div>

  <div class="ltlc-Base64ToPdf-pageTool" *ngIf="numPages > 1 && !showAllPages">
    <mat-icon [class.disabled]="currentPage === 1" *ngIf="numPages > 2" (click)="currentPage = 1">first_page</mat-icon>
    <mat-icon [class.disabled]="currentPage === 1" (click)="changePage(-1)">chevron_left</mat-icon>
    <strong class="ltlc-Base64ToPdf-pageTool-counter"> {{ currentPage }} / {{ numPages }} </strong>
    <mat-icon [class.disabled]="currentPage === numPages" (click)="changePage(1)">chevron_right</mat-icon>
    <mat-icon [class.disabled]="currentPage === numPages" *ngIf="numPages > 2" (click)="currentPage = numPages"
      >last_page</mat-icon
    >
  </div>
</ng-container>

<div class="ltlc-Base64ToPdf-fakePage" *ngIf="!base64">
  <div class="ltlc-Base64ToPdf-fakePage-content" *ngIf="!error">
    <span class="ltlc-skeleton-animated" style="width: 20%; height: 2rem"></span>
    <br />

    <table>
      <tbody>
        <tr>
          <td><span class="ltlc-skeleton-animated" style="width: 30%"></span></td>
          <td><span class="ltlc-skeleton-animated" style="width: 40%"></span></td>
        </tr>
        <tr>
          <td><span class="ltlc-skeleton-animated" style="width: 60%"></span></td>
          <td><span class="ltlc-skeleton-animated" style="width: 90%"></span></td>
        </tr>
        <tr>
          <td><span class="ltlc-skeleton-animated" style="width: 10%"></span></td>
          <td><span class="ltlc-skeleton-animated" style="width: 50%"></span></td>
        </tr>
        <tr>
          <td><span class="ltlc-skeleton-animated" style="width: 30%"></span></td>
          <td><span class="ltlc-skeleton-animated" style="width: 40%"></span></td>
        </tr>
        <tr>
          <td><span class="ltlc-skeleton-animated" style="width: 80%"></span></td>
          <td><span class="ltlc-skeleton-animated" style="width: 60%"></span></td>
        </tr>
        <tr>
          <td><span class="ltlc-skeleton-animated" style="width: 75%"></span></td>
          <td><span class="ltlc-skeleton-animated" style="width: 25%"></span></td>
        </tr>
      </tbody>
    </table>
    <br />
    <p class="ltlc-skeleton-animated" style="width: 90%"></p>
    <p class="ltlc-skeleton-animated" style="width: 95%"></p>
    <p class="ltlc-skeleton-animated" style="width: 100%"></p>
    <p class="ltlc-skeleton-animated" style="width: 80%"></p>
    <p class="ltlc-skeleton-animated" style="width: 78%"></p>
    <p class="ltlc-skeleton-animated" style="width: 88%"></p>
    <p class="ltlc-skeleton-animated" style="width: 50%"></p>
    <p class="ltlc-skeleton-animated" style="width: 100%"></p>
    <p class="ltlc-skeleton-animated" style="width: 90%"></p>
  </div>
  <div class="ltlc-Base64ToPdf-fakePage-overlay">
    <mat-spinner *ngIf="!error"></mat-spinner>

    <div *ngIf="error" class="ltlc-Base64ToPdf-fakePage-overlay-error">
      <mat-icon class="ltlc-Base64ToPdf-fakePage-overlay-error-warningIcon">warning</mat-icon>
      <span class="ltlc-Base64ToPdf-fakePage-overlay-error-warningText">
        {{ 'pdf.errorLoadingPdf' | translate }}
      </span>
      <button
        *ngIf="enableTryAgain"
        mat-raised-button
        class="ltlc-Base64ToPdf-fakePage-overlay-error-warningTryAgain"
        color="warn"
        (click)="tryAgain.emit(true)"
      >
        {{ 'webforms.unsuccessfulPage.defaultTryAgain' | translate }}
      </button>
    </div>
  </div>
</div>
