import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { Observable, of } from 'rxjs';
import { Base64PDFModal } from './base64-to-pdf.interface';
@Component({
  selector: 'ltlcc-base64-to-pdf-viewer',
  templateUrl: './base64-to-pdf-viewer.component.html',
  styleUrls: ['./base64-to-pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ltlc-Base64ToPdf mat-elevation-z2',
  },
})
export class Base64ToPdfViewerComponent {
  error?: HttpErrorResponse;
  base64: string;
  numPages = 1;
  currentPage = 1;
  base64Url: any;
  enableTryAgain?: boolean;

  @Input() zoom: number;
  @Input() showAllPages: false;
  @Input() set pdfConfig(pdfConfig: Base64PDFModal | null) {
    this.error = pdfConfig?.error;
    this.enableTryAgain = pdfConfig?.enableTryAgain;
    if (!pdfConfig?.contentType) {
      return;
    }

    this.base64 = pdfConfig.contentType;
    this.base64Url = {
      data: atob(this.base64),
    };
  }

  @Output() tryAgain = new EventEmitter<boolean>();

  setErrorViewer(error: string): void {
    if (error) {
      this.error = new HttpErrorResponse({ error });
    }
  }

  rendered(pdfDetail?: PDFDocumentProxy): void {
    if (!pdfDetail) {
      return;
    }
    this.numPages = pdfDetail.numPages;
  }

  changePage(page: number): void {
    const newPage = this.currentPage + page;
    if (newPage < 1 || newPage > this.numPages) {
      return;
    }
    this.currentPage = newPage;
  }
  pageCountArray(n: number): Observable<number[]> {
    if (!this.showAllPages) {
      return of(Array(1));
    }
    return of(Array(n));
  }
}
