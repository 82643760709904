import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AlertService, FormControlsBaseComponent, OptionSelect, XpoSnackBarStatus } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { SpecialShipmentOptions } from '../../../consts';
import { AccessorialPurCodes } from '../../../enums';
import { LegacyOptionSelect } from '../../../interfaces';

@Component({
  selector: 'ltlcc-form-select-accessorials-pur',
  templateUrl: './select-accessorials-pur.component.html',
  styleUrls: ['./select-accessorials-pur.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAccessorialsPurComponent extends FormControlsBaseComponent {
  accessorials: OptionSelect[] = SpecialShipmentOptions.filter(
    (item: LegacyOptionSelect) => item.code !== AccessorialPurCodes.apptInd
  ).map((item: LegacyOptionSelect) => {
    return { value: item.code, label: this.translate.instant(`accessorials.${item.name}`) };
  });

  @Input() set exceeding20kLbs(exceeds: boolean) {
    const doesExceed = coerceBooleanProperty(exceeds);
    if (doesExceed !== this._exceeding20kLbs) {
      this._exceeding20kLbs = doesExceed;
    }
  }

  private readonly prefixKeyAccessorials = 'createPickupRequest.accessorialAlerts.';
  private readonly alertConfig = {
    status: 'warn' as XpoSnackBarStatus,
    duration: this.alertService.LONGER_DURATION,
  };
  private readonly allAccessorialCodes = <AccessorialPurCodes[]>(
    Object.values(SpecialShipmentOptions).map((special) => special.code)
  );
  private _exceeding20kLbs: boolean;
  private weekendAlertShown: boolean;
  private premAlertShown: boolean;

  constructor(
    private alertService: AlertService,
    public translate: TranslateService,
    public cd: ChangeDetectorRef,
    parentControl: NgControl
  ) {
    super(translate, cd, parentControl);
  }

  openedChange(opened: boolean): void {
    if (opened) {
      this.selectAccessorialChange();
    }
  }

  selectAccessorialChange(): void {
    // Enable every option to start with a clean initial state
    this.toggledDisablingAccessorials(this.allAccessorialCodes, false);
    // keep this as one of the first conditions
    if (this._exceeding20kLbs) {
      this.setInitialExclusivity();
    }
    const accessorials = [...(this.control.value ?? [])];
    const premSelected = accessorials.some((accessory) => accessory === AccessorialPurCodes.prem);
    const hazmatSelected = accessorials.some((accessory) => accessory === AccessorialPurCodes.hazmat);
    const freezableSelected = accessorials.some((accessory) => accessory === AccessorialPurCodes.freezble);
    const rrsSelected = accessorials.some((accessory) => accessory === AccessorialPurCodes.rapidRemoteService);
    const guaranteedByNoonSelected = accessorials.some(
      (accessory) => accessory === AccessorialPurCodes.guaranteedByNoon
    );
    const guaranteedSelected = accessorials.some((accessory) => accessory === AccessorialPurCodes.guaranteedService);
    const weekendHolidayDelivery = accessorials.some(
      (accessory) => accessory === AccessorialPurCodes.weekendHolidayDelivery
    );

    // keep this as one of the first conditions
    if (weekendHolidayDelivery && !this.weekendAlertShown) {
      this.weekendAlertShown = true; // This boolean is used to show the alert once only
      this.alertService.showGeneralMessage({
        body: this.translate.instant(`${this.prefixKeyAccessorials}WEEKEND_HOLIDAY_DELIVERY`),
        ...this.alertConfig,
      });
    }

    if (premSelected && !this.premAlertShown) {
      this.premAlertShown = true;
      this.alertService.showGeneralMessage({
        body: this.translate.instant(`${this.prefixKeyAccessorials}MABD_ALERT`),
        ...this.alertConfig,
      });
    } else if (!premSelected) {
      this.premAlertShown = false;
    }

    // If "hazmat" or "freezable" options are selected, G!, RRS and G12 option must be disabled
    if (freezableSelected || hazmatSelected) {
      const accessorialsToDisable = [AccessorialPurCodes.rapidRemoteService, AccessorialPurCodes.guaranteedByNoon];
      this.unselectAccessorial(accessorialsToDisable);
      this.toggledDisablingAccessorials(accessorialsToDisable);
      if (rrsSelected || guaranteedByNoonSelected) {
        const keyTranslate = rrsSelected ? 'RRS' : guaranteedByNoonSelected ? 'G12' : null;
        this.alertService.showGeneralMessage({
          title: this.translate.instant(`${this.prefixKeyAccessorials}PUR_CREATE_PICKUP_${keyTranslate}_ERROR`),
          body: this.translate.instant(
            freezableSelected
              ? `${this.prefixKeyAccessorials}PUR_CREATE_PICKUP_FREEZABLE_${keyTranslate}_ERROR`
              : `${this.prefixKeyAccessorials}PUR_CREATE_PICKUP_HAZMAT_${keyTranslate}_ERROR`
          ),
          ...this.alertConfig,
        });
      }
      return;
    }

    // If G12 option is selected, disable G!, RRS and freezable protection
    if (guaranteedByNoonSelected) {
      const accessorialsToDisable = [
        AccessorialPurCodes.guaranteedService,
        AccessorialPurCodes.rapidRemoteService,
        AccessorialPurCodes.freezble,
      ];
      this.unselectAccessorial(accessorialsToDisable);
      this.toggledDisablingAccessorials(accessorialsToDisable);
      return;
    }

    // if RRS or G! are selected, disable G!12 and freezable protection
    if (rrsSelected || guaranteedSelected) {
      const accessorialsToDisable = [AccessorialPurCodes.guaranteedByNoon, AccessorialPurCodes.freezble];
      this.unselectAccessorial(accessorialsToDisable);
      this.toggledDisablingAccessorials(accessorialsToDisable);
      return;
    }
  }

  private setInitialExclusivity(): void {
    const accessorialsToDisable = [AccessorialPurCodes.guaranteedService, AccessorialPurCodes.guaranteedByNoon];
    this.unselectAccessorial(accessorialsToDisable, this._exceeding20kLbs);
    this.toggledDisablingAccessorials(accessorialsToDisable, this._exceeding20kLbs);
  }

  private unselectAccessorial(selectedAccessorials: AccessorialPurCodes[], isSelected: boolean = true): void {
    if (isSelected) {
      const newAccessorialsSource = this.control.value?.filter((acc) => !selectedAccessorials.includes(acc));
      this.control.setValue(newAccessorialsSource);
    }
  }

  private toggledDisablingAccessorials(toggledAccessorials: AccessorialPurCodes[], toggled: boolean = true): void {
    this.accessorials.forEach((acc) => {
      if (!!toggledAccessorials.find((toggledAcc) => acc.value === toggledAcc)) {
        acc.disabled = toggled;
      }
    });
  }
}
