import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'ltlc-ag-grid-row-loading',
  templateUrl: './ag-grid-row-loading.component.html',
  styleUrls: ['./ag-grid-row-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridRowLoadingComponent implements ICellRendererAngularComp {
  isLoading = false;

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return true;
  }

  private mapParamsToTemplateValues(params: ICellRendererParams): void {
    this.isLoading = !params.data;
  }
}
