import { Injectable } from '@angular/core';
import { XpoLtlAuthenticationService } from '@ltlc/auth';
import { ConfirmationDialogService } from '@ltlc/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  // 45mins, avg. lunch break
  private readonly idleTimeInSeconds = 3600;
  private confirmationDialogIsOpen = false;

  constructor(
    private idle: Idle,
    private confirmationService: ConfirmationDialogService,
    private translateService: TranslateService,
    private authenticationService: XpoLtlAuthenticationService
  ) {}

  init(): void {
    this.idle.setIdle(this.idleTimeInSeconds);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.subscribe(() => {
      if (!this.confirmationDialogIsOpen) {
        this.confirmationDialogIsOpen = true;
        // Clear the token so fresh one can be fetched if user hits cancel instead of refresh.
        this.authenticationService.logout();

        this.confirmationService
          .open({
            title: this.translateService.instant('sessionTimeout.title'),
            body: this.translateService.instant('sessionTimeout.body'),
            actionText: this.translateService.instant('sessionTimeout.refresh'),
            actionColor: 'primary',
          })
          .pipe(take(1))
          .subscribe((confirm) => {
            this.confirmationDialogIsOpen = false;

            if (confirm) {
              window.location.href = window.location.href;
            }
          });
      }
    });
    this.idle.watch();
  }
}
