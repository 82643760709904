import { Injectable } from '@angular/core';
import { EmailMessage } from '@ltlc/api';
import { WebConfigService } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountEmailTemplateParams } from './add-account-modal.interface';

@Injectable({ providedIn: 'root' })
export class EmailTemplateService {
  constructor(private config: WebConfigService, private translate: TranslateService) {}

  removeAccountEmailTemplate(params: AccountEmailTemplateParams): EmailMessage {
    const cleanParams: AccountEmailTemplateParams = this.cleanParams(params);
    return {
      from: cleanParams.fromEmail,
      fromName: cleanParams.fromName.split(' ')[0],
      messageBody: `Account ${cleanParams.accountName}
                    Account Type: ${this.translate.instant(`locationType.${cleanParams.locationType}`)}
                    Location: ${cleanParams.country}
                    Company Name: ${cleanParams.companyName}
                    PRO Number: ${cleanParams.proNumber}
                    Address Line: ${cleanParams.addressLine1}
                    Address Line 2: ${cleanParams.addressLine2}
                    City: ${cleanParams.city}
                    State/Province: ${cleanParams.stateCode}
                    Zip Code: ${cleanParams.postalCode}
                    -----------------------
      `.trim(),
      sentDateTime: new Date(),
      subject: this.translate.instant('accountList.deleteAccount.emailSubject'),
      to: this.config.getSetting('accountEmail'),
    };
  }

  addAccountEmailTemplate(params: AccountEmailTemplateParams): EmailMessage {
    const template = this.removeAccountEmailTemplate(params);
    template.subject = this.translate.instant('accountList.addAccount.emailSubject');
    return template;
  }

  private cleanParams(params): any {
    const cleanParams = {};
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key]?.trim() ?? '-';
        cleanParams[key] = value;
      }
    }
    return cleanParams;
  }
}
