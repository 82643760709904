import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CopyableModule } from '../../copyable/copyable.module';
import { CopyableCellRendererComponent } from './copyable-cell-renderer.component';

@NgModule({
  declarations: [CopyableCellRendererComponent],
  imports: [CommonModule, CopyableModule, MatButtonModule],
  exports: [CopyableCellRendererComponent],
})
export class CopyableCellRendererModule {}
