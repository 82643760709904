import { AbstractControl } from '@angular/forms';
import { CountryCode } from '@ltlc/api';
import { ZipMaskHelper } from '@ltlc/core';

export class ZipCodeValidator {
  static US = (control: AbstractControl) => {
    return ZipMaskHelper.isValidPostalCode(control.value, CountryCode.UNITED_STATES) ? null : { zipCodeUS: true };
  };

  static CA = (control: AbstractControl) => {
    return ZipMaskHelper.isValidPostalCode(control.value, CountryCode.CANADA) ? null : { zipCodeCA: true };
  };
}
