export class AccountFormHelper {
  static readonly fullWidthClass = 'ltlc-Utils-grid-spanColumnAll';

  static elementWidths(formControls: HTMLCollection): number[] | null {
    if (!formControls) {
      return null;
    }

    const layout = Array.from(formControls).map((control) =>
      control.classList.contains(AccountFormHelper.fullWidthClass) ? 2 : 1
    );

    return layout;
  }

  static balanceWidths(itemWidths: number[]): number[] | null {
    if (!Array.isArray(itemWidths)) {
      return null;
    }

    const prevPairWidth = (index: number) => {
      return (itemWidths[index - 1] ?? 0) + (itemWidths[index - 2] ?? 0);
    };

    itemWidths = [...itemWidths];
    let sumWidth = 0;
    for (let index = 0; index < itemWidths.length; index++) {
      const width = itemWidths[index];
      if ((sumWidth + width) % 2 !== 0) {
        if (
          (!itemWidths[index - 1] || itemWidths[index - 1] === 2 || prevPairWidth(index) === 2) &&
          (itemWidths[index + 1] === 2 || !itemWidths[index + 1])
        ) {
          itemWidths[index] = 2;
          sumWidth += 2;
          continue;
        }
      }

      sumWidth += width;
    }

    return itemWidths;
  }

  static applyWidths(formControls: HTMLCollection): void {
    if (!formControls) {
      return;
    }
    const childWidths = AccountFormHelper.elementWidths(formControls);
    const balanced = AccountFormHelper.balanceWidths(childWidths);

    const threeColumns = {
      phonePostal: [],
    };

    for (let index = 0; index < balanced.length; index++) {
      const width = balanced[index];
      if (width === 2 && !formControls.item(index).classList.contains(AccountFormHelper.fullWidthClass)) {
        formControls.item(index).classList.add(AccountFormHelper.fullWidthClass);
      }

      const formControlName = formControls.item(index).attributes.getNamedItem('formcontrolname')?.value;
      if (formControlName && ['postalCode', 'phoneNumber', 'phoneExtension'].includes(formControlName)) {
        threeColumns.phonePostal.push(index);
      }
    }

    for (const key in threeColumns) {
      if (Object.prototype.hasOwnProperty.call(threeColumns, key)) {
        const columns = threeColumns[key];
        if (columns.length === 3) {
          columns.forEach((index) => {
            formControls.item(index).classList.remove(AccountFormHelper.fullWidthClass);
            // Grid has 6 columns, hence 2/3 of the grid
            formControls.item(index).classList.add('ltlc-Utils-grid-spanColumn2');
          });
        }
      }
    }
  }
}
