import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { NgxAuthTokenGuard } from '@ltlc/auth';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { APP_ROUTE_PATHS } from '../../app-core/constants/routes.const';

@Injectable({ providedIn: 'root' })
export class IsAuthenticatedGuard implements CanActivate, CanLoad {
  constructor(private ngxAuthTokenGuard: NgxAuthTokenGuard, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return environment.bypassAuth
      ? of(true)
      : this.ngxAuthTokenGuard.canLoad(route, segments).pipe(tap((val) => this.goToNotAllowedIfFalse(val)));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return environment.bypassAuth
      ? of(true)
      : this.ngxAuthTokenGuard.canActivate(route, state).pipe(tap((val) => this.goToNotAllowedIfFalse(val)));
  }

  private goToNotAllowedIfFalse(hasAccess: boolean): void {
    if (!hasAccess && this.ngxAuthTokenGuard.ltlAuthRan) {
      this.router.navigate([APP_ROUTE_PATHS.forbidden]);
      throw Error('User does not have access token');
    }
  }
}
