import { FormControl, FormGroup } from '@angular/forms';
import { CountryCode } from '@ltlc/api';
import { FormValidatorHelper } from '@ltlc/core';
import { AccountDetailsForm } from '../../interfaces/account-details-form.interface';
import { DockingIntervalGroup } from '../form-controls';

export class AccountDetailsFormHelper {
  static accountDetailsForm() {
    return new FormGroup(<{ [Property in keyof AccountDetailsForm] }>{
      madCode: new FormControl(null),
      accountId: new FormControl(null),
      companyName: new FormControl(null, FormValidatorHelper.nameFieldBadCharactersValidator()),
      // TODO: Assess if needed LWAPP-1184
      // careOf: new FormControl(null),
      countryCode: new FormControl(CountryCode.UNITED_STATES),
      addressLines: new FormControl(null),
      cityName: new FormControl(null, FormValidatorHelper.noBadCharacterValidator()),
      stateCode: new FormControl(null),
      postalCode: new FormControl(null),
      phoneNumber: new FormControl(null),
      phoneExtension: new FormControl(null, FormValidatorHelper.noBadCharacterValidator()),
      email: new FormControl(null),
      // TODO: Assess if needed LWAPP-1184
      locationType: new FormControl(null),
      contactFullName: new FormControl(null),
      contactPhoneNumber: new FormControl(null),
      contactEmail: new FormControl(null),
      dockingInterval: new DockingIntervalGroup(),
      comment: new FormControl(null),
      id: new FormControl(null),
    });
  }
}
