import { Provider } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DrawerComponent } from '../drawer.component';

export const MockDrawerComponentProvider: Provider = {
  provide: DrawerComponent,
  useValue: {
    closeDrawerOnError: (v) => catchError((error) => throwError(error)),
  },
};
