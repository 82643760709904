import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[ltlcResetButton]',
  standalone: true,
})
export class ResetButtonDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit() {
    const button = this.el.nativeElement;
    button.classList.add('ltlc-Utils-resetButton');
    button.type = 'reset';
  }

  /**
   * There is a bug in this material version where the ng-invalid class is not removed from the form fields,
   * which causes the form to be invalid even after it has been reset.
   * By clicking the reset button, we are able to remove the ng-invalid class from the form fields.
   */
  reset(): void {
    this.el.nativeElement.click();
  }
}
