import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { DisableToggleDirective } from './disable-toggle/disable-toggle.directive';

/** Module that exports helper function used with the MatExpansionModule */
@NgModule({
  declarations: [DisableToggleDirective],
  imports: [CommonModule],
  exports: [DisableToggleDirective, MatExpansionModule],
})
export class ConnectExpansionModule {}
