import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertService, ArrayHelper, BUSINESS_CONSTRAINTS, SearchComponent } from '@ltlc/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ProNumberHelper } from '../../helpers/pro-number.helper';

@UntilDestroy()
@Component({
  selector: 'ltlcc-pro-number-search',
  templateUrl: './pro-number-search.component.html',
  styleUrls: ['./pro-number-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'ltlcc-ProNumberSearch' },
})
export class ProNumberSearchComponent {
  //TODO: Consider Refactoring component to form control

  readonly textAreaForm: FormGroup = this.fb.group({
    proNumbers: new FormControl(null),
  });

  @Input() placeholder: string;
  @Input() hideClearButton: boolean;
  @Input() hint: string;
  @Input() isTextArea?: boolean = false;
  @Input() enableReferenceNumberSearch = false;
  @Input()
  get suppressSearchByEnter(): boolean {
    return this._suppressSearchByEnter;
  }
  set suppressSearchByEnter(suppressSearchByEnter: boolean) {
    this._suppressSearchByEnter = coerceBooleanProperty(suppressSearchByEnter);
  }
  private _suppressSearchByEnter: boolean = false;
  @Input() autofocus: boolean;
  @Input() set value(value: string) {
    if (this.textAreaForm.get('proNumbers').value !== value) {
      this.textAreaForm.get('proNumbers').setValue(value);
    }
  }
  @Input() allowSpaceSeparation: boolean;
  @Input() maxProNumbers = BUSINESS_CONSTRAINTS.MAX_PRO_NUMBERS;

  @Output() searchValue = new EventEmitter<string[]>();

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  constructor(
    private alertService: AlertService,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {}

  searchByProNumbers(proNumber: string): void {
    const proNumbers = proNumber?.trim();

    if (proNumber) {
      if (
        this.enableReferenceNumberSearch ||
        ProNumberHelper.isValidProNumber(proNumbers, this.allowSpaceSeparation, this.maxProNumbers)
      ) {
        this.emitSearchValue(proNumbers);
      } else {
        this.alertService.showApiError({
          title: this.translateService.instant('shipments.trackingShipment.SHIPMENT_INVALID_TITLE'),
          body: this.translateService.instant('shipments.trackingShipment.SHIPMENT_INVALID_BODY', {
            maxProNumbers: this.maxProNumbers,
          }),
        });
      }
    } else {
      this.searchValue.emit();
    }
  }

  // function to get pro numbers when in text area MODE
  getProNumbersFromTextArea(): void {
    const proNumbers: string = this.textAreaForm.controls.proNumbers.value?.trim();

    if (!proNumbers) {
      return this.searchValue.emit();
    }

    if (
      this.enableReferenceNumberSearch ||
      ProNumberHelper.isValidProNumber(proNumbers, this.allowSpaceSeparation, this.maxProNumbers)
    ) {
      this.emitSearchValue(proNumbers);
    } else {
      this.alertService.showApiError({
        title: 'Invalid',
        body: 'Please enter valid PRO numbers (format: xxx-xxxxxx)',
      });
    }
  }

  clear(): void {
    this.searchComponent.clear();
  }

  handlePasteEvent(clipboardEvent: ClipboardEvent): void {
    const clipboardData = clipboardEvent.clipboardData || (<any>window).clipboardData;
    const pastedText = clipboardData.getData('text');

    if (this.isExcelFormat(pastedText)) {
      clipboardEvent.preventDefault();

      this.searchComponent.setValue(this.excelFormatToCommaSeperators(pastedText));
    }
  }

  private isExcelFormat(text: string): boolean {
    return RegExp(/\s*[\t\n]+\s*/).test(text);
  }

  private excelFormatToCommaSeperators(pastedText: string): string {
    return pastedText
      .replace(/\t+|\n+/g, ',') // replace all \t or \n with ','
      .replace(/^,+|,+$/g, ''); // trim ,'s surrounding string
  }

  private emitSearchValue(proNumbers: string): void {
    const proNumbersArray = proNumbers.split(
      this.allowSpaceSeparation
        ? ProNumberHelper.allowedSeparationWithSpaceRegex
        : ProNumberHelper.allowedSeparationBaseRegex
    );
    this.searchValue.emit(ArrayHelper.removeDuplicates(proNumbersArray).filter((text) => !!text));
  }
}
