import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlsModule } from '../../form-controls/form-controls.module';

// TODO: temp placement for future renderers
@NgModule({
  declarations: [],
  imports: [CommonModule, FormControlsModule, ReactiveFormsModule],
  exports: [],
})
export class AgGridRenderersModule {}
