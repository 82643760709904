import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONNECT_API_URI } from '../tokens';
import {
  CreditCardIframeResponse,
  CreditCardTokenResponse,
  CreditCardTransactionFeeRequest,
  CreditCardTransactionFeeResponse,
  MultiInvoicePaymentInformation,
  MultiInvoiceTransactionRequest,
  PartialPaymentReasonCodeItem,
  PaymentConfirmation,
  PaymentResponse,
  QuoteRequest,
  SavedCardItem,
  TransactionFeeResponse,
  UpdateSavedCardRequest,
} from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class PaymentsApiService {
  constructor(@Inject(CONNECT_API_URI) private baseApiUri: string, private http: HttpClient) {}

  get paymentsURI(): string {
    return `${this.baseApiUri}payment/`;
  }

  get paymentV2URI(): string {
    return `${this.baseApiUri}v2/payment/`;
  }

  getCreditCardIframe(quoteRequest: Partial<QuoteRequest>): Observable<CreditCardIframeResponse> {
    return this.http.post<CreditCardIframeResponse>(this.paymentsURI + 'creditcardcontrols', quoteRequest);
  }

  getTransactionFee(orderAmount: number): Observable<TransactionFeeResponse> {
    const url = this.paymentsURI + 'transactionfee?orderAmount=' + orderAmount;
    return this.http.get<TransactionFeeResponse>(url);
  }

  savedcreditcarddetails(partnerIdentifierCode: string, userName: string): Observable<CreditCardTokenResponse[]> {
    const url = new URL(`${this.paymentsURI}savedcreditcarddetails`);
    url.searchParams.append('userId', userName);
    url.searchParams.append('partnerIdentifierCode', partnerIdentifierCode);
    return this.http.get<CreditCardTokenResponse[]>(url.toString());
  }

  deleteSavedCreditCard(request: SavedCardItem): Observable<CreditCardTokenResponse> {
    const url = this.paymentV2URI + 'deletecredittoken';
    return this.http.post<CreditCardTokenResponse>(url, request);
  }

  updateSavedCreditCard(request: UpdateSavedCardRequest): Observable<CreditCardTokenResponse> {
    const url = this.paymentV2URI + 'updatecredittoken';
    return this.http.post<CreditCardTokenResponse>(url, request);
  }

  submitInvoice(paymentDetails: MultiInvoicePaymentInformation): Observable<PaymentResponse> {
    const url = `${this.paymentV2URI}payByCreditCard`;
    return this.http.post<PaymentResponse>(url, paymentDetails);
  }

  getInvoiceConfirmationDetails(request: MultiInvoiceTransactionRequest): Observable<[PaymentConfirmation]> {
    const url = `${this.paymentV2URI}multiinvoicetransaction/details`;
    return this.http.post<[PaymentConfirmation]>(url, request);
  }

  getPartialPaymentReasonCodeItems(): Observable<PartialPaymentReasonCodeItem[]> {
    const url = `${this.paymentV2URI}getPartialPaymentReasonCodeItems`;
    return this.http.get<PartialPaymentReasonCodeItem[]>(url);
  }

  getCreditCardTransactionFee(request: CreditCardTransactionFeeRequest): Observable<CreditCardTransactionFeeResponse> {
    const url = `${this.paymentV2URI}getCreditCardTransactionFee`;
    return this.http.post<CreditCardTransactionFeeResponse>(url, request);
  }
}
