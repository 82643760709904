import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BUSINESS_CONSTRAINTS, StringFormatHelper } from '@ltlc/core';

export class ProNumberHelper {
  static readonly maxProNumbers = BUSINESS_CONSTRAINTS.MAX_PRO_NUMBERS;
  static readonly allowedSeparationBaseRegex: RegExp = /\s*[,\n\t;]+\s*/;
  static readonly allowedSeparationWithSpaceRegex: RegExp = /\s*[ ,\n\t;]+\s*/;

  static isValidProNumber(
    proSearchInput: string,
    allowSpaceSeparation: boolean,
    maxProNumbers = this.maxProNumbers
  ): boolean {
    if (!proSearchInput?.trim()) {
      return false;
    }
    if (allowSpaceSeparation) {
      proSearchInput = proSearchInput.replace(/[ \t]+/g, ','); // Replace all spaces with commas
    }

    // Create array of PRO(s) and format it
    let proNumbers = [proSearchInput];
    if (this.isMoreThanOnePro(proSearchInput)) {
      proNumbers = proSearchInput.split(this.allowedSeparationBaseRegex);
    } else {
      proSearchInput = proSearchInput.replace('-', '');
      if (!this.isCorrectProNumberFormat(proSearchInput)) {
        return false;
      }
    }

    proNumbers = proNumbers.map(ProNumberHelper.formatProNumber);

    if (proNumbers.length > maxProNumbers) {
      return false;
    }

    return proNumbers
      .map((p) => p.replace('-', ''))
      .filter((v) => !!v)
      .every(this.isCorrectProNumberFormat);
  }

  /**
   * Converts PRO number to standardized 9-digit database format by removing special characters and leading/middle zeros.
   *
   * Examples:
   * - "123-456789"     → "123456789"    // Standard format with dash
   * - "0123-456789"    → "123456789"    // Leading zero removed
   * - "123 456789"     → "123456789"    // Space removed
   * - "000123-456789"  → "123456789"    // Multiple leading zeros removed
   * - "123-0456789"    → "123456789"    // Zero after dash removed
   * - "123456789"      → "123456789"    // Already correct format
   * - "0123 0456789"   → "123456789"    // Leading zeros and space removed
   * - "0001230456789"   → "123456789"    // Extra digits removed from left
   * - "0001230056789"   → "123056789"    // Middle zeros removed
   *
   * @param proNumber - The PRO number string to format
   * @returns A 9-digit string without special characters or leading/middle zeros
   */
  static convertProToDatabaseFormat(proNumber: string): string {
    return (proNumber ?? '')
      .trim()
      .replace(/[^\d]/g, '') // Remove all non-digits
      .replace(/^0+/, '') // Remove leading zeros
      .replace(/(?<=\d{3})0+(?=\d{6}$)/, '') // Remove zeros after first 3 digits
      .slice(-9); // Take last 9 digits only
  }

  static formatProNumber(proNumber: string): string {
    const flatFormat = ProNumberHelper.convertProToDatabaseFormat(proNumber); // 9-digit format: xxxxxxxxx
    const end = flatFormat.slice(3);

    // proNbr format is XXX-YYYYYY
    return !!proNumber ? `${flatFormat.slice(0, 3)}-${end.substring(end.length - 6)}` : '';
  }

  /**
   * Formats any proNumber format to an 11 digit proNumber 0xxx0xxxxxx. Used needed for legacy apis
   *
   * @param proNumber
   * @returns
   */
  static formatTo11DigitPro(proNumber: string): string {
    const normalizedProNumber = ProNumberHelper.convertProToDatabaseFormat(proNumber);
    return `0${normalizedProNumber.slice(0, 3)}0${normalizedProNumber.slice(3, 9)}`;
  }

  private static isCorrectProNumberFormat(input: string): boolean {
    return (
      RegExp(/^\d+$/).test(input) &&
      input.length >= BUSINESS_CONSTRAINTS.LENGTH_PRO_NUMBER_MIN &&
      input.length <= BUSINESS_CONSTRAINTS.LENGTH_PRO_NUMBER_MAX
    );
  }

  private static isMoreThanOnePro(proNum: string): boolean {
    return RegExp(this.allowedSeparationBaseRegex).test(proNum);
  }
}

export const isValidProNumberValidator = (control: AbstractControl): ValidationErrors => {
  const proNumber = control.value?.trim();

  if (!!proNumber) {
    const allowSpaceSeparation = proNumber.includes(' ');
    const isValid = ProNumberHelper.isValidProNumber(proNumber, allowSpaceSeparation, ProNumberHelper.maxProNumbers);
    if (isValid) {
      return null;
    }
  }

  return { invalidProNumber: true };
};
