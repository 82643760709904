<mat-form-field appearance="outline">
  <mat-label *ngIf="label"
    >{{ label }}{{ !required ? ' (' + ('form.labels.optional' | translate) + ') ' : '' }}</mat-label
  >
  <mat-select [formControl]="control" [required]="required" [attr.data-cy]="dataCy">
    <mat-select-trigger class="ltlc-SelectCountry-countryOption" *ngIf="control.value">
      {{ 'countries.' + control.value | translate }}
    </mat-select-trigger>
    <mat-option *ngFor="let countryOption of _countries" [value]="countryOption.value">
      <div class="country-option">
        <ng-container *ngTemplateOutlet="flagEmoji; context: { flag: countryOption.value }"></ng-container>
        {{ 'countries.' + countryOption.label | translate }}
      </div>
    </mat-option>
  </mat-select>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <mat-hint>
    <ng-content select="mat-hint"></ng-content>
    {{ hint }}
  </mat-hint>
</mat-form-field>
