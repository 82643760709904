import { CANADA_STATES_LITERALS, CountryCode, US_STATES_CODES_LITERALS } from '@ltlc/api';
import { Masks } from './../../../../consts/mask-patterns.const';
export class ZipMaskHelper {
  static zipCodesRegex = {
    US: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    CA: /[a-z]\d[a-z][\s\-]?\d[a-z]\d/i, // pattern is --> A2A 2A2  A2A-2A2 A2A2A2
    CA2: /[a-z]\d[a-z][\s\-]\d[a-z]\d/i, // pattern --> A2A 2A2  || A2A-2A2
  };

  static getZipMask(countryCode: CountryCode, zipCode: string = ''): string {
    if (countryCode === CountryCode.UNITED_STATES || countryCode === CountryCode.MEXICO) {
      return Masks.zip.US;
    } else if (countryCode === CountryCode.CANADA) {
      return this.getZipMaskCanada(zipCode);
    } else {
      return Masks.zip.default2;
    }
  }

  static getZipMaskCanada(value: string = ''): string {
    if (value && value.length > 3) {
      const character = value.split('')[3];
      if (character !== '_') {
        if (RegExp(/[\d]/).test(character)) {
          return Masks.zip.CAAux;
        } else {
          return Masks.zip.CA2;
        }
      }
    }
    return Masks.zip.CA;
  }

  static transformCAZipCode(zipCode: string): string {
    if (RegExp(ZipMaskHelper.zipCodesRegex.CA).test(zipCode)) {
      return zipCode.replace(/\s|-/g, '');
    } else {
      return zipCode;
    }
  }

  /**
   * @description Will return a mask for the zip code based on how the incoming string shape is
   * @tutorial https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#mask-function
   * @param  {string} rawValue
   * @returns PatternMask
   */
  static getZipMaskDynamic(rawValue: string): string {
    if (RegExp(/^[a-z]/i).test(rawValue)) {
      return ZipMaskHelper.getZipMaskCanada(rawValue);
    } else if (RegExp(/^[0-9]/).test(rawValue)) {
      return Masks.zip.US;
    }
    return Masks.zip.default;
  }

  static isZipCodeFromCountry(postalCode: string, zipMask: CountryCode): boolean {
    return RegExp(ZipMaskHelper.zipCodesRegex[zipMask]).test(postalCode?.trim());
  }

  static isUSState(stateCode: string): boolean {
    return Object.values(US_STATES_CODES_LITERALS).some((_stateCode) => _stateCode.toString() === stateCode);
  }

  static isCAState(stateCode: string): boolean {
    return Object.values(CANADA_STATES_LITERALS).some((_stateCode) => _stateCode.toString() === stateCode);
  }

  static isValidPostalCode = (postalCode: string, countryCode: string): boolean => {
    return RegExp(ZipMaskHelper.zipCodesRegex[countryCode]).test(postalCode);
  };

  static getCountryCodeFromPostalCode(postalCode: string): CountryCode | null {
    if (!postalCode) {
      return null;
    }
    return ZipMaskHelper.isZipCodeFromCountry(postalCode, CountryCode.UNITED_STATES)
      ? CountryCode.UNITED_STATES
      : ZipMaskHelper.isZipCodeFromCountry(postalCode, CountryCode.CANADA)
      ? CountryCode.CANADA
      : null;
  }
}
