import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommoditiesDeleteApiResponse,
  CommodityItemApiResponse,
  CommodityItemRequest,
  CommodityItemResponse,
  UserMaintenanceService,
} from '@ltlc/api';
import { ArraySortHelper, ErrorHandlerService } from '@ltlc/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { CommodityHelper } from '../helpers/commodity.helper';
import { Commodity, SaveCommodityState } from '../interfaces/commodity.interface';
import { CommodityListState } from '../modules/account-list-light/interfaces/commodity-list-state.interface';
import { LtlConnectUserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LtlConnectCommodityService {
  private readonly _commodityListState$ = new BehaviorSubject<CommodityListState>(null);
  //TODO: fix model
  readonly commodityListState$: Observable<CommodityListState> = this._commodityListState$.asObservable().pipe(
    switchMap((commodityState) => {
      if (!Array.isArray(commodityState?.data)) {
        return this.userMaintenanceService.getCommodities().pipe(
          this.errorHandler.snackbarOnError(),
          map((commodities: CommodityItemApiResponse[]) => {
            const data: CommodityItemApiResponse[] = ArraySortHelper.orderBy(commodities, ['commodityDescription']);
            this._commodityListState$.next({ data });
            return { data };
          })
        );
      }

      return of(commodityState);
    })
  );
  readonly commodityList$: Observable<CommodityItemApiResponse[]> = this.commodityListState$.pipe(
    map((state) => ArraySortHelper.orderBy(state.data, ['commodityDescription']))
  );

  constructor(
    private userMaintenanceService: UserMaintenanceService,
    private errorHandler: ErrorHandlerService,
    private ltlConnectUserService: LtlConnectUserService
  ) {}

  saveCommodity(request: CommodityItemRequest): Observable<SaveCommodityState> {
    return this.checkDuplicateCommodityDescription(request).pipe(
      take(1),
      switchMap((duplicate: boolean) => {
        if (duplicate) {
          this.updateError(new HttpErrorResponse({ error: 'duplicate' }));
          return of(<SaveCommodityState>{
            errorState: 'duplicate',
          });
        }

        return this.userMaintenanceService.saveCommodity(request).pipe(
          catchError((error) => this.updateError(error)),
          map((savedCommodity: CommodityItemResponse) => {
            const insertCommodity: CommodityItemApiResponse = {
              ...request.userCommodity,
              ...savedCommodity.data,
            };
            this.updateCommodityList(insertCommodity);
            return <SaveCommodityState>{
              savedCommodity: insertCommodity,
            };
          })
        );
      })
    );
  }

  updateCommodity(request: CommodityItemRequest): Observable<SaveCommodityState> {
    return this.checkDuplicateCommodityDescription(request, request.userCommodity.commodityId.toString()).pipe(
      take(1),
      switchMap((duplicate: boolean) => {
        if (duplicate) {
          this.updateError(new HttpErrorResponse({ error: 'duplicate' }));
          return of(<SaveCommodityState>{
            errorState: 'duplicate',
          });
        }

        return this.userMaintenanceService.updateCommodity(request, request.userCommodity.commodityId).pipe(
          catchError((error) => this.updateError(error)),
          map((savedCommodity: CommoditiesDeleteApiResponse) => {
            this.updateCommodityList(request.userCommodity);
            return <SaveCommodityState>{
              savedCommodity: request.userCommodity,
            };
          })
        );
      })
    );
  }

  deleteCommodity(idCommodity: string | number): Observable<CommoditiesDeleteApiResponse> {
    return this.userMaintenanceService.deleteCommodity(idCommodity).pipe(
      catchError((error) => this.updateError(error)),
      tap(() => {
        this.removeFromCommodityList(idCommodity);
      })
    );
  }
  getUpsertCommodity(commodity: Commodity): Observable<CommodityItemRequest> {
    return this.ltlConnectUserService.getProfileInstId$().pipe(
      catchError((error) => this.updateError(error)),
      map((profileInstId) => CommodityHelper.transformCommodityItemRequest(commodity, profileInstId, commodity.id))
    );
  }

  private checkDuplicateCommodityDescription(
    request: CommodityItemRequest,
    editIdCommodity?: string
  ): Observable<boolean> {
    return this.commodityList$.pipe(
      map(
        (commodities: CommodityItemApiResponse[]) =>
          !!commodities.find((commodity) => {
            if (editIdCommodity && commodity.commodityId.toString() === editIdCommodity?.toString()) {
              return false;
            }
            return commodity.commodityDescription === request.userCommodity.commodityDescription;
          })
      )
    );
  }

  private updateCommodityList(insertCommodity: CommodityItemApiResponse) {
    let updatedCommodityList: CommodityItemApiResponse[] = this.commodityListValue();
    const existingCommodityIndex = updatedCommodityList.findIndex(
      (commodity) => commodity.commodityId.toString() === insertCommodity.commodityId.toString()
    );
    if (existingCommodityIndex !== -1) {
      updatedCommodityList[existingCommodityIndex] = insertCommodity;
    } else {
      updatedCommodityList = [insertCommodity, ...updatedCommodityList];
    }

    this._commodityListState$.next({ data: updatedCommodityList });
  }

  private removeFromCommodityList(idCommodity: string | number) {
    let updatedCommodityList: CommodityItemApiResponse[] = this.commodityListValue();
    const existingCommodityIndex = updatedCommodityList.findIndex(
      (commodity) => commodity.commodityId.toString() === idCommodity.toString()
    );
    if (existingCommodityIndex !== -1) {
      updatedCommodityList.splice(existingCommodityIndex, 1);
      this._commodityListState$.next({ data: updatedCommodityList });
      return;
    }
    console.error('commodity not found in list');
  }

  private updateError(error: HttpErrorResponse): Observable<never> {
    this._commodityListState$.next({ data: this.commodityListValue(), error });
    return throwError(error);
  }

  private commodityListValue(): CommodityItemApiResponse[] | null {
    return this._commodityListState$.getValue()?.data?.slice() ?? null;
  }
}
