export const environmentBase = (legacyBaseUrl: string, legacyLegacyBaseUrl: string) => ({
  instantGuaranteedLink: 'https://www.xpo.com/help-center/guaranteed-freight-delivery-services/',
  tdcTimeDateCritic: 'https://www.xpo.com/help-center/time-date-critical/',
  rrsLink: 'https://www.xpo.com/help-center/rapid-remote-service/',
  holidayScheduleLink:
    'http://xpo.com/solutions/transportation/ltl-help-center/holiday-schedules-us-canada-and-puerto-rico',
  premiumServicesLink: 'https://www.xpo.com/help-center/premium-service-overview/',
  uspsLink: 'https://tools.usps.com/go/ZipLookupAction_input',
  cnpsLink: 'https://www.canadapost.ca/cpo/mc/personal/postalcode/fpc.jsf',
  fuelSurchargeLink: 'https://www.xpo.com/fuel-surcharge-table',
  linealFeetDisclaimerUrl:
    'https://www.xpo.com/cdn/download_files/site_1/page_2831/CNWY_199-AH.4_Tri-Fold_Effective_11_13_2023.pdf',
  humanResourcesUrl: 'https://theworknumber.com',
  xpoContactPage: 'https://www.xpo.com/contact-us/',
  cubicCapacityLink: 'https://www.xpo.com/cdn/download_files/site_1/page_2831/CNWY_199.AH_4.pdf',
  helpCenterSearch: 'https://www.xpo.com/help-center/search/?search_term=',
  accessorialRatesChargesReferenceGuideUrl: 'https://www.xpo.com/cdn/download_files/site_1/page_2831/CNWY_199.AH_4.pdf',
  legacyBaseUrl,
  legacyLegacyBaseUrl,
  legacyBolFromLink: `${legacyLegacyBaseUrl}/webapp/bol_template_app/BolRequester.jsp`,
  legacyFreightBillCopyFromLink: `${legacyLegacyBaseUrl}/webapp/corrections_app/shipments/freight_bill_copy/search.jsp`,
  legacyBolManageLink: `${legacyLegacyBaseUrl}/webapp/bol_template_app/BolArchiveManager.jsp`,
  legacyBolFromRQLink: `${legacyLegacyBaseUrl}/webapp/bol_template_app/BolCreateOnePage.jsp?dest=ebola&screenlocator=newmainmanage&FromRateQuote=Y`,
  registerUserLink: `${legacyLegacyBaseUrl}/webapp/membership_app/membershipSignupCompanySearch.do`,
  transitTimeMapLink: `${legacyLegacyBaseUrl}/webapp/servicecenter_app/ServiceCenterInfo/ServiceCenterTransitTimeFull.jsp?SIC=`,
  estimatedDeliveryDateDefinitionLink: `${legacyLegacyBaseUrl}/webapp/manifestrpts_p_app/Tracking/ShipmentStatusDefinitions.jsp#EstDlvr`,
  localServiceLink: `${legacyLegacyBaseUrl}/webapp/servicecenter_app/ServiceCenterInfo/ServiceCenterStart.jsp?dest=fastmap2000&logotoggle=S&SICInfo=Y&TTMapInfo=N`,
  fileClaimLink: `${legacyBaseUrl}/appjs/claims-external/claim-entry`,
  claimListLink: `${legacyLegacyBaseUrl}/webapp/membership_app/appRedirect/regRedirect.do?app=claims-external`,
  freightBillCopy: `${legacyLegacyBaseUrl}/webapp/corrections_app/shipments/freight_bill_copy/search.jsp`,
  freightBillCopyProSearch: `${legacyLegacyBaseUrl}/webapp/corrections_app/shipments/freight_bill_copy/show.jsp?dest=freightbillrs&PROnumber=`,
  imagingAppUrl: `${legacyLegacyBaseUrl}/webapp/imaging_app/imageretrieverequest.do?dest=fastdocview&`,
  shipmentNotificationLink: `${legacyBaseUrl}/appjs/shipment-notifications`,
  analyticsTrackingId: 'UA-134044452-12',
  tagManagerTrackingId: 'GTM-W4C29QZ',
  help_center: 'https://www.xpo.com/help-center/',
  terms_use: 'https://www.xpo.com/terms-of-use/',
  privacy_policy: 'http://xpo.com/privacy-policy',
  tariff_library: 'https://www.xpo.com/tariff-library/',
  fuel_surcharge: 'https://www.xpo.com/fuel-surcharge-table/',
  baseXPOPhoneNumber: '8007552728',
  passwordReminderForm: `${legacyLegacyBaseUrl}/webapp/membership_app/password_reminder/PasswordReminderForm.jsp`,
  supportEmail: 'LTLWebSupport@xpo.com',
  customerCareEmail: 'ltl.customercare@xpo.com',
  forceDynamicPricing: true, // TODO: Remove (For testing purposes only)
  userFakeEmail: 'guest@xpo.com',
  termsAndConditionsOnboardingPUR: 'https://www.xpo.com/cdn/files/s1/XPO_Online_Quoting_and_Booking_Terms_v1.0.pdf',
  cusmaLink: 'https://www.xpo.com/cdn/files/s8/CUSMA_Certification_of_Origin_2.pdf',
  usmcaLink: 'https://www.xpo.com/cdn/files/s8/USMCA_Certification_of_Origin_2.pdf',
});
